var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper d-flex flex-column align-center justify-center"},[_c('v-card',{staticClass:"mb-4 d-flex flex-column pa-4",attrs:{"max-width":"1400px","width":"100%","height":"100%"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"clearable":"","dense":"","outlined":"","label":"Κατηγορίες","items":_vm.categoryTypes,"item-text":"description","item-value":"cd","no-data-text":"Δεδομένα μη διαθέσιμα"},model:{value:(_vm.categoryType),callback:function ($$v) {_vm.categoryType=$$v},expression:"categoryType"}})],1)],1)],1),_c('v-btn',{staticClass:"ml-auto",attrs:{"depressed":"","color":"primary"},on:{"click":_vm.handler}},[_vm._v(" Αναζήτηση")])],1),_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%"}},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        },"headers":_vm.headers,"items":_vm.approveApplications.content,"server-items-length":_vm.approveApplications.elements,"options":_vm.options,"loading":_vm.tableLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.userName",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.person.firstName + " " + item.person.lastName)+" ")])]}},{key:"item.application",fn:function(ref){
        var item = ref.item;
return [(item.onlineApplication.description)?_c('span',[_vm._v(" "+_vm._s(item.onlineApplication.description)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.onlineApplication.commonOnlineApplication.description)+" ")])]}},{key:"item.creationDate",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.creationDate).toLocaleDateString("el")))])]}},{key:"item.flgPublish",fn:function(ref){
        var item = ref.item;
return [(item.flgPublish == 1)?_c('span',[_vm._v(" Εγκρίθηκε ")]):(item.flgPublish == -1)?_c('span',[_vm._v(" Απορρίφθηκε ")]):(item.flgPublish == 0)?_c('span',[_vm._v(" Εκκρεμεί απάντηση ")]):_vm._e()]}},{key:"item.replyAttachFile",fn:function(ref){
        var item = ref.item;
return [(item.replyAttachFile != null)?_c('v-btn',{staticClass:"pa-0",attrs:{"plain":"","color":"primary"},on:{"click":function($event){return _vm.onDownload(item.replyAttachFile.id)}}},[_vm._v(_vm._s(item.replyAttachFile.name)+" "),_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-download")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item, 'view')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Προβολή")])]),(
              item.onlineApplication.commonOnlineApplication.reportId !=
              undefined
            )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":"","loading":_vm.loader[index].loader},on:{"click":function($event){return _vm.onPrint(item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-printer ")])],1)]}}],null,true)},[_c('span',[_vm._v("Εκτύπωση")])]):_vm._e()]}}],null,true)})],1)],1),(_vm.dialog.type == 'view')?_c('v-dialog',{attrs:{"persistent":"","max-width":"1500px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'view')?_c('ViewSubmittedApplication',{attrs:{"application":_vm.dialog.application},on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }