<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
    >
      <v-card-title class="greyish--text font-weight-regular">
        Διαχείριση ρόλων εκτυπωτικού {{ this.reportRoles.description }}
      </v-card-title>
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="reportRoles.roles"
            :loading="tableLoader"
            :options.sync="options"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <!-- <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip> -->

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <div style="d-flex justify-center">
        <v-btn
          depressed
          color="primary ml-auto"
          class="mr-4"
          @click="openDialog(null, 'add')"
        >
          Δημιουργία
          <v-icon dense right>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          depressed
          color="light ml-auto"
          @click="$router.push(`/reports`)"
        >
          Πίσω
        </v-btn>
      </div>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddReportRole
        v-if="dialog.open && dialog.type == 'add'"
        :report="reportRoles"
        @close="closeDialog"
      />
    </v-dialog>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteReportRole
        v-if="dialog.open && dialog.type == 'delete'"
        :reportParameter="dialog.data"
        :report="reportRoles"
        @close="closeDialog"
      />
    </v-dialog>
    <!--     <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteReportParameter
        v-if="dialog.open && dialog.type == 'delete'"
        :reportParameter="dialog.data"
        @close="closeDialog"
      />
    </v-dialog> -->
  </div>
</template>


<script>
import axios from "axios";
import AddReportRole from "../../components/Reports/AddReportRole.vue";
import DeleteReportRole from "../../components/Reports/DeleteReportRole.vue";
// import DeleteReportParameter from "../../components/Reports/DeleteReportParameter.vue";
// import EditReportParameter from "../../components/Reports/EditReportParameter.vue";

export default {
  name: "ReportRoles",
  components: {
    AddReportRole,
    DeleteReportRole,
  },
  created() {
    this.fetchReportRoles();
  },
  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Ρόλος",
          value: "description",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      dialog: {
        open: false,
        type: null,
        data: null,
      },
      reportRoles: [],
    };
  },
  computed: {},
  methods: {
    async fetchReportRoles() {
      try {
        this.tableLoader = true;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/report/${this.$route.params.id}`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
            },
          }
        );
        this.reportRoles = res.data;
        this.tableLoader = false;
      } catch (e) {
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },
    openDialog(item, type) {
      this.dialog = {
        data: item,
        open: true,
        type,
      };
    },
    closeDialog() {
      this.dialog = {
        data: null,
        open: false,
        type: null,
      };

      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.fetchReportRoles();
    },
  },
};
</script>