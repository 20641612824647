import axios from 'axios'
export default {
    state: {
        schoolTypes: []
    },

    mutations: {
        getSchoolTypes(state, schoolTypes) {
            state.schoolTypes = schoolTypes
        }
    },

    actions: {
        async getSchoolTypes({ commit, dispatch }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/school/type/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                        deleted: payload.deleted
                    }
                })
                commit('getSchoolTypes', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async addSchoolType({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/school/type`, payload)
                await dispatch('getSchoolTypes', {
                    deleted: rootState.base.filters.deleted,
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.schoolTypes.elements : rootState.base.filters.itemsPerPage,
                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία τύπου σχολείου ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

            }
        },

        async editSchoolType({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/school/type/${payload.schoolTypeId}`, payload.content)
                await dispatch('getSchoolTypes', {
                    deleted: rootState.base.filters.deleted,
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.schoolTypes.elements : rootState.base.filters.itemsPerPage,
                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία τύπου σχολείου ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async deleteSchoolType({ commit, dispatch, rootState, state }, schoolTypeId) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/school/type/${schoolTypeId}`)
                if (state.schoolTypes.limit == 1 && state.schoolType.page != 1) {

                    commit('changePage', true)
                } else {
                    await dispatch('getSchoolTypes', {
                        deleted: rootState.base.filters.deleted,
                        page: rootState.base.filters.page,
                        limit: rootState.base.filters.itemsPerPage == -1 ? state.schoolTypes.elements : rootState.base.filters.itemsPerPage,

                    })
                }
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή τύπου σχολής ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },
    }
}