<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
      v-if="!dialog.open"
    >
      <v-card class="d-flex flex-column mb-4 pa-4">
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                dense
                outlined
                clearable
                label="Κατηγορίες"
                v-model="currentCategoryType"
                :items="getDataStaticList(159)"
                item-text="description"
                item-value="cd"
                no-data-text="Δεδομένα μη διαθέσιμα"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn depressed color="primary" class="ml-auto" @click="onSearch"
          >Αναζήτηση</v-btn
        >
      </v-card>

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="filtersSet ? configureApplications.content : []"
            :loading="tableLoader"
            :options.sync="options"
            :server-items-length="configureApplications.elements"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'view')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'assign')">
                    <v-icon color="success" small v-bind="attrs" v-on="on">
                      mdi-clipboard-arrow-left-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span
                  >Σύνδεση με {{ appId == 1 ? "σχολή" : getFieldName() }}</span
                >
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onActionsView(item)">
                    <v-icon color="success" small v-bind="attrs" v-on="on">
                      mdi-gesture-tap
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ενέργειες</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onRequirementView(item)">
                    <v-icon color="success" small v-bind="attrs" v-on="on">
                      mdi-asterisk
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προαπαιτούμενα πτυχία/πιστοποιητικά</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary ml-auto"
            @click="openDialog(null, 'add')"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'view'"
      max-width="1600px"
      scrollable
    >
      <ViewApplication
        v-if="dialog.open && dialog.type == 'view'"
        :application="dialog.application"
        :type="'view'"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="1800px"
      scrollable
    >
      <AddApplication
        v-if="dialog.open && dialog.type == 'add'"
        :application="dialog.application"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="1600px"
      scrollable
    >
      <EditApplication
        v-if="dialog.open && dialog.type == 'edit'"
        :application="dialog.application"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteApplication
        v-if="dialog.open && dialog.type == 'delete'"
        :application="dialog.application"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'action'"
      max-width="800px"
      scrollable
    >
      <ActionsModal
        v-if="dialog.open && dialog.type == 'action'"
        :application="dialog.application"
        @close="closeDialog"
      />
    </v-dialog>

    <!-- <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'assign'"
      max-width="900px"
      scrollable
    > -->
    <AssignApplication
      v-if="dialog.open && dialog.type == 'assign'"
      :application="dialog.application"
      @close="closeDialog"
    />
    <!-- </v-dialog> -->
  </div>
</template>

<script>
import ViewApplication from "../../components/Applications/ViewApplication.vue";
import DeleteApplication from "../../components/Applications/DeleteApplication.vue";
import AddApplication from "../../components/Applications/AddApplication.vue";
import EditApplication from "../../components/Applications/EditApplication.vue";
import AssignApplication from "../../components/Applications/AssignApplication1.vue";
import Helpers from "./../../helpers/helpers";

import { mapState, mapMutations, mapActions } from "vuex";
export default {
  components: {
    ViewApplication,
    DeleteApplication,
    AddApplication,
    EditApplication,
    AssignApplication,
  },

  async created() {},

  data() {
    return {
      tableLoader: false,
      filtersSet: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Κατηγοριοποίηση",
          value: "categoryType.description",
          sortable: false,
        },
        {
          text: "Περιγραφή",
          value: "description",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        open: false,
        type: null,
        role: null,
      },
    };
  },
  watch: {
    async options() {
      //if (this.currentCategoryType != null)
      await this.handler();
    },
  },

  computed: {
    ...mapState({
      categoryTypes: (state) => state.configure_applications.categoryTypes,
      configureApplications: (state) =>
        state.configure_applications.configureApplications,
      appId: (state) => state.auth.appId,
      pageChanged: (state) => state.base.pageChanged,
      staticList: (state) => state.auth.userInfo.staticView,
    }),

    currentCategoryType: {
      get() {
        return this.$store.state.configure_applications.currentCategoryType;
      },
      set(value) {
        this.setCurrentCategoryType(value);
      },
    },
  },

  methods: {
    ...mapMutations([
      "setFilters",
      "changePage",
      "setCurrentCommonOnlineApplication",
      "setCurrentCategoryType",
    ]),

    getFieldName() {
      return Helpers.getFieldName(1);
    },

    ...mapActions(["getConfigureApplications", "getCategoryTypes"]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    openDialog(application, type) {
      this.dialog = {
        application,
        open: true,
        type,
      };
    },
    closeDialog() {
      this.dialog = {
        application: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        await this.getConfigureApplications({
          page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
          limit:
            this.options.itemsPerPage == -1 ? null : this.options.itemsPerPage,
          appId: this.appId,
          categoryType: this.currentCategoryType,
        });
        this.tableLoader = false;
        this.filtersSet = true;
      } catch (e) {
        console.log(e);
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    onActionsView(application) {
      this.setCurrentCommonOnlineApplication(application);
      this.$router.push(`${this.$route.path}/${application.id}/actions`);
    },
    onRequirementView(application) {
      this.setCurrentCommonOnlineApplication(application);
      this.$router.push(`${this.$route.path}/${application.id}/requirements`);
    },
    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>