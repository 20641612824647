
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-breadcrumbs :items="breadcrumb_items" divider=">"></v-breadcrumbs>
      <v-card class="mb-4 pa-4 d-flex flex-column">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="searchText"
              label="Όνομα"
              @keyup.enter="handler"
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              dense
              outlined
              v-model="category"
              clearable
              hide-details="auto"
              type="text"
              :items="getDataStaticList(155)"
              item-text="description"
              item-value="cd"
              label="Κατηγορία"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-btn class="ml-auto mt-2" depressed color="primary" @click="handler">
          Αναζήτηση
        </v-btn>
      </v-card>
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="shipVessels.content"
            :options.sync="options"
            :server-items-length="shipVessels.elements"
            :loading="tableLoader"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary"
            class="mr-2"
            @click="openDialog(null, 'add')"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="800px"
      scrollable
    >
      <AddShipCompanyVessel
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="800px"
      scrollable
    >
      <EditShipCompanyVessel
        v-if="dialog.open && dialog.type == 'edit'"
        :shipCompanyVessel="dialog.shipCompanyVessel"
        @close="closeDialog"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteShipCompanyVessel
        v-if="dialog.open && dialog.type == 'delete'"
        :shipCompanyVessel="dialog.shipCompanyVessel"
        @close="closeDialog"
        @handler="handler"
        :shipVessels="shipVessels"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AddShipCompanyVessel from "../../components/ShipCompanyVesselsModals/AddShipCompanyVessel.vue";
import EditShipCompanyVessel from "../../components/ShipCompanyVesselsModals/EditShipCompanyVessel.vue";
import DeleteShipCompanyVessel from "../../components/ShipCompanyVesselsModals/DeleteShipCompanyVessel.vue";
import axios from "axios";
export default {
  components: {
    AddShipCompanyVessel,
    EditShipCompanyVessel,
    DeleteShipCompanyVessel,
  },

  created() {
    this.breadcrumb_items.push(
      {
        text: `${this.currentShipCompany.name}`,
        disabled: false,
        link: true,
        exact: true,
        to: "/ship_companies",
      },
      {
        text: `Πλοία`,
        disabled: true,
      }
    );
  },

  data() {
    return {
      shipVessels: [],
      searchText: null,
      category: null,
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      breadcrumb_items: [],

      headers: [
        {
          text: "Όνομα",
          value: "name",
          sortable: false,
        },

        {
          text: "Κατηγορία",
          value: "category.description",
          sortable: false,
        },

        {
          text: "Κόροι ολικής χωρητικότητας",
          value: "kox",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        shipCompany: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      await this.handler();
    },
  },

  computed: {
    ...mapState({
      currentShipCompany: (state) => state.ship_companies.currentShipCompany,
      pageChanged: (state) => state.base.pageChanged,
      staticList: (state) => state.auth.userInfo.staticView,
    }),
  },

  methods: {
    ...mapMutations(["changePage"]),

    async handler() {
      try {
        this.tableLoader = true;

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/ship/company/vessel/list`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              shipCompanyId: this.$route.params.ship_company_id,
              searchText: this.searchText != "" ? this.searchText : null,
              cd: this.category,
            },
          }
        );

        this.shipVessels = res.data;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.shipVessels = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    openDialog(shipCompanyVessel, type) {
      this.dialog = {
        shipCompanyVessel,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        shipCompanyVessel: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.handler();
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>