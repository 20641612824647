<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία επιτηρητή σε τμήμα
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="d-flex flex-column pa-4">
      <v-progress-circular
        v-if="cardLoader"
        indeterminate
        color="primary"
        class="align-self-center ma-4"
        :size="50"
      ></v-progress-circular>
      <v-form v-else @submit.prevent="onSubmit" class="pa-4">
        <v-select
          dense
          outlined
          v-model="classExamId"
          hide-details="auto"
          type="text"
          class="mb-4"
          :items="listExamClasses"
          item-text="description"
          item-value="cd"
          :error-messages="classExamIdErrors"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Τμήμα
          </template>
        </v-select>

        <v-select
          dense
          outlined
          v-model="teacher"
          hide-details="auto"
          type="text"
          class="mb-4"
          :items="teachers.content"
          :item-text="(el) => el.firstName + ' ' + el.lastName"
          item-value="personId"
          :error-messages="teacherErrors"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Εκπαιδευτικός
          </template>
        </v-select>

        <v-select
          v-model="type"
          class="mb-4"
          :error-messages="typeErrors"
          :items="getDataStaticList(156)"
          item-text="description"
          hide-details="auto"
          item-value="cd"
          dense
          outlined
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span> Τύπος
          </template>
        </v-select>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Αποθήκευση</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  props: ["classExamTeacher"],

  async created() {
    try {
      await this.getTeachers({
        id: this.facultyId,
        page: 0,
      });
      let x =
        this.classExamTeacher.edu_class_exam_hd_id.toString() +
        "." +
        this.classExamTeacher.edu_lessons_id.toString();

      this.classExamId = x;

      this.teacher = this.classExamTeacher.person_id;
      this.type = this.classExamTeacher.tpy;

      this.cardLoader = false;
    } catch (e) {}
  },

  data() {
    return {
      cardLoader: true,

      loader: false,
      type: null,
      classExamId: null,
      teacher: null,
    };
  },

  validations: {
    classExamId: {
      required,
    },

    teacher: {
      required,
    },

    type: {
      required,
    },
  },

  computed: {
    ...mapState({
      teachers: (state) => state.teachers.teachers,
      listExamClasses: (state) => state.generic_filter.listExamClasses,
      examClassId: (state) => state.generic_filter.examClassId,
      staticList: (state) => state.auth.userInfo.staticView,
      facultyId: (state) => state.generic_filter.facultyId,
    }),
    classExamIdErrors() {
      const errors = [];
      if (!this.$v.classExamId.$dirty) return errors;
      if (!this.$v.classExamId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    teacherErrors() {
      const errors = [];
      if (!this.$v.teacher.$dirty) return errors;
      if (!this.$v.teacher.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    typeErrors() {
      const errors = [];
      if (!this.$v.type.$dirty) return errors;
      if (!this.$v.type.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["editClassExamTeacher", "getTeachers"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          personId: this.teacher,
          type: this.type,
          classExamId: this.classExamId.split(".")[0],
        };
        await this.editClassExamTeacher({
          classExamTeacherId: this.classExamTeacher.id,
          content,
        });
        this.loader = false;
        await this.$emit("handler");

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>