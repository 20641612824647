
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="5"
        @onSearchAction="onSearch"
        :requiredFields="{
          masterSchool: true,
          school: true,
        }"
      />

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="!filtersSet ? [] : personAwards.hashContent"
            :options.sync="options"
            :server-items-length="personAwards.elements"
            :loading="tableLoader"
          >
            <template v-slot:[`item.issue_date`]="{ item }">
              <span>{{
                new Date(item.issue_date).toLocaleDateString("el")
              }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
          <div class="custom-card-buttons">
            <v-btn
              depressed
              color="primary"
              class="mr-2"
              :disabled="!filtersSet"
              @click="openDialog(null, 'add')"
            >
              Δημιουργία
              <v-icon dense right>mdi-plus</v-icon>
            </v-btn>          
          </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddPersonAward
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="500px"
      scrollable
    >
      <EditPersonAward
        v-if="dialog.open && dialog.type == 'edit'"
        :personAward="dialog.personAward"
        @close="closeDialog"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeletePersonAward
        v-if="dialog.open && dialog.type == 'delete'"
        :personAward="dialog.personAward"
        @close="closeDialog"
        @handler="handler"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import AddPersonAward from "../../components/PersonAwardsModals/AddPersonAward.vue";
import EditPersonAward from "../../components/PersonAwardsModals/EditPersonAward.vue";
import DeletePersonAward from "../../components/PersonAwardsModals/DeletePersonAward.vue";

import axios from "axios";
export default {
  components: {
    AddPersonAward,
    EditPersonAward,
    DeletePersonAward,
    GenericFilter,
  },

  async created() {
    try {
      if (this.semesterId != null) await this.handler();
    } catch (e) {
      throw e;
    }
  },

  data() {
    return {
      personAwards: [],
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      filtersSet: false,
      headers: [
        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "last_name",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "first_name",
          sortable: false,
        },

        {
          text: "Περιγραφή",
          value: "award",
          sortable: false,
        },

        {
          text: "Ημερομηνία δημιουργίας",
          value: "issue_date",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        personAward: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },
  },

  computed: {
    ...mapState({
      progTrips: (state) => state.prog_trips.progTrips,
      pageChanged: (state) => state.base.pageChanged,

      facultyId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      academicYearId: (state) => state.generic_filter.academicYearId,
      semesterId: (state) => state.generic_filter.semesterId,
      classId: (state) => state.generic_filter.classId,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/listForAward`,
          {
            params: {
              masterSchoolId: this.facultyId,
              schoolId: this.schoolId,
              yearId: this.academicYearId,
              progTrainPeriodId: this.semesterId,
              classId: this.classId,

              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
            },
          }
        );

        this.personAwards = res.data;

        if (!this.filtersSet) this.filtersSet = true;

        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.personAwards = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    openDialog(personAward, type) {
      this.dialog = {
        personAward,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        personAward: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>