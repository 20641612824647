var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-4"},[_c('v-card-text',[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
        'items-per-page-text': 'Σειρές ανά σελίδα',
        'items-per-page-all-text': 'Όλες',
        'items-per-page-options': [5, 10, 15, -1],
      },"headers":_vm.teacherExperienceHeaders,"items":_vm.teacherExperience.content,"server-items-length":_vm.teacherExperience.elements,"options":_vm.teacherExperienceOptions,"loading":_vm.teacherExperienceTableLoader},on:{"update:options":function($event){_vm.teacherExperienceOptions=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item, 'view')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Προβολή")])])]}},{key:"item.startDate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.startDate).toLocaleDateString("el")))])]}},{key:"item.endDate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.endDate).toLocaleDateString("el")))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }