var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"primary"}},[_vm._v(" Επεξεργασία απουσίας "),_c('v-btn',{staticClass:"ml-auto",attrs:{"depressed":"","icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-4 d-flex flex-column"},[(_vm.cardLoader)?_c('v-progress-circular',{staticClass:"align-self-center ma-4",attrs:{"indeterminate":"","color":"primary","size":50}}):_c('v-form',{staticClass:"pa-4",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Σπουδαστής:")]),_c('p',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.absence.firstName)+" "+_vm._s(_vm.absence.lastName)+" ")])]),(_vm.schoolDetails != null && _vm.schoolDetails.absence.cd == '1')?_c('v-select',{staticClass:"mb-4",attrs:{"items":_vm.attendLessons.content,"item-text":"lesson.description","hide-details":"auto","item-value":"id","dense":"","outlined":"","loading":_vm.lessonLoader,"disabled":_vm.personId == null || _vm.lessonLoader,"no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Μάθημα ")]},proxy:true}],null,false,732350047),model:{value:(_vm.attendLessonId),callback:function ($$v) {_vm.attendLessonId=$$v},expression:"attendLessonId"}}):_vm._e(),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"dense":"","outlined":"","hide-details":"auto","error-messages":_vm.absDateErrors,"disabled":_vm.personId == null || _vm.lessonLoader},on:{"blur":function($event){_vm.absDate = _vm.parseDate(_vm.absDateFormatted, 'absDate')}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-calendar")])]},proxy:true},{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Ημερομηνία απουσίας ")]},proxy:true}],null,true),model:{value:(_vm.absDateFormatted),callback:function ($$v) {_vm.absDateFormatted=$$v},expression:"absDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":"el","scrollable":"","hide-details":"auto"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.absDate),callback:function ($$v) {_vm.absDate=$$v},expression:"absDate"}})],1),_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","outlined":"","hide-details":"auto","error-messages":_vm.absHoursErrors,"disabled":_vm.personId == null || _vm.lessonLoader},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Ώρες απουσιών ")]},proxy:true}]),model:{value:(_vm.absHours),callback:function ($$v) {_vm.absHours=$$v},expression:"absHours"}}),(
          _vm.progTrainDetails.trainingPeriod.dailyHours != null &&
          _vm.progTrainDetails.trainingPeriod.dailyHours != undefined &&
          _vm.progTrainDetails.trainingPeriod.dailyHours != 0
        )?_c('div',[_c('div',{staticClass:"d-flex flex-direction-row"},_vm._l((_vm.progTrainDetails.trainingPeriod.dailyHours),function(j){return _c('v-checkbox',{key:j,staticClass:"ml-1",attrs:{"label":j + 'η',"value":String(j)},model:{value:(_vm.absPerHour),callback:function ($$v) {_vm.absPerHour=$$v},expression:"absPerHour"}})}),1),(_vm.absPerHourErrors.length > 0)?_c('span',{staticStyle:{"color":"red","margin-bottom":"1px"}},[_vm._v(_vm._s(_vm.absPerHourErrors[0]))]):_vm._e()]):_vm._e(),_c('v-select',{staticClass:"mb-4 mt-1",attrs:{"error-messages":_vm.absTypeErrors,"items":_vm.getDataStaticList(100),"hide-details":"auto","item-text":"description","item-value":"cd","dense":"","outlined":"","disabled":_vm.personId == null || _vm.lessonLoader,"no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Δικαιολογημένη ")]},proxy:true}]),model:{value:(_vm.absType),callback:function ($$v) {_vm.absType=$$v},expression:"absType"}}),(_vm.absType == '1')?_c('v-textarea',{attrs:{"dense":"","outlined":"","hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Σχόλιο ")]},proxy:true}],null,false,403368814),model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}):_vm._e(),_c('div',{staticClass:"d-flex justify-center mt-2"},[_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Ακύρωση")]),_c('v-btn',{staticClass:"ml-4",attrs:{"depressed":"","color":"success","type":"submit","loading":_vm.loader}},[_vm._v("Αποθήκευση")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }