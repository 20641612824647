<template>
  <v-card class="mb-2 pa-2">
    <v-card-text class="d-flex flex-column">
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-select
            dense
            outlined
            clearable
            v-model="selectedFaculty"
            :items="listFaculty"
            item-text="description"
            hide-details="auto"
            item-value="cd"
            @change="onSetFaculty"
            :error-messages="selectedFacultyErrors"
            v-if="this.level >= 1"
          >
            <template v-slot:label>
              <span v-if="requiredFields.masterSchool" class="error--text"
                >*</span
              >{{ appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης" }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            dense
            outlined
            clearable
            :label="requiredFields.school ? '* Σχολή' : 'Σχολή'"
            v-model="selectedSchool"
            :items="listSchool"
            :error-messages="selectedSchoolErrors"
            hide-details="auto"
            item-text="description"
            item-value="cd"
            @change="onSetSchool"
            no-data-text="Δεδομένα μη διαθέσιμα"
            v-if="this.level >= 2"
          >
            <template v-slot:label>
              <span v-if="requiredFields.masterSchool" class="error--text"
                >*</span
              >{{ appId == 1 ? "Σχολή" : getFieldName() }}
            </template></v-select
          >
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            dense
            outlined
            clearable
            :label="
              requiredFields.year ? '* Ακαδημαϊκό έτος' : 'Ακαδημαϊκό έτος'
            "
            v-model="selectedAcademicYear"
            :items="listAcademicYear"
            hide-details="auto"
            :error-messages="selectedAcademicYearErrors"
            item-text="description"
            item-value="cd"
            no-data-text="Δεδομένα μη διαθέσιμα"
            v-if="this.level >= 3"
          >
            <template v-slot:label>
              <span v-if="requiredFields.year" class="error--text">*</span
              >{{ appId == 1 ? "Ακαδημαϊκό έτος" : "Εκπαιδευτικό έτος" }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="selectedDateFormatted"
                prepend-inner-icon="mdi-calendar"
                :error-messages="selectedDateFormattedErrors"
                hide-details="auto"
                class="mb-4"
                @blur="
                  selectedDate = parseDate(selectedDateFormatted, 'endDate')
                "
              >
                <template v-slot:prepend-inner>
                  <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                </template>
                <template v-slot:label>
                  <span class="error--text">*</span>
                  Ημερομηνία
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              locale="el"
              v-model="selectedDate"
              scrollable
              hide-details="auto"
              @input="menu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-btn class="ml-auto" depressed color="primary" @click="this.onSearch">
        Αναζήτηση
      </v-btn>
    </v-card-text>
  </v-card>
</template>


<script>
import { mapState } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import Helpers from "../../helpers/helpers";

import axios from "axios";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  name: "ClosurePeriodFilter",
  props: {
    level: Number,
    onSearchAction: Function,
    listActive: Number,
    requiredFields: Object,
  }, //level: level of lists,onSearchAction: function on click search,listActive: prop to know the end point group id (only active or all data)
  async created() {
    await this.loadFaculties();
  },
  data() {
    return {
      listFaculty: [],
      listSchool: [],
      listAcademicYear: [],

      selectedFaculty: null,
      selectedSchool: null,
      selectedAcademicYear: null,
      selectedDateFormatted: null,
      selectedDate: null,
      menu: null,
      dates: [],
    };
  },
  validations: {
    selectedFaculty: {
      required: requiredIf(function () {
        return this.requiredFields?.masterSchool;
      }),
    },
    selectedSchool: {
      required: requiredIf(function () {
        return this.requiredFields?.school;
      }),
    },
    selectedAcademicYear: {
      required: requiredIf(function () {
        return this.requiredFields?.year;
      }),
    },
    selectedDateFormatted: {
      required: requiredIf(function () {
        return this.requiredFields?.dateFormatted;
      }),
    },
    selectedDate: {
      required: requiredIf(function () {
        return this.requiredFields?.date;
      }),
    },
  },
  watch: {
    selectedDate(val) {
      this.selectedDateFormatted = this.formatDate(this.selectedDate);
    },
  },
  methods: {
    async loadFaculties() {
      let result = await this.fetchDataDynamic(
        this.listActive == 1 ? 2020 : 2000,
        null
      );
      this.listFaculty = result.data;
      if (result.data.length == 1) {
        this.selectedFaculty = result.data[0].cd;
        this.onSetFaculty(result.data[0].cd);
      }
    },
    getFieldName() {
      return Helpers.getFieldName(1);
    },
    async onSetFaculty(value) {
      // call end point tou get schools 2001
      let result = await this.fetchDataDynamic(
        this.listActive == 1 ? 2021 : 2001,
        value
      );
      this.listSchool = result.data;
      if (result.data.length == 1) {
        this.selectedSchool = result.data[0].cd;
        this.onSetSchool(result.data[0].cd);
      }
    },
    async onSetSchool(value) {
      let result = await this.fetchDataDynamic(
        this.listActive == 1 ? 2022 : 2002,
        value
      );
      this.listAcademicYear = result.data;
      if (result.data.length == 1) {
        this.selectedAcademicYear = result.data[0].cd;
      }
    },

    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },
    onSearch() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      let payload = {
        facultyId: this.selectedFaculty,
        schoolId: this.selectedSchool,
        academicYearId: this.selectedAcademicYear,
        date: this.selectedDate,
      };
      this.$store.dispatch("setAllFilter", payload).then(() => {
        this.$emit("onSearchAction");
      });
    },
    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "endDate") {
          this.selectedDate = null;
          this.selectedDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  computed: {
    ...mapState({
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
    }),

    selectedFacultyErrors() {
      const errors = [];
      if (!this.$v.selectedFaculty.$dirty) return errors;
      if (!this.$v.selectedFaculty.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedSchoolErrors() {
      const errors = [];
      if (!this.$v.selectedSchool.$dirty) return errors;
      if (!this.$v.selectedSchool.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedAcademicYearErrors() {
      const errors = [];
      if (!this.$v.selectedAcademicYear.$dirty) return errors;
      if (!this.$v.selectedAcademicYear.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedDateFormattedErrors() {
      const errors = [];
      if (!this.$v.selectedDateFormatted.$dirty) return errors;
      if (!this.$v.selectedDateFormatted.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },
};
</script>

<style>
</style>