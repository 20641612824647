var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper d-flex align-center justify-center"},[_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('v-card',{staticClass:"mb-2 pa-2"},[_c('v-card-text',{staticClass:"d-flex flex-column"},[_c('v-row',[_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"dense":"","outlined":"","hide-details":"auto"},on:{"blur":function($event){_vm.dateFrom = _vm.parseDate(_vm.dateFromFormatted, 'dateFrom')}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-calendar")])]},proxy:true},{key:"label",fn:function(){return [_vm._v(" Ημερομηνία Από ")]},proxy:true}],null,true),model:{value:(_vm.dateFromFormatted),callback:function ($$v) {_vm.dateFromFormatted=$$v},expression:"dateFromFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":"el","hide-details":"auto","scrollable":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)],1),_c('v-col',[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"dense":"","outlined":"","hide-details":"auto"},on:{"blur":function($event){_vm.dateTo = _vm.parseDate(_vm.dateToFormatted, 'dateTo')}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-calendar")])]},proxy:true},{key:"label",fn:function(){return [_vm._v(" Ημερομηνία Έως ")]},proxy:true}],null,true),model:{value:(_vm.dateToFormatted),callback:function ($$v) {_vm.dateToFormatted=$$v},expression:"dateToFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"el","hide-details":"auto","scrollable":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1),_c('v-col',[_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","label":"Χρήστης","outlined":""},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1)],1),_c('v-row',{staticClass:"ml-auto"},[_c('v-col',{staticClass:"ml-auto"},[_c('v-btn',{staticClass:"mr-2 mt-2",attrs:{"depressed":"","color":"green","loading":_vm.loader,"disabled":_vm.isDisabled},on:{"click":_vm.printData}},[_vm._v(" Εκτύπωση ")]),_c('v-btn',{staticClass:"ml-auto mt-2",attrs:{"depressed":"","color":"primary"},on:{"click":_vm.fetchAuditing}},[_vm._v(" Αναζήτηση ")])],1)],1)],1)],1),_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          },"headers":_vm.headers,"items":_vm.data.content,"server-items-length":_vm.data.elements,"options":_vm.options,"loading":_vm.tableLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdByLastname + " " + item.createdByFirstname)+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }