import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import base from './base'
import auth from './auth'
import roles from './roles'
import pistopoihseis from './pistopoihseis'
import users from './users'
import faculties from './faculties'
import schools from './schools'
import academic_years from './academic_years'
import exams from './exams'
import semesters from './semesters'
import supporting_documents from './supporting_documents'
import certificates from './certificates'
import professors_categories from './professors_categories'
import courses from './courses'
import scheduling_exams from './scheduling_exams'
import scheduling_semesters from './scheduling_semesters'
import notifications from './notifications'
import applications from './applications'
import configure_applications from './configure_applications'
import teachers from './teachers'
import students from './students'
import exam_results from './exam_results'
import approve_applications from './approve_applications'
import school_rooms from './school_rooms'
import classes from './classes'
import announcements from './announcements'
import lists from './lists'
import degrees from './degrees'
import files from './files'
import absences from './absences'
import attend_lessons from './attend_lessons'
import grades from './grades'
import ship_companies from './ship_companies'
import prog_trips from './prog_trips'
import school_types from './school_types'
import libraries from './libraries'
import books from './books'
import lending_books from './lending_books'
import user_roles from './user_roles'
import class_exam_teachers from './class_exam_teachers'
import class_exam_persons from './class_exam_persons'
import errors from './errors'


import generic_filter from './genericFilter'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    base,
    auth,
    roles,
    users,
    pistopoihseis,
    faculties,
    schools,
    academic_years,
    exams,
    semesters,
    supporting_documents,
    certificates,
    professors_categories,
    courses,
    scheduling_exams,
    scheduling_semesters,
    notifications,
    configure_applications,
    teachers,
    students,
    exam_results,
    applications,
    approve_applications,
    generic_filter,
    school_rooms,
    classes,
    announcements,
    lists,
    degrees,
    files,
    absences,
    attend_lessons,
    grades,
    ship_companies,
    prog_trips,
    school_types,
    libraries,
    books,
    lending_books,
    user_roles,
    class_exam_teachers,
    class_exam_persons,
    errors
  },
  plugins: [createPersistedState()]

})
