var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"component-wrapper d-flex align-center justify-center",attrs:{"color":"transparent"}},[_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('ExamFilter',{key:_vm.query,attrs:{"level":5,"requiredFields":{
        masterSchool: true,
        school: true,
        examYear: true,
        progExamPeriod: true,
        examClass: true,
      }},on:{"onSearchAction":_vm.onSearch}}),_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          },"headers":_vm.getHeaders,"items":_vm.studentsWithGrades,"options":_vm.options,"server-items-length":_vm.examGrades.elements,"loading":_vm.tableLoader,"show-select":"","item-key":"person_id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
          var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":!item.isSelectable,"value":item,"hide-details":""},model:{value:(_vm.selectedExamGrades),callback:function ($$v) {_vm.selectedExamGrades=$$v},expression:"selectedExamGrades"}})]}}],null,true),model:{value:(_vm.selectedExamGrades),callback:function ($$v) {_vm.selectedExamGrades=$$v},expression:"selectedExamGrades"}})],1),_c('div',{staticClass:"custom-card-buttons"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"success","disabled":_vm.selectedExamGrades.length == 0,"loading":_vm.approveLoader},on:{"click":_vm.onMassApprove}},[_vm._v(" Μαζικός έλεγχος ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }