<template>
  <v-card
    color="transparent"
    class="component-wrapper d-flex align-center justify-center"
  >
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <ExamFilter
        :level="5"
        :key="query"
        @onSearchAction="onSearch"
        :requiredFields="{
          masterSchool: true,
          school: true,
          examYear: true,
          progExamPeriod: true,
          examClass: true,
        }"
      />

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="getHeaders"
            :items="studentsWithGrades"
            :options.sync="options"
            :server-items-length="examGrades.elements"
            :loading="tableLoader"
            v-model="selectedExamGrades"
            show-select
            item-key="person_id"
          >
            <template v-slot:[`item.data-table-select`]="{ item }">
              <v-checkbox
                :disabled="!item.isSelectable"
                v-model="selectedExamGrades"
                :value="item"
                hide-details
              />
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="success"
            class="mr-2"
            @click="onMassApprove"
            :disabled="selectedExamGrades.length == 0"
            :loading="approveLoader"
          >
            Μαζικός έλεγχος
          </v-btn>
        </div>
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ExamFilter from "../../components/GenericFilter/ExamFilter.vue";

import { required } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  components: {
    ExamFilter,
  },

  data() {
    return {
      tableLoader: false,
      query: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      gradeList: [],
      edit: false,

      filtersSet: false,

      lessonInfo: null,
      menu: false,
      menu1: false,
      dateMenu: false,
      exmDate: null,
      exmDateFormatted: null,
      saveLoader: false,
      selectedExamGrades: [],
      approveLoader: false,
      examGrades: [],
      headers: [
        {
          text: "ΑΜ",
          value: "student_school_code",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "last_name",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "first_name",
          sortable: false,
        },

        {
          text: "Πρόοδος",
          value: "oral_grade",
          sortable: false,
        },

        {
          text: "Βαθμολογία",
          value: "written",
          sortable: false,
        },
      ],
      headersOnlyWritten: [
        {
          text: "ΑΜ",
          value: "student_school_code",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "last_name",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "first_name",
          sortable: false,
        },

        {
          text: "Βαθμολογία",
          value: "written",
          sortable: false,
        },
      ],
      headersWrittenAndWorkshop: [
        {
          text: "ΑΜ",
          value: "student_school_code",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "last_name",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "first_name",
          sortable: false,
        },

        {
          text: "Πρακτικά",
          value: "workshop",
          sortable: false,
        },

        {
          text: "Βαθμολογία",
          value: "written",
          sortable: false,
        },
      ],
      dialog: {
        grade: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },
    $route() {
      this.query = this.$route.params.id;
    },
  },

  validations: {
    exmDate: {
      required,
    },
  },

  computed: {
    ...mapState({
      staticList: (state) => state.auth.userInfo.staticView,
      pageChanged: (state) => state.base.pageChanged,

      masterSchoolId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      examYearId: (state) => state.generic_filter.examYearId,
      progExamPeriodId: (state) => state.generic_filter.progExamPeriodId,
      examClassId: (state) => state.generic_filter.examClassId,
      examLessonId: (state) => state.generic_filter.examLessonId,
    }),

    studentsWithGrades() {
      return this.examGrades?.hashContent?.map((x) => {
        if (x.written != null)
          return {
            ...x,
            isSelectable: true,
          };
        else
          return {
            ...x,
            isSelectable: false,
          };
      });
    },

    exmDateErrors() {
      const errors = [];
      if (!this.$v.exmDate.$dirty) return errors;
      if (!this.$v.exmDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    getHeaders() {
      if (this.lessonInfo != null) {
        return this.lessonInfo.examType.cd == "3"
          ? this.headers
          : this.lessonInfo.examType.cd == "1"
          ? this.headersOnlyWritten
          : this.lessonInfo.examType.cd == "5"
          ? this.headersWrittenAndWorkshop
          : this.headers;
      } else {
        return this.headersOnlyWritten;
      }
    },
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["addStudentGrade", "getAttendLessons", "approveExamGrades"]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        // get lesson info
        const resLesson = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/lesson/${
            this.examClassId.split(".")[1]
          }`,
          {
            params: {
              page: 0,
            },
          }
        );
        this.lessonInfo = resLesson.data;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/listForGrade/written`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              masterSchoolId: this.masterSchoolId,
              schoolId: this.schoolId,
              yearId: this.examYearId,
              // lessonId: this.examLessonId,
              classId: this.examClassId.split(".")[0],
              approved: false,
              progExamPeriodId: this.progExamPeriodId,
            },
          }
        );

        this.examGrades = res.data;

        this.gradeList = [];
        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        for (const student of this.examGrades.hashContent) {
          //for every student
          if (student.oral != null) {
            this.gradeList.push({
              classExamPersonId: student.edu_class_id,
              person_id: student.person_id,
              examDate: this.exm_date,
              grade: student.written,
              isNew: false,
            });
          } else {
            this.gradeList.push({
              classExamPersonId: student.edu_class_id,
              person_id: student.person_id,
              examDate: this.exm_date,
              grade: null,
              isNew: true,
            });
          }
        }

        this.selectedExamGrades = [];

        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.examGrades = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    async onMassApprove() {
      try {
        console.log(this.selectedExamGrades);
        this.approveLoader = true;
        let content = [];
        for (const student of this.selectedExamGrades) {
          content.push({
            id: student.edu_exam_results_id,
            approve: true,
          });
        }

        await this.approveExamGrades(content);
        this.selectedExamGrades = [];
        this.approveLoader = false;

        await this.handler();
      } catch (e) {
        this.approveLoader = false;

        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
