<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία προγραμματισμού εξεταστικής περιόδου
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4 d-flex flex-column align-center">
      <v-progress-circular
        v-if="cardLoader"
        indeterminate
        color="primary"
        class="align-self-center"
      ></v-progress-circular>
      <v-form @submit.prevent="onSubmit" class="pa-4" v-else>
        <v-card class="mb-4">
          <v-card-title class="mb-4"> Βασικά στοιχεία </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" sm="6">
                <v-select
                  dense
                  outlined
                  v-model="examPeriodId"
                  :items="examList"
                  hide-details="auto"
                  item-text="description"
                  item-value="cd"
                  :error-messages="examPeriodIdErrors"
                  @change="onSetExamPeriod"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    {{
                      appId == 1
                        ? "Εξεταστική περίοδος εξαμήνου"
                        : "Εξεταστική περίοδος κύκλου σπουδών"
                    }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" v-if="this.appId != 1">
                <v-text-field
                  dense
                  outlined
                  v-model="seira"
                  :error-messages="seiraErrors"
                  hide-details="auto"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Σειρά
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-textarea
                  dense
                  outlined
                  v-model="description"
                  type="text"
                  disabled
                  :error-messages="descriptionErrors"
                  hide-details="auto"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Περιγραφή
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title class="mb-4"> Επιπλέον πληροφορίες </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="studentPart"
                      :items="getDataStaticList(117)"
                      hide-details="auto"
                      item-text="description"
                      item-value="cd"
                      :error-messages="studentPartErrors"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Σπουδαστές που μπορούν να λάβουν μέρος
                      </template>
                    </v-select>
                  </template>
                  <span>Σπουδαστές που μπορούν να λάβουν μέρος</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      hide-details="auto"
                      v-model="gradeFlag"
                      :items="getDataStaticList(123)"
                      item-text="description"
                      item-value="cd"
                      :error-messages="gradeFlagErrors"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Καταχώρηση βαθμολογίας περιόδου
                      </template>
                    </v-select>
                  </template>
                  <span>Καταχώρηση βαθμολογίας περιόδου</span>
                </v-tooltip>
              </v-col>

              <!-- <v-col cols="12" md="4" sm="6">
                <v-select
                  dense
                  outlined
                  v-model="resultIssue"
                  :items="getDataStaticList(124)"
                  item-text="description"
                  item-value="cd"
                  hide-details="auto"
                  :error-messages="resultIssueErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Έκδοση αποτελεσμάτων
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-select
                  dense
                  outlined
                  v-model="finalResultIssue"
                  :items="getDataStaticList(124)"
                  hide-details="auto"
                  item-text="description"
                  item-value="cd"
                  :error-messages="finalResultIssueErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Τελική έκδοση αποτελεσμάτων
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-select
                  dense
                  outlined
                  v-model="certIssue"
                  :items="getDataStaticList(100)"
                  hide-details="auto"
                  item-text="description"
                  item-value="cd"
                  :error-messages="certIssueErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Έκδοση τίτλου/βεβαίωσης
                  </template>
                </v-select>
              </v-col> -->
              <v-col cols="12" md="4" sm="6">
                <v-select
                  clearable
                  dense
                  outlined
                  v-model="activation"
                  label="Ενεργοποίηση κύκλου"
                  hide-details="auto"
                  type="text"
                  class="mb-4"
                  disabled
                  :items="getDataStaticList(118)"
                  item-text="description"
                  item-value="cd"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    {{
                      appId == 1
                        ? "Ενεργοποίηση εξαμήνου σπουδών"
                        : "Ενεργοποίηση κύκλου σπουδών"
                    }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title class="mb-4"> Διάρκεια </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="startDateFormatted"
                      hide-details="auto"
                      class="mb-4"
                      @blur="
                        startDate = parseDate(startDateFormatted, 'startDate')
                      "
                      :error-messages="startDateErrors"
                    >
                      <template v-slot:prepend-inner>
                        <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                      </template>
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Ημερομηνία έναρξης
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="el"
                    v-model="startDate"
                    scrollable
                    hide-details="auto"
                    @input="menu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="endDateFormatted"
                      hide-details="auto"
                      prepend-inner-icon="mdi-calendar"
                      class="mb-4"
                      @blur="endDate = parseDate(endDateFormatted, 'endDate')"
                      :error-messages="endDateErrors"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Ημερομηνία λήξης
                      </template>
                      <template v-slot:prepend-inner>
                        <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="el"
                    v-model="endDate"
                    hide-details="auto"
                    scrollable
                    @input="menu1 = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Αποθήκευση</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";
import axios from "axios";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  props: ["exam"],
  async created() {
    try {
      console.log(this.genericFilter);
      let body = {
        id: null,
        progTrainPeriodId:
          this.currentSchedulingSemester != null
            ? this.currentSchedulingSemester.trainingPeriod.id
            : this.genericFilter.semesterId != null
            ? this.genericFilter.semesterId
            : null,
      };
      // await this.getAllExams(body);
      this.fetchExam();

      // this.certIssue = this.exam.certIssue.cd;
      this.description = this.exam.description;
      this.endDate = this.exam.endDate;
      // this.finalResultIssue = this.exam.finalResultIssue.cd;
      this.gradeFlag = this.exam.gradeFlag.cd;
      this.seira = this.exam.seira;
      this.startDate = this.exam.startDate;
      this.studentPart = this.exam.studentPart.cd;
      // this.resultIssue = this.exam.resultIssue.cd;
      this.examPeriodId = String(this.exam.examPeriod.id); //todo
      this.cardLoader = false;
      this.activation = this.exam.activation.cd;
    } catch (e) {
      this.cardLoader = false;
      console.log(e);
    }
  },
  data() {
    return {
      seira: null,
      description: null,
      startDate: null,
      startDateFormatted: null,
      endDate: null,
      endDateFormatted: null,
      studentPart: null,
      gradeFlag: null,
      resultIssue: null,
      finalResultIssue: null,
      examPeriodId: null,
      certIssue: null,
      cardLoader: true,
      loader: false,
      menu: false,
      menu1: false,
      examList: [],
      activation: null,
    };
  },

  validations() {
    const self = this;
    return {
      description: {
        required,
      },
      seira: {
        required: requiredIf((e) => {
          return self.appId == 2;
        }),
        numeric,
      },

      startDate: {
        required,
      },
      endDate: {
        required,
      },
      studentPart: {
        required,
      },
      gradeFlag: {
        required,
      },
      // resultIssue: {
      //   required,
      // },
      examPeriodId: {
        required,
      },
      // finalResultIssue: {
      //   required,
      // },
      // certIssue: {
      //   required,
      // },
    };
  },

  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
    },

    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },

  computed: {
    ...mapState({
      currentAcademicYear: (state) => state.academic_years.currentAcademicYear,
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      currentSchedulingSemester: (state) =>
        state.scheduling_semesters.currentSchedulingSemester,
      allExams: (state) => state.scheduling_exams.allExams,
      staticList: (state) => state.auth.userInfo.staticView,
      genericFilter: (state) => state.generic_filter,
      appId: (state) => state.auth.appId,
      selectedRole: (state) => state.auth.selectedRole,
    }),

    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    seiraErrors() {
      const errors = [];
      if (!this.$v.seira.$dirty) return errors;
      if (!this.$v.seira.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.seira.numeric) errors.push("Επιτρέπονται μόνο αριθμοί");

      return errors;
    },
    startDateErrors() {
      const errors = [];
      if (!this.$v.startDate.$dirty) return errors;
      if (!this.$v.startDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    endDateErrors() {
      const errors = [];
      if (!this.$v.endDate.$dirty) return errors;
      if (!this.$v.endDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    studentPartErrors() {
      const errors = [];
      if (!this.$v.studentPart.$dirty) return errors;
      if (!this.$v.studentPart.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    gradeFlagErrors() {
      const errors = [];
      if (!this.$v.gradeFlag.$dirty) return errors;
      if (!this.$v.gradeFlag.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    // resultIssueErrors() {
    //   const errors = [];
    //   if (!this.$v.resultIssue.$dirty) return errors;
    //   if (!this.$v.resultIssue.required) errors.push("Υποχρεωτικό πεδίο");
    //   return errors;
    // },

    examPeriodIdErrors() {
      const errors = [];
      if (!this.$v.examPeriodId.$dirty) return errors;
      if (!this.$v.examPeriodId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    // finalResultIssueErrors() {
    //   const errors = [];
    //   if (!this.$v.finalResultIssue.$dirty) return errors;
    //   if (!this.$v.finalResultIssue.required) errors.push("Υποχρεωτικό πεδίο");
    //   return errors;
    // },

    // certIssueErrors() {
    //   const errors = [];
    //   if (!this.$v.certIssue.$dirty) return errors;
    //   if (!this.$v.certIssue.required) errors.push("Υποχρεωτικό πεδίο");
    //   return errors;
    // },
  },

  methods: {
    ...mapActions(["editSchedulingExam", "getAllExams"]),

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async onSetExamPeriod(value) {
      this.description = this.examList.filter((e, i) => {
        if (e.cd == value) {
          return e.description;
        }
      })[0].description;

      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/exam/period/${value}`
        );
        console.log("exam", res);
        this.studentPart = res.data.studentPart.cd;
      } catch (e) {
        throw e;
      }
    },
    async fetchExam() {
      let body = {
        type: this.appId,
        parentCd: this.genericFilter.semesterId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/2011`,
          body
        );
        this.examList = res.data;
      } catch (e) {
        throw e;
      }
    },
    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "startDate") {
          this.startDate = null;
          this.startDateFormatted = null;
        } else if (dateName == "endDate") {
          this.endDate = null;
          this.endDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        this.loader = true;
        let content = {
          // certIssue: this.certIssue,
          description: this.description,
          endDate: this.endDate,
          examPeriodId: this.examPeriodId,
          // finalResultIssue: this.finalResultIssue,
          // resultIssue: this.resultIssue,
          gradeFlag: this.gradeFlag,
          masterSchoolId: this.genericFilter.facultyId,
          schoolId: this.genericFilter.schooleId,
          // seira: this.seira,
          startDate: this.startDate,
          studentPart: this.studentPart,
          yearId: this.genericFilter.academicYearId,
        };

        if (this.appId != 1) {
          content.seira = this.seira;
        }
        await this.editSchedulingExam({
          id: this.exam.id,
          // progTrainPeriodId: this.currentSchedulingSemester.id,
          content,
        });

        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>