import axios from 'axios'
export default {
    state: {
        userRoles: [],
    },

    mutations: {
        getUserRoles(state, userRoles) {
            state.userRoles = userRoles
        },


    },

    actions: {

        async getUserRoles({ commit, dispatch }, filters) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/person/list/roles`,
                    {
                        params: {
                            page: filters.page,
                            limit: filters.limit,
                            personId: filters.personId,
                        }
                    }
                )
                commit('getUserRoles', res.data.content[0].roles)

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e
            }
        },

        async addUserRole({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/person/${payload.personId}/add/role`, null, {
                    params: {
                        roleId: payload.roleId
                    }
                })
                // await dispatch('getUserRoles', {
                //     page: rootState.base.filters.page,
                //     limit: rootState.base.filters.itemsPerPage == -1 ? state.userRoles.elements : rootState.base.filters.itemsPerPage,
                //     personId: payload.personId

                // }
                // )
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία ρόλου ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async editUserRole({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/person/${payload.personId}/update/role`, null, {
                    params: {
                        newRoleId: payload.newRoleId,
                        oldRoleId: payload.oldRoleId,
                    }
                })
                // await dispatch('getUserRoles', {
                //     page: rootState.base.filters.page,
                //     limit: rootState.base.filters.itemsPerPage == -1 ? state.userRoles.elements : rootState.base.filters.itemsPerPage,
                //     personId: payload.personId

                // }
                // )
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία ρόλου ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async deleteUserRole({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/person/${payload.personId}/remove/role`, {
                    params: {
                        roleId: payload.roleId
                    }
                })
                // await dispatch('getUserRoles', {
                //     page: rootState.base.filters.page,
                //     limit: rootState.base.filters.itemsPerPage == -1 ? state.userRoles.elements : rootState.base.filters.itemsPerPage,
                //     personId: payload.personId

                // }
                // )
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή ρόλου ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },
    }
}