<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία σύνδεση
      {{
        appId == 1
          ? "Ακαδημίας με σχολή"
          : "Δομής Μετεκπαίδευσης με εκπαιδευτικό κύκλο/ειδικό τμήμα"
      }}
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-2">
        <v-select
          clearable
          dense
          outlined
          disabled
          v-model="schoolId"
          label="Σχολή"
          hide-details="auto"
          :items="schools.data"
          class="mb-4"
          item-text="description"
          item-value="cd"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            {{ appId == 1 ? "Σχολή" : getFieldName() }}
          </template>
        </v-select>
        <v-text-field
          dense
          outlined
          v-model="description"
          label="Περιγραφή"
          type="text"
          hide-details="auto"
          class="mb-4"
        ></v-text-field>
        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Αποθήκευση</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import Helpers from "../../helpers/helpers.js";
export default {
  props: ["masterSchoolId", "school"],
  async created() {
    await this.fetchSchools();
    console.log(this.school);
    this.schoolId = String(this.school.school.id);
    this.description =
      this.school.description == undefined
        ? this.school.school.description
        : this.school.description;
  },
  data() {
    return {
      loader: false,
      description: null,
      schoolId: null,
      schools: [],
    };
  },
  validations: {
    schoolId: {
      required,
    },
  },

  computed: {
    ...mapState({
      privilegesList: (state) => state.roles.privileges,
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
    }),
  },

  methods: {
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/combine/${this.school.id}`,
          {
            schoolId: this.schoolId,
            masterSchoolId: this.masterSchoolId,
            description: this.description,
          }
        );
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    getFieldName() {
      return Helpers.getFieldName(1);
    },
    async fetchSchools() {
      let body = {
        type: this.appId,
        parentCd: this.masterSchoolId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/2228`,
          body
        );
        this.schools = res;
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>