var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper d-flex align-center justify-center"},[_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          },"headers":_vm.headers,"items":_vm.transferAppl.content,"server-items-length":_vm.transferAppl.elements,"options":_vm.options,"loading":_vm.tableLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-select',{attrs:{"dense":"","outlined":"","items":_vm.listFaculty,"item-text":"description","item-value":"cd","no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.appId == 1 ? "Προς Ακαδημία" : " Προς Δομή Μετεκπαίδευσης")+" ")]},proxy:true}]),model:{value:(_vm.selectedFaculty),callback:function ($$v) {_vm.selectedFaculty=$$v},expression:"selectedFaculty"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-select',{attrs:{"dense":"","outlined":"","label":"Κατάσταση","items":_vm.listStatus,"item-text":"description","item-value":"id","no-data-text":"Δεδομένα μη διαθέσιμα"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{staticClass:"ml-auto",staticStyle:{"margin-right":"5px"},attrs:{"depressed":"","color":"primary","disabled":_vm.isDisabled},on:{"click":_vm.onSearch}},[_vm._v(" Αναζήτηση ")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"depressed":"","color":"primary","disabled":_vm.isDisabledAppl},on:{"click":_vm.calculateGrade}},[_vm._v(" Μοριοδότηση Αιτήσεων ")])],1)],1)]},proxy:true},{key:"item.username",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.person.firstName + " " + item.person.lastName)+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item.personApplication, 'view')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Προβολή")])]),(_vm.showButton == 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item, 'approve', 'approve')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-marked-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Έλεγχος")])]):_vm._e(),(_vm.showButton == 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item, 'approve', 'reject')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-box-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Απόρριψη")])]):_vm._e()]}}],null,true)})],1)],1)],1),(_vm.dialog.type == 'view')?_c('v-dialog',{attrs:{"persistent":"","max-width":"1500px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'view')?_c('ViewSubmittedApplication',{attrs:{"application":_vm.dialog.application},on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e(),(_vm.dialog.type == 'approve')?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'approve')?_c('TransferApproveApplication',{attrs:{"application":_vm.dialog.application,"appType":_vm.dialog.appType},on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }