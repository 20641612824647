var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper d-flex align-center justify-center"},[(this.dialog.type != 'details')?_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('GenericFilter',{attrs:{"level":3,"requiredFields":{
      masterSchool: true,
      school: true,
      year: true,
      progTrainPeriod: false,
      class: false,
      lesson: false,
    }},on:{"onSearchAction":_vm.onSearch}}),_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        },"headers":_vm.headers,"items":_vm.thesis.content,"server-items-length":_vm.thesis.elements,"options":_vm.options,"loading":_vm.tableLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item, 'edit')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pen ")])],1)]}}],null,true)},[_c('span',[_vm._v("Επεξεργασία")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item, 'delete')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Διαγραφή")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.onViewPoreia(item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Προβολή")])])]}},{key:"item.examDate",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.examDate).toLocaleDateString("el")))])]}},{key:"item.person",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.person.firstName + " " + item.person.lastName)+" ")])]}},{key:"item.exam1",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.examinerPerson1.firstName + " " + item.examinerPerson1.lastName))])]}},{key:"item.exam2",fn:function(ref){
        var item = ref.item;
return [(item.examinerPerson2 != null)?_c('span',[_vm._v(_vm._s(item.examinerPerson2.firstName + " " + item.examinerPerson2.lastName))]):_vm._e()]}},{key:"item.exam3",fn:function(ref){
        var item = ref.item;
return [(item.examinerPerson3 != null)?_c('span',[_vm._v(_vm._s(item.examinerPerson3.firstName + " " + item.examinerPerson3.lastName))]):_vm._e()]}},{key:"item.exam4",fn:function(ref){
        var item = ref.item;
return [(item.examinerPerson4 != null)?_c('span',[_vm._v(_vm._s(item.examinerPerson4.firstName + " " + item.examinerPerson4.lastName))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"custom-card-buttons"},[_c('v-btn',{staticClass:"ml-auto",attrs:{"depressed":"","color":"primary ","disabled":_vm.isDisabled},on:{"click":function($event){return _vm.openDialog(null, 'add')}}},[_vm._v(" Δημιουργία "),_c('v-icon',{attrs:{"dense":"","right":""}},[_vm._v("mdi-plus")])],1)],1)],1)],1):_vm._e(),(_vm.dialog.type == 'add')?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'add')?_c('AddDegree',{on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e(),(_vm.dialog.type == 'edit')?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'edit')?_c('EditDegree',{attrs:{"degree":_vm.dialog.degree},on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e(),(_vm.dialog.type == 'delete')?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'delete')?_c('DeleteDegree',{attrs:{"degree":_vm.dialog.degree},on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e(),(_vm.dialog.open && _vm.dialog.type == 'details')?_c('ThesisDetails',{attrs:{"thesis":_vm.dialog.degree},on:{"close":_vm.closeDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }