<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία εκπαιδευτικού ταξιδιού
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4 d-flex flex-column">
      <v-progress-circular
        v-if="cardLoader"
        indeterminate
        color="primary"
        class="align-self-center ma-4"
        :size="50"
      ></v-progress-circular>
      <v-form v-else @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              dense
              outlined
              v-model="progTrainPeriodId"
              hide-details="auto"
              :items="listSemester"
              item-text="description"
              item-value="cd"
              :disabled="true"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                {{ appId == 1 ? "Εξάμηνο σπουδών" : "Κύκλο σπουδών" }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              dense
              outlined
              v-model="personId"
              :items="studentList.data"
              hide-details="auto"
              item-text="description"
              item-value="cd"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Σπουδαστής
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details="auto"
                  outlined
                  v-model="dateOfShippingFormatted"
                  v-bind="attrs"
                  v-on="on"
                  class="mb-4"
                  @blur="
                    dateOfShipping = parseDate(
                      dateOfShippingFormatted,
                      'dateOfShipping'
                    )
                  "
                  :error-messages="dateOfShippingErrors"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Ημερομηνία έναρξης
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="dateOfShipping"
                hide-details="auto"
                scrollable
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="dateOfDismissalFormatted"
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                  class="mb-4"
                  label="Ημερομηνία απόλυσης"
                  @blur="
                    dateOfDismissal = parseDate(
                      dateOfDismissalFormatted,
                      'dateOfDismissal'
                    )
                  "
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="dateOfDismissal"
                scrollable
                hide-details="auto"
                @input="menu1 = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Συνολική θαλάσσια υπηρεσία σε ημέρες"
                  dense
                  outlined
                  v-model="totalMaritimeService"
                  type="text"
                  hide-details="auto"
                  :error-messages="totalMaritimeServiceErrors"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <span>Συνολική θαλάσσια υπηρεσία σε ημέρες</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details="auto"
                  outlined
                  v-model="examDateFormatted"
                  v-bind="attrs"
                  v-on="on"
                  class="mb-4"
                  @blur="examDate = parseDate(examDateFormatted, 'examDate')"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:label>
                    <!-- <span class="error--text">*</span> -->
                    Ημερομηνία εξέτασης
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="examDate"
                hide-details="auto"
                scrollable
                @input="menu2 = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-select
                  label="Αποτέλεσμα εκπαιδευτικού ταξιδιού"
                  dense
                  outlined
                  v-model="educationalTripAssessment"
                  type="text"
                  hide-details="auto"
                  :items="getDataStaticList(144)"
                  item-text="description"
                  item-value="cd"
                  v-bind="attrs"
                  v-on="on"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                </v-select>
              </template>
              <span>Αποτέλεσμα εκπαιδευτικού ταξιδιού</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="grade"
              dense
              outlined
              type="number"
              :min="0"
              :max="10"
              @change="onChangeGrade"
            >
              <template v-slot:label> Bαθμός </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="menu3"
              v-model="menu3"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details="auto"
                  outlined
                  v-model="receiveDateKepFormatted"
                  v-bind="attrs"
                  v-on="on"
                  class="mb-4"
                  @blur="
                    receiveDateKep = parseDate(
                      receiveDateKepFormatted,
                      'receiveDateKep'
                    )
                  "
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:label>
                    <!-- <span class="error--text">*</span> -->
                    Ημερομηνία παραλαβής ΚΕΠ
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="receiveDateKep"
                hide-details="auto"
                scrollable
                @input="menu3 = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-select
                  label="Μη παρουσίαση για αξιολόγηση"
                  dense
                  outlined
                  v-model="nonPresEval"
                  type="text"
                  hide-details="auto"
                  :items="getDataStaticList(100)"
                  item-text="description"
                  item-value="cd"
                  v-bind="attrs"
                  v-on="on"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                </v-select>
              </template>
              <span>Μη παρουσίαση για αξιολόγηση</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-select
                  label="Μόνιμη διαγραφή"
                  dense
                  outlined
                  v-model="permanentlyDelete"
                  hide-details="auto"
                  type="text"
                  :items="getDataStaticList(100)"
                  item-text="description"
                  item-value="cd"
                  v-bind="attrs"
                  v-on="on"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                </v-select>
              </template>
              <span>Μόνιμη διαγραφή</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-textarea
              dense
              outlined
              v-model="memo"
              hide-details="auto"
              label="Σχόλια"
              type="text"
            >
            </v-textarea>
          </v-col>
        </v-row>

        <div class="d-flex justify-center mt-2">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
import axios from "axios";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  async created() {
    try {
      await this.getStudents({
        progTrainPrdId: this.progTrainPeriodId,
        page: 0,
      });
      await this.fetchSemesterDetail();
      await this.fetchTripDetails();
      this.cardLoader = false;
    } catch (e) {
      this.cardLoader = false;
      console.log(e);
    }
  },

  data() {
    return {
      cardLoader: true,
      loader: false,
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,

      dateOfDismissal: null,
      dateOfDismissalFormatted: null,
      dateOfShipping: null,
      dateOfShippingFormatted: null,
      educationalTripAssessment: null,
      memo: null,
      nonPresEval: null,
      permanentlyDelete: null,
      personId: null,
      totalMaritimeService: null,
      examDate: null,
      examDateFormatted: null,
      grade: null,
      receiveDateKep: null,
      receiveDateKepFormatted: null,
      roundGrade: null,
      akep: null,
      bkep: null,
      studentList: [],
    };
  },

  watch: {
    dateOfDismissal(val) {
      this.dateOfDismissalFormatted = this.formatDate(this.dateOfDismissal);
    },
    dateOfShipping(val) {
      this.dateOfShippingFormatted = this.formatDate(this.dateOfShipping);
    },
    examDate() {
      this.examDateFormatted = this.formatDate(this.examDate);
    },
    receiveDateKep() {
      this.receiveDateKepFormatted = this.formatDate(this.receiveDateKep);
    },
  },

  validations: {
    dateOfShipping: {
      required,
    },

    totalMaritimeService: {
      numeric,
    },
  },

  computed: {
    ...mapState({
      students: (state) => state.students.students,
      schedulingSemesters: (state) =>state.scheduling_semesters.schedulingSemesters,
      appId: (state) => state.auth.appId,
      progTrainPeriodId: (state) => state.generic_filter.semesterId,
      progTrainDetails: (state) => state.generic_filter.progTrainDetails,
      listSemester: (state) => state.generic_filter.listSemester,
      staticList: (state) => state.auth.userInfo.staticView,
      selectedRole: (state) => state.auth.selectedRole,
      schoolId:(state)=>state.generic_filter.schoolId
    }),

    dateOfShippingErrors() {
      const errors = [];
      if (!this.$v.dateOfShipping.$dirty) return errors;
      if (!this.$v.dateOfShipping.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    totalMaritimeServiceErrors() {
      const errors = [];
      if (!this.$v.totalMaritimeService.$dirty) return errors;
      if (!this.$v.totalMaritimeService.numeric)
        errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addProgTrip", "getStudents", "getSchedulingSemesters"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        let content = {
          progTrainPeriodId: this.progTrainPeriodId,
          personId: this.personId,
          dateOfShipping: this.dateOfShipping,
        };

        if (this.memo != null) {
          content.memo = this.memo;
        }

        if (this.dateOfDismissal != null) {
          content.dateOfDismissal = this.dateOfDismissal;
        }

        if (this.educationalTripAssessment != null) {
          content.educationalTripAssessment = this.educationalTripAssessment;
        }

        if (this.nonPresEval != null) {
          content.nonPresEval = this.nonPresEval;
        }

        if (this.permanentlyDelete != null) {
          content.permanentlyDelete = this.permanentlyDelete;
        }

        if (this.totalMaritimeService != null) {
          content.totalMaritimeService = this.totalMaritimeService;
        }
        if (this.examDate != null) content.examDate = this.examDate;

        if (this.receiveDateKep != null)
          content.receiveDateKep = this.receiveDateKep;

        if (this.grade != null) content.grade = this.grade;

        await this.addProgTrip(content);
        this.loader = false;

        await this.$emit("handler");
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
    decimalCount(num) {
      // Convert to String
      const numStr = String(num);
      // String Contains Decimal
      if (numStr.includes(".")) {
        return numStr.split(".")[1].length;
      }
      // String Does Not Contain Decimal
      return 0;
    },
    onChangeGrade() {
      if (this.roundGrade != null) {
        let gradeNew = Number(this.grade);
        let decimals = this.decimalCount(this.roundGrade);
        this.grade = gradeNew.toFixed(decimals);
      }
    },

    async fetchSemesterDetail() {
      try {
        if (this.progTrainPeriodId != null) {
          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/prog/train/${this.progTrainPeriodId}`
          );
          this.roundGrade = res.data.trainingPeriod?.roundGrade;
          console.log(res);
        }
      } catch (e) {
        return [];
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "dateOfDismissal") {
          this.dateOfDismissal = null;
          this.dateOfDismissalFormatted = null;
        } else if (dateName == "dateOfShipping") {
          this.dateOfShipping = null;
          this.dateOfShippingFormatted = null;
        } else if (dateName == "examDate") {
          this.examDate = null;
          this.examDateFormatted = null;
        } else if (dateName == "receiveDateKep") {
          this.receiveDateKep = null;
          this.receiveDateKepFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },

    async fetchTripDetails() {
      const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/parameter`, {
        params: {
          param: 300.001,
        },
      });
      console.log(res.data);
      this.akep = res.data.toString().split(",");
      const res2 = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/parameter`,
        {
          params: {
            param: 300.002,
          },
        }
      );
      this.bkep = res2.data.toString().split(",");
      console.log(this.progTrainDetails.trainingPeriod);
      if (this.progTrainDetails.trainingPeriod != undefined) {
        
        if (this.akep.includes(this.progTrainDetails.trainingPeriod.id.toString())) {
          this.studentList = await this.fetchDataDynamic(2620,this.schoolId);
        }

        if (this.bkep.includes(this.progTrainDetails.trainingPeriod.id.toString())) {
          this.studentList = await this.fetchDataDynamic(2621,this.schoolId);
        }
      }
    },

    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
