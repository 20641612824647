
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="6"
        :key="query"
        @onSearchAction="onSearch"
        :listActive="1"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: true,
          class: true,
          lesson: requiredLesson,
        }"
      />
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="appId == 1 ? headers : headersKesen"
            :items="absences.content"
            :options.sync="options"
            :server-items-length="absences.elements"
            :loading="tableLoader"
            v-model="selectedAbsences"
            show-select
            item-key="dailyAbsenceId"
          >
            <template v-slot:[`item.absDate`]="{ item }">
              <span>{{ new Date(item.absDate).toLocaleDateString("el") }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onStudentView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary"
            class="mr-2"
            @click="onMassApprove"
            :disabled="selectedAbsences.length == 0"
            :loading="approveLoader"
          >
            Μαζικός έλεγχος
          </v-btn>
        </div>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import axios from "axios";
export default {
  components: {
    GenericFilter,
  },

  async created() {
    try {
      if (this.progTrainPeriodId != null) await this.handler();
    } catch (e) {
      throw e;
    }
  },

  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },

      query: false,
      filtersSet: false,
      approveLoader: false,
      absences: [],
      headers: [
        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },

        {
          text: "Ώρες",
          value: "absHours",
          sortable: false,
        },

        {
          text: "Μάθημα",
          value: "lessonDescription",
          sortable: false,
        },

        {
          text: "Ημερομηνία",
          value: "absDate",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      headersKesen: [
        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },
        {
          text: "ΜΕΘ",
          value: "meth",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },

        {
          text: "Ώρες",
          value: "absHours",
          sortable: false,
        },

        {
          text: "Μάθημα",
          value: "lessonDescription",
          sortable: false,
        },

        {
          text: "Ημερομηνία",
          value: "absDate",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      selectedAbsences: [],
    };
  },

  watch: {
    async options() {
      if (this.filtersSet && this.progTrainPeriodId != null)
        await this.handler();
    },
    $route() {
      this.query = this.$route.params.id;
    },
  },

  computed: {
    ...mapState({
      pageChanged: (state) => state.base.pageChanged,
      progTrainPeriodId: (state) => state.generic_filter.semesterId,
      courseId: (state) => state.generic_filter.courseId,
      classId: (state) => state.generic_filter.classId,
      schoolDetails: (state) => state.generic_filter.schoolDetails,
      appId: (state) => state.auth.appId,
      // dates: (state) => state.generic_filter.dates,
    }),
    requiredLesson() {
      if (this.schoolDetails != null) {
        if (this.schoolDetails.absence) {
          if (this.schoolDetails.absence.cd == "1") {
            return true;
          }
        }
      }
      return false;
    },
  },

  methods: {
    ...mapMutations(["setFilters", "changePage", "setCurrentStudent"]),
    ...mapActions(["approveAbsences"]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    onStudentView(student) {
      this.setCurrentStudent(student);
      this.$router.push(
        `/absences/${student.personId}/view_student/atomika_stoixeia_absence`
      );
    },

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/daily/absence/list/view`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              progTrainPeriodId: this.progTrainPeriodId,
              lessonId: this.courseId,
              classId: this.classId,
              // absenceDateFrom: this.dates.length > 0 ? this.dates : null,
              // absenceDateTo: this.dates.length == 2 ? this.dates[1] : null,
              approved: false,
            },
          }
        );

        this.absences = res.data;

        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        this.tableLoader = false;
        this.selectedAbsences = [];
      } catch (e) {
        console.log(e);
        this.absences = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    async onMassApprove() {
      try {
        this.approveLoader = true;
        let content = [];
        for (const student of this.selectedAbsences) {
          content.push({
            id: student.dailyAbsenceId,
            approve: true,
          });
        }

        await this.approveAbsences(content);
        this.selectedAbsences = [];
        this.approveLoader = false;

        await this.handler();
      } catch (e) {
        this.approveLoader = false;

        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>