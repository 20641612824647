var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"primary"}},[_vm._v(" Βαθμολογίες "),_c('v-btn',{staticClass:"ml-auto",attrs:{"depressed":"","icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"d-flex flex-column"},[_c('v-card',{staticClass:"mt-4 pa-4"},[_c('v-card-text',[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          },"headers":_vm.headers,"items":_vm.bathmologia.hashContent,"server-items-length":_vm.bathmologia.elements,"options":_vm.options,"loading":_vm.tableLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.exam_date",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.exam_date).toLocaleDateString("el")))])]}},{key:"item.approve_oral",fn:function(ref){
          var item = ref.item;
return [(item.approve_oral)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e(),(!item.approve_oral)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")]):_vm._e()]}},{key:"item.approve_written",fn:function(ref){
          var item = ref.item;
return [(item.approve_written)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e(),(!item.approve_written)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")]):_vm._e()]}}],null,true)})],1)],1),_c('v-btn',{staticClass:"ml-auto ma-4",attrs:{"depressed":"","color":"error"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Επιστροφή ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }