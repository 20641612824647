import axios from "axios";

export default {
    state: {
        pistopoihseis: [],
        typoiPistopoihshs: []
    },

    mutations: {
        getPistopoihseis(state, pistopoihseis) {
            state.pistopoihseis = pistopoihseis
        },

        getTypoiPistopoihshs(state, typoiPistopoihshs) {
            state.typoiPistopoihshs = typoiPistopoihshs
        }

    },

    actions: {
        async getPistopoihseis({ commit, dispatch }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/person/authorization/document/list`, {
                    params: {
                        page: payload.filters.page,
                        limit: payload.filters.limit,
                        personId: payload.id
                    }
                })
                commit('getPistopoihseis', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async addPistopoihsh({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/person/authorization/document`, payload)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία ταυτοποίησης ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async editPistopoihsh({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/person/authorization/document/${payload.id}`, payload.formData)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία ταυτοποίησης ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async deletePistopoihsh({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/person/authorization/document/${payload.id}`)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή ταυτοποίησης ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async getTypoiPistopoihshs({ commit, dispatch }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/value/101`)
                commit('getTypoiPistopoihshs', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                console.log(e)
            }
        }
    }
}