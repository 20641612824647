var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"primary"}},[_c('span',[_vm._v("Εκτύπωση "+_vm._s(this.report.description))]),_c('v-btn',{staticClass:"ml-auto",attrs:{"depressed":"","icon":"","color":"white"},on:{"click":function () {
          this$1.$v.$reset();
          _vm.$emit('close');
        }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-4"},[_c('v-card',[_c('v-form',{staticClass:"pa-4",attrs:{"id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-row',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.outputType),callback:function ($$v) {_vm.outputType=$$v},expression:"outputType"}},_vm._l((_vm.outputTypeList),function(n){return _c('v-radio',{key:n.id,attrs:{"label":n.desc,"value":n.id}})}),1)],1),_vm._l((_vm.report.parameters),function(par,i){return _c('v-row',{key:i},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(par.type == '1')?_c('v-text-field',_vm._g(_vm._b({staticClass:"dynamicField",attrs:{"dense":"","outlined":"","label":par.label,"type":"text","hide-details":"auto","error-messages":_vm.errorMessages(par, i)},model:{value:(_vm.content[i].value),callback:function ($$v) {_vm.$set(_vm.content[i], "value", $$v)},expression:"content[i].value"}},'v-text-field',attrs,false),on)):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(par.label))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(par.type == '2')?_c('v-text-field',_vm._g(_vm._b({staticClass:"dynamicField",attrs:{"dense":"","outlined":"","label":par.label,"hide-details":"auto","error-messages":_vm.errorMessages(par, i)},model:{value:(_vm.content[i].value),callback:function ($$v) {_vm.$set(_vm.content[i], "value", $$v)},expression:"content[i].value"}},'v-text-field',attrs,false),on)):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(par.label))])]),_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-x":"","left":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(par.type == '3')?_c('v-text-field',_vm._g(_vm._b({staticClass:"dynamicField",attrs:{"dense":"","outlined":"","label":par.label,"prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":"auto","error-messages":_vm.errorMessages(par, i)},model:{value:(_vm.content[i].value),callback:function ($$v) {_vm.$set(_vm.content[i], "value", $$v)},expression:"content[i].value"}},'v-text-field',attrs,false),on)):_vm._e()]}}],null,true),model:{value:(_vm.content[i].menu),callback:function ($$v) {_vm.$set(_vm.content[i], "menu", $$v)},expression:"content[i].menu"}},[_c('v-date-picker',{attrs:{"locale":"el","scrollable":"","error-messages":_vm.errorMessages(par, i)},model:{value:(_vm.content[i].value),callback:function ($$v) {_vm.$set(_vm.content[i], "value", $$v)},expression:"content[i].value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","text":"","color":"primary"},on:{"click":function($event){_vm.content[i].menu = false;
                  _vm.content[i].value = null;}}},[_vm._v(" Ακύρωση ")]),_c('v-btn',{attrs:{"dense":"","text":"","color":"primary"},on:{"click":function($event){_vm.content[i].menu = false}}},[_vm._v(" OK ")])],1)],1),(par.type == '4')?_c('v-checkbox',{attrs:{"label":par.label,"error-messages":_vm.errorMessages(par, i)},model:{value:(_vm.content[i].value),callback:function ($$v) {_vm.$set(_vm.content[i], "value", $$v)},expression:"content[i].value"}}):_vm._e(),(par.type == '6')?_c('v-select',{staticClass:"dynamicField",attrs:{"label":par.label,"dense":"","item-text":"description","clearable":"","item-value":"cd","error-messages":_vm.errorMessages(par, i),"hide-details":"auto","items":_vm.fetchDataList(par.lovId.cd, i, par),"outlined":"","no-data-text":"Δεδομένα μη διαθέσιμα"},on:{"change":function($event){return _vm.onChangeDataList($event, par)}},model:{value:(_vm.content[i].value),callback:function ($$v) {_vm.$set(_vm.content[i], "value", $$v)},expression:"content[i].value"}}):_vm._e()],1)}),_c('div',{staticClass:"d-flex flex-grow-1 align-center justify-center mt-8"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"success","loading":_vm.loader},on:{"click":_vm.onSubmit}},[_vm._v(" Έκτυπωση ")])],1)],2)],1)],1),(_vm.showLoader)?_c('Loader'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }