var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"primary"}},[_vm._v(" Επεξεργασία "+_vm._s(_vm.appId == 1 ? "εξαμήνου σπουδών" : "κύκλου σπουδών")+" "),_c('v-btn',{staticClass:"ml-auto",attrs:{"depressed":"","icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-4 d-flex flex-column align-center"},[(_vm.cardLoader)?_c('v-progress-circular',{staticClass:"align-self-center",attrs:{"indeterminate":"","color":"primary"}}):_c('v-form',{staticClass:"pa-4",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',{staticClass:"mb-4"},[_vm._v(" Βασικά στοιχεία ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"text","error-messages":_vm.cdErrors,"hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Κωδικός ")]},proxy:true}],null,true),model:{value:(_vm.cd),callback:function ($$v) {_vm.cd=$$v},expression:"cd"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Κωδικός")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-textarea',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"text","hide-details":"auto","error-messages":_vm.descriptionErrors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Περιγραφή ")]},proxy:true}],null,true),model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}},'v-textarea',attrs,false),on))]}}])},[_c('span',[_vm._v("Περιγραφή")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-textarea',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":"auto","type":"text","error-messages":_vm.shortDescriptionErrors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Σύντομη περιγραφή ")]},proxy:true}],null,true),model:{value:(_vm.shortDescription),callback:function ($$v) {_vm.shortDescription=$$v},expression:"shortDescription"}},'v-textarea',attrs,false),on))]}}])},[_c('span',[_vm._v("Σύντομη περιγραφή")])])],1)],1)],1)],1),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',{staticClass:"mb-4"},[_vm._v(" Επιπλέον πληροφορίες ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"text","hide-details":"auto","error-messages":_vm.minDeptErrors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Ελάχιστος αριθμός σπουδαστών στο τμήμα ")]},proxy:true}],null,true),model:{value:(_vm.minDept),callback:function ($$v) {_vm.minDept=$$v},expression:"minDept"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Ελάχιστος αριθμός σπουδαστών στο τμήμα")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"text","error-messages":_vm.maxDeptErrors,"hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Μέγιστος αριθμός σπουδαστών στο τμήμα ")]},proxy:true}],null,true),model:{value:(_vm.maxDept),callback:function ($$v) {_vm.maxDept=$$v},expression:"maxDept"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Μέγιστος αριθμός σπουδαστών στο τμήμα")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"text","error-messages":_vm.lessonTypeErrors,"hide-details":"auto","items":_vm.getDataStaticList(111),"item-text":"description","item-value":"cd","no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Είδος μαθημάτων στην εξεταστική περίοδο ")]},proxy:true}],null,true),model:{value:(_vm.lessonType),callback:function ($$v) {_vm.lessonType=$$v},expression:"lessonType"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v("Είδος μαθημάτων στην εξεταστική περίοδο")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":"auto","min":"0","label":"Οφειλόμενα μαθήματα","type":"number","placeholder":"Οφειλόμενα μαθήματα"},model:{value:(_vm.registerAgainstNumFailedLessons),callback:function ($$v) {_vm.registerAgainstNumFailedLessons=$$v},expression:"registerAgainstNumFailedLessons"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Οφειλόμενα μαθήματα")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":"auto","type":"text","error-messages":_vm.examDurationErrors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Διάρκεια εξέτασης ")]},proxy:true}],null,true),model:{value:(_vm.examDuration),callback:function ($$v) {_vm.examDuration=$$v},expression:"examDuration"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Διάρκεια εξέτασης")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Αριθμός μεταφερόμενων μαθημάτων σε επόμενη περίοδο","hide-details":"auto","type":"text","error-messages":_vm.lessonTransNextPerErrors},model:{value:(_vm.lessonTransNextPer),callback:function ($$v) {_vm.lessonTransNextPer=$$v},expression:"lessonTransNextPer"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Αριθμός μεταφερόμενων μαθημάτων σε επόμενη περίοδο")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":_vm.appId == 1
                        ? 'Πλήθος εγγραφών κατά το εξάμηνο σπουδών'
                        : 'Πλήθος εγγραφών κατά το κύκλο σπουδών',"type":"text","error-messages":_vm.maxRegisterNrErrors,"hide-details":"auto"},model:{value:(_vm.maxRegisterNr),callback:function ($$v) {_vm.maxRegisterNr=$$v},expression:"maxRegisterNr"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.appId == 1 ? "Πλήθος εγγραφών κατά το εξάμηνο σπουδών" : "Πλήθος εγγραφών κατά το κύκλο σπουδών"))])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"clearable":"","dense":"","outlined":"","label":_vm.appId == 1
                        ? 'Προηγούμενο εξάμηνο σπουδών'
                        : 'Προηγούμενο κύκλο σπουδών',"type":"text","items":_vm.getSemesters,"hide-details":"auto","item-text":"description","item-value":"id","no-data-text":"Δεδομένα μη διαθέσιμα"},model:{value:(_vm.prevTrainingPeriodId),callback:function ($$v) {_vm.prevTrainingPeriodId=$$v},expression:"prevTrainingPeriodId"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.appId == 1 ? "Προηγούμενο εξάμηνο σπουδών" : "Προηγούμενο κύκλο σπουδών"))])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"clearable":"","dense":"","outlined":"","label":_vm.appId == 1
                        ? 'Επόμενο εξάμηνο σπουδών'
                        : 'Επόμενο κύκλο σπουδών',"type":"text","hide-details":"auto","items":_vm.getSemesters,"item-text":"description","item-value":"id","no-data-text":"Δεδομένα μη διαθέσιμα"},model:{value:(_vm.nextTrainingPeriodId),callback:function ($$v) {_vm.nextTrainingPeriodId=$$v},expression:"nextTrainingPeriodId"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.appId == 1 ? "Επόμενο εξάμηνο σπουδών" : "Επόμενο κύκλο σπουδών"))])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"clearable":"","hide-details":"auto","dense":"","outlined":"","type":"text","items":_vm.getDataStaticList(100),"item-text":"description","item-value":"cd","error-messages":_vm.shipTrainingIdErrors,"no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Θαλάσσιο εκπαιδευτικό ταξίδι ")]},proxy:true}],null,true),model:{value:(_vm.shipTrainingId),callback:function ($$v) {_vm.shipTrainingId=$$v},expression:"shipTrainingId"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v("* Θαλάσσιο εκπαιδευτικό ταξίδι")])])],1),(_vm.shipTrainingId == '1')?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":"auto","label":"Πιστωτικές μονάδες","type":"text","error-messages":_vm.creditUnitsErrors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Πιστωτικές μονάδες ")]},proxy:true}],null,true),model:{value:(_vm.creditUnits),callback:function ($$v) {_vm.creditUnits=$$v},expression:"creditUnits"}},'v-text-field',attrs,false),on))]}}],null,false,1166357214)},[_c('span',{staticClass:"error--text"},[_vm._v("*")]),_c('span',[_vm._v("Πιστωτικές μονάδες")])])],1):_vm._e(),(
                _vm.protSchoolDetails != null &&
                _vm.protSchoolDetails.trainPrd &&
                _vm.protSchoolDetails.trainPrd.cd == '2' &&
                _vm.appId == 2
              )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"text","items":_vm.certificateList,"hide-details":"auto","item-text":"description","item-value":"cd","clearable":"","no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Εκδιδόμενο πιστοποιητικό ")]},proxy:true}],null,true),model:{value:(_vm.titleId),callback:function ($$v) {_vm.titleId=$$v},expression:"titleId"}},'v-select',attrs,false),on))]}}],null,false,1557594178)},[_c('span',[_vm._v(" Εκδιδόμενο πιστοποιητικό ")])])],1):_vm._e()],1)],1)],1),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',{staticClass:"mb-4"},[_vm._v(" Διάρκεια ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":"auto","label":"Μέγιστο πλήθος επαναλήψεων περιόδου","type":"number","error-messages":_vm.lessonRepeatNrErrors,"min":"1"},model:{value:(_vm.lessonRepeatNr),callback:function ($$v) {_vm.lessonRepeatNr=$$v},expression:"lessonRepeatNr"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Μέγιστο πλήθος επαναλήψεων μαθήματος")])])],1),(Number(_vm.lessonRepeatNr) <= 1)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[(Number(_vm.lessonRepeatNr) <= 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Ημέρα έναρξης","type":"text","hide-details":"auto","error-messages":_vm.lessonFromDdErrors,"items":_vm.days,"disabled":_vm.lessonRepeatNr > 1 ? true : false,"no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([(_vm.lessonRepeatNr >= 1)?{key:"label",fn:function(){return [(_vm.lessonRepeatNr == 1)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e(),_vm._v(" Ημέρα έναρξης ")]},proxy:true}:null],null,true),model:{value:(_vm.lessonFromDd),callback:function ($$v) {_vm.lessonFromDd=$$v},expression:"lessonFromDd"}},'v-select',attrs,false),on))]}}],null,false,661666078)},[_c('span',[_vm._v("Ημέρα έναρξης")])]):_vm._e()],1):_vm._e(),(Number(_vm.lessonRepeatNr) <= 1)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[(Number(_vm.lessonRepeatNr) <= 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Μήνας έναρξης","type":"text","error-messages":_vm.lessonFromMmErrors,"items":_vm.months,"item-text":"description","hide-details":"auto","item-value":"cd","disabled":_vm.lessonRepeatNr > 1 ? true : false,"no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([(_vm.lessonRepeatNr >= 1)?{key:"label",fn:function(){return [(_vm.lessonRepeatNr == 1)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e(),_vm._v(" Μήνας έναρξης ")]},proxy:true}:null],null,true),model:{value:(_vm.lessonFromMm),callback:function ($$v) {_vm.lessonFromMm=$$v},expression:"lessonFromMm"}},'v-select',attrs,false),on))]}}],null,false,2573356773)},[_c('span',[_vm._v("Μήνας έναρξης")])]):_vm._e()],1):_vm._e(),(Number(_vm.lessonRepeatNr) <= 1)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[(Number(_vm.lessonRepeatNr) <= 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Ημέρα λήξης","type":"text","error-messages":_vm.lessonToDdErrors,"items":_vm.days,"hide-details":"auto","disabled":_vm.lessonRepeatNr > 1 ? true : false,"no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([(_vm.lessonRepeatNr >= 1)?{key:"label",fn:function(){return [(_vm.lessonRepeatNr == 1)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e(),_vm._v(" Ημέρα λήξης ")]},proxy:true}:null],null,true),model:{value:(_vm.lessonToDd),callback:function ($$v) {_vm.lessonToDd=$$v},expression:"lessonToDd"}},'v-select',attrs,false),on))]}}],null,false,1173001310)},[_c('span',[_vm._v("Ημέρα λήξης")])]):_vm._e()],1):_vm._e(),(Number(_vm.lessonRepeatNr) <= 1)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[(Number(_vm.lessonRepeatNr) <= 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Μήνας λήξης","type":"text","error-messages":_vm.lessonToMmErrors,"items":_vm.months,"item-text":"description","hide-details":"auto","item-value":"cd","disabled":_vm.lessonRepeatNr > 1 ? true : false,"no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([(_vm.lessonRepeatNr >= 1)?{key:"label",fn:function(){return [(_vm.lessonRepeatNr == 1)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e(),_vm._v(" Μήνας λήξης ")]},proxy:true}:null],null,true),model:{value:(_vm.lessonToMm),callback:function ($$v) {_vm.lessonToMm=$$v},expression:"lessonToMm"}},'v-select',attrs,false),on))]}}],null,false,1637639333)},[_c('span',[_vm._v("Μήνας λήξης")])]):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[(_vm.lessonRepeatNr != 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"text","error-messages":_vm.durationErrors,"hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Διάρκεια σε ημέρες ")]},proxy:true}],null,true),model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}},'v-text-field',attrs,false),on))]}}],null,false,2540174703)},[_c('span',[_vm._v("Διάρκεια σε ημέρες")])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"text","error-messages":_vm.hoursErrors,"hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Συνολικές ώρες κύκλου ")]},proxy:true}],null,true),model:{value:(_vm.hours),callback:function ($$v) {_vm.hours=$$v},expression:"hours"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Συνολικές ώρες κύκλου")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"text","error-messages":_vm.labHoursErrors,"hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Εργαστηριακές ώρες κύκλου ")]},proxy:true}],null,true),model:{value:(_vm.labHours),callback:function ($$v) {_vm.labHours=$$v},expression:"labHours"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Εργαστηριακές ώρες κύκλου")])])],1)],1)],1)],1),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',{staticClass:"mb-4"},[_vm._v(" Απουσίες και βαθμολογίες ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"clearable":"","dense":"","outlined":"","label":"Τρόπος υπολογισμού απουσιών","type":"text","hide-details":"auto","items":_vm.getDataStaticList(112),"item-text":"description","item-value":"cd","no-data-text":"Δεδομένα μη διαθέσιμα"},model:{value:(_vm.absCalcMode),callback:function ($$v) {_vm.absCalcMode=$$v},expression:"absCalcMode"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v("Τρόπος υπολογισμού απουσιών")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"clearable":"","dense":"","outlined":"","label":"Είδος ορίου απουσιών","type":"text","items":_vm.getDataStaticList(113),"item-text":"description","hide-details":"auto","item-value":"cd","no-data-text":"Δεδομένα μη διαθέσιμα"},model:{value:(_vm.absLimType),callback:function ($$v) {_vm.absLimType=$$v},expression:"absLimType"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v("Είδος ορίου απουσιών")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"text","error-messages":_vm.totUnjustifiedAbsErrors,"hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Αδικαιολόγητες απουσίες ")]},proxy:true}],null,true),model:{value:(_vm.totUnjustifiedAbs),callback:function ($$v) {_vm.totUnjustifiedAbs=$$v},expression:"totUnjustifiedAbs"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Αδικαιολόγητες απουσίες")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"text","hide-details":"auto","error-messages":_vm.totAbsErrors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Σύνολο απουσιών ")]},proxy:true}],null,true),model:{value:(_vm.totAbs),callback:function ($$v) {_vm.totAbs=$$v},expression:"totAbs"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Σύνολο απουσιών")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"text","error-messages":_vm.absLimExcErrors,"items":_vm.getDataStaticList(114),"hide-details":"auto","item-text":"description","item-value":"cd","no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Αποτέλεσμα υπέρβασης ορίου απουσιών ")]},proxy:true}],null,true),model:{value:(_vm.absLimExc),callback:function ($$v) {_vm.absLimExc=$$v},expression:"absLimExc"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v("Aποτέλεσμα υπέρβασης ορίου απουσιών")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":"auto","type":"text","error-messages":_vm.dailyHoursErrors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Ανώτατο όριο ωρών διδασκαλίας ")]},proxy:true}],null,true),model:{value:(_vm.dailyHours),callback:function ($$v) {_vm.dailyHours=$$v},expression:"dailyHours"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Ανώτατο όριο ωρών διδασκαλίας")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Βάση γενικής βαθμολογίας","type":"text","hide-details":"auto","error-messages":_vm.passGradeErrors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Βάση γενικής βαθμολογίας ")]},proxy:true}],null,true),model:{value:(_vm.passGrade),callback:function ($$v) {_vm.passGrade=$$v},expression:"passGrade"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Βάση γενικής βαθμολογίας")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Μέγιστη βαθμολογία ","type":"text","hide-details":"auto","error-messages":_vm.maxGradeErrors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Μέγιστη βαθμολογία ")]},proxy:true}],null,true),model:{value:(_vm.maxGrade),callback:function ($$v) {_vm.maxGrade=$$v},expression:"maxGrade"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Μέγιστη βαθμολογία")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":"auto","label":"Στρογγυλοποίηση βαθμολογίας ","type":"text","error-messages":_vm.roundGradeErrors},model:{value:(_vm.roundGrade),callback:function ($$v) {_vm.roundGrade=$$v},expression:"roundGrade"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Στρογγυλοποίηση βαθμολογίας")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Έγκριση βαθμολογίας ","type":"text","items":_vm.getDataStaticList(100),"item-text":"description","hide-details":"auto","item-value":"cd","error-messages":_vm.flgReqApprErrors,"no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Έγκριση βαθμολογίας ")]},proxy:true}],null,true),model:{value:(_vm.flgReqAppr),callback:function ($$v) {_vm.flgReqAppr=$$v},expression:"flgReqAppr"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v("Έγκριση βαθμολογίας")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Αποποίηση βαθμολογίας ","type":"text","items":_vm.getDataStaticList(100),"item-text":"description","hide-details":"auto","item-value":"cd","error-messages":_vm.apopErrors,"no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Αποποίηση βαθμολογίας ")]},proxy:true}],null,true),model:{value:(_vm.apop),callback:function ($$v) {_vm.apop=$$v},expression:"apop"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v("Αποποίηση βαθμολογίας")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":"auto","label":"Χρόνος αποποίησης βαθμολογίας","type":"text","items":_vm.getDataStaticList(122),"item-text":"description","item-value":"cd","error-messages":_vm.apop1Errors,"disabled":_vm.apop == null || _vm.apop == 0 ? true : false,"no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([(_vm.apop != null && _vm.apop != 0)?{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Χρόνος αποποίησης βαθμολογίας ")]},proxy:true}:null],null,true),model:{value:(_vm.apop1),callback:function ($$v) {_vm.apop1=$$v},expression:"apop1"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v("Χρόνος αποποίησης βαθμολογίας")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"text","error-messages":_vm.lessonRejectErrors,"hide-details":"auto","items":_vm.getDataStaticList(115),"item-text":"description","item-value":"cd","no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Αποτέλεσμα απόρριψης σπουδαστή σε μάθημα ")]},proxy:true}],null,true),model:{value:(_vm.lessonReject),callback:function ($$v) {_vm.lessonReject=$$v},expression:"lessonReject"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v("Αποτέλεσμα απόρριψης σπουδαστή σε μάθημα")])])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-textarea',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Σχόλια","type":"text","hide-details":"auto"},model:{value:(_vm.memo),callback:function ($$v) {_vm.memo=$$v},expression:"memo"}},'v-textarea',attrs,false),on))]}}])},[_c('span',[_vm._v("Σχόλια")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-checkbox',{staticClass:"mb-4",attrs:{"label":"Ενεργή","error-messages":_vm.flgActiveErrors,"true-value":"1","false-value":"0","hide-details":"auto"},model:{value:(_vm.flgActive),callback:function ($$v) {_vm.flgActive=$$v},expression:"flgActive"}})],1)],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Ακύρωση")]),_c('v-btn',{staticClass:"ml-4",attrs:{"depressed":"","color":"success","type":"submit","loading":_vm.loader}},[_vm._v("Αποθήκευση")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }