import axios from "axios";
export default {
  state: {
    teachers: [],
    currentTeacher: JSON.parse(localStorage.getItem("teacher")) || null,

    teacherHours: [],
    teacherAbsences: [],
    teacherLessons: [],
    teacherExperience: [],
    teacherList: [],
  },

  mutations: {
    getTeachers(state, teachers) {
      state.teachers = teachers;
    },

    setCurrentTeacher(state, teacher) {
      state.currentTeacher = teacher;
      localStorage.setItem("teacher", JSON.stringify(teacher));
    },

    getTeacherHours(state, teacherHours) {
      state.teacherHours = teacherHours;
    },

    getTeacherAbsences(state, teacherAbsences) {
      state.teacherAbsences = teacherAbsences;
    },

    getTeacherLessons(state, teacherLessons) {
      state.teacherLessons = teacherLessons;
    },

    getTeacherExperience(state, teacherExperience) {
      state.teacherExperience = teacherExperience;
    },

    getTeacherList(state, teacherList) {
      state.teacherList = teacherList;
    },
  },

  actions: {
    async getTeachers({ commit }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/teacher/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              masterSchoolId: payload.id,
              enabled: payload.enable,
            },
          }
        );
        commit("getTeachers", res.data);
      } catch (e) {
        throw e;
      }
    },

    async setCurrentTeacher({ commit }, id) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/${id}`
        );
        commit("setCurrentTeacher", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getTeacherLessons({ commit }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/teacher/lessons/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              personId: payload.id,
              progTrainPeriodId: payload.progTrainPrdId,
              lessonId: payload.lessonId,
              classId: payload.classId,
              yearId: payload.yearId,
              masterSchoolId: payload.masterSchoolId,
            },
          }
        );
        commit("getTeacherLessons", res.data);
      } catch (e) {
        throw e;
      }
    },

    async addTeacherLesson({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.post(`${process.env.VUE_APP_BASE_URL}/attend/lesson`, {
          lessonId: payload.lessonId,
          personId: payload.personId,
          progTrainPeriodId: payload.progTrainPeriodId,
        });
        await axios.post(`${process.env.VUE_APP_BASE_URL}/class/teacher`, {
          eduClassId: payload.eduClassId,
          endDate: payload.endDate,
          personId: payload.personId,
          startDate: payload.startDate,
        });

        commit("toggleSnackbar", {
          open: true,
          text: "Η σύνδεση ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        if (
          e?.response?.data?.code == 409 &&
          e.response.config.url.split("/")[3] != "class"
        ) {
          await axios.post(`${process.env.VUE_APP_BASE_URL}/class/teacher`, {
            eduClassId: payload.eduClassId,
            endDate: payload.endDate,
            personId: payload.personId,
            startDate: payload.startDate,
          });
          commit("toggleSnackbar", {
            open: true,
            text: "Η σύνδεση ολοκληρώθηκε με επιτυχία",
            color: "success",
          });
        }

        throw e;
      }
    },

    async editTeacherLesson({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/attend/lesson/${payload.attendLessonId}`,
          {
            lessonId: payload.lessonId,
            personId: payload.personId,
            progTrainPeriodId: payload.progTrainPeriodId,
          }
        );
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/class/teacher/${payload.classTeacherId}`,
          {
            eduClassId: payload.eduClassId,
            lessonId: payload.lessonId,
            endDate: payload.endDate,
            personId: payload.personId,
            startDate: payload.startDate,
          }
        );

        commit("toggleSnackbar", {
          open: true,
          text: "Η επεξεργασία σύνδεσης ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          text: `Σφάλμα. παρακαλώ ξαναπροσπαθήστε σε λίγο`,
          color: "error",
        });
        throw e;
      }
    },

    async deleteTeacherLesson({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/attend/lesson/${payload.attendLessonId}`
        );
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/class/teacher/${payload.classTeacherId}`
        );

        commit("toggleSnackbar", {
          open: true,
          text: "Η διαγραφή σύνδεσης ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          text: `Σφάλμα. παρακαλώ ξαναπροσπαθήστε σε λίγο`,
          color: "error",
        });
        throw e;
      }
    },

    async getTeacherAbsences({ commit }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/daily/absence/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              personId: payload.id,
            },
          }
        );
        commit("getTeacherAbsences", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getTeacherHours({ commit }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/teacher/hours/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              personId: payload.id,
              progTrainPeriodId: payload.progTrainPeriodId,
              classId: payload.classId,
              lessonId: payload.lessonId,
              entryDate: payload.entryDate,
            },
          }
        );
        commit("getTeacherHours", res.data);
      } catch (e) {
        throw e;
      }
    },

    async addTeacherHour({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/teacher/hours`,
          payload
        );

        commit("toggleSnackbar", {
          open: true,
          text: "Η δημιουργία παρουσίας ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          text: `Σφάλμα. παρακαλώ ξαναπροσπαθήστε σε λίγο`,
          color: "error",
        });
        throw e;
      }
    },

    async editTeacherHour({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/teacher/hours/${payload.teacherHourId}`,
          payload.content
        );

        commit("toggleSnackbar", {
          open: true,
          text: "Η επεξεργασία παρουσίας ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          text: `Σφάλμα. παρακαλώ ξαναπροσπαθήστε σε λίγο`,
          color: "error",
        });
        throw e;
      }
    },

    async deleteTeacherHour(
      { commit, dispatch, rootState, state },
      teacherHourId
    ) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/teacher/hours/${teacherHourId}`
        );
        commit("toggleSnackbar", {
          open: true,
          text: "Η διαγραφή παρουσίας ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          text: `Σφάλμα. παρακαλώ ξαναπροσπαθήστε σε λίγο`,
          color: "error",
        });
        throw e;
      }
    },

    async getTeacherExperience({ commit }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/experience/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              personId: payload.id,
            },
          }
        );
        commit("getTeacherExperience", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getTeacherList({ commit }, payload) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/2229`,
          payload
        );
        commit("getTeacherList", res.data);
      } catch (e) {
        throw e;
      }
    },
  },
};
