var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper d-flex align-center justify-center"},[_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('v-card',{staticClass:"mb-4 pa-4"},[_c('v-select',{staticClass:"mb-4",attrs:{"dense":"","outlined":"","type":"text","items":_vm.schoolTypesList,"item-text":"description","item-value":"cd","no-data-text":"Δεδομένα μη διαθέσιμα"},on:{"change":_vm.fetchSchools},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Επιλογή ")]},proxy:true}]),model:{value:(_vm.schoolTypeId),callback:function ($$v) {_vm.schoolTypeId=$$v},expression:"schoolTypeId"}})],1),_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          },"headers":_vm.headers,"items":_vm.schools.content,"server-items-length":_vm.schools.elements,"options":_vm.options,"loading":_vm.tableLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.flgActive.cd",fn:function(ref){
          var item = ref.item;
return [(item.flgActive && item.flgActive.cd == '1')?_c('span',[_vm._v(" Ενεργό ")]):_c('span',[_vm._v(" Ανενεργό ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item, 'edit')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pen ")])],1)]}}],null,true)},[_c('span',[_vm._v("Επεξεργασία")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item, 'delete')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Διαγραφή")])])]}}],null,true)})],1),_c('div',{staticClass:"custom-card-buttons"},[(_vm.selectedSystemRole == 'ADMIN_ROLE')?_c('v-btn',{attrs:{"depressed":"","color":"primary ml-auto","disabled":!_vm.filtersSet},on:{"click":function($event){return _vm.openDialog(null, 'add')}}},[_vm._v(" Δημιουργία "),_c('v-icon',{attrs:{"dense":"","right":""}},[_vm._v("mdi-plus")])],1):_vm._e()],1)],1)],1),(_vm.dialog.type == 'add')?_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'add')?_c('AddSchool',{attrs:{"schoolTypeId":_vm.schoolTypeId},on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e(),(_vm.dialog.type == 'edit')?_c('v-dialog',{attrs:{"persistent":"","max-width":"1200px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'edit')?_c('EditSchool',{attrs:{"school":_vm.dialog.school,"schoolTypeId":_vm.schoolTypeId},on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e(),(_vm.dialog.type == 'delete')?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'delete')?_c('DeleteSchool',{attrs:{"school":_vm.dialog.school},on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }