<template>
  <v-card :loading="cardLoader">
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία ρόλου
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4" v-if="!cardLoader">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-select
          dense
          outlined
          v-model="schoolTypeId"
          :items="schoolTypes"
          item-text="description"
          hide-details="auto"
          item-value="cd"
          :error-messages="schoolTypeErrors"
          class="mb-4"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Κατηγορία
          </template>
        </v-select>
        <v-text-field
          dense
          outlined
          v-model="roleName"
          label="Όνομα ρόλου"
          hide-details="auto"
          type="text"
          class="mb-4"
          :error-messages="roleNameErrors"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Όνομα ρόλου
          </template>
        </v-text-field>
        <v-text-field
          dense
          outlined
          v-model="description"
          label="Περιγραφή"
          hide-details="auto"
          type="text"
          class="mb-4"
        ></v-text-field>
        <!-- <v-select
          outlined
          v-model="privileges"
          label="Δικαιώματα πρόσβασης"
          multiple
          chips
          hide-details="auto"
          small-chips
          :items="privilegesList.content"
          class="mb-4"
          item-text="name"
          item-value="id"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
        </v-select> -->
        <v-text-field
          dense
          outlined
          hide-details="auto"
          v-model="defaultPage"
          label="Default page"
          type="text"
          class="mb-4"
        ></v-text-field>

        <v-select
          dense
          outlined
          v-model="systemRole"
          :items="systemRoles"
          item-text="description"
          item-value="cd"
          hide-details="auto"
          :error-messages="systemRoleErrors"
          class="mb-4"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Συστεμικός ρόλος
          </template>
        </v-select>

        <div class="d-flex justify-center">
          <v-btn depressed color="success mr-4" type="submit" :loading="loader"
            >Αποθήκευση</v-btn
          >
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  props: ["role"],

  created() {
    this.cardLoader = true;
    this.roleName = this.role.name;
    this.defaultPage = this.role.defaultPage;
    this.description = this.role.description;
    this.getSchoolTypes();
    this.getSystemRoles();
    this.schoolTypeId = String(this.role.schoolType.id);
    this.systemRole = this.role.systemRole;
    try {
      if (this.role.privileges != undefined) {
        this.privileges = this.role.privileges.map((el) => {
          return el.id;
        });
      }
    } catch (e) {
      console.log(e);
    }

    this.cardLoader = false;
  },

  data() {
    return {
      cardLoader: false,
      loader: false,
      roleName: "",
      schoolTypeId: null,
      privileges: [],
      schoolTypes: [],
      description: null,
      systemRole: null,
      defaultPage: null,
      systemRoles: [],
    };
  },

  validations: {
    roleName: {
      required,
    },
    schoolTypeId: {
      required,
    },
    systemRole: {
      required,
    },
    // privileges: {
    //   required,
    // },
  },

  computed: {
    ...mapState({
      privilegesList: (state) => state.roles.privileges,
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
    }),
    roleNameErrors() {
      const errors = [];
      if (!this.$v.roleName.$dirty) return errors;
      if (!this.$v.roleName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    schoolTypeErrors() {
      const errors = [];
      if (!this.$v.schoolTypeId.$dirty) return errors;
      if (!this.$v.schoolTypeId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    systemRoleErrors() {
      const errors = [];
      if (!this.$v.systemRole.$dirty) return errors;
      if (!this.$v.systemRole.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    privilegesErrors() {
      const errors = [];
      if (!this.$v.privileges.$dirty) return errors;
      if (!this.$v.privileges.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["editRole"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        await this.editRole({
          content: {
            roleName: this.roleName,
            privileges: this.privileges,
            defaultPage: this.defaultPage,
            description: this.description,
            schoolTypeId: this.schoolTypeId,
            systemRole: this.systemRole,
          },
          id: this.role.id,
        });
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
    async getSchoolTypes() {
      let body = {
        type: 1,
        parentCd: null,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/104`,
          body
        );
        this.schoolTypes = res.data;
      } catch (e) {
        throw e;
      }
    },
    async getSystemRoles() {
      let body = {
        type: this.appId,
        parentCd: null,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/2307`,
          body
        );
        this.systemRoles = res.data;
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>