
 <template>
  <div class="component-wrapper d-flex flex-column align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="mb-4 d-flex flex-column pa-4"
    >
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              clearable
              dense
              outlined
              label="Κατηγορίες"
              v-model="categoryType"
              :items="categoryTypes"
              item-text="description"
              item-value="cd"
              no-data-text="Δεδομένα μη διαθέσιμα"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-btn class="ml-auto" depressed color="primary" @click="handler">
        Αναζήτηση</v-btn
      >
    </v-card>

    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
    >
      <v-card-text class="pa-4">
        <v-data-table
          no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
          loading-text="Φόρτωση..."
          :footer-props="{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          }"
          :headers="headers"
          :items="approveApplications.content"
          :server-items-length="approveApplications.elements"
          :options.sync="options"
          :loading="tableLoader"
        >
          <template v-slot:[`item.userName`]="{ item }">
            <span>
              {{ item.person.firstName + " " + item.person.lastName }}
            </span>
          </template>
          <template v-slot:[`item.application`]="{ item }">
            <span v-if="item.onlineApplication.description">
              {{ item.onlineApplication.description }}
            </span>
            <span v-else>
              {{ item.onlineApplication.commonOnlineApplication.description }}
            </span>
          </template>
          <template v-slot:[`item.creationDate`]="{ item }">
            <span>{{
              new Date(item.creationDate).toLocaleDateString("el")
            }}</span>
          </template>

          <template v-slot:[`item.flgPublish`]="{ item }">
            <span v-if="item.flgPublish == 1"> Εγκρίθηκε </span>
            <span v-else-if="item.flgPublish == -1"> Απορρίφθηκε </span>
            <span v-else-if="item.flgPublish == 0"> Εκκρεμεί απάντηση </span>
          </template>

          <template v-slot:[`item.replyAttachFile`]="{ item }">
            <v-btn
              v-if="item.replyAttachFile != null"
              plain
              color="primary"
              class="pa-0"
              @click="onDownload(item.replyAttachFile.id)"
              >{{ item.replyAttachFile.name }}
              <v-icon color="success">mdi-download</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.actions`]="{ item, index }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed icon @click="openDialog(item, 'view')">
                  <v-icon color="primary" small v-bind="attrs" v-on="on">
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <span>Προβολή</span>
            </v-tooltip>
            <v-tooltip
              left
              v-if="
                item.onlineApplication.commonOnlineApplication.reportId !=
                undefined
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  icon
                  @click="onPrint(item)"
                  :loading="loader[index].loader"
                >
                  <v-icon color="primary" small v-bind="attrs" v-on="on">
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>Εκτύπωση</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'view'"
      max-width="1500px"
      scrollable
    >
      <ViewSubmittedApplication
        v-if="dialog.open && dialog.type == 'view'"
        :application="dialog.application"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ViewSubmittedApplication from "../../Applications/ViewSubmittedApplication.vue";
import axios from "axios";
export default {
  components: {
    ViewSubmittedApplication,
  },

  async created() {
    try {
      await this.getCategoryTypes();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      tableLoader: false,
      categoryType: null,
      faculty: null,
      start: false,
      loader: [],
      approveApplications: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Όνομα",
          value: "application",
          sortable: false,
        },

        {
          text: "Ημερομηνία",
          value: "creationDate",
          sortable: false,
        },

        {
          text: "Κατάσταση",
          value: "flgPublish",
          sortable: false,
        },

        {
          text: "Λόγος απόρριψης",
          value: "rejectReason",
          sortable: false,
        },

        {
          text: "Επισυναπτόμενο αρχείο",
          value: "replyAttachFile",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        open: false,
        type: null,
        role: null,
      },
    };
  },

  watch: {
    async options() {
      await this.handler();
    },
  },

  computed: {
    ...mapState({
      categoryTypes: (state) => state.configure_applications.categoryTypes,

      currentApplication: (state) =>
        state.configure_applications.currentApplication,
      currentUser: (state) => state.users.currentUser,
    }),
  },

  methods: {
    ...mapMutations(["setFilters"]),
    ...mapActions([
      "getCurrentApplication",
      "getCategoryTypes",
      "downloadFile",
    ]),

    async onDownload(id) {
      try {
        await this.downloadFile(id);
      } catch (e) {
        console.log(e);
      }
    },

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/application/list`,
          {
            params: {
              personId: this.currentUser.id,
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,

              categoryType:
                this.categoryType != null ? parseInt(this.categoryType) : null,
            },
          }
        );
        this.approveApplications = res.data;

        this.loader = res.data.content.map((a) => {
          return { id: a.id, loader: false };
        });
        this.tableLoader = false;
        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
      } catch (e) {
        this.approveApplications = [];
        this.$router.dispatch("errorHandler", e);

        console.log(e);
        this.tableLoader = false;
      }
    },

    openDialog(application, type, appType) {
      this.dialog = {
        application,
        open: true,
        type,
        appType: appType != null ? appType : null,
      };
    },

    closeDialog() {
      this.dialog = {
        application: null,
        open: false,
        type: null,
        appType: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
    async onPrint(item, index) {
      try {
        console.log(item);
        this.loader[index].loader = true;
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/application/${item.id}/print`,
          {
            responseType: "blob",
          }
        );
        console.log(response);

        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: `application/pdf`,
          })
        );
        var fileLink = document.createElement("a");

        let name =
          item.onlineApplication.description != undefined
            ? item.onlineApplication.description
            : item.onlineApplication.commonOnlineApplication.description;
        fileLink.href = fileURL;

        fileLink.setAttribute("download", name + ".pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loader[index].loader = false;
      } catch (e) {
        this.loader[index].loader = false;

        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>