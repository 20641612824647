import axios from 'axios'

export default {
    state: {
        classExamTeachers: []
    },

    mutations: {
        getClassExamTeachers(state, classExamTeachers) {
            state.classExamTeachers = classExamTeachers
        }
    },

    actions: {
        async getClassExamTeachers({ commit, dispatch }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/class/exam/teacher/list`,
                    {
                        params: {
                            page: payload.page,
                            limit: payload.limit,
                            progExamPeriodId: payload.progExamPeriodId,
                            classExamId: payload.classExamId
                        }
                    })
                commit('getClassExamTeachers', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async addClassExamTeacher({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/class/exam/teacher`, payload)

                commit('toggleSnackbar', {
                    open: true,
                    text: `Η προσθήκη επιτηρητή σε τμήμα ολοκληρώθηκε με επιτυχία`,
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async editClassExamTeacher({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/class/exam/teacher/${payload.classExamTeacherId}`, payload.content)


                commit('toggleSnackbar', {
                    open: true,
                    text: `Η επεξεργασία επιτηρητή σε τμήμα ολοκληρώθηκε με επιτυχία`,
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async deleteClassExamTeacher({ commit, dispatch, rootState, state }, classExamTeacherId) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/class/exam/teacher/${classExamTeacherId}`)


                commit('toggleSnackbar', {
                    open: true,
                    text: `Η διαγραφή επιτηρητή ολοκληρώθηκε με επιτυχία`,
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },




    }
}