<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία ταυτοποίησης
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-select
          dense
          outlined
          v-model="type"
          type="text"
          class="mb-4"
          :error-messages="typeErrors"
          :items="getDataStaticList(101)"
          item-text="description"
          item-value="cd"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Τύπος ταυτοποίησης
          </template>
        </v-select>

        <v-text-field
          dense
          outlined
          v-model="folderNumber"
          class="mb-4"
          :error-messages="folderNumberErrors"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Αριθμός εγγράφου
          </template>
        </v-text-field>

        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          v-if="type == '3'"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              v-model="issueDateFormatted"
              class="mb-4"
              @blur="issueDate = parseDate(issueDateFormatted, 'issueDate')"
            >
              <template v-slot:prepend-inner>
                <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
              </template>
              <template v-slot:label>
                <!-- <span class="error--text">*</span> -->
                Ημερομηνία έκδοσης
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            locale="el"
            v-model="issueDate"
            scrollable
            @input="menu2 = false"
          >
          </v-date-picker>
        </v-menu>

        <v-text-field
          dense
          outlined
          v-model="issuingAuthority"
          class="mb-4"
          v-if="type == '3'"
        >
          <template v-slot:label>
            <!-- <span class="error--text">*</span> -->
            Εκδούσα Αρχή
          </template>
        </v-text-field>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              v-model="startDateFormatted"
              class="mb-4"
              @blur="startDate = parseDate(startDateFormatted, 'startDate')"
              :error-messages="startDateErrors"
            >
              <template v-slot:prepend-inner>
                <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
              </template>
              <template v-slot:label>
                <span class="error--text">*</span>
                Ημερομηνία έναρξης
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            locale="el"
            v-model="startDate"
            scrollable
            @input="menu = false"
          >
          </v-date-picker>
        </v-menu>

        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              v-model="endDateFormatted"
              class="mb-4"
              @blur="endDate = parseDate(endDateFormatted, 'endDate')"
            >
              <template v-slot:prepend-inner>
                <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
              </template>
              <template v-slot:label> Ημερομηνία λήξης </template>
            </v-text-field>
          </template>
          <v-date-picker
            locale="el"
            v-model="endDate"
            scrollable
            @input="menu1 = false"
          >
          </v-date-picker>
        </v-menu>

        <v-textarea
          dense
          outlined
          v-model="comments"
          label="Σχόλια"
        ></v-textarea>

        <v-file-input
          label="Επισυναπτόμενο αρχείο"
          dense
          outlined
          v-model="attachedFile"
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
        >
        </v-file-input>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" class="mr-4" @click="$emit('close')"
            >Ακύρωση</v-btn
          >
          <v-btn depressed color="success " type="submit" :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  props: ["userType"],
  data() {
    return {
      loader: false,
      menu: false,
      menu1: false,
      menu2: false,
      type: "",
      folderNumber: "",
      startDate: "",
      startDateFormatted: "",
      endDate: "",
      endDateFormatted: "",
      comments: "",
      attachedFile: [],
      issueDate: "",
      issueDateFormatted: "",
      issuingAuthority: null,
    };
  },

  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
    },

    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
    issueDate(val) {
      this.issueDateFormatted = this.formatDate(this.issueDate);
    },
  },

  validations: {
    type: {
      required,
    },
    folderNumber: {
      required,
    },
    startDate: {
      required,
    },
  },

  computed: {
    ...mapState({
      staticList: (state) => state.auth.userInfo.staticView,

      currentUser: (state) => state.users.currentUser,
      currentTeacher: (state) => state.teachers.currentTeacher,
    }),

    typeErrors() {
      const errors = [];
      if (!this.$v.type.$dirty) return errors;
      if (!this.$v.type.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    folderNumberErrors() {
      const errors = [];
      if (!this.$v.folderNumber.$dirty) return errors;
      if (!this.$v.folderNumber.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    startDateErrors() {
      const errors = [];
      if (!this.$v.startDate.$dirty) return errors;
      if (!this.$v.startDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addPistopoihsh"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        let formData = new FormData();
        formData.append("documentNumber", this.folderNumber);
        formData.append("documentType", this.type);
        formData.append("validDateFrom", this.startDate);
        formData.append("issuingAuthority", this.issuingAuthority);

        if (this.endDate != "") formData.append("validDateTo", this.endDate);

        if (this.issueDate != "") formData.append("issueDate", this.issueDate);

        if (this.issuingAuthority != null)
          formData.append("issuingAuthority", this.issuingAuthority);

        if (this.attachedFile.length != 0)
          formData.append("attachedFile", this.attachedFile);

        if (this.comments != null) {
          formData.append("comments", this.comments);
        }
        if (this.userType == "user") {
          formData.append("personId", this.currentUser.id);
        } else if (this.userType == "teacher") {
          formData.append("personId", this.currentTeacher.id);
        }
        await this.addPistopoihsh(formData);
        this.loader = false;
        await this.$emit("handler");
        this.$emit("close");
      } catch (e) {
        console.log(e);
        this.loader = false;
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "startDate") {
          this.startDate = null;
          this.startDateFormatted = null;
        } else if (dateName == "endDate") {
          this.endDate = null;
          this.endDateFormatted = null;
        } else if (dateName == "issueDate") {
          this.issueDate = null;
          this.issueDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>

