<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card v-if="pageLoader" max-width="1400px" width="100%" height="100%" class="pa-4 d-flex flex-column" id="form">
      <v-breadcrumbs :items="breadcrumb_items" divider=">"> </v-breadcrumbs>

      <v-card class="mb-4" :loading="cardLoader">
        <v-card-title class="mb-4 greyish--text">
          Προσωπικά στοιχεία
        </v-card-title>
        <v-card-text v-if="!cardLoader">
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field dense outlined v-model="firstName" type="text" :error-messages="firstNameErrors">
                <template v-slot:label>
                  Όνομα <span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field dense outlined v-model="lastName" type="text" :error-messages="lastNameErrors">
                <template v-slot:label>
                  Επώνυμο <span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field dense outlined v-model="fatherName" type="text" :error-messages="fatherNameErrors">
                <template v-slot:label>
                  Πατρώνυμο <span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field dense outlined v-model="motherName" label="Μητρώνυμο " type="text"
                :error-messages="motherNameErrors">
                <template v-slot:label>
                  Μητρώνυμο <span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense outlined v-model="dateOfBirthFormatted" prepend-inner-icon="mdi-calendar"
                    class="mb-4" @blur="
                      dateOfBirth = parseDate(
                        dateOfBirthFormatted,
                        'dateOfBirth'
                      )
                    " :error-messages="dateOfBirthErrors">
                    <template v-slot:label>
                      Ημερομηνία γέννησης <span class="error--text">*</span>
                    </template>
                    <template v-slot:prepend-inner>
                      <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker locale="el" v-model="dateOfBirth" scrollable @input="menu = false">
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field dense outlined v-model="countryOfBirth" type="text">
                <template v-slot:label> Χώρα γέννησης </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field dense outlined v-model="regionOfBirth" type="text">
                <template v-slot:label> Περιοχή γέννησης </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field dense outlined v-model="cityOfBirth" type="text">
                <template v-slot:label>
                  Πόλη γέννησης
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-select clearable dense outlined v-model="sexId" :error-messages="sexIdErrors"
                :items="getDataStaticList(128)" item-text="description" item-value="cd"
                no-data-text="Δεδομένα μη διαθέσιμα">
                <template v-slot:label>
                  Φύλο <span class="red--text">*</span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field dense outlined v-model="municipalRegister" label="Δημοτολόγιο " type="text">
                <template v-slot:label>
                  Δημοτολόγιο
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-select clearable dense outlined v-model="swimAbility" :items="getDataStaticList(100)"
                item-text="description" item-value="cd" no-data-text="Δεδομένα μη διαθέσιμα"
                v-if="this.$route.path.split('/')[1] == 'students'">
                <template v-slot:label> Κολύμβηση </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row v-if="!cardLoader">
        <v-col cols="12" sm="6" md="3">
          <v-textarea dense outlined v-model="comments" label="Σχόλια">
          </v-textarea>
        </v-col>
      </v-row>

      <v-card class="mb-4 d-flex flex-column" :loading="cardLoader">
        <v-card-title class="greyish--text font-weight-regular">Στοιχεία επικοινωνίας</v-card-title>
        <v-divider></v-divider>

        <v-data-table no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα" loading-text="Φόρτωση..." :footer-props="{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        }" :headers="userAddressHeaders" :items="userAddress.content" :server-items-length="userAddress.elements"
          :options.sync="userAddressOptions" :loading="userAddressTableLoader" class="pa-4">
          <template v-slot:[`item.startDate`]="{ item }">
            {{ new Date(item.startDate).toLocaleDateString("el") }}
          </template>
          <template v-slot:[`item.actions`]="{ item }" v-if="this.selectedSystemRole == 'SECRETARY_ROLE'">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed icon @click="openDialogAddress(item, 'edit')">
                  <v-icon color="primary" small v-bind="attrs" v-on="on">
                    mdi-pen
                  </v-icon>
                </v-btn>
              </template>
              <span>Επεξεργασία</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed icon @click="openDialogAddress(item, 'delete')">
                  <v-icon color="error" small v-bind="attrs" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Διαγραφή</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.dateFrom`]="{ item }">
            <span>{{ new Date(item.dateFrom).toLocaleDateString("el") }}</span>
          </template>
        </v-data-table>
        <v-btn dense v-if="this.selectedSystemRole == 'SECRETARY_ROLE'" color="primary ml-auto mb-2 mr-4"
          @click="openDialogAddress(currentUser, 'add')">Δημιουργία <v-icon dense>mdi-plus</v-icon>
        </v-btn>
      </v-card>

      <v-card class="mb-8 d-flex flex-column">
        <v-card-title class="greyish--text font-weight-regular">Εγγραφές</v-card-title>
        <v-divider></v-divider>

        <v-data-table no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα" loading-text="Φόρτωση..." :footer-props="{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        }" :headers="
  appId == 1 ? userRegisterHeadersAen : userRegisterHeadersKesen
" :items="userRegisters.content" :server-items-length="userRegisters.elements"
          :options.sync="userRegistersOptions" :loading="userRegistersTableLoader" class="pa-4">
          <template v-slot:[`item.startDate`]="{ item }">
            {{ new Date(item.startDate).toLocaleDateString("el") }}
          </template>

          <template v-slot:[`item.endDate`]="{ item }">
            {{
                item.endDate != null
                  ? new Date(item.endDate).toLocaleDateString("el")
                  : ""
            }}
          </template>

          <template v-slot:[`item.actions`]="{ item }" v-if="this.selectedSystemRole == 'ADMIN_ROLE'">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed icon @click="openDialogRegister(item, 'edit')">
                  <v-icon color="primary" small v-bind="attrs" v-on="on">
                    mdi-pen
                  </v-icon>
                </v-btn>
              </template>
              <span>Επεξεργασία</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed icon @click="openDialogRegister(item, 'delete')">
                  <v-icon color="error" small v-bind="attrs" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Διαγραφή</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-btn dense v-if="this.selectedSystemRole == 'ADMIN_ROLE'" color="primary ml-auto mb-2 mr-4"
          @click="openDialogRegister({ person: currentUser }, 'add')">Δημιουργία <v-icon dense>mdi-plus</v-icon>
        </v-btn>
      </v-card>

      <v-card class="mb-8 d-flex flex-column">
        <v-card-title class="greyish--text font-weight-regular">Στοιχεία ταυτοποίησης</v-card-title>
        <v-divider></v-divider>

        <v-data-table no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα" loading-text="Φόρτωση..." :footer-props="{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        }" :headers="headers" :items="pistopoihseis.content" :server-items-length="pistopoihseis.elements"
          :options.sync="options" :loading="tableLoader" class="pa-4">
          <template v-slot:[`item.validDateFrom`]="{ item }">
            <span>{{
                new Date(item.validDateFrom).toLocaleDateString("el")
            }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed icon @click="openDialog(item, 'edit')">
                  <v-icon color="primary" small v-bind="attrs" v-on="on">
                    mdi-pen
                  </v-icon>
                </v-btn>
              </template>
              <span>Επεξεργασία</span>
            </v-tooltip>

            <v-tooltip left v-if="selectedSystemRole == 'ADMIN_ROLE'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed icon @click="openDialog(item, 'delete')">
                  <v-icon color="error" small v-bind="attrs" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Διαγραφή</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-btn dense color="primary ml-auto mb-2 mr-4" @click="openDialog(null, 'add')">
          Δημιουργία
          <v-icon dense>mdi-plus</v-icon>
        </v-btn>
      </v-card>

      <div class="d-flex align-center ml-auto">

        <v-btn v-if="$route.path.split('/')[1] == 'users'" depressed color="error" @click="$router.push('/users')">
          Επιστροφή
        </v-btn>
        <v-btn v-if="$route.path.split('/')[1] == 'teachers'" depressed color="error"
          @click="$router.push('/teachers')">Επιστροφή
        </v-btn>

        <v-btn v-if="$route.path.split('/')[1] == 'students'" depressed color="error"
          @click="$router.push('/students')">Επιστροφή
        </v-btn>
        <v-btn depressed color="primary" class="ml-2" @click="onSave" :loading="loader">Αποθήκευση
        </v-btn>
      </div>
    </v-card>

    <v-progress-circular v-else indeterminate color="primary" class="ml-auto mr-auto mt-auto mb-auto">
    </v-progress-circular>

    <v-dialog persistent v-model="dialog.open" v-if="dialog.type == 'add'" max-width="500px" scrollable>
      <AddPistopoihsh v-if="dialog.open && dialog.type == 'add'" :userType="'user'" @close="closeDialog" />
    </v-dialog>

    <v-dialog persistent v-model="dialog.open" v-if="dialog.type == 'edit'" max-width="500px" scrollable>
      <EditPistopoihsh v-if="dialog.open && dialog.type == 'edit'" :pistopoihsh="dialog.pistopoihsh" :userType="'user'"
        @close="closeDialog" />
    </v-dialog>

    <v-dialog persistent v-model="dialog.open" v-if="dialog.type == 'delete'" max-width="400px" scrollable>
      <DeletePistopoihsh v-if="dialog.open && dialog.type == 'delete'" :pistopoihsh="dialog.pistopoihsh"
        :userType="'user'" @close="closeDialog" />
    </v-dialog>

    <v-dialog persistent v-model="dialog.open" v-if="dialog.type == 'add'" max-width="500px" scrollable>
      <AddPistopoihsh v-if="dialog.open && dialog.type == 'add'" :userType="'user'" @close="closeDialog"
        @handler="handler1" />
    </v-dialog>

    <v-dialog persistent v-model="dialog.open" v-if="dialog.type == 'edit'" max-width="500px" scrollable>
      <EditPistopoihsh v-if="dialog.open && dialog.type == 'edit'" :pistopoihsh="dialog.pistopoihsh" :userType="'user'"
        @close="closeDialog" @handler="handler1" />
    </v-dialog>

    <v-dialog persistent v-model="dialog.open" v-if="dialog.type == 'delete'" max-width="400px" scrollable>
      <DeletePistopoihsh v-if="dialog.open && dialog.type == 'delete'" :pistopoihsh="dialog.pistopoihsh"
        :userType="'user'" @close="closeDialog" @handler="handler1" :pistopoihseis="pistopoihseis" />
    </v-dialog>

    <v-dialog persistent v-model="dialogAddress.open" v-if="dialogAddress.type == 'add'" max-width="1000px" scrollable>
      <AddPersonAddress v-if="dialogAddress.open && dialogAddress.type == 'add'" @close="closeDialogAddress"
        @handler="handler3" />
    </v-dialog>

    <v-dialog persistent v-model="dialogAddress.open" v-if="dialogAddress.type == 'edit'" max-width="1000px" scrollable>
      <EditPersonAddress v-if="dialogAddress.open && dialogAddress.type == 'edit'"
        :pistopoihsh="dialogAddress.pistopoihsh" :address="dialogAddress.address" @close="closeDialogAddress"
        @handler="handler3" />
    </v-dialog>

    <v-dialog persistent v-model="dialogAddress.open" v-if="dialogAddress.type == 'delete'" max-width="400px"
      scrollable>
      <DeletePersonAddress v-if="dialogAddress.open && dialogAddress.type == 'delete'"
        :pistopoihsh="dialogAddress.pistopoihsh" :address="dialogAddress.address" @close="closeDialogAddress"
        @handler="handler3" :userAddress="userAddress" />
    </v-dialog>

    <v-dialog v-if="
      dialogRegister.open == true &&
      (dialogRegister.type == 'edit' || dialogRegister.type == 'add')
    " persistent v-model="dialogRegister.open" max-width="800px" scrollable>
      <PersonRegister @close="closePersonDialog" :data="dialogRegister" :user="currentUser" />
    </v-dialog>
    <v-dialog persistent v-if="dialogRegister.open && dialogRegister.type == 'delete'" v-model="dialogRegister.open"
      max-width="800px" scrollable>
      <DeleteRegister :person="dialogRegister.person" @close="closePersonDialog" />
    </v-dialog>
  </div>
</template>

<script>
import AddPistopoihsh from "../../components/UserModals/Pistopoihseis/AddPistopoihsh.vue";
import EditPistopoihsh from "../../components/UserModals/Pistopoihseis/EditPistopoihsh.vue";
import DeletePistopoihsh from "../../components/UserModals/Pistopoihseis/DeletePistopoihsh.vue";
import PersonRegister from "../../components/UserModals/PersonRegister.vue";
import DeleteRegister from "../../components/UserModals/DeleteRegister.vue";
import AddPersonAddress from "../../components/UserModals/PersonAddress/AddPersonAddress.vue";
import EditPersonAddress from "../../components/UserModals/PersonAddress/EditPersonAddress.vue";
import DeletePersonAddress from "../../components/UserModals/PersonAddress/DeletePersonAddress.vue";

import axios from "axios";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

import { mapState, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  async created() {
    await this.setCurrentUser(this.$route.path.split("/")[2]);
    this.pageLoader = true;

    if (this.$route.path.split("/")[1] == "users")
      this.breadcrumb_items.push(
        {
          text: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
          disabled: false,
          link: true,
          exact: true,
          to: "/users",
        },
        {
          text: `Επεξεργασία χρήστη
      `,
          disabled: true,
          to: "/users/edit",
        }
      );

    if (this.$route.path.split("/")[1] == "teachers")
      this.breadcrumb_items.push(
        {
          text: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
          disabled: false,
          link: true,
          exact: true,
          to: "/teachers",
        },
        {
          text: `Επεξεργασία χρήστη
      `,
          disabled: true,
        }
      );

    if (this.$route.path.split("/")[1] == "students")
      this.breadcrumb_items.push(
        {
          text: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
          disabled: false,
          link: true,
          exact: true,
          to: "/students",
        },
        {
          text: `Επεξεργασία χρήστη
      `,
          disabled: true,
        }
      );

    if (this.$route.path.split("/")[1] == "operators")
      this.breadcrumb_items.push(
        {
          text: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
          disabled: false,
          link: true,
          exact: true,
          to: "/operators",
        },
        {
          text: `Επεξεργασία χειριστή
      `,
          disabled: true,
        }
      );

    this.firstName = this.currentUser.firstName;
    this.lastName = this.currentUser.lastName;
    this.fatherName = this.currentUser.fatherName;
    this.motherName = this.currentUser.motherName;
    this.cityOfBirth = this.currentUser.cityOfBirth;
    this.countryOfBirth = this.currentUser.countryOfBirth;
    this.regionOfBirth = this.currentUser.regionOfBirth;
    this.dateOfBirth = this.currentUser.dateOfBirth;
    this.sexId = this.currentUser.sex.cd;
    if (this.$route.path.split("/")[1] == "students") {
      this.swimAbility = this.currentUser?.swimAbility?.cd;
    }
    this.municipalRegister = this.currentUser.municipalityRegister;
    this.comments = this.currentUser.comments;
  },

  components: {
    AddPistopoihsh,
    EditPistopoihsh,
    DeletePistopoihsh,
    PersonRegister,
    AddPersonAddress,
    EditPersonAddress,
    DeletePersonAddress,
    DeleteRegister,
  },

  data() {
    return {
      pageLoader: false,
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      loader: false,
      pistopoihseis: [],
      userRegisters: [],
      userAddress: [],
      tableLoader: false,
      userRegistersTableLoader: false,
      userAddressTableLoader: false,
      cardLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },

      userRegistersOptions: {
        itemsPerPage: 10,
        page: 1,
      },

      userAddressOptions: {
        itemsPerPage: 10,
        page: 1,
      },

      breadcrumb_items: [],

      firstName: "",
      firstNameLatin: "",
      lastName: "",
      lastNameLatin: "",
      fatherName: "",
      fatherNameLatin: "",
      motherName: "",
      motherNameLatin: "",

      countryOfBirth: "",
      countryOfBirthLatin: "",
      dateOfBirth: "",
      dateOfBirthFormatted: "",

      cityOfBirth: "",
      cityOfBirthLatin: "",
      regionOfBirth: "",
      regionOfBirthLatin: "",
      startDate: "",
      dateEnd: "",
      sexId: "",
      swimAbility: "",
      municipalRegister: "",

      phone: "",
      addressDateFrom: "",
      addressDateFromFormatted: "",
      addressDateTo: "",
      addressDateToFormatted: "",

      streetName: "",
      num: "",

      city: "",
      region: "",
      zipCode: null,

      firstFlagId: "1",
      comments: "",

      headers: [
        {
          text: "Τύπος",
          value: "documentType.description",
          sortable: false,
        },
        {
          text: "Αριθμός εγγράφου",
          value: "documentNumber",
          sortable: false,
        },
        {
          text: "Ημερομηνία έναρξης",
          value: "validDateFrom",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      userRegisterHeadersAen: [
        {
          text: "Είδος εγγραφής",
          value: "propertyType.description",
          sortable: false,
        },
        {
          text: "Ημερομηνία έναρξης",
          value: "startDate",
          sortable: false,
        },
        {
          text: "Ημερομηνία λήξης",
          value: "endDate",
          sortable: false,
        },
        {
          text: "Ακαδημία",
          value: "masterSchool.description",
          sortable: false,
        },
        {
          text: "Σχολή",
          value: "school.school.description",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      userRegisterHeadersKesen: [
        {
          text: "Είδος εγγραφής",
          value: "propertyType.description",
          sortable: false,
        },
        {
          text: "Ημερομηνία έναρξης",
          value: "startDate",
          sortable: false,
        },
        {
          text: "Ημερομηνία λήξης",
          value: "endDate",
          sortable: false,
        },
        {
          text: "Δομή Μετεκπαίδευσης",
          value: "masterSchool.description",
          sortable: false,
        },
        {
          text: "Εκπαιδευτικός Κύκλος/Ειδικό Τμήμα",
          value: "school.school.description",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      userAddressHeaders: [
        {
          text: "Τύπος",
          value: "type.description",
          sortable: false,
        },
        {
          text: "Πόλη",
          value: "city",
          sortable: false,
        },

        {
          text: "Διεύθυνση",
          value: "streetName",
          sortable: false,
        },

        {
          text: "Αριθμός",
          value: "num",
          sortable: false,
        },
        {
          text: "Ταχυδρομικός κώδικας",
          value: "zipCode",
          sortable: false,
        },

        {
          text: "Ημερομηνία έναρξης",
          value: "dateFrom",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      dialogAddress: {
        open: false,
        type: null,
        address: null,
      },

      dialogRegister: {
        open: false,
        type: null,
        person: null,
      },
      dialog: {
        open: false,
        type: null,
        user: null,
      },
    };
  },

  validations: {
    firstName: {
      required,
    },

    lastName: {
      required,
    },

    fatherName: {
      required,
    },

    motherName: {
      required,
    },

    /*countryOfBirth: {
      required,
    },*/

    dateOfBirth: {
      required,
    },

    /* cityOfBirth: {
       required,
     },*/

    sexId: {
      required,
    },
    /* municipalRegister: {
       required,
     },*/
    /*  regionOfBirth: {
      required,
    },*/
  },

  watch: {
    async options() {
      await this.handler1();
    },

    async userRegistersOptions() {
      await this.handler2();
    },

    async userAddressOptions() {
      await this.handler3();
    },

    dateOfBirth(val) {
      this.dateOfBirthFormatted = this.formatDate(this.dateOfBirth);
    },

    addressDateFrom(val) {
      this.addressDateFromFormatted = this.formatDate(this.addressDateFrom);
    },

    addressDateTo(val) {
      this.addressDateToFormatted = this.formatDate(this.addressDateTo);
    },
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      staticList: (state) => state.auth.userInfo.staticView,
      pageChanged: (state) => state.base.pageChanged,
      selectedRole: (state) => state.auth.selectedRole,
      selectedSystemRole: (state) => state.auth.selectedSystemRole,
      appId: (state) => state.auth.appId,
    }),

    //validations
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      if (!this.$v.firstName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    firstNameLatinErrors() {
      const errors = [];
      if (!this.$v.firstNameLatin.$dirty) return errors;
      if (!this.$v.firstNameLatin.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      if (!this.$v.lastName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    lastNameLatinErrors() {
      const errors = [];
      if (!this.$v.lastNameLatin.$dirty) return errors;
      if (!this.$v.lastNameLatin.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    fatherNameErrors() {
      const errors = [];
      if (!this.$v.fatherName.$dirty) return errors;
      if (!this.$v.fatherName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    fatherNameLatinErrors() {
      const errors = [];
      if (!this.$v.fatherNameLatin.$dirty) return errors;
      if (!this.$v.fatherNameLatin.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    motherNameErrors() {
      const errors = [];
      if (!this.$v.motherName.$dirty) return errors;
      if (!this.$v.motherName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    motherNameLatinErrors() {
      const errors = [];
      if (!this.$v.motherNameLatin.$dirty) return errors;
      if (!this.$v.motherNameLatin.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    countryOfBirthErrors() {
      const errors = [];
      if (!this.$v.countryOfBirth.$dirty) return errors;
      if (!this.$v.countryOfBirth.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    countryOfBirthLatinErrors() {
      const errors = [];
      if (!this.$v.countryOfBirthLatin.$dirty) return errors;
      if (!this.$v.countryOfBirthLatin.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    dateOfBirthErrors() {
      const errors = [];
      if (!this.$v.dateOfBirth.$dirty) return errors;
      if (!this.$v.dateOfBirth.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    cityOfBirthErrors() {
      const errors = [];
      if (!this.$v.cityOfBirth.$dirty) return errors;
      if (!this.$v.cityOfBirth.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    cityOfBirthLatinErrors() {
      const errors = [];
      if (!this.$v.cityOfBirthLatin.$dirty) return errors;
      if (!this.$v.cityOfBirthLatin.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    regionOfBirthErrors() {
      const errors = [];
      if (!this.$v.regionOfBirth.$dirty) return errors;
      if (!this.$v.regionOfBirth.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    regionOfBirthLatinErrors() {
      const errors = [];
      if (!this.$v.regionOfBirthLatin.$dirty) return errors;
      if (!this.$v.regionOfBirthLatin.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    sexIdErrors() {
      const errors = [];
      if (!this.$v.sexId.$dirty) return errors;
      if (!this.$v.sexId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    municipalRegisterErrors() {
      const errors = [];
      if (!this.$v.municipalRegister.$dirty) return errors;
      if (!this.$v.municipalRegister.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      if (!this.$v.phone.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.phone.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      if (!this.$v.phone.minLength) errors.push("Τουλάχιστον 10 ψηφία");
      if (!this.$v.phone.maxLength) errors.push("Μέχρι 12 ψηφία");
      return errors;
    },

    addressDateFromErrors() {
      const errors = [];
      if (!this.$v.addressDateFrom.$dirty) return errors;
      if (!this.$v.addressDateFrom.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    streetNameErrors() {
      const errors = [];
      if (!this.$v.streetName.$dirty) return errors;
      if (!this.$v.streetName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    numErrors() {
      const errors = [];
      if (!this.$v.num.$dirty) return errors;
      if (!this.$v.num.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.num.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },

    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      if (!this.$v.city.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    regionErrors() {
      const errors = [];
      if (!this.$v.region.$dirty) return errors;
      if (!this.$v.region.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    zipCodeErrors() {
      const errors = [];
      if (!this.$v.zipCode.$dirty) return errors;
      if (!this.$v.zipCode.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.zipCode.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },

    //validations
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["editUser", "setCurrentUser"]),

    async onSave() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          //todo scroll gia mobile scroll sto prwto error
          document
            .getElementById("form")
            .scrollIntoView({ behavior: "smooth" });
          return;
        }
        this.loader = true;
        let content = {
          cityOfBirth: this.cityOfBirth,

          countryOfBirth: this.countryOfBirth,

          regionOfBirth: this.regionOfBirth,

          dateOfBirth: this.dateOfBirth,
          fatherName: this.fatherName,

          firstName: this.firstName,

          lastName: this.lastName,

          motherName: this.motherName,

          sex: this.sexId,

          // swimAbility: this.swimAbility,

          municipalRegister: this.municipalRegister,
          comments: this.comments,
        };

        if (this.$route.path.split("/")[1] == "students") {
          content.swimAbility = this.swimAbility;
        }

        await this.editUser({
          id: this.currentUser.id,
          content,
        });
        this.loader = false;
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "startDate") {
          this.startDate = null;
          this.startDateFormatted = null;
        } else if (dateName == "endDate") {
          this.endDate = null;
          this.endDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    openDialog(pistopoihsh, type) {
      this.dialog = {
        pistopoihsh,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        pistopoihsh: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
    openDialogRegister(item, type) {
      console.log(item);
      this.dialogRegister = {
        open: true,
        type: type,
        person: item,
      };
    },
    closePersonDialog() {
      this.dialogRegister = {
        open: false,
        type: null,
        person: null,
      };
      this.handler2();
    },

    async handlerPersonRegister() {
      try {
        this.userRegistersTableLoader = true;
        this.setFilters(this.userRegistersOptions);
        await this.getUserRegisters({
          id: this.$route.path.split("/")[2],
          filters: {
            page: this.userRegistersOptions.page,
            limit:
              this.userRegistersOptions.itemsPerPage == -1
                ? this.userRegisters.elements
                : this.userRegistersOptions.itemsPerPage,
          },
        });
        this.userRegistersTableLoader = false;
      } catch (e) {
        console.log(e);
        this.userRegistersTableLoader = false;
      }
    },
    openDialogAddress(item, type) {
      this.dialogAddress = {
        open: true,
        type: type,
        address: item,
      };
    },
    closeDialogAddress() {
      this.dialogAddress = {
        open: false,
        type: null,
        address: null,
      };
      this.handler3();
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },

    async handler1() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/authorization/document/list`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              personId: this.$route.path.split("/")[2],
            },
          }
        );

        this.pistopoihseis = res.data;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.pistopoihseis = [];

        this.tableLoader = false;
      }
    },

    async handler2() {
      try {
        this.userRegistersTableLoader = true;
        this.setFilters(this.userRegistersOptions);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/register/list`,
          {
            params: {
              page: this.userRegistersOptions.page,
              limit:
                this.userRegistersOptions.itemsPerPage == -1
                  ? this.userRegisters.elements
                  : this.userRegistersOptions.itemsPerPage,
              personId: this.$route.path.split("/")[2],
            },
          }
        );
        this.userRegisters = res.data;
        this.userRegistersTableLoader = false;
      } catch (e) {
        console.log(e);
        this.userRegisters = [];

        this.userRegistersTableLoader = false;
      }
    },

    async handler3() {
      try {
        this.userAddressTableLoader = true;
        this.setFilters(this.userAddressOptions);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/address/list`,
          {
            params: {
              page: this.userAddressOptions.page,
              limit:
                this.userAddressOptions.itemsPerPage == -1
                  ? this.userAddress.elements
                  : this.userAddressOptions.itemsPerPage,
              personId: this.$route.path.split("/")[2],
            },
          }
        );

        this.userAddress = res.data;

        this.userAddressTableLoader = false;
      } catch (e) {
        console.log(e);
        this.userAddress = [];

        this.userAddressTableLoader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>