
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="shipCompanies.content"
            :options.sync="options"
            :server-items-length="shipCompanies.elements"
            :loading="tableLoader"
          >
            <template v-slot:top>
              <v-text-field
                dense
                outlined
                v-model="searchText"
                label="Όνομα"
                class="mx-4"
                @keyup.enter="handler"
                clearable
              ></v-text-field>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.vessel`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onVesselView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.companyRep`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onCompanyRepView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.streetName`]="{ item }">
              <span>
                {{ item.streetName + " " + item.number }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <div class="ml-auto">
        <v-btn
          depressed
          color="primary"
          class="mr-2"
          @click="openDialog(null, 'add')"
        >
          Δημιουργία
          <v-icon dense right>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="800px"
      scrollable
    >
      <AddShipCompany
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="800px"
      scrollable
    >
      <EditShipCompany
        v-if="dialog.open && dialog.type == 'edit'"
        :shipCompany="dialog.shipCompany"
        @handler="handler"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteShipCompany
        v-if="dialog.open && dialog.type == 'delete'"
        :shipCompany="dialog.shipCompany"
        @close="closeDialog"
        @handler="handler"
        :shipCompanies="shipCompanies"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import AddShipCompany from "../../components/ShipCompanyModals/AddShipCompany.vue";
import EditShipCompany from "../../components/ShipCompanyModals/EditShipCompany.vue";
import DeleteShipCompany from "../../components/ShipCompanyModals/DeleteShipCompany.vue";
import axios from "axios";
export default {
  components: {
    AddShipCompany,
    EditShipCompany,
    DeleteShipCompany,
  },

  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      shipCompanies: [],
      searchText: null,
      headers: [
        {
          text: "Όνομα",
          value: "name",
          sortable: false,
        },

        {
          text: "Πλοία",
          value: "vessel",
          sortable: false,
        },

        {
          text: "Εκπρόσωποι/Εκπαιδευτές",
          value: "companyRep",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        shipCompany: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      await this.handler();
    },
  },

  computed: {
    ...mapState({
      pageChanged: (state) => state.base.pageChanged,
    }),
  },

  methods: {
    ...mapMutations(["changePage", "setCurrentShipCompany"]),
    ...mapActions(["getShipCompanies"]),

    async handler() {
      try {
        this.tableLoader = true;

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/ship/company/list`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              searchText: this.searchText != "" ? this.searchText : null,
            },
          }
        );

        this.shipCompanies = res.data;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.shipCompanies = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    openDialog(shipCompany, type) {
      this.dialog = {
        shipCompany,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        shipCompany: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },

    onVesselView(item) {
      this.setCurrentShipCompany(item);
      this.$router.push(`${this.$route.path}/${item.id}/vessels`);
    },

    onCompanyRepView(item) {
      this.setCurrentShipCompany(item);
      this.$router.push(`${this.$route.path}/${item.id}/company_reps`);
    },
  },
};
</script>

<style scoped lang="scss">
</style>