
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-card class="mb-4">
        <v-card-text class="pa-4 d-flex flex-column">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-select
                v-model="facultyId"
                outlined
                dense
                :items="listFaculty"
                item-text="description"
                item-value="cd"
                @change="onFacultyChange"
                clearable
                no-data-text="Δεδομένα μη διαθέσιμα"
                :error-messages="facultyIdErrors"
              >
                <template v-slot:label>
                  <span class="error--text">*</span>
                  {{ appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης" }}
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-select
                v-model="libraryId"
                no-data-text="Δεδομένα μη διαθέσιμα"
                label="Βιβλιοθήκες"
                outlined
                dense
                :items="libraries.content"
                item-text="description"
                item-value="id"
                clearable
                :error-messages="libraryIdErrors"
              >
                <template v-slot:label>
                  <span class="error--text">*</span>
                  Βιβλιοθήκες
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field
                dense
                outlined
                v-model="searchText"
                label="Τίτλος, Συγγραφέας, ISBN"
                @keyup.enter="onSearch"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn class="ml-auto" depressed color="primary" @click="onSearch">
            Αναζήτηση
          </v-btn>
        </v-card-text>
      </v-card>
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="filtersSet ? books.hashContent : []"
            :options.sync="options"
            :server-items-length="books.elements"
            :loading="tableLoader"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onBookView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή / Χρέωση</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  async created() {
    try {
      if (this.facultyId == null) await this.loadFaculties();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      tableLoader: false,
      libraryId: null,
      searchText: null,
      availability: null,
      filtersSet: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Τίτλος ",
          value: "title",
          sortable: false,
        },
        {
          text: "Συγγραφέας",
          value: "author",
          sortable: false,
        },

        {
          text: "Ποσότητα",
          value: "rest",
          sortable: false,
        },

        {
          text: "Εκδότης",
          value: "publisher",
          sortable: false,
        },

        {
          text: "ISBN",
          value: "isbn",
          sortable: false,
        },

        {
          text: "Προβολή/Χρέωση",
          value: "actions",
          sortable: false,
        },
      ],
      libraries: [],
      books: [],
    };
  },

  validations: {
    facultyId: {
      required,
    },

    libraryId: {
      required,
    },
  },

  watch: {
    async options() {
      if (this.libraryId != null) await this.handler();
      else if (this.facultyId != null) {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/library/list`,
          {
            params: {
              page: 0,
              masterSchoolId: this.facultyId,
            },
          }
        );
        this.libraries = res.data;
      }
    },
  },

  computed: {
    ...mapState({
      pageChanged: (state) => state.base.pageChanged,
      listFaculty: (state) => state.generic_filter.listFaculty,

      appId: (state) => state.auth.appId,
    }),

    facultyId: {
      get() {
        return this.$store.state.generic_filter.facultyId;
      },
      set(value) {
        this.setFaculty(value);
      },
    },

    facultyIdErrors() {
      const errors = [];
      if (!this.$v.facultyId.$dirty) return errors;
      if (!this.$v.facultyId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    libraryIdErrors() {
      const errors = [];
      if (!this.$v.libraryId.$dirty) return errors;
      if (!this.$v.libraryId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapMutations([
      "setFilters",
      "changePage",
      "setCurrentBook",

      "setFaculty",
      "getListFaculty",
    ]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    onBookView(item) {
      this.setCurrentBook(item);
      this.$router.push(
        `${this.$route.path}/${item.edu_library_books_id}/lend`
      );
    },

    async handler() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/library/book/list`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              libraryId: this.libraryId,
              searchText: this.searchText != "" ? this.searchText : null,
            },
          }
        );

        this.books = res.data;
        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.books = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    async onFacultyChange() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/library/list`,
          {
            params: {
              page: 0,
              masterSchoolId: this.facultyId,
            },
          }
        );
        this.libraries = res.data;
        if (res.data.content.length == 1) {
          this.libraryId = res.data.content[0].id;
        }
        
      } catch (e) {
        console.log();
      }
    },

    async loadFaculties() {
      let result = await this.fetchDataDynamic(2000, null);
      this.getListFaculty(result.data);

      if (result.data.length == 1) {
        this.facultyId = result.data[0].cd;
        this.onFacultyChange();
      }
    },
    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>