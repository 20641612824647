
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
    >
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="schoolTypes.content"
            :options.sync="options"
            :server-items-length="schoolTypes.elements"
            :loading="tableLoader"
          >
            <template v-slot:[`item.absDate`]="{ item }">
              <span>{{ new Date(item.absDate).toLocaleDateString("el") }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <div class="ml-auto">
        <v-btn
          depressed
          color="primary"
          class="mr-2"
          @click="openDialog(null, 'add')"
        >
          Δημιουργία
          <v-icon dense right>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddSchoolType
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="500px"
      scrollable
    >
      <EditSchoolType
        v-if="dialog.open && dialog.type == 'edit'"
        :schoolType="dialog.schoolType"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteSchoolType
        v-if="dialog.open && dialog.type == 'delete'"
        :schoolType="dialog.schoolType"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import AddSchoolType from "../../components/SchoolTypeModals/AddSchoolType.vue";
import EditSchoolType from "../../components/SchoolTypeModals/EditSchoolType.vue";
import DeleteSchoolType from "../../components/SchoolTypeModals/DeleteSchoolType.vue";

export default {
  components: {
    AddSchoolType,
    EditSchoolType,
    DeleteSchoolType,
  },

  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },

      headers: [
        {
          text: "Περιγραφή",
          value: "description",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        schoolType: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      await this.handler();
    },
  },

  computed: {
    ...mapState({
      pageChanged: (state) => state.base.pageChanged,
      schoolTypes: (state) => state.school_types.schoolTypes,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getSchoolTypes"]),

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        await this.getSchoolTypes({
          page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
          limit:
            this.options.itemsPerPage == -1 ? null : this.options.itemsPerPage,
        });
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    openDialog(schoolType, type) {
      this.dialog = {
        schoolType,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        schoolType: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>