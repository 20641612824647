<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία βιβλίου
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              v-model="title"
              hide-details="auto"
              :error-messages="titleErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Τίτλος
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              v-model="author"
              hide-details="auto"
              :error-messages="authorErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Συγγραφέας
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              hide-details="auto"
              v-model="isbn"
              :error-messages="isbnErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                ISBN
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              hide-details="auto"
              v-model="quantity"
              :error-messages="quantityErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Ποσότητα
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              label="Συντάκτης"
              v-model="editor"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              hide-details="auto"
              outlined
              label="Γλώσσα βιβλίου"
              v-model="language"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              label="Εκδότης"
              v-model="publisher"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              hide-details="auto"
              label="Μεταφραστής"
              v-model="translator"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-textarea
              dense
              outlined
              v-model="description"
              label="Περιγραφή"
              :error-messages="descriptionErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Περιγραφή
              </template>
            </v-textarea>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      loader: false,
      author: null,
      description: null,
      editor: null,
      isbn: null,
      language: null,
      publisher: null,
      quantity: null,
      title: null,
      translator: null,
    };
  },

  validations: {
    title: {
      required,
    },

    description: {
      required,
    },

    author: {
      required,
    },

    isbn: {
      required,
    },

    quantity: {
      required,
      numeric,
    },
  },

  computed: {
    ...mapState({
      facultyId: (state) => state.generic_filter.facultyId,
      listFaculty: (state) => state.generic_filter.listFaculty,
      currentLibrary: (state) => state.schools.currentLibrary,
    }),

    titleErrors() {
      const errors = [];
      if (!this.$v.title.$dirty) return errors;
      if (!this.$v.title.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    authorErrors() {
      const errors = [];
      if (!this.$v.author.$dirty) return errors;
      if (!this.$v.author.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    isbnErrors() {
      const errors = [];
      if (!this.$v.isbn.$dirty) return errors;
      if (!this.$v.isbn.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    quantityErrors() {
      const errors = [];
      if (!this.$v.quantity.$dirty) return errors;
      if (!this.$v.quantity.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.quantity.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addBook"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        const content = {
          title: this.title,
          description: this.description,
          author: this.author,
          isbn: this.isbn,
          quantity: this.quantity,
          libraryId: this.$route.params.library_id,
        };

        if (this.language != null) {
          content.language = this.language;
        }

        if (this.publisher != null) {
          content.publisher = this.publisher;
        }

        if (this.editor != null) {
          content.editor = this.editor;
        }

        if (this.translator != null) {
          content.translator = this.translator;
        }

        await this.addBook(content);

        this.loader = false;
        await this.$emit("handler");
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>