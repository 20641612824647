<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="appId == 1 ? headers : headersKesen"
            :items="approveApplications.content"
            :server-items-length="approveApplications.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:top>
              <div class="d-flex flex-column">
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-select
                      dense
                      outlined
                      label="* Σχολες"
                      v-model="selectedFaculty"
                      hide-details="auto"
                      :items="listFaculty"
                      item-text="description"
                      item-value="cd"
                      @change="onSetFaculty"
                      :error-messages="selectedFacultyErrors"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                      clearable
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        {{ appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης" }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-select
                      dense
                      outlined
                      hide-details="auto"
                      label="* Σχολή"
                      v-model="selectedSchool"
                      :items="listSchool"
                      item-text="description"
                      item-value="cd"
                      :error-messages="selectedSchoolErrors"
                      @change="onSetSchool"
                      clearable
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        {{ appId == 1 ? "Σχολή" : getFieldName() }}
                      </template></v-select
                    >
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-select
                      dense
                      clearable
                      outlined
                      :label="'Ακαδημαϊκό έτος'"
                      v-model="selectedAcademicYear"
                      :items="listAcademicYear"
                      item-text="description"
                      hide-details="auto"
                      item-value="cd"
                      @change="onSetAcademicYear"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        {{
                          appId == 1 ? "Ακαδημαϊκό έτος" : "Εκπαιδευτικό έτος"
                        }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-select
                      dense
                      outlined
                      clearable
                      v-if="appId == 1"
                      :label="appId == 1 ? 'Εξάμηνο σπουδών' : 'Κύκλος σπουδών'"
                      v-model="selectedSemester"
                      hide-details="auto"
                      :items="listSemester"
                      item-text="description"
                      item-value="cd"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        {{ appId == 1 ? "Εξάμηνο σπουδών" : "Κύκλο σπουδών" }}
                      </template>
                    </v-select>

                    <v-select
                      dense
                      outlined
                      clearable
                      v-if="appId == 2"
                      label="Σειρά"
                      v-model="selectedSeries"
                      hide-details="auto"
                      :items="listSeries"
                      item-text="description"
                      item-value="cd"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        {{ "Σειρά" }}
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-select
                      clearable
                      dense
                      outlined
                      label="Κατηγορίες"
                      hide-details="auto"
                      v-model="categoryType"
                      :items="categoryTypes"
                      item-text="description"
                      item-value="cd"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      dense
                      outlined
                      label="Κατάσταση"
                      v-model="status"
                      hide-details="auto"
                      :items="statusArray"
                      item-text="description"
                      item-value="id"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      clearable
                      dense
                      outlined
                      label="ΑΜ, Όνομα, Επώνυμο"
                      v-model="searchText"
                      hide-details="auto"
                      @keyup.enter="handler"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      clearable
                      dense
                      outlined
                      label="Αριθμός Πρωτοκόλλου"
                      v-model="protocolNo"
                      hide-details="auto"
                      @keyup.enter="handler"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="ml-auto">
                  <v-col>
                    <v-btn
                      class="ml-auto mt-2 mr-2"
                      depressed
                      color="green"
                      @click="onFetchPrint"
                      :disabled="isPrintDisabled"
                      :loading="loaderPrint"
                    >
                      Εκτύπωση
                    </v-btn>
                    <v-btn
                      class="ml-auto mt-2"
                      depressed
                      color="primary"
                      @click="onSearch"
                      :disabled="isDisabled"
                    >
                      Αναζήτηση
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </template>

            <template v-slot:[`item.userName`]="{ item }">
              <span>
                {{ item.firstName + " " + item.lastName }}
              </span>
            </template>

            <template v-slot:[`item.application`]="{ item }">
              <span v-if="item.onlineApplication.description">
                {{ item.onlineApplication.description }}
              </span>
              <span v-else>
                {{ item.onlineApplication.commonOnlineApplication.description }}
              </span>
            </template>

            <template v-slot:[`item.creationDate`]="{ item }">
              <span>{{
                new Date(item.creationDate).toLocaleDateString("el")
              }}</span>
            </template>

            <template v-slot:[`item.flgPublish`]="{ item }">
              <span v-if="item.flgPublish == 1"> Εγκρίθηκε </span>
              <span v-else-if="item.flgPublish == -1"> Απορρίφθηκε </span>
              <span v-else-if="item.flgPublish == 0"> Εκκρεμεί απάντηση </span>
            </template>

            <template
              v-slot:[`item.actions`]="{ item, index }"
              class="inline-flex"
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'view')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
              <v-tooltip v-if="appId == 1 && item.canBeDeleted == true" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="cancel(item, index)">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-close-circle
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ακύρωση</span>
              </v-tooltip>

              <v-tooltip
                left
                v-if="
                  item.onlineApplication.commonOnlineApplication.reportId !=
                    undefined
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    icon
                    @click="onPrint(item, index)"
                    :loading="loader[index].loader"
                  >
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-printer
                    </v-icon>
                  </v-btn>
                </template>
                <span>Εκτύπωση</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'view'"
      max-width="1500px"
      scrollable
    >
      <ViewSubmittedApplication
        v-if="dialog.open && dialog.type == 'view'"
        :application="dialog.application"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";
import axios from "axios";
import ViewSubmittedApplication from "../../components/Applications/ViewSubmittedApplication.vue";
import Helpers from "../../helpers/helpers";

export default {
  components: {
    ViewSubmittedApplication,
  },

  async created() {
    try {
      await this.getCategoryTypes();
      await this.loadFaculties();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      tableLoader: false,
      categoryType: null,
      selectedAcademicYear: null,
      selectedSemester: null,
      selectedSeries: null,
      faculty: null,
      start: false,
      loaderPrint: false,
      loader: [],
      searchText: null,
      protocolNo: null,
      approveApplications: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Αίτηση",
          value: "application",
          sortable: false,
        },

        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },
        {
          text: "Αιτών",
          value: "userName",
          sortable: false,
        },

        {
          text: "Ημερομηνία",
          value: "creationDate",
          sortable: false,
        },

        {
          text: "Αριθμός Πρωτοκόλλου",
          value: "protocolNo",
          sortable: false,
        },
        {
          text: "Κατάσταση",
          value: "flgPublish",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      headersKesen: [
        {
          text: "Αίτηση",
          value: "application",
          sortable: false,
        },

        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },
        {
          text: "ΜΕΘ",
          value: "meth",
          sortable: false,
        },
        {
          text: "Αιτών",
          value: "userName",
          sortable: false,
        },

        {
          text: "Ημερομηνία",
          value: "creationDate",
          sortable: false,
        },

        {
          text: "Αριθμός Πρωτοκόλλου",
          value: "protocolNo",
          sortable: false,
        },
        {
          text: "Κατάσταση",
          value: "flgPublish",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      headersExcel: [
        {
          attributes: "onlineApplication.commonOnlineApplication.description",
          title: "Αίτηση",
        },
        { attributes: "cd", title: "AM" },
        { attributes: "firstName,lastName", title: "Αιτών" },
        { attributes: "creationDate", title: "Ημερομηνία" },
        { attributes: "protocolNo", title: "Αριθμός Πρωτοκόλλου" },
        { attributes: "flgPublish", title: "Κατάσταση" },
      ],
      headersExcelKesen: [
        {
          attributes: "onlineApplication.commonOnlineApplication.description",
          title: "Αίτηση",
        },
        { attributes: "meth", title: "ΜΕΘ" },
        { attributes: "firstName,lastName", title: "Αιτών" },
        { attributes: "creationDate", title: "Ημερομηνία" },
        { attributes: "protocolNo", title: "Αριθμός Πρωτοκόλλου" },
        { attributes: "flgPublish", title: "Κατάσταση" },
      ],
      statusArray: [
        { description: "Εγκεκριμένες", id: 1 },
        { description: "Απορριφθείσες", id: -1 },
      ],
      status: 1,

      dialog: {
        open: false,
        type: null,
        role: null,
      },
      selectedSchool: null,
      selectedFaculty: null,
      listSchool: [],
      listFaculty: [],
      listAcademicYear: [],
      listSemester: [],
      listSeries: [],
    };
  },
  validations() {
    return {
      selectedFaculty: {
        required,
      },
      selectedSchool: {
        required,
      },
    };
  },
  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },
    $route() {
      this.getCategoryTypes();
      this.loadFaculties();
    },
  },
  computed: {
    ...mapState({
      categoryTypes: (state) => state.configure_applications.categoryTypes,
      currentApplication: (state) =>
        state.configure_applications.currentApplication,
      appId: (state) => state.auth.appId,
    }),

    isDisabled() {
      if (this.selectedFaculty != "" && this.selectedFaculty != null) {
        return false;
      }
      return true;
    },
    isPrintDisabled() {
      if (
        this.approveApplications.content &&
        this.approveApplications.content.length > 0
      ) {
        return false;
      }
      return true;
    },
    selectedFacultyErrors() {
      const errors = [];
      if (!this.$v.selectedFaculty.$dirty) return errors;
      if (!this.$v.selectedFaculty.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    selectedSchoolErrors() {
      const errors = [];
      if (!this.$v.selectedSchool.$dirty) return errors;
      if (!this.$v.selectedSchool.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapMutations(["setFilters"]),
    ...mapActions(["getCurrentApplication", "getCategoryTypes"]),
    async onSearch() {
      console.log(this.approveApplications);
      this.$v.$touch();

      if (this.$v.$invalid) return;
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    getFieldName() {
      return Helpers.getFieldName(1);
    },
    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        var params = {
          page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
          limit:
            this.options.itemsPerPage == -1 ? null : this.options.itemsPerPage,

          publish: this.status,
          categoryType:
            this.categoryType != null ? parseInt(this.categoryType) : null,
          masterSchoolId: this.selectedFaculty,
          schoolId: this.selectedSchool,
          searchText: this.searchText != "" ? this.searchText : null,
          protocolNo: this.protocolNo != "" ? this.protocolNo : null,
        };

        if (this.appId == 1) params["progTrainPrdId"] = this.selectedSemester;
        if (this.appId == 2) params["rowNum"] = this.selectedSeries;

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/application/list/view`,
          {
            params: params,
          }
        );

        this.approveApplications = res.data;

        this.loader = res.data.content.map((a) => {
          return { id: a.id, loader: false };
        });
        this.tableLoader = false;
        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
      } catch (e) {
        console.log(e);
        this.approveApplications = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    async onFetchPrint() {
      try {
        this.loaderPrint = true;
        let params = {
          page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
          limit:
            this.options.itemsPerPage == -1 ? null : this.options.itemsPerPage,
          masterSchoolId: this.selectedFaculty,
          schoolId: this.selectedSchool,
          progTrainPrdId: this.selectedSemester,
          publish: this.status,
          categoryType:
            this.categoryType != null ? parseInt(this.categoryType) : null,
          searchText: this.searchText != "" ? this.searchText : null,
          protocolNo: this.protocolNo != "" ? this.protocolNo : null,
        };
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/person/application/list/view/export`,
          this.appId == 1 ? this.headersExcel : this.headersExcelKesen,
          {
            params,
            responseType: "blob",
          }
        );
        // var url = window.URL.createObjectURL(
        //   new Blob(["\uFEFF" + response.data], {
        //     type: "text/csv; charset=utf-8",
        //   })
        // );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        let currentDate = new Date();
        currentDate = new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "_");
        link.setAttribute("download", "application_" + currentDate + ".xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();

        this.loaderPrint = false;
      } catch (err) {
        console.log(err, "Error");
        this.loaderPrint = false;
      }
    },

    openDialog(application, type, appType) {
      this.dialog = {
        application,
        open: true,
        type,
        appType: appType != null ? appType : null,
      };
    },
    async cancel(item, index) {
      console.log(item.onlineApplication.commonOnlineApplication.id);
      console.log(item);
      try {
        this.loader[index].loader = true;
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/person/application/${item.id}/cancel`
        );
        this.loader[index].loader = false;
        this.handler();
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
        console.log(e.response);
        this.loader[index].loader = false;
      }
    },

    closeDialog() {
      this.dialog = {
        application: null,
        open: false,
        type: null,
        appType: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },

    async onSetFaculty(value) {
      // call end point to get schools 2001
      let result = await this.fetchDataDynamic(2001, value);
      this.listSchool = result.data;
      if (result.data.length == 1) {
        this.selectedSchool = result.data[0].cd;
        this.onSetSchool(result.data[0].cd); //call onSetSchool
      }
    },

    async onSetSchool(value) {
      let result = await this.fetchDataDynamic(2002, value);
      this.listAcademicYear = result.data;
      console.log(result.data);
      if (result.data.length == 1) {
        this.selectedAcademicYear = result.data[0].cd;
        this.onSetAcademicYear(result.data[0].cd); //call onSetAcademicYear
      }
    },
    async onSetAcademicYear(value) {
      if (this.appId == 2) {
        let result = await this.fetchDataDynamic(2428, value);
        this.listSeries = result.data;
        if (result.data.length == 1) {
          this.selectedSeries = result.data[0].cd;
        }
      } else {
        let result = await this.fetchDataDynamic(2003, value);
        this.listSemester = result.data;
        if (result.data.length == 1) {
          this.selectedSemester = result.data[0].cd;
        }
      }
    },

    async loadFaculties() {
      let result = await this.fetchDataDynamic(2000, null);
      this.listFaculty = result.data;

      if (result.data.length == 1) {
        this.selectedFaculty = result.data[0].cd;
        this.onSetFaculty(result.data[0].cd);
      }
    },
    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },
    async onPrint(item, index) {
      try {
        console.log(item);
        this.loader[index].loader = true;
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/application/${item.id}/print`,
          {
            responseType: "blob",
          }
        );
        console.log(response);

        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: `application/pdf`,
          })
        );
        var fileLink = document.createElement("a");

        let name =
          item.onlineApplication.description != undefined
            ? item.onlineApplication.description
            : item.onlineApplication.commonOnlineApplication.description;
        fileLink.href = fileURL;

        fileLink.setAttribute("download", name + ".pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loader[index].loader = false;
      } catch (e) {
        this.loader[index].loader = false;

        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-application .green {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
  color: #fff !important;
}
</style>
