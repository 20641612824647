<template>
  <div class="mt-4">
    <v-card class="mb-4">
      <v-card-title class="greyish--text">Προσωπικά στοιχεία</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-start">
            <span class="body-1 mr-2">Όνομα:</span>
            <span class="body-1">{{ currentUser.firstName }}</span>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-start">
            <span class="body-1 mr-2">Επώνυμο:</span>
            <span class="body-1">{{ currentUser.lastName }}</span>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-start">
            <span class="body-1 mr-2">Πατρώνυμο:</span>
            <span class="body-1">{{ currentUser.fatherName }}</span>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-start">
            <span class="body-1 mr-2">Μητρώνυμο:</span>
            <span class="body-1">{{ currentUser.motherName }}</span>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-start">
            <span class="body-1 mr-2">Ημερομηνία γέννησης:</span>
            <span class="body-1">{{
                new Date(currentUser.dateOfBirth).toLocaleDateString("el")
            }}</span>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-start">
            <span class="body-1 mr-2">Χώρα γέννησης:</span>
            <span class="body-1">{{ currentUser.countryOfBirth }}</span>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-start">
            <span class="body-1 mr-2">Περιοχή γέννησης:</span>
            <span class="body-1">{{ currentUser.regionOfBirth }}</span>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-start">
            <span class="body-1 mr-2">Πόλη γέννησης:</span>
            <span class="body-1">{{ currentUser.cityOfBirth }}</span>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-start">
            <span class="body-1 mr-2">Φύλο:</span>
            <span v-if="currentUser.sex != null" class="body-1">{{
                currentUser.sex.description
            }}</span>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-start">
            <span class="body-1 mr-2">Δημοτολόγιο:</span>
            <span class="body-1">{{ currentUser.municipalityRegister }}</span>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-start">
            <span class="body-1 mr-2">Κολύμβηση:</span>
            <span class="body-1" v-if="currentUser.swimAbility">{{
                currentUser.swimAbility.description
            }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-4 d-flex flex-column">
      <v-card-title class="greyish--text font-weight-regular">Σχόλια</v-card-title>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-textarea dense outlined v-model="currentUser.comments" disabled> </v-textarea>
        </v-col>
      </v-row>

    </v-card>

    <v-card class="mb-4 d-flex flex-column">
      <v-card-title class="greyish--text font-weight-regular">Στοιχεία επικοινωνίας</v-card-title>
      <v-divider></v-divider>

      <v-data-table no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα" loading-text="Φόρτωση..." :footer-props="{
        'items-per-page-text': 'Σειρές ανά σελίδα',
        'items-per-page-all-text': 'Όλες',
        'items-per-page-options': [5, 10, 15, -1],
      }" :headers="userAddressHeaders" :items="userAddress.content" :server-items-length="userAddress.elements"
        :options.sync="userAddressOptions" :loading="userAddressTableLoader" class="pa-4">
        <template v-slot:[`item.dateFrom`]="{ item }">
          {{ new Date(item.dateFrom).toLocaleDateString("el") }}
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mb-8 d-flex flex-column">
      <v-card-title class="greyish--text font-weight-regular">Στοιχεία ταυτοποίησης</v-card-title>
      <v-divider></v-divider>

      <v-data-table no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα" loading-text="Φόρτωση..." :footer-props="{
        'items-per-page-text': 'Σειρές ανά σελίδα',
        'items-per-page-all-text': 'Όλες',
        'items-per-page-options': [5, 10, 15, -1],
      }" :headers="headers" :items="pistopoihseis.content" :server-items-length="pistopoihseis.elements"
        :options.sync="options" :loading="tableLoader" class="pa-4">
        <template v-slot:[`item.validDateFrom`]="{ item }">
          <span>{{
              new Date(item.validDateFrom).toLocaleDateString("el")
          }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      userAddressOptions: {
        itemsPerPage: 10,
        page: 1,
      },
      pistopoihseis: [],
      userAddress: [],

      tableLoader: false,
      userAddressTableLoader: false,

      headers: [
        {
          text: "Τύπος",
          value: "documentType.description",
          sortable: false,
        },
        {
          text: "Αριθμός εγγράφου",
          value: "documentNumber",
          sortable: false,
        },
        {
          text: "Ημερομηνία έναρξης",
          value: "validDateFrom",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      userAddressHeaders: [
        {
          text: "Τύπος",
          value: "type.description",
          sortable: false,
        },
        {
          text: "Πόλη",
          value: "city",
          sortable: false,
        },

        {
          text: "Διεύθυνση",
          value: "streetName",
          sortable: false,
        },

        {
          text: "Αριθμός",
          value: "num",
          sortable: false,
        },
        {
          text: "Ταχυδρομικός κώδικας",
          value: "zipCode",
          sortable: false,
        },

        {
          text: "Ημερομηνία έναρξης",
          value: "dateFrom",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    async options() {
      await this.handler1();
    },
    async userAddressOptions() {
      await this.handler2();
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      pageChanged: (state) => state.base.pageChanged,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),

    async handler1() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/authorization/document/list`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              personId: this.currentUser.id,
            },
          }
        );

        this.pistopoihseis = res.data;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.pistopoihseis = [];
        this.$router.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },
    async handler2() {
      try {
        this.userAddressTableLoader = true;
        this.setFilters(this.userAddressOptions);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/address/list`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              personId: this.currentUser.id,
            },
          }
        );

        this.userAddress = res.data;
        this.userAddressTableLoader = false;
      } catch (e) {
        console.log(e);
        this.userAddress = [];

        this.userAddressTableLoader = false;
      }
    },
  },
};
</script>

<style>
</style>