<template>
  <v-card class="mt-4">
    <v-card-text>
      <v-data-table
        no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
        loading-text="Φόρτωση..."
        :footer-props="{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        }"
        :headers="appId == 1 ? headersAen : headersKesen"
        :items="userRegisters.content"
        :server-items-length="userRegisters.elements"
        :options.sync="options"
        :loading="tableLoader"
      >
        <template v-slot:[`item.startDate`]="{ item }">
          <span v-if="item.startDate != undefined">
            {{ new Date(item.startDate).toLocaleDateString("el") }}
          </span>
        </template>

        <template v-slot:[`item.bathmologies`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed icon @click="openDialog(item, 'bathmologies')">
                <v-icon color="primary" small v-bind="attrs" v-on="on">
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Προβολή</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.apousies`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed icon @click="openDialog(item, 'apousies')">
                <v-icon color="primary" small v-bind="attrs" v-on="on">
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Προβολή</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'bathmologies'"
      max-width="1200px"
      scrollable
    >
      <Bathmologia
        v-if="dialog.open && dialog.type == 'bathmologies'"
        :gradeProp="dialog.grade"
        :personId="currentUser.id"
        :data="dialog.register"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'apousies'"
      max-width="1200px"
      scrollable
    >
      <Apousies
        v-if="dialog.open && dialog.type == 'apousies'"
        :gradeProp="dialog.register"
        @close="closeDialog"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Bathmologia from "./Bathmologia.vue";
import Apousies from "./Apousies.vue";
import axios from "axios";
export default {
  components: {
    Bathmologia,
    Apousies,
  },

  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      userRegisters: [],
      headersAen: [
        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },
        {
          text: "Ημερομηνία",
          value: "startDate",
          sortable: false,
        },
        {
          text: "Ακαδημία",
          value: "masterSchool.description",
          sortable: false,
        },
        {
          text: "Σχολή",
          value: "school.school.description",
          sortable: false,
        },
        {
          text: "Ακαδημαϊκό Έτος",
          value: "progTrainPrd.year.year",
          sortable: false,
        },
        {
          text: "Προγραμματισμός εξαμήνου σπουδών",
          value: "progTrainPrd.description",
          sortable: false,
        },

        {
          text: "Είδος εγγραφής",
          value: "registrationType.description",
          sortable: false,
        },

        {
          text: "Βαθμολογίες",
          value: "bathmologies",
          sortable: false,
        },

        {
          text: "Απουσίες",
          value: "apousies",
          sortable: false,
        },
      ],
      headersKesen: [
        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },
        {
          text: "Ημερομηνία",
          value: "startDate",
          sortable: false,
        },
        {
          text: "Δομές Μετεκπαίδευσης",
          value: "masterSchool.description",
          sortable: false,
        },
        {
          text: "Εκπαιδευτικός Κύκλος/Ειδικό Τμήμα",
          value: "school.school.description",
          sortable: false,
        },
        {
          text: "Εκπαιδευτικό έτος",
          value: "progTrainPrd.year.year",
          sortable: false,
        },
        {
          text: "Προγραμματισμός κύκλου σπουδών",
          value: "progTrainPrd.description",
          sortable: false,
        },

        {
          text: "Είδος εγγραφής",
          value: "registrationType.description",
          sortable: false,
        },

        {
          text: "Βαθμολογίες",
          value: "bathmologies",
          sortable: false,
        },

        {
          text: "Απουσίες",
          value: "apousies",
          sortable: false,
        },
      ],
      dialog: {
        register: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    options: {
      async handler() {
        try {
          this.tableLoader = true;
          this.setFilters(this.options);

          let types = ["1", "5"];
          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/person/register/list`,
            {
              params: {
                propertyType: 1,
                registrationType: types.reduce((f, s) => `${f},${s}`),
                page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                limit:
                  this.options.itemsPerPage == -1
                    ? null
                    : this.options.itemsPerPage,
                personId: this.currentUser.id,
              },
            }
          );
          this.userRegisters = res.data;
          this.tableLoader = false;
        } catch (e) {
          console.log(e);
          this.userRegisters = [];
          this.$router.dispatch("errorHandler", e);

          this.tableLoader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      appId: (state) => state.auth.appId,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "setCurrentRegister"]),
    ...mapActions(["getUserRegisters"]),

    openDialog(register, type) {
      this.setCurrentRegister(register);
      this.dialog = {
        register,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        register: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>