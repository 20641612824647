import axios from 'axios'
export default {
    state: {
        professorsCategories: [],
        contracts: [],
        categoryTeams: [],

        categoryType: null,
        groupType: null,
        enabled: null,
    },

    mutations: {
        getProfessorsCategories(state, professorsCategory) {
            state.professorsCategories = professorsCategory
        },

        getContracts(state, contracts) {
            state.contracts = contracts
        },

        getCategoryTeams(state, categoryTeams) {
            state.categoryTeams = categoryTeams
        },

        setCategoryType(state, categoryType) {
            state.categoryType = categoryType
        },

        setGroupType(state, groupType) {
            state.groupType = groupType
        },

        setEnabled(state, enabled) {
            state.enabled = enabled
        },

        clearProfessorsFilters(state) {
            state.categoryType = null,
                state.groupType = null,
                state.enabled = null
        }




    },

    actions: {
        async getProfessorsCategories({ commit, dispatch }, filters) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/teacher/category/list`, {
                    params: {
                        page: filters.page,
                        limit: filters.limit,
                        categoryType: filters.categoryType,
                        groupType: filters.groupType,
                        enabled: filters.enabled
                    }
                })
                commit('getProfessorsCategories', res.data)

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async addProfessorsCategory({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/teacher/category`, payload)
                await dispatch('getProfessorsCategories', {
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.professorsCategories.elements : rootState.base.filters.itemsPerPage,
                    categoryType: state.categoryType,
                    groupType: state.groupType,
                    enabled: state.enabled
                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία κατηγορίας εκπαιδευτικών ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async editProfessorsCategory({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/teacher/category/${payload.id}`, payload.content)
                await dispatch('getProfessorsCategories', {
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.professorsCategories.elements : rootState.base.filters.itemsPerPage,
                    categoryType: state.categoryType,
                    groupType: state.groupType,
                    enabled: state.enabled
                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία κατηγορίας εκπαιδευτικών ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async deleteProfessorsCategory({ commit, dispatch, rootState, state }, id) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/teacher/category/${id}`)
                if (state.professorsCategories.limit == 1) {
                    commit('changePage', true)
                } else {
                    await dispatch('getProfessorsCategories', {
                        page: rootState.base.filters.page,
                        limit: rootState.base.filters.itemsPerPage == -1 ? state.professorsCategories.elements : rootState.base.filters.itemsPerPage,
                        categoryType: state.categoryType,
                        groupType: state.groupType,
                        enabled: state.enabled
                    })
                }
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή κατηγορίας εκπαιδευτικών ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async getContracts({ commit, dispatch }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/value/142`)
                commit(`getContracts`, res.data)

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async getCategoryTeams({ commit, dispatch }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/value/152`)
                commit(`getCategoryTeams`, res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        }

    }
}