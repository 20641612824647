<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία ανακοίνωσης
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="d-flex flex-column pa-4">
      <v-progress-circular
        v-if="cardLoader"
        indeterminate
        color="primary"
        class="align-self-center ma-4"
        :size="50"
      ></v-progress-circular>
      <v-form v-else @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col>
            <v-select
              v-if="announcement.masterSchool != null"
              v-model="facultyId"
              outlined
              hide-details="auto"
              dense
              :items="listFaculty"
              item-text="description"
              item-value="cd"
              :disabled="true"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                {{ appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης" }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-if="announcement.school != null"
              v-model="schoolId"
              outlined
              hide-details="auto"
              dense
              :disabled="true"
              :items="listSchool"
              item-text="description"
              item-value="cd"
              label="Εκπαιδευτικός Κύκλος/Ειδικό Τμήμα"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                {{ appId == 1 ? "Σχολή" : getFieldName() }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-if="announcement.progTrainPeriod != null"
              v-model="semesterId"
              outlined
              hide-details="auto"
              dense
              :disabled="true"
              :items="listSemester"
              item-text="description"
              item-value="cd"
              no-data-text="Δεδομένα μη διαθέσιμα"
              :label="
                appId == 1 ? 'Έτος-Εξάμηνο σπουδών' : 'Έτος-Κύκλος σπουδών'
              "
            ></v-select> </v-col
        ></v-row>
        <v-row>
          <v-col>
            <v-select
              v-if="announcement.eduClass != null"
              v-model="classId"
              outlined
              hide-details="auto"
              dense
              :disabled="true"
              :items="listClass"
              item-text="description"
              item-value="cd"
              label="Τμήμα"
              no-data-text="Δεδομένα μη διαθέσιμα"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-if="announcement.lesson != null"
              v-model="courseId"
              outlined
              hide-details="auto"
              dense
              :disabled="true"
              :items="listCourse"
              item-text="description"
              item-value="cd"
              label="Μάθημα"
              no-data-text="Δεδομένα μη διαθέσιμα"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="selectedSystemRole != 'TEACHER_ROLE'">
          <v-col>
            <v-select
              dense
              outlined
              v-model="type"
              hide-details="auto"
              :items="getDataStaticList(146)"
              item-text="description"
              item-value="cd"
              no-data-text="Δεδομένα μη διαθέσιμα"
              :error-messages="typeErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Τύπος
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              dense
              outlined
              v-model="flgActive"
              hide-details="auto"
              label="Ενεργό"
              :items="getDataStaticList(100)"
              item-text="description"
              item-value="cd"
              :error-messages="flgActiveErrors"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Ενεργό
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details="auto"
                  outlined
                  v-model="startDateFormatted"
                  class="mb-4"
                  @blur="startDate = parseDate(startDateFormatted, 'startDate')"
                  :error-messages="startDateErrors"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Ημερομηνία έναρξης
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="startDate"
                scrollable
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details="auto"
                  outlined
                  v-model="endDateFormatted"
                  label="Ημερομηνία λήξης"
                  prepend-inner-icon="mdi-calendar"
                  class="mb-4"
                  @blur="endDate = parseDate(endDateFormatted, 'endDate')"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="endDate"
                scrollable
                @input="menu1 = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="title"
              class="mb-4"
              hide-details="auto"
              :error-messages="titleErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Τίτλος
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              dense
              outlined
              v-model="message"
              hide-details="auto"
              :error-messages="messageErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Μήνυμα
              </template>
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input
              label="Επισυναπτόμενα αρχεία"
              dense
              outlined
              v-model="attachedFiles"
              hide-details="auto"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              multiple
              chips
              small-chips
              :clearable="false"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip close @click:close="deleteChip(index, text)">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>

        <span
          class="d-flex flex-column align-start"
          v-if="announcement.attachedFiles != null"
        >
          <v-btn
            v-for="(file, i) in announcement.attachedFiles"
            :key="i + file.id"
            plain
            color="primary"
            class="pa-0"
            @click="onDownload(file.id)"
            >{{ file.name }}
            <v-icon color="success">mdi-download</v-icon>
          </v-btn>
        </span>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Αποθήκευση</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import Helpers from "../../helpers/helpers";
const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  props: ["announcement"],

  async created() {
    try {
      console.log(this.announcement);
      if (this.announcement.masterSchool != null) await this.loadFaculties();
      if (this.announcement.school != null) {
        let res = await this.fetchDataDynamic(
          2001,
          this.announcement.masterSchool.id.toString()
        );
        this.listSchool = res.data;
      }

      if (this.announcement.progTrainPeriod != null) {
        let res = await this.fetchDataDynamic(
          2015,
          this.announcement?.school?.id.toString()
        );
        this.listSemester = res.data;
      }

      if (this.announcement.eduClass != null) {
        let res = await this.fetchDataDynamic(
          2004,
          this.announcement?.progTrainPeriod?.id.toString()
        );
        this.listClass = res.data;
      }

      if (this.announcement.lesson != null) {
        let res = await this.fetchDataDynamic(
          2008,
          this.announcement?.progTrainPeriod?.id.toString()
        );
        this.listCourse = res.data;
      }

      this.cardLoader = false;

      this.endDate = this.announcement?.endDate?.split(" ")[0];
      this.flgActive = this.announcement?.flgActive?.cd;
      this.message = this.announcement?.message;
      this.startDate = this.announcement?.startDate.split(" ")[0];
      this.type = this.announcement.type?.cd;
      this.title = this.announcement?.title;

      this.schoolId = this.announcement?.school?.id?.toString();
      this.facultyId = this.announcement?.masterSchool?.id?.toString();
      this.semesterId = this.announcement?.progTrainPeriod?.id?.toString();
      this.classId = this.announcement?.eduClass?.id?.toString();
      this.courseId = this.announcement?.lesson?.id?.toString();
    } catch (e) {
      this.cardLoader = false;

      console.log(e);
    }
  },

  data() {
    return {
      menu: false,
      menu1: false,
      loader: false,
      uploadNew: false,
      cardLoader: true,

      flgActive: null,
      attachedFiles: null,

      message: null,

      endDate: null,
      endDateFormatted: null,
      startDate: null,
      startDateFormatted: null,
      type: null,
      title: null,

      schoolId: null,
      facultyId: null,
      semesterId: null,
      classId: null,
      courseId: null,

      listFaculty: [],
      listSchool: [],
      listSemester: [],
      listClass: [],
      listCourse: [],
    };
  },

  validations: {
    flgActive: {
      required,
    },

    message: {
      required,
    },
    startDate: {
      required,
    },
    type: {
      required,
    },
    title: {
      required,
    },
  },

  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
    },

    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },

  computed: {
    ...mapState({
      staticList: (state) => state.auth.userInfo.staticView,
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
      selectedSystemRole: (state) => state.auth.selectedSystemRole,
    }),

    flgActiveErrors() {
      const errors = [];
      if (!this.$v.flgActive.$dirty) return errors;
      if (!this.$v.flgActive.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      if (!this.$v.message.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    startDateErrors() {
      const errors = [];
      if (!this.$v.startDate.$dirty) return errors;
      if (!this.$v.startDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    typeErrors() {
      const errors = [];
      if (!this.$v.type.$dirty) return errors;
      if (!this.$v.type.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    titleErrors() {
      const errors = [];
      if (!this.$v.title.$dirty) return errors;
      if (!this.$v.title.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["editAnnouncement", "downloadFile"]),
    getFieldName() {
      return Helpers.getFieldName(1);
    },

    async loadFaculties() {
      let result = await this.fetchDataDynamic(2000, null);
      this.listFaculty = result.data;
    },

    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },

    deleteChip(index, text) {
      this.attachedFiles.splice(index, 1);
    },

    async onDownload(id) {
      try {
        await this.downloadFile(id);
      } catch (e) {
        console.log(e);
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "startDate") {
          this.startDate = null;
          this.startDateFormatted = null;
        } else if (dateName == "endDate") {
          this.endDate = null;
          this.endDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        const formData = new FormData();

        formData.set("flgActive", this.flgActive);
        formData.set("masterSchoolId", this.facultyId);
        formData.set("message", this.message);
        formData.set("startDate", this.startDate);
        formData.set("type", this.type);
        formData.set("title", this.title);

        if (this.schoolId != null) formData.set("schoolId", this.schoolId);
        if (this.semesterId != null)
          formData.set("progTrainPeriodId", this.semesterId);
        if (this.classId != null) formData.set("eduClassID", this.classId);
        if (this.courseId != null) formData.set("lessonId", this.courseId);

        if (this.endDate != null) formData.set("endDate", this.endDate);
        if (this.attachedFiles != null) {
          for (let i = 0; i < this.attachedFiles.length; i++) {
            formData.append("attachedFiles", this.attachedFiles[i]);
          }
        }

        await this.editAnnouncement({
          announcementId: this.announcement.id,
          formData,
        });
        this.loader = false;
        await this.$emit("handler");

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>