<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία στοιχείων επικοινωνίας
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-select
              dense
              outlined
              v-model="type"
              type="text"
              class="mb-4"
              :items="addressTypes"
              item-text="description"
              item-value="cd"
              :error-messages="typeErrors"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Τύπος
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              dense
              outlined
              v-model="region"
              label="Νομός"
              type="text"
              class="mb-4"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              dense
              outlined
              v-model="city"
              label="Πόλη"
              type="text"
              class="mb-4"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              dense
              outlined
              v-model="place"
              label="Περιοχή"
              type="text"
              class="mb-4"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              dense
              outlined
              v-model="streetName"
              label="Οδός"
              type="text"
              class="mb-4"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              dense
              outlined
              v-model="num"
              label="Αριθμός"
              type="text"
              class="mb-4"
              :error-messages="numErrors"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              dense
              outlined
              v-model="streetName1"
              label="Οδός 1"
              type="text"
              class="mb-4"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              dense
              outlined
              v-model="streetName2"
              label="Οδός 2"
              type="text"
              class="mb-4"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              dense
              outlined
              v-model="zipCode"
              label="Τ.Κ."
              type="text"
              class="mb-4"
              :error-messages="zipCodeErrors"
            >
            </v-text-field>
          </v-col>
          <v-col cols="9"> </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              dense
              outlined
              v-model="phone"
              label="Σταθερό τηλέφωνο"
              type="text"
              class="mb-4"
              :error-messages="phoneErrors"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              dense
              outlined
              v-model="cellphone"
              label="Κινητό τηλέφωνο"
              type="text"
              class="mb-4"
              :error-messages="cellphoneErrors"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="dateFromFormatted"
                  class="mb-4"
                  @blur="dateFrom = parseDate(dateFromFormatted, 'dateFrom')"
                  :error-messages="dateFromErrors"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Ημερομηνία από
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="dateFrom"
                scrollable
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="dateToFormatted"
                  class="mb-4"
                  @blur="dateTo = parseDate(dateToFormatted, 'dateTo')"
                  label="Ημερομηνία μέχρι"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="dateTo"
                scrollable
                @input="menu1 = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  props: ["user"],
  async created() {
    try {
      this.getAddressTypes();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      menu: false,
      menu1: false,
      loader: false,

      cellphone: null,
      city: null,
      dateFrom: null,
      dateFromFormatted: null,
      dateTo: null,
      dateToFormatted: null,
      num: null,
      phone: null,
      place: null,
      region: null,
      streetName: null,
      streetName1: null,
      streetName2: null,
      type: null,
      zipCode: null,
    };
  },

  validations: {
    cellphone: {
      numeric,
    },
    phone: {
      numeric,
    },
    num: {
      numeric,
    },
    zipCode: {
      numeric,
    },
    type: {
      required,
    },
    dateFrom: {
      required,
    },
  },

  watch: {
    dateFrom(val) {
      this.dateFromFormatted = this.formatDate(this.dateFrom);
    },

    dateTo(val) {
      this.dateToFormatted = this.formatDate(this.dateTo);
    },
  },

  computed: {
    ...mapState({
      addressTypes: (state) => state.users.addressTypes,
      currentUser: (state) => state.users.currentUser,
    }),
    cellphoneErrors() {
      const errors = [];
      if (!this.$v.cellphone.$dirty) return errors;
      if (!this.$v.cellphone.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },

    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      if (!this.$v.phone.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },

    numErrors() {
      const errors = [];
      if (!this.$v.num.$dirty) return errors;
      if (!this.$v.num.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },

    zipCodeErrors() {
      const errors = [];
      if (!this.$v.zipCode.$dirty) return errors;
      if (!this.$v.zipCode.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },

    typeErrors() {
      const errors = [];
      if (!this.$v.type.$dirty) return errors;
      if (!this.$v.type.required) errors.push("Το πεδίο είναι υποχρεωτικό");
      return errors;
    },

    dateFromErrors() {
      const errors = [];
      if (!this.$v.dateFrom.$dirty) return errors;
      if (!this.$v.dateFrom.required) errors.push("Το πεδίο είναι υποχρεωτικό");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addUserAddress", "getAddressTypes"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          type: this.type,
          personId: this.currentUser.id,
          dateFrom: this.dateFrom,
        };

        if (this.cellphone != null) content.cellphone = this.cellphone;
        if (this.city != null) content.city = this.city;
        if (this.dateTo != null) content.dateTo = this.dateTo;
        if (this.num != null) content.num = this.num;
        if (this.phone != null) content.phone = this.phone;
        if (this.place != null) content.place = this.place;
        if (this.region != null) content.region = this.region;
        if (this.streetName != null) content.streetName = this.streetName;
        if (this.streetName1 != null) content.streetName1 = this.streetName1;
        if (this.streetName2 != null) content.streetName2 = this.streetName2;
        if (this.zipCode != null) content.zipCode = this.zipCode;

        await this.addUserAddress(content);
        this.loader = false;

        await this.$emit("handler");
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "dateFrom") {
          this.dateFrom = null;
          this.dateFromFormatted = null;
        } else if (dateName == "dateTo") {
          this.dateTo = null;
          this.dateToFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped lang="scss">
</style>