var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"primary"}},[_vm._v(" Δημιουργία "),_c('v-btn',{staticClass:"ml-auto",attrs:{"depressed":"","icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-4"},[_c('v-form',{staticClass:"pa-4",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","type":"text","hide-details":"auto","error-messages":_vm.masterSchoolIdErrors,"items":_vm.masterSchools.content,"item-text":"description","item-value":"id","no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης")+" ")]},proxy:true}],null,true),model:{value:(_vm.masterSchoolId),callback:function ($$v) {_vm.masterSchoolId=$$v},expression:"masterSchoolId"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης"))])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":"auto","type":"text"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Περιγραφή ")]},proxy:true}],null,true),model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Περιγραφή")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":"auto","error-messages":_vm.lastNumErrors,"type":"text"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Τελευταίος αριθμός ")]},proxy:true}],null,true),model:{value:(_vm.lastNum),callback:function ($$v) {_vm.lastNum=$$v},expression:"lastNum"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Τελευταίος αριθμός")])])],1)],1),_c('div',{staticClass:"d-flex justify-center mt-2"},[_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Ακύρωση")]),_c('v-btn',{staticClass:"ml-4",attrs:{"depressed":"","color":"success","type":"submit","loading":_vm.loader}},[_vm._v("Αποθήκευση")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }