
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-card class="mb-4 pa-4">
        <v-card-text class="d-flex flex-column">
          <v-row>
            <v-col>
              <v-select
                dense
                outlined
                v-model="schoolTypeId"
                type="text"
                class="mb-4"
                :items="schoolTypesList"
                @change="fetchSchools"
                item-text="description"
                item-value="cd"
                no-data-text="Δεδομένα μη διαθέσιμα"
              >
                <template v-slot:label>
                  {{
                    appId == 1
                      ? "Τύπος Ακαδημίας"
                      : "Τύπος Δομής Μετεκπαίδευσης"
                  }}
                </template>
              </v-select>
            </v-col>
            <v-col>
              <v-select
                dense
                outlined
                v-model="schoolId"
                type="text"
                class="mb-4"
                :items="schoolsList"
                item-text="description"
                no-data-text="Δεδομένα μη διαθέσιμα"
                item-value="id"
              >
                <template v-slot:label>
                  {{ appId == 1 ? "Σχολές" : getFieldName() }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-btn
            class="ml-auto"
            depressed
            color="primary"
            @click="this.fetchCertificates"
          >
            Αναζήτηση
          </v-btn>
        </v-card-text>
      </v-card>
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="certificates.content"
            :server-items-length="certificates.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.academicYears`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary ml-auto"
            @click="openDialog(null, 'add')"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddCertificate
        v-if="dialog.open && dialog.type == 'add'"
        :schoolId="this.schoolId"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="500px"
      scrollable
    >
      <EditCertificate
        v-if="dialog.open && dialog.type == 'edit'"
        :certificate="dialog.certificate"
        :schoolId="this.schoolId"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteCertificate
        v-if="dialog.open && dialog.type == 'delete'"
        :certificate="dialog.certificate"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import AddCertificate from "../../../components/CertificateModals/AddCertificate.vue";
import EditCertificate from "../../../components/CertificateModals/EditCertificate.vue";
import DeleteCertificate from "../../../components/CertificateModals/DeleteCertificate.vue";
import Helpers from "../../../helpers/helpers";

export default {
  components: {
    AddCertificate,
    EditCertificate,
    DeleteCertificate,
  },
  created() {
    this.$store.dispatch("setFilterSemester", {
      schoolTypeId: null,
      schoolId: null,
    });
    this.fetchSchoolTypes();
  },
  data() {
    return {
      tableLoader: false,
      search: "",
      schoolTypesList: [],
      query: false,
      schoolsList: [],
      schoolTypeId: null,
      schoolId: null,
      certificates: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Κωδικός",
          value: "cd",
          sortable: false,
        },
        {
          text: "Περιγραφή",
          value: "description",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        certificate: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    options: {
      async handler() {
        try {
          if (this.schoolId != null) {
            this.tableLoader = true;
            this.setFilters(this.options);
            await this.fetchCertificates();
            this.tableLoader = false;
          }
        } catch (e) {
          console.log(e);
          this.tableLoader = false;
        }
      },
    },
    $route() {
      this.fetchSchoolTypes();
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      //   certificates: (state) => state.certificates.certificates,
      pageChanged: (state) => state.base.pageChanged,
      appId: (state) => state.auth.appId,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getCertificates"]),

    openDialog(certificate, type) {
      this.dialog = {
        certificate,
        open: true,
        type,
      };
    },
    getFieldName() {
      return Helpers.getFieldName(2);
    },

    closeDialog() {
      this.dialog = {
        certificate: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.fetchCertificates();
    },
    async fetchSchoolTypes() {
      let body = {
        type: 1,
        parentCd: null,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/104`,
          body
        );
        this.schoolTypesList = res.data;
      } catch (e) {
        throw e;
      }
    },
    async fetchSchools() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/school/list/prototypes`,
          {
            params: {
              page: 0,
              schoolTypeId: this.schoolTypeId,
            },
          }
        );

        this.schoolsList = res.data.content;
      } catch (e) {
        throw e;
      }
    },
    async fetchCertificates() {
      try {
        this.tableLoader = true;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/certificate/list/prototypes`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              schoolId: this.schoolId,
            },
          }
        );

        this.certificates = res.data;
        this.tableLoader = false;
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>