
import axios from 'axios'
export default {
    state: {
        examResults: []
    },

    mutations: {
        getExamResults(state, examResults) {
            state.examResults = examResults
        }
    },

    actions: {
        async getExamResults({ commit, dispatch }, filters) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/exam/result/list`,
                    {
                        params: {
                            page: filters.page,
                            limit: filters.limit,
                            lessonId: filters.lessonId,
                        }
                    })
                commit('getExamResults', res.data)

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async deleteExamGrade({ commit, dispatch, rootState, state }, gradeId) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/exam/result/${gradeId}`)
                if (state.examResults.limit == 1 && state.examResults.page != 1) {
                    commit('changePage', true)
                }
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή βαθμολογίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },
    }
}