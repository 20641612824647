<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
      v-if="!dialog.open && dialog.type != 'view'"
    >
      <v-card class="mb-4 pa-4">
        <v-select
          dense
          outlined
          v-model="schoolTypeId"
          type="text"
          class="mb-4"
          :items="schoolTypesList"
          @change="fetchScoolGenCd"
          item-text="description"
          item-value="cd"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label> Επιλογή </template>
        </v-select>
      </v-card>
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="appId == 1 ? headersAen : headersKesen"
            :items="schoolGenCd.content"
            :server-items-length="schoolGenCd.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.schools`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'view')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
          <div class="custom-card-buttons">
            <v-btn
              depressed
              color="primary"
              class="mr-2"
              @click="openDialog(null, 'add')"
              :disabled="!filterSet"
            >
              Δημιουργία
              <v-icon dense right>mdi-plus</v-icon>
            </v-btn>
          </div>
      </v-card>
    </v-card>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="1000px"
      scrollable
    >
      <AddSchoolGenCd
        v-if="dialog.open && dialog.type == 'add'"
        :schoolTypeId="schoolTypeId"
        @close="closeDialog"
      />
    </v-dialog>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="1200px"
      scrollable
    >
      <EditSchoolGenCd
        v-if="dialog.open && dialog.type == 'edit'"
        :schoolGenCd="dialog.schoolGenCd"
        :schoolTypeId="schoolTypeId"
        @close="closeDialog"
      />
    </v-dialog>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteSchoolGenCd
        v-if="dialog.open && dialog.type == 'delete'"
        :schoolGenCd="dialog.schoolGenCd.id"
        @close="closeDialog"
      />
    </v-dialog>
    <SchoolGenCdSchools
      v-if="dialog.open && dialog.type == 'view'"
      :schoolGenCd="dialog.schoolGenCd"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from "axios";
import AddSchoolGenCd from "../../components/SchoolGenCd/AddSchoolGenCd.vue";
import EditSchoolGenCd from "../../components/SchoolGenCd/EditSchoolGenCd.vue";
import DeleteSchoolGenCd from "../../components/SchoolGenCd/DeleteSchoolGenCd.vue";
import SchoolGenCdSchools from "./SchoolGenCdSchools.vue";

export default {
  name: "schoolGenCd",
  components: {
    AddSchoolGenCd,
    EditSchoolGenCd,
    DeleteSchoolGenCd,
    SchoolGenCdSchools,
  },
  async created() {
    await this.fetchSchoolTypes();
  },
  data() {
    return {
      schoolTypesList: [],
      schoolTypeId: null,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      filterSet: false,
      tableLoader: false,
      headersAen: [
        {
          text: "Ακαδημία ",
          value: "masterSchool.description",
          sortable: false,
        },
        {
          text: "Περιγραφή ",
          value: "description",
          sortable: false,
        },
        {
          text: "Τελευταίος αριθμός ",
          value: "lastNum",
          sortable: false,
        },
        { text: "Επιλογές", value: "actions", sortable: false },
        {
          text: "Σχολές",
          value: "schools",
          sortable: false,
        },
      ],
      headersKesen: [
        {
          text: "Δομή Μετεκπαίδευσης",
          value: "masterSchool.description",
          sortable: false,
        },
        {
          text: "Περιγραφή ",
          value: "description",
          sortable: false,
        },
        {
          text: "Τελευταίος αριθμός ",
          value: "lastNum",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
        {
          text: "Σχολεία",
          value: "schools",
          sortable: false,
        },
      ],
      dialog: {
        schoolGenCd: null,
        open: false,
        type: null,
      },
      schoolGenCd: [],
    };
  },
  watch: {
    async options() {
      if (this.schoolTypeId != null) {
        await this.fetchScoolGenCd();
      }
    },
  },
  computed: {
    ...mapState({
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
    }),
  },
  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    async fetchSchoolTypes() {
      let body = {
        type: 1,
        parentCd: null,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/104`,
          body
        );
        this.schoolTypesList = res.data;

        if (res.data.length == 1) {
          this.schoolTypeId = res.data[0].cd;
          this.fetchScoolGenCd();
        }
      } catch (e) {
        throw e;
      }
    },
    async fetchScoolGenCd() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        this.filterSet = true;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/school/cd/list`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              schoolTypeId: this.schoolTypeId,
            },
          }
        );
        this.schoolGenCd = res.data;
        this.tableLoader = false;
      } catch (e) {
        throw e;
      }
    },
    openDialog(schoolGenCd, type) {
      this.dialog = {
        schoolGenCd,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        schoolGenCd: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }

      this.fetchScoolGenCd();
    },
  },
};
</script>
