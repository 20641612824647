<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία εκτυπωτικού
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="description"
              type="text"
              class="mb-4"
              :error-messages="descriptionErrors"
              hide-details="true"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Περιγραφή
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="reportName"
              type="text"
              disabled
              class="mb-4"
              hide-details="true"
            >
              <template v-slot:label> Όνομα εκτυπωτικού </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="reportPath"
              type="text"
              class="mb-4"
              hide-details="true"
            >
              <template v-slot:label> Διαδρομή </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="tpy"
              label="Τύπος"
              dense
              item-text="description"
              item-value="cd"
              hide-details="auto"
              :items="tpyList"
              outlined
              :error-messages="tpyErrors"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input
              v-model="attachedFile"
              dense
              outlined
              label="Αρχείο"
              prepend-inner-icon="mdi-paperclip"
              :prepend-icon="null"
              hide-details="auto"
            ></v-file-input>
          </v-col>
        </v-row>
        <div class="d-flex justify-center btn-container">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Αποθήκευση</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>


<script>
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "EditReport",
  props: ["report"],
  async created() {
    await this.fetchTpy();
    this.description = this.report.description;
    this.reportName = this.report.reportName;
    if (this.report.reportPath != undefined)
      this.reportPath = this.report.reportPath;
    this.tpy = this.report?.tpy?.cd;
    this.id = this.report.id;
  },
  data() {
    return {
      id: null,
      description: null,
      reportName: null,
      reportPath: null,
      tpy: null,
      tpyList: [],
      attachedFile: null,
      loader: false,
    };
  },
  validations: {
    description: {
      required,
    },

    tpy: {
      required,
    },
  },
  computed: {
    ...mapState({
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
    }),
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    tpyErrors() {
      const errors = [];
      if (!this.$v.tpy.$dirty) return errors;
      if (!this.$v.tpy.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },
  methods: {
    reportPathErrors() {
      const errors = [];
      if (!this.$v.reportPath.$dirty) return errors;
      if (!this.$v.reportPath.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        let formData = new FormData();
        formData.append("description", this.description);
        if (this.reportPath != undefined && this.reportPath != null)
          formData.append("reportPath", this.reportPath);
        formData.append("tpy", this.tpy);
        if (this.attachedFile != undefined)
          formData.append("reportFile", this.attachedFile);
          
        formData.append("type", this.appId);
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/report/${this.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // await axios.put(`${process.env.VUE_APP_BASE_URL}/report/${this.id}`, {
        //   description: this.description,
        //   reportName: this.reportName,
        //   reportPath: this.reportPath,
        //   tpy: this.tpy,
        // });

        this.loader = false;

        this.$emit("close");
      } catch (e) {}
    },
    async fetchTpy() {
      let result = await this.fetchDataDynamic(221, null);
      this.tpyList = result.data;
    },
    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },
  },
};
</script>