//function kind

import store from "@/store"
export default {
    getFieldName(value) {
        let kindId = store.state.auth.kindId;
        if (value == 1) {
            if (kindId == 4 || kindId == 6 || kindId == 9 || kindId == 10 || kindId == 11) {
                return 'Εκπαιδευτικός Κύκλος Σπουδών'
            } else if (kindId == 5 || kindId == 7 || kindId == 8) {
                return 'Ειδικής Εκπαίδευσης'
            }
            else {
                return 'Εκπαιδευτικός Κύκλος/ Ειδικό Τμήμα'
            }
        } else if(value == 2) {
            if (kindId == 4 || kindId == 6 || kindId == 9 || kindId == 10 || kindId == 11) {
                return 'Εκπαιδευτικοί Κύκλοι Σπουδών'
            } else if (kindId == 5 || kindId == 7 || kindId == 8) {
                return 'Ειδικής Εκπαίδευσης'
            }
            else {
                return 'Εκπαιδευτικοί Κύκλοι/ Ειδικά Τμήματα'
            }
        }

    }

}