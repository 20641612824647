<template>
  <v-card class="d-flex flex-column">
    <v-toolbar color="primary" class="white--text">
      Μαθήματα
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card class="mt-4 pa-4">
      <v-card-text>
        <v-data-table
          no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
          loading-text="Φόρτωση..."
          :footer-props="{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          }"
          :headers="teacherLessonsHeaders"
          :items="teacherLessons"
          :server-items-length="teacherLessons.elements"
          :options.sync="teacherLessonsOptions"
          :loading="teacherLessonsTableLoader"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed icon @click="openDialog(item, 'view')">
                  <v-icon color="primary" small v-bind="attrs" v-on="on">
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <span>Προβολή</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-btn depressed color="error" class="ml-auto ma-4" @click="$emit('close')"
      >Επιστροφή
    </v-btn>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from "axios";
export default {
  props: ["gradeProp"],
  data() {
    return {
      teacherLessons: [],
      teacherLessonsTableLoader: false,
      teacherLessonsOptions: {
        itemsPerPage: 10,
        page: 1,
      },
      teacherLessonsHeaders: [
        {
          text: "Όνομα",
          value: "lesson",
          sortable: false,
        },
      ],
    };
  },

  watch: {
    teacherLessonsOptions: {
      async handler() {
        try {
          this.teacherLessonsTableLoader = true;
          this.setFilters(this.teacherLessonsOptions);

          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/teacher/lessons/preview`,
            {
              params: {
                page:
                  this.teacherLessonsOptions.itemsPerPage == -1
                    ? 0
                    : this.teacherLessonsOptions.page,
                limit:
                  this.teacherLessonsOptions.itemsPerPage == -1
                    ? null
                    : this.teacherLessonsOptions.itemsPerPage,
                personId: this.currentTeacher.id,
                progTrainPeriodId: this.currentRegister.progTrainPrd?.id,
                masterSchoolId: this.gradeProp.masterSchool.id,
              },
            }
          );
          console.log(res);
          let data = res.data.content.map((r) => {
            return { lesson: r };
          });
          console.log(data);
          this.teacherLessons = data;
          this.teacherLessonsTableLoader = false;
        } catch (e) {
          console.log(e);
          this.teacherLessons = [];
          this.$router.dispatch("errorHandler", e);

          this.teacherLessonsTableLoader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentTeacher: (state) => state.teachers.currentTeacher,
      currentRegister: (state) => state.users.currentRegister,
      currentFaculty: (state) => state.faculties.currentFaculty,
    }),
  },

  methods: {
    ...mapMutations(["setFilters"]),
  },
};
</script>

<style scoped lang="scss">
</style>