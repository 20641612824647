<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Βαθμολογίες
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="d-flex flex-column">
      <!-- <v-card class="mt-4 pa-4 d-flex flex-column align-center">
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="progExamPerdiodId"
                label="Προγραμματισμός εξεταστικής περιόδου"
                dense
                outlined
                :items="schedulingExams.content"
                item-text="description"
                item-value="id"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-btn class="ml-auto" depressed color="primary" @click="handler">
          Αναζήτηση
        </v-btn>
      </v-card> -->

      <v-card class="mt-4 pa-4">
        <v-card-text>
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="bathmologia.hashContent"
            :server-items-length="bathmologia.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.exam_date`]="{ item }">
              <span>{{ new Date(item.exam_date).toLocaleDateString("el") }}</span>
            </template>
            <template v-slot:[`item.approve_oral`]="{ item }">
              <v-icon v-if="item.approve_oral" color="success"
                >mdi-check</v-icon
              >
              <v-icon v-if="!item.approve_oral" color="error">mdi-close</v-icon>
            </template>
            <template v-slot:[`item.approve_written`]="{ item }">
              <v-icon v-if="item.approve_written" color="success"
                >mdi-check</v-icon
              >
              <v-icon v-if="!item.approve_written" color="error"
                >mdi-close</v-icon
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-btn
        depressed
        color="error"
        class="ml-auto ma-4"
        @click="$emit('close')"
        >Επιστροφή
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
export default {
  props: ["data", "personId"],
  async created() {
    // await this.getSchedulingExams({
    //   page: 0,
    //   progTrainPeriodId: this.currentRegister.progTrainPrd.id,
    // });
  },
  data() {
    return {
      progExamPerdiodId: null,
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      bathmologia: [],
      headers: [
        {
          text: "Μάθημα",
          value: "description",
          sortable: false,
        },
        {
          text: "Εξεταστική",
          value: "eedu_prog_exam_prd_description",
          sortable: false,
        },
        {
          text: "Ημερομηνία εξέτασης",
          value: "exam_date",
          sortable: false,
        },
        {
          text: "Βαθμολογία γραπτών",
          value: "written",
          sortable: false,
        },
        {
          text: "Βαθμολογία προόδου",
          value: "oral_grade",
          sortable: false,
        },
        {
          text:"Επίδοση",
          value:"grade",
          sortable:false
        },
        // {
        //   text: "Τελική βαθμολογία",
        //   value: "grade",
        //   sortable: false,
        // },
        {
          text: "Ένδειξη επιτυχίας γραπτής",
          value: "approve_written",
          sortable: false,
        },
        {
          text: "Ένδειξη επιτυχίας προόδου",
          value: "approve_oral",
          sortable: false,
        },
      ],
    };
  },

  watch: {
    async options() {
      await this.handler();
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      currentRegister: (state) => state.users.currentRegister,
      schedulingExams: (state) => state.scheduling_exams.schedulingExams,
    }),
  },

  methods: {
    ...mapMutations(["setFilters"]),
    ...mapActions(["getSchedulingExams"]),

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/listForGrade/written`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              personId: this.personId,
              progTrainPeriodId: this.data.progTrainPrd?.id,
              masterSchoolId: this.data.masterSchool?.id,
              schoolId: this.data.school?.id,
              // progExamPeriodId: this.progExamPerdiodId,
              // approved: true,
            },
          }
        );
        console.log(res.data);
        this.bathmologia = res.data;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.bathmologia = [];
        this.$router.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>