<template>
  <v-card>
    <v-toolbar color="error" class="mb-4" dark>
      Διαγραφή Απαίτηση
      <v-btn
        depressed
        icon
        class="ml-auto"
        color="white"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <div
        class="d-flex align-center justify-center text-body-1 black--text mb-4"
      >
        <v-icon color="error" class="mr-1">mdi-alert-circle-outline</v-icon>
        <div>
          Είστε σίγουροι ότι θέλετε να διαγράψετε το Απαίτηση
          {{ action.description }};
        </div>
      </div>
      <div class="d-flex justify0-center pb-2">
        <v-btn dense outlined color="erro" class="mr-4" @click="$emit('close')">
          Ακύρωση</v-btn
        >
        <v-btn depressed color="error" @click="onDelete" :loading="loader"
          >Διαγραφή</v-btn
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "DeleteApplicationRec",
  props: ["action"],
  data() {
    return {
      loader: false,
    };
  },
  methods: {
    async onDelete() {
      try {
        this.loader = true;
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/common/online/application/requirement/${this.action.id}`
        );
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },
  },
};
</script>

<style>
</style>