import axios from 'axios'
export default {
    state: {
        supportingDocuments: [],
    },

    mutations: {
        getSupportingDocuments(state, supportingDocuments) {
            state.supportingDocuments = supportingDocuments
        },

    },

    actions: {
        async getSupportingDocuments({ commit, dispatch }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/req/doc/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,

                        schoolId: payload.id
                    }
                })
                commit('getSupportingDocuments', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async addSupportingDocument({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/req/doc`, payload)
                await dispatch('getSupportingDocuments', {
                    id: payload.schoolId,
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.supportingDocuments.elements : rootState.base.filters.itemsPerPage,

                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία δικαιολογητικού ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async editSupportingDocument({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/req/doc/${payload.supportingDocumentId}`, payload.content)
                await dispatch('getSupportingDocuments', {
                    id: payload.content.schoolId,
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.supportingDocuments.elements : rootState.base.filters.itemsPerPage,

                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία δικαιολογητικού ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async deleteSupportingDocument({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/req/doc/${payload.supportingDocumentId}`,)
                if (state.supportingDocuments.limit == 1 && state.supportingDocuments.page != 1) {
                    commit('changePage', true)
                } else {
                    await dispatch('getSupportingDocuments', {
                        id: payload.schoolId,
                        page: rootState.base.filters.page,
                        limit: rootState.base.filters.itemsPerPage == -1 ? state.supportingDocuments.elements : rootState.base.filters.itemsPerPage,

                    })
                }
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή δικαιολογητικού ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

    }
}