import axios from "axios";
import store from "../store";

export default {
  state: {
    semesters: [],
    currentSemester: JSON.parse(localStorage.getItem("semester")) || null,
    //dropdowns
    allSemesters: [],
    absCalcModes: [],
    absLimTypes: [],
    absLimExcs: [],
    lessonRejects: [],
    apop1s: [],
    lessonTypes: [],
    schoolType: null,
    schoolId: null,
  },

  mutations: {
    getSemesters(state, semesters) {
      state.semesters = semesters;
    },

    setCurrentSemester(state, semester) {
      state.currentSemester = semester;
      localStorage.setItem("semester", JSON.stringify(semester));
    },
    setFilterSemester(state, data) {
      state.schoolType = data.schoolTypeId;
      state.schoolId = data.schoolId;
    },

    //drowpdowns
    getAllSemesters(state, allSemesters) {
      state.allSemesters = allSemesters;
    },
    getAbsCalcModes(state, absCalcModes) {
      state.absCalcModes = absCalcModes;
    },
    getAbsLimType(state, absLimTypes) {
      state.absLimTypes = absLimTypes;
    },
    getAbsLimExcs(state, absLimExcs) {
      state.absLimExcs = absLimExcs;
    },
    getLessonRejects(state, lessonRejects) {
      state.lessonRejects = lessonRejects;
    },
    getApop1s(state, apop1s) {
      state.apop1s = apop1s;
    },
    getLessonTypes(state, lessonTypes) {
      state.lessonTypes = lessonTypes;
    },
  },

  actions: {
    async getSemesters({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/train/period/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,

              schoolId: payload.schoolId,
            },
          }
        );
        commit("getSemesters", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async addSemester({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/train/period`,
          payload
        );
        // await dispatch("getSemesters", {
        //   schoolId: payload.schoolId,
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.semesters.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        let text =
          store.state.auth.appId == 1
            ? '"Η δημιουργία εξαμήνου σπουδών ολοκληρώθηκε με επιτυχία'
            : '"Η δημιουργία κύκλου σπουδών ολοκληρώθηκε με επιτυχία';
        commit("toggleSnackbar", {
          open: true,
          text: text,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async editSemester({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/train/period/${payload.id}`,
          payload.content
        );
        // await dispatch("getSemesters", {
        //   schoolId: payload.content.schoolId,
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.semesters.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        let text =
          store.state.auth.appId == 1
            ? "Η επεξεργασία εξαμήνου σπουδών ολοκληρώθηκε με επιτυχία"
            : "Η επεξεργασία κύκλου σπουδών ολοκληρώθηκε με επιτυχία";
        commit("toggleSnackbar", {
          open: true,
          text: text,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteSemester({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/train/period/${payload.id}`
        );
        if (state.semesters.limit == 1 && state.semesters.page != 1) {
          commit("changePage", true);
        } else {
          //   await dispatch("getSemesters", {
          //     schoolId: payload.schoolId,
          //     page: rootState.base.filters.page,
          //     limit:
          //       rootState.base.filters.itemsPerPage == -1
          //         ? state.semesters.elements
          //         : rootState.base.filters.itemsPerPage,
          //   });
        }
        commit("toggleSnackbar", {
          open: true,
          text: "Η διαγραφή κύκλου σπουδών ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    // dropdowns
    async getAllSemesters({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/train/period/list`,
          {
            params: {
              page: 0,

              schoolId: payload.schoolId,
            },
          }
        );
        commit("getAllSemesters", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getLessonTypes({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/111`
        );
        commit("getLessonTypes", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getAbsCalcModes({ commit }) {
      //Τρόπος με τον οποίο υπολογίζονται οι απουσίες
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/112`
        );
        commit("getAbsCalcModes", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getAbsLimType({ commit }) {
      //Είδος ορίου απουσιών στην περίπτωση που οι απουσίες υπολογίζονται στο επίπεδο του κύκλου
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/113`
        );
        commit("getAbsLimType", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getAbsLimExcs({ commit }) {
      //Αποτέλεσμα υπέρβασης ορίου απουσιών
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/114`
        );
        commit("getAbsLimExcs", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getLessonRejects({ commit }) {
      //Αποτέλεσμα απόρριψης σπουδαστή σε μάθημα
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/115`
        );
        commit("getLessonRejects", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getApop1s({ commit }) {
      //Χρόνος αποποίησης βαθμολογίας απο σπουδαστές
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/122`
        );
        commit("getApop1s", res.data);
      } catch (e) {
        throw e;
      }
    },
    setFilterSemester({ commit }, payload) {
      commit("setFilterSemester", payload);
    },
  },
};
