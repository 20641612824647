<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία {{ appId == 1 ? "εξαμήνου σπουδών" : "κύκλου σπουδών" }}
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-card class="mb-4">
          <v-card-title class="mb-4"> Βασικά στοιχεία </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      v-model="cd"
                      type="text"
                      :error-messages="cdErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Κωδικός
                      </template>
                    </v-text-field>
                  </template>
                  <span>Κωδικός</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-textarea
                      dense
                      outlined
                      hide-details="auto"
                      v-model="description"
                      type="text"
                      :error-messages="descriptionErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Περιγραφή
                      </template>
                    </v-textarea>
                  </template>
                  <span>Περιγραφή</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-textarea
                      dense
                      outlined
                      hide-details="auto"
                      v-model="shortDescription"
                      type="text"
                      :error-messages="shortDescriptionErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Σύντομη περιγραφή
                      </template>
                    </v-textarea>
                  </template>
                  <span>Σύντομη περιγραφή</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title class="mb-4"> Επιπλέον πληροφορίες </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="minDept"
                      hide-details="auto"
                      type="text"
                      :error-messages="minDeptErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Ελάχιστος αριθμός σπουδαστών στο τμήμα
                      </template>
                    </v-text-field>
                  </template>
                  <span>Ελάχιστος αριθμός σπουδαστών στο τμήμα</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="maxDept"
                      type="text"
                      hide-details="auto"
                      :error-messages="maxDeptErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Μέγιστος αριθμός σπουδαστών στο τμήμα
                      </template>
                    </v-text-field>
                  </template>
                  <span>Μέγιστος αριθμός σπουδαστών στο τμήμα</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="lessonType"
                      type="text"
                      :error-messages="lessonTypeErrors"
                      :items="getDataStaticList(111)"
                      hide-details="auto"
                      item-text="description"
                      item-value="cd"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Είδος μαθημάτων στην εξεταστική περίοδο
                      </template>
                    </v-select>
                  </template>
                  <span>Είδος μαθημάτων στην εξεταστική περίοδο</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="registerAgainstNumFailedLessons"
                      min="0"
                      label="Οφειλόμενα μαθήματα"
                      hide-details="auto"
                      type="number"
                      v-bind="attrs"
                      placeholder="Οφειλόμενα μαθήματα"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span>Οφειλόμενα μαθήματα</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="examDuration"
                      type="text"
                      :error-messages="examDurationErrors"
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Διάρκεια εξέτασης
                      </template>
                    </v-text-field>
                  </template>
                  <span>Διάρκεια εξέτασης</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="lessonTransNextPer"
                      label="Αριθμός μεταφερόμενων μαθημάτων σε επόμενη περίοδο"
                      type="text"
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="lessonTransNextPerErrors"
                      hide-details="auto"
                    ></v-text-field>
                  </template>
                  <span
                    >Αριθμός μεταφερόμενων μαθημάτων σε επόμενη περίοδο</span
                  >
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      hide-details="auto"
                      outlined
                      v-model="maxRegisterNr"
                      :label="
                        appId == 1
                          ? 'Πλήθος εγγραφών κατά το εξάμηνο σπουδών'
                          : 'Πλήθος εγγραφών κατά τον κύκλο σπουδών'
                      "
                      type="text"
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="maxRegisterNrErrors"
                    ></v-text-field>
                  </template>
                  <span>{{
                    appId == 1
                      ? "Πλήθος εγγραφών κατά το εξάμηνο σπουδών"
                      : "Πλήθος εγγραφών κατά τον κύκλο σπουδών"
                  }}</span>
                </v-tooltip>
              </v-col>

              <!-- <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="lastIssueNr"
                      hide-details="auto"
                      label="Τελευταίος αριθμός θεώρησης πτυχίου"
                      type="text"
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="lastIssueNrErrors"
                    ></v-text-field>
                  </template>
                  <span>Τελευταίος αριθμός θεώρησης πτυχίου</span>
                </v-tooltip>
              </v-col> -->

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      clearable
                      dense
                      outlined
                      v-model="prevTrainingPeriodId"
                      :label="
                        appId == 1
                          ? 'Προηγούμενο εξάμηνο σπουδών'
                          : 'Προηγούμενος κύκλος σπουδών'
                      "
                      hide-details="auto"
                      type="text"
                      :items="semesterList.content"
                      item-text="description"
                      item-value="id"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    ></v-select>
                  </template>
                  <span>{{
                    appId == 1
                      ? "Προηγούμενο εξάμηνο σπουδών"
                      : "Προηγούμενος κύκλος σπουδών"
                  }}</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      clearable
                      dense
                      outlined
                      v-model="nextTrainingPeriodId"
                      hide-details="auto"
                      :label="
                        appId == 1
                          ? 'Επόμενο εξάμηνο σπουδών'
                          : 'Επόμενο κύκλο σπουδών '
                      "
                      type="text"
                      :items="semesterList.content"
                      item-text="description"
                      item-value="id"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    ></v-select>
                  </template>
                  <span>{{
                    appId == 1
                      ? "Επόμενο εξάμηνο σπουδών"
                      : "Επόμενο κύκλο σπουδών"
                  }}</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      clearable
                      dense
                      outlined
                      v-model="shipTrainingId"
                      label="Θαλάσσιο εκπαιδευτικό ταξίδι "
                      type="text"
                      :items="getDataStaticList(100)"
                      item-text="description"
                      item-value="cd"
                      v-bind="attrs"
                      hide-details="auto"
                      :error-messages="shipTrainingIdErrors"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    ></v-select>
                  </template>
                  <span>* Θαλάσσιο εκπαιδευτικό ταξίδι</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" sm="6" md="3" v-if="shipTrainingId == '1'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="creditUnits"
                      hide-details="auto"
                      label="Πιστωτικές μονάδες"
                      type="text"
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="creditUnitsErrors"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Πιστωτικές μονάδες
                      </template>
                    </v-text-field>
                  </template>
                  <span class="error--text">*</span>
                  <span>Πιστωτικές μονάδες</span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                v-if="
                  protSchoolDetails != null &&
                  protSchoolDetails.trainPrd &&
                  protSchoolDetails.trainPrd.cd == '2' &&
                  appId == 2
                "
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="titleId"
                      type="text"
                      :items="certificateList"
                      hide-details="auto"
                      item-text="description"
                      item-value="cd"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        Εκδιδόμενο πιστοποιητικό
                      </template>
                    </v-select>
                  </template>
                  <span> Εκδιδόμενο πιστοποιητικό </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title class="mb-4"> Διάρκεια </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="lessonRepeatNr"
                      min="1"
                      label="Μέγιστο πλήθος επαναλήψεων περιόδου"
                      type="number"
                      :error-messages="lessonRepeatNrErrors"
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                      @change="onChangeLesson"
                    ></v-text-field>
                  </template>
                  <span>Μέγιστο πλήθος επαναλήψεων μαθήματος</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" sm="6" md="3" v-if="Number(lessonRepeatNr) <= 1">
                <v-tooltip top v-if="Number(lessonRepeatNr) <= 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="lessonFromDd"
                      label="Ημέρα έναρξης"
                      type="text"
                      hide-details="auto"
                      :error-messages="lessonFromDdErrors"
                      :items="days"
                      :disabled="lessonRepeatNr > 1 ? true : false"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-if="lessonRepeatNr >= 1" v-slot:label>
                        <span class="error--text" v-if="lessonRepeatNr == 1"
                          >*</span
                        >
                        Ημέρα έναρξης
                      </template>
                    </v-select>
                  </template>
                  <span>Ημέρα έναρξης</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3" v-if="Number(lessonRepeatNr) <= 1">
                <v-tooltip top v-if="Number(lessonRepeatNr) <= 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="lessonFromMm"
                      label="Μήνας έναρξης"
                      hide-details="auto"
                      type="text"
                      :error-messages="lessonFromMmErrors"
                      :items="months"
                      item-text="description"
                      item-value="cd"
                      :disabled="lessonRepeatNr > 1 ? true : false"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-if="lessonRepeatNr >= 1" v-slot:label>
                        <span class="error--text" v-if="lessonRepeatNr == 1"
                          >*</span
                        >
                        Μήνας έναρξης
                      </template>
                    </v-select>
                  </template>
                  <span>Μήνας έναρξης</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3" v-if="Number(lessonRepeatNr) <= 1">
                <v-tooltip top v-if="Number(lessonRepeatNr) <= 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="lessonToDd"
                      label="Ημέρα λήξης"
                      type="text"
                      hide-details="auto"
                      :error-messages="lessonToDdErrors"
                      :items="days"
                      :disabled="lessonRepeatNr > 1 ? true : false"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-if="lessonRepeatNr >= 1" v-slot:label>
                        <span class="error--text" v-if="lessonRepeatNr == 1"
                          >*</span
                        >
                        Ημέρα λήξης
                      </template>
                    </v-select>
                  </template>
                  <span>Ημέρα λήξης</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3" v-if="Number(lessonRepeatNr) <= 1">
                <v-tooltip top v-if="Number(lessonRepeatNr) <= 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="lessonToMm"
                      label="Μήνας λήξης"
                      type="text"
                      :error-messages="lessonToMmErrors"
                      :items="months"
                      hide-details="auto"
                      item-text="description"
                      item-value="cd"
                      :disabled="lessonRepeatNr > 1 ? true : false"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-if="lessonRepeatNr >= 1" v-slot:label>
                        <span class="error--text" v-if="lessonRepeatNr == 1"
                          >*</span
                        >
                        Μήνας λήξης
                      </template>
                    </v-select>
                  </template>
                  <span>Μήνας λήξης</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top v-if="lessonRepeatNr != 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="duration"
                      type="number"
                      :error-messages="durationErrors"
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Διάρκεια σε ημέρες
                      </template>
                    </v-text-field>
                  </template>
                  <span>Διάρκεια σε ημέρες</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="hours"
                      type="text"
                      :error-messages="hoursErrors"
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        {{ appId == 1 ? "Συνολικές ώρες εξαμήνου" : "Συνολικές ώρες κύκλου" }}                        
                      </template>
                    </v-text-field>
                  </template>
                  <span>{{ appId == 1 ? "Συνολικές ώρες εξαμήνου" : "Συνολικές ώρες κύκλου" }}</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="labHours"
                      type="text"
                      :error-messages="labHoursErrors"
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        {{ appId == 1 ? "Εργαστηριακές ώρες εξαμήνου" : "Εργαστηριακές ώρες κύκλου" }}
                      </template>
                    </v-text-field>
                  </template>
                  <span>{{ appId == 1 ? "Εργαστηριακές ώρες εξαμήνου" : "Εργαστηριακές ώρες κύκλου" }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title class="mb-4"> Απουσίες και βαθμολογίες </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      clearable
                      dense
                      outlined
                      v-model="absCalcMode"
                      label="Τρόπος υπολογισμού απουσιών"
                      hide-details="auto"
                      type="text"
                      :items="getDataStaticList(112)"
                      item-text="description"
                      item-value="cd"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    ></v-select>
                  </template>
                  <span>Τρόπος υπολογισμού απουσιών</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      clearable
                      dense
                      outlined
                      v-model="absLimType"
                      label="Είδος ορίου απουσιών"
                      hide-details="auto"
                      type="text"
                      :items="getDataStaticList(113)"
                      item-text="description"
                      item-value="cd"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    ></v-select>
                  </template>
                  <span>Είδος ορίου απουσιών</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="totUnjustifiedAbs"
                      type="text"
                      hide-details="auto"
                      :error-messages="totUnjustifiedAbsErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Αδικαιολόγητες απουσίες
                      </template>
                    </v-text-field>
                  </template>
                  <span>Αδικαιολόγητες απουσίες</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="totAbs"
                      hide-details="auto"
                      type="text"
                      :error-messages="totAbsErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Σύνολο απουσιών
                      </template>
                    </v-text-field>
                  </template>
                  <span>Σύνολο απουσιών</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="absLimExc"
                      type="text"
                      :error-messages="absLimExcErrors"
                      hide-details="auto"
                      :items="getDataStaticList(114)"
                      item-text="description"
                      item-value="cd"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Αποτέλεσμα υπέρβασης ορίου απουσιών
                      </template>
                    </v-select>
                  </template>
                  <span>Aποτέλεσμα υπέρβασης ορίου απουσιών</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="dailyHours"
                      hide-details="auto"
                      type="text"
                      :error-messages="dailyHoursErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Ανώτατο όριο ωρών διδασκαλίας
                      </template>
                    </v-text-field>
                  </template>
                  <span>Ανώτατο όριο ωρών διδασκαλίας</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      v-model="passGrade"
                      label="Βάση γενικής βαθμολογίας"
                      type="text"
                      :error-messages="passGradeErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Βάση γενικής βαθμολογίας
                      </template>
                    </v-text-field>
                  </template>
                  <span>Βάση γενικής βαθμολογίας</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="maxGrade"
                      label="Μέγιστη βαθμολογία "
                      hide-details="auto"
                      type="text"
                      :error-messages="maxGradeErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Μέγιστη βαθμολογία
                      </template>
                    </v-text-field>
                  </template>
                  <span>Μέγιστη βαθμολογία</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="roundGrade"
                      label="Στρογγυλοποίηση βαθμολογίας "
                      type="text"
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="roundGradeErrors"
                    ></v-text-field>
                  </template>
                  <span>Στρογγυλοποίηση βαθμολογίας</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="flgReqAppr"
                      label="Έγκριση βαθμολογίας "
                      type="text"
                      :items="getDataStaticList(100)"
                      item-text="description"
                      hide-details="auto"
                      item-value="cd"
                      :error-messages="flgReqApprErrors"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Έγκριση βαθμολογίας
                      </template>
                    </v-select>
                  </template>
                  <span>Έγκριση βαθμολογίας</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="apop"
                      label="Αποποίηση βαθμολογίας "
                      type="text"
                      :items="getDataStaticList(100)"
                      item-text="description"
                      hide-details="auto"
                      item-value="cd"
                      :error-messages="apopErrors"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Αποποίηση βαθμολογίας
                      </template>
                    </v-select>
                  </template>
                  <span>Αποποίηση βαθμολογίας</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="apop1"
                      label="Χρόνος αποποίησης βαθμολογίας"
                      type="text"
                      :items="getDataStaticList(122)"
                      item-text="description"
                      item-value="cd"
                      hide-details="auto"
                      :error-messages="apop1Errors"
                      :disabled="apop == null || apop == 0 ? true : false"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-if="apop != null && apop != 0" v-slot:label>
                        <span class="error--text">*</span>
                        Χρόνος αποποίησης βαθμολογίας
                      </template>
                    </v-select>
                  </template>
                  <span>Χρόνος αποποίησης βαθμολογίας</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="lessonReject"
                      type="text"
                      :error-messages="lessonRejectErrors"
                      :items="getDataStaticList(115)"
                      item-text="description"
                      item-value="cd"
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Αποτέλεσμα απόρριψης σπουδαστή σε μάθημα
                      </template>
                    </v-select>
                  </template>
                  <span>Αποτέλεσμα απόρριψης σπουδαστή σε μάθημα</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-textarea
                  dense
                  outlined
                  v-model="memo"
                  label="Σχόλια"
                  type="text"
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                ></v-textarea>
              </template>
              <span>Σχόλια</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-checkbox
              v-model="flgActive"
              hide-details="auto"
              label="Ενεργή"
              class="mb-4"
              :error-messages="flgActiveErrors"
              true-value="1"
              false-value="0"
            >
            </v-checkbox>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import {
  required,
  requiredIf,
  numeric,
  decimal,
} from "vuelidate/lib/validators";
export default {
  async created() {
    try {
      for (let i = 0; i < 31; i++) {
        this.days[i] = i + 1;
      }

      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/train/period/list/prototypes`,
          {
            params: {
              page: 0,
              schoolId: this.schoolId,
            },
          }
        );
        this.semesterList = res.data;
        if (
          this.protSchoolDetails != null &&
          this.protSchoolDetails.trainPrd &&
          this.protSchoolDetails.trainPrd.cd == "2" &&
          this.appId == 2
        ) {
          this.fetchCertificates();
        }
      } catch (e) {
        throw e;
      }
    } catch (e) {
      console.log(e);
    }
  },
  props: ["schoolId", "protSchoolDetails"],
  data() {
    return {
      semesterList: [],
      cd: null,
      description: null,
      shortDescription: null,
      minDept: null,
      maxDept: null,
      duration: null,
      examDuration: null,
      hours: null,
      labHours: null,
      lessonType: null,
      lessonRepeatNr: 1,
      lessonFromDd: null,
      lessonFromMm: null,
      lessonToDd: null,
      lessonToMm: null,
      absCalcMode: null,
      absLimType: null,
      totUnjustifiedAbs: null,
      totAbs: null,
      absLimExc: null,
      lessonReject: null,
      lessonTransNextPer: null,
      maxRegisterNr: null,
      prevTrainingPeriodId: null,
      nextTrainingPeriodId: null,
      registerAgainstNumFailedLessons: null,
      // lastIssueNr: null,
      apop: null,
      apop1: null,
      passGrade: null,
      maxGrade: null,
      dailyHours: null,
      flgActive: 0,
      flgReqAppr: "0",
      roundGrade: null,
      shipTrainingId: null,
      creditUnits: null,
      memo: null,
      loader: false,
      days: [],
      certificateList: [],
      titleId: null,
      months: [
        {
          description: "Ιανουάριος",
          cd: 1,
        },
        {
          description: "Φεβρουάριος",
          cd: 2,
        },
        {
          description: "Μάρτιος",
          cd: 3,
        },
        {
          description: "Απρίλιος",
          cd: 4,
        },
        {
          description: "Μάϊος",
          cd: 5,
        },
        {
          description: "Ιούνιος",
          cd: 6,
        },
        {
          description: "Ιούλιος",
          cd: 7,
        },
        {
          description: "Αύγουστος",
          cd: 8,
        },

        {
          description: "Σεπτέμβριος",
          cd: 9,
        },

        {
          description: "Οκτώβριος",
          cd: 10,
        },
        {
          description: "Νοέμβριος",
          cd: 11,
        },

        {
          description: "Δεκέμβριος",
          cd: 12,
        },
      ],
    };
  },

  validations: {
    cd: {
      required,
    },
    description: {
      required,
    },
    shortDescription: {
      required,
    },
    minDept: {
      required,
      numeric,
    },
    maxDept: {
      required,
      numeric,
    },
    shipTrainingId: {
      required,
    },
    dailyHours: {
      required,
      numeric,
    },
    duration: {
      required: requiredIf(function () {
        return this.lessonRepeatNr != 1;
      }),
      numeric,
    },
    creditUnits: {
      required: requiredIf(function () {
        return this.shipTrainingId == "1";
      }),
    },
    examDuration: {
      required,
      numeric,
    },
    hours: {
      required,
      numeric,
    },
    labHours: {
      required,
      numeric,
    },
    lessonType: {
      required,
    },
    lessonRepeatNr: {
      required,
    },

    lessonTransNextPer: {
      numeric,
    },

    maxRegisterNr: {
      numeric,
    },

    // lastIssueNr: {
    //   numeric,
    // },

    lessonFromDd: {
      required: requiredIf(function () {
        return this.lessonRepeatNr == 1;
      }),
    },
    lessonFromMm: {
      required: requiredIf(function () {
        return this.lessonRepeatNr == 1;
      }),
    },
    lessonToDd: {
      required: requiredIf(function () {
        return this.lessonRepeatNr == 1;
      }),
    },
    lessonToMm: {
      required: requiredIf(function () {
        return this.lessonRepeatNr == 1;
      }),
    },
    totUnjustifiedAbs: {
      required,
      numeric,
    },
    totAbs: {
      required,
      numeric,
    },
    absLimExc: {
      required,
    },
    lessonReject: {
      required,
    },
    apop: {
      required,
    },
    apop1: {
      required: requiredIf(function () {
        return this.apop == 1;
      }),
    },
    passGrade: {
      required,
      numeric,
    },
    maxGrade: {
      required,
      numeric,
    },
    flgActive: {
      required,
    },
    flgReqAppr: {
      required,
    },
    roundGrade: {
      decimal,
    },
  },

  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      allSemesters: (state) => state.semesters.allSemesters,
      appId: (state) => state.auth.appId,
      staticList: (state) => state.auth.userInfo.staticView,
    }),

    cdErrors() {
      const errors = [];
      if (!this.$v.cd.$dirty) return errors;
      if (!this.$v.cd.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    shortDescriptionErrors() {
      const errors = [];
      if (!this.$v.shortDescription.$dirty) return errors;
      if (!this.$v.shortDescription.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    minDeptErrors() {
      const errors = [];
      if (!this.$v.minDept.$dirty) return errors;
      if (!this.$v.minDept.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.minDept.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },
    maxDeptErrors() {
      const errors = [];
      if (!this.$v.maxDept.$dirty) return errors;
      if (!this.$v.maxDept.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.maxDept.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    durationErrors() {
      const errors = [];
      if (!this.$v.duration.$dirty) return errors;
      if (!this.$v.duration.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.duration.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    dailyHoursErrors() {
      const errors = [];
      if (!this.$v.dailyHours.$dirty) return errors;
      if (!this.$v.dailyHours.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.dailyHours.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    examDurationErrors() {
      const errors = [];
      if (!this.$v.examDuration.$dirty) return errors;
      if (!this.$v.examDuration.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.examDuration.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    hoursErrors() {
      const errors = [];
      if (!this.$v.hours.$dirty) return errors;
      if (!this.$v.hours.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.hours.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    labHoursErrors() {
      const errors = [];
      if (!this.$v.labHours.$dirty) return errors;
      if (!this.$v.labHours.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.labHours.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    lessonTypeErrors() {
      const errors = [];
      if (!this.$v.lessonType.$dirty) return errors;
      if (!this.$v.lessonType.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    lessonRepeatNrErrors() {
      const errors = [];
      if (!this.$v.lessonRepeatNr.$dirty) return errors;
      if (!this.$v.lessonRepeatNr.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    lessonTransNextPerErrors() {
      const errors = [];
      if (!this.$v.lessonTransNextPer.$dirty) return errors;
      if (!this.$v.lessonTransNextPer.numeric)
        errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },

    maxRegisterNrErrors() {
      const errors = [];
      if (!this.$v.maxRegisterNr.$dirty) return errors;
      if (!this.$v.maxRegisterNr.numeric)
        errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },

    // lastIssueNrErrors() {
    //   const errors = [];
    //   if (!this.$v.lastIssueNr.$dirty) return errors;
    //   if (!this.$v.lastIssueNr.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

    //   return errors;
    // },
    lessonFromDdErrors() {
      const errors = [];
      if (this.lessonRepeatNr == 1) {
        if (!this.$v.lessonFromDd.$dirty) return errors;
        if (!this.$v.lessonFromDd.required) errors.push("Υποχρεωτικό πεδίο");
      }

      return errors;
    },
    lessonFromMmErrors() {
      const errors = [];

      if (!this.$v.lessonFromMm.$dirty) return errors;
      if (!this.$v.lessonFromMm.required) errors.push("Υποχρεωτικό πεδίο");

      return errors;
    },
    lessonToDdErrors() {
      const errors = [];

      if (!this.$v.lessonToDd.$dirty) return errors;
      if (!this.$v.lessonToDd.required) errors.push("Υποχρεωτικό πεδίο");

      return errors;
    },
    lessonToMmErrors() {
      const errors = [];

      if (!this.$v.lessonToMm.$dirty) return errors;
      if (!this.$v.lessonToMm.required) errors.push("Υποχρεωτικό πεδίο");

      return errors;
    },
    totUnjustifiedAbsErrors() {
      const errors = [];
      if (!this.$v.totUnjustifiedAbs.$dirty) return errors;
      if (!this.$v.totUnjustifiedAbs.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.totUnjustifiedAbs.numeric)
        errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    totAbsErrors() {
      const errors = [];
      if (!this.$v.totAbs.$dirty) return errors;
      if (!this.$v.totAbs.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.totAbs.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    absLimExcErrors() {
      const errors = [];
      if (!this.$v.absLimExc.$dirty) return errors;
      if (!this.$v.absLimExc.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    lessonRejectErrors() {
      const errors = [];
      if (!this.$v.lessonReject.$dirty) return errors;
      if (!this.$v.lessonReject.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    apopErrors() {
      const errors = [];
      if (!this.$v.apop.$dirty) return errors;
      if (!this.$v.apop.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    apop1Errors() {
      const errors = [];
      if (!this.$v.apop1.$dirty) return errors;
      if (!this.$v.apop1.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    passGradeErrors() {
      const errors = [];
      if (!this.$v.passGrade.$dirty) return errors;
      if (!this.$v.passGrade.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.passGrade.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    maxGradeErrors() {
      const errors = [];
      if (!this.$v.maxGrade.$dirty) return errors;
      if (!this.$v.maxGrade.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.maxGrade.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    flgActiveErrors() {
      const errors = [];
      if (!this.$v.flgActive.$dirty) return errors;
      if (!this.$v.flgActive.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    roundGradeErrors() {
      const errors = [];
      if (!this.$v.roundGrade.$dirty) return errors;
      if (!this.$v.roundGrade.decimal) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },
    creditUnitsErrors() {
      const errors = [];
      if (!this.$v.creditUnits.$dirty) return errors;
      if (!this.$v.creditUnits.required) errors.push("ΕΥποχρεωτικό πεδίο");
      return errors;
    },
    flgReqApprErrors() {
      const errors = [];
      if (!this.$v.flgReqAppr.$dirty) return errors;
      if (!this.$v.flgReqAppr.required) errors.push("ΕΥποχρεωτικό πεδίο");
      return errors;
    },
    shipTrainingIdErrors() {
      const errors = [];
      if (!this.$v.shipTrainingId.$dirty) return errors;
      if (!this.$v.shipTrainingId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addSemester", "getAllSemesters"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        let content = {
          absLimExc: this.absLimExc,
          apop: this.apop,
          cd: this.cd,
          description: this.description,
          duration: this.duration,
          examDuration: this.examDuration,
          flgActive: this.flgActive,
          flgReqAppr: this.flgReqAppr,
          hours: this.hours,
          labHours: this.labHours,
          registerAgainstNumFailedLessons: this.registerAgainstNumFailedLessons,
          dailyHours: this.dailyHours,
          lessonRepeatNr: this.lessonRepeatNr,
          lessonReject: this.lessonReject,

          lessonType: this.lessonType,
          // masterSchoolId: this.currentFaculty.id,
          maxDept: this.maxDept,
          maxGrade: this.maxGrade,

          minDept: this.minDept,
          passGrade: this.passGrade,

          schoolId: this.schoolId,
          shortDescription: this.shortDescription,
          totAbs: this.totAbs,
          totUnjustifiedAbs: this.totUnjustifiedAbs,
        };

        if (this.apop == 1) content.apop1 = this.apop1;

        if (this.lessonRepeatNr >= 1) {
          content.lessonFromDd = this.lessonFromDd;
          content.lessonFromMm = this.lessonFromMm;
          content.lessonToDd = this.lessonToDd;
          content.lessonToMm = this.lessonToMm;
        }

        if (this.memo != null) {
          content.memo = this.memo;
        }

        if (this.prevTrainingPeriodId != null) {
          content.prevTrainingPeriodId = this.prevTrainingPeriodId;
        }

        if (this.nextTrainingPeriodId != null) {
          content.nextTrainingPeriodId = this.nextTrainingPeriodId;
        }

        if (this.absCalcMode != null) {
          content.absCalcMode = this.absCalcMode;
        }

        if (this.absLimType != null) {
          content.absLimType = this.absLimType;
        }

        if (this.lessonTransNextPer != null) {
          content.lessonTransNextPer = this.lessonTransNextPer;
        }

        if (this.maxRegisterNr != null) {
          content.maxRegisterNr = this.maxRegisterNr;
        }

        // if (this.lastIssueNr != null) {
        //   content.lastIssueNr = this.lastIssueNr;
        // }

        if (this.shipTrainingId != null) {
          content.shipTrainingId = this.shipTrainingId;
          if (this.shipTrainingId == "1") {
            content.creditUnits = this.creditUnits;
          }
        }

        if (this.roundGrade != null) {
          content.roundGrade = this.roundGrade;
        }

        if (
          this.protSchoolDetails != null &&
          this.protSchoolDetails.trainPrd &&
          this.protSchoolDetails.trainPrd.cd == "2" &&
          this.appId == 2
        ) {
          content.titleId = this.titleId;
        }

        await this.addSemester(content);
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
    onChangeLesson() {
      if (this.lessonRepeatNr != "1") {
        this.lessonToDd = null;
        this.lessonToMm = null;
        this.lessonFromDd = null;
        this.lessonFromMm = null;
      } else {
        this.duration = null;
      }
    },
    async fetchCertificates() {
      let body = {
        type: 1,
        parentCd: null,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/106`,
          body
        );
        this.certificateList = res.data;
      } catch (e) {
        throw e;
      }
    },
    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>