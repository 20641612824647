import axios from "axios";
export default {
  state: {
    classes: [],
    classStudents: [],

    nicStudents: [],
    classTypes: [],
    epiloghTaksinomhshs: [],

    currentClass: null,
  },

  mutations: {
    getClasses(state, classes) {
      state.classes = classes;
    },

    getClassStudents(state, classStudents) {
      state.classStudents = classStudents;
    },

    getNicStudents(state, nicStudents) {
      state.nicStudents = nicStudents;
    },

    setCurrentClass(state, currentClass) {
      state.currentClass = currentClass;
    },

    getClassTypes(state, classTypes) {
      state.classTypes = classTypes;
    },

    getEpiloghTaksinomhshs(state, epiloghTaksinomhshs) {
      state.epiloghTaksinomhshs = epiloghTaksinomhshs;
    },
  },

  actions: {
    async getClasses({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/class/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              trainPeriodId: payload.trainPeriodId,
            },
          }
        );
        commit("getClasses", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getClassStudents({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/class/person/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              eduClassId: payload.eduClassId,
              searchText: payload.searchText,
            },
          }
        );
        commit("getClassStudents", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getNicStudents({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/listForClass`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              progTrainPeriodId: payload.progTrainPeriodId,
              masterSchoolId: payload.masterSchoolId,
            },
          }
        );
        commit("getNicStudents", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async createClasses({ commit, dispatch }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/class/init?progTrainPeriodId=${payload.progTrainPeriodId}&sortingType=${payload.sortingType}`,
          payload.classesInputs
        );
        commit("toggleSnackbar", {
          open: true,
          text: `Η δημιουργία ${
            payload.length == 1 ? "τμήματος" : "τμημάτων"
          } ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async createClasse({ commit, dispatch }, payload) {
      try {
        await axios.post(`${process.env.VUE_APP_BASE_URL}/class`, payload);
        commit("toggleSnackbar", {
          open: true,
          text: `Η δημιουργία τμήματος ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },
    async editClass({ commit, dispatch }, payload) {
      try {
        await axios.put(`${process.env.VUE_APP_BASE_URL}/class/${payload.id}`, {
          description: payload.description,
          progTrainPeriodId: payload.progTrainPeriodId,
          type: payload.type,
        });
        commit("toggleSnackbar", {
          open: true,
          text: `Η επεξεργασία τμήματος ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },
    async deleteClass({ commit, dispatch }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/class/${payload.classId}`
        );
        commit("toggleSnackbar", {
          open: true,
          text: `Η διαγραφή τμήματος ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        console.log(e);
        dispatch("errorHandler", e);
        throw e;
      }
    },

    async getClassTypes({ commit, dispatch }) {
      try {
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/value/56`);
        commit("getClassTypes", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async addStudentToClass({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/class/person/mass`,
          payload
        );
        console.log(rootState.base.filters);

        commit("toggleSnackbar", {
          open: true,
          text: `Η προσθήκη σπουδαστή στο τμήμα ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteStudentFromClass(
      { commit, dispatch, rootState, state },
      payload
    ) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/class/person/${payload.eduClassPersonId}`
        );

        commit("toggleSnackbar", {
          open: true,
          text: `Η διαγραφή σπουδαστή απο το τμήμα ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getEpiloghTaksinomhshs({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/160`
        );
        commit("getEpiloghTaksinomhshs", res.data);
      } catch (e) {
        throw e;
      }
    },
  },
};
