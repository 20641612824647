<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="6"
        :search="false"
        @onSearchAction="onSearch"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: true,
          class: false,
          lesson: false,
        }"
      />
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="archiveTeaching.content"
            :loading="tableLoader"
            :options.sync="options"
            :server-items-length="archiveTeaching.elements"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.actionDate`]="{ item }">
              <span>{{
                new Date(item.actionDate).toLocaleDateString("el")
              }}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary"
            class="mr-2"
            :disabled="isDisabled"
            @click="openDialog(null, 'add')"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddArchiveTeaching
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
      />
    </v-dialog>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="500px"
      scrollable
    >
      <EditArchiveTeaching
        v-if="dialog.open && dialog.type == 'edit'"
        :data="dialog.role"
        @close="closeDialog"
      />
    </v-dialog>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteArchiveTeaching
        v-if="dialog.open && dialog.type == 'delete'"
        :data="dialog.role"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AddArchiveTeaching from "../../components/ArchiveTeaching/AddArchiveTeaching.vue";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import EditArchiveTeaching from "../../components/ArchiveTeaching/EditArchiveTeaching.vue";
import DeleteArchiveTeaching from "../../components/ArchiveTeaching/DeleteArchiveTeaching.vue";

import axios from "axios";

export default {
  components: {
    AddArchiveTeaching,
    EditArchiveTeaching,
    DeleteArchiveTeaching,
    GenericFilter,
  },
  async created() {},
  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Θέμα",
          value: "memo",
          sortable: false,
        },
        {
          text: "Ημερομηνία",
          value: "actionDate",
          sortable: false,
        },
        {
          text: "Τμήμα",
          value: "eduClass.description",
          sortable: false,
        },
        {
          text: "Μάθημα",
          value: "lesson.description",
          sortable: false,
        },
        {
          text: "Ώρα",
          value: "scheduleHour.description",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      dialog: {
        open: false,
        type: null,
        role: null,
      },
      archiveTeaching: [],
    };
  },
  //   watch: {
  //     options: {
  //       async handler() {
  //         try {
  //           this.tableLoader = true;
  //           this.setFilters(this.options);
  //           this.fetchArchiveTeaching();
  //           this.tableLoader = false;
  //         } catch (e) {
  //           console.log(e);
  //           this.tableLoader = false;
  //         }
  //       },
  //     },
  //     deep: true,
  //   },

  computed: {
    ...mapState({
      roles: (state) => state.roles.roles,
      pageChanged: (state) => state.base.pageChanged,
      genericFilter: (state) => state.generic_filter,
    }),
    isDisabled() {
      if (
        this.genericFilter.classId != null &&
        this.genericFilter.courseId != null
      ) {
        return false;
      }
      return true;
    },
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.fetchArchiveTeaching();
      }
    },

    async fetchArchiveTeaching() {
      try {
        this.tableLoader = true;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/archive/teaching/material/list`,
          {
            params: {
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              lessonId: this.genericFilter.courseId,
              classId: this.genericFilter.classId,
              dateFrom: this.genericFilter.dateFrom,
              dateTo: this.genericFilter.dateTo,
            },
          }
        );

        this.archiveTeaching = res.data;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.archiveTeaching = [];

        this.$store.dispatch("errorHandler", e);
      }
    },
    openDialog(role, type) {
      this.dialog = {
        role,
        open: true,
        type,
      };
    },
    closeDialog() {
      this.dialog = {
        role: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.fetchArchiveTeaching();
    },
  },
};
</script>

<style scoped lang="scss">
</style>