var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper d-flex align-center justify-center"},[_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumb_items,"divider":">"}}),_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          },"headers":_vm.headers,"items":_vm.requirement.content,"loading":_vm.tableLoader,"options":_vm.options,"server-items-length":_vm.requirement.elements},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item, 'edit')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pen ")])],1)]}}],null,true)},[_c('span',[_vm._v("Επεξεργασία")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item, 'delete')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Διαγραφή")])])]}}],null,true)})],1),_c('div',{staticClass:"custom-card-buttons"},[_c('v-btn',{attrs:{"depressed":"","color":"primary ml-auto"},on:{"click":function($event){return _vm.openDialog(null, 'add')}}},[_vm._v(" Δημιουργία "),_c('v-icon',{attrs:{"dense":"","right":""}},[_vm._v("mdi-plus")])],1)],1)],1)],1),(_vm.dialog.type == 'add')?_c('v-dialog',{attrs:{"persistent":"","max-width":"800px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'add')?_c('AddApplicationRec',{attrs:{"cmnApplId":_vm.$route.params.common_online_application_id},on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e(),(_vm.dialog.type == 'edit')?_c('v-dialog',{attrs:{"persistent":"","max-width":"800px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'edit')?_c('EditApplicationRec',{attrs:{"action":_vm.dialog.action},on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e(),(_vm.dialog.type == 'delete')?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'delete')?_c('DeleteApplicationRec',{attrs:{"action":_vm.dialog.action},on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }