import axios from "axios";
export default {
  state: {},

  mutations: {},

  actions: {
    async approveApplication({ commit, dispatch }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/person/application/${payload.personApplicationId}/approve`,
          payload?.formData,
          {
            params: {
              approve: payload.approve ? true : false,
              protocolNo: payload.protocolNo,
            },
          }
        );
        commit("toggleSnackbar", {
          open: true,
          text: `${
            payload.approve ? "Ο έλεγχος" : "Η απόρριψη"
          }  αίτησης ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },
  },
};
