<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία λεπτομερειών εκπαιδευτικού ταξιδιού
      <v-btn depressed icon color="white" class="ml-auto" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-select dense outlined v-model="shipCompanyId" hide-details="auto" :items="shipCompanies.content"
              item-text="name" item-value="id" :error-messages="shipCompanyIdErrors" 
              no-data-text="Δεδομένα μη διαθέσιμα">
              <template v-slot:label>
                <span class="error--text">*</span>
                Πλοιοκτήτρια εταιρία
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-select dense outlined v-model="category" :items="getDataStaticList(155)" hide-details="auto"
              item-text="description" item-value="cd" no-data-text="Δεδομένα μη διαθέσιμα">
              <template v-slot:label> Κατηγορία </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <!-- <v-select
              dense
              outlined
              v-model="shipCompanyVesselId"
              :items="shipVessels.content"
              hide-details="auto"
              item-text="name"
              item-value="id"
              :error-messages="shipCompanyVesselIdErrors"
              :loading="shipCompanyLoader"
              :disabled="shipCompanyLoader || shipCompanyId == null"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Πλοίο εταιρίας
              </template>
            </v-select> -->
            <v-text-field label="Πλοίο εταιρίας" hide-details="auto" dense outlined v-model="shipName" type="text"
              :error-messages="shipNameErrors">
              <template v-slot:label>
                <span class="error--text">*</span>
                Πλοίο εταιρίας
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-select
                  dense
                  hide-details="auto"
                  outlined
                  label="Εκπρόσωπος / Εκπαιδευτής εταιρίας"
                  v-model="shipCompanyRepresentativeId"
                  :items="shipCompanyReps.content"
                  :item-text="(item) => item.firstName + ' ' + item.lastName"
                  item-value="id"
                  :loading="shipCompanyLoader"
                  :disabled="shipCompanyLoader || shipCompanyId == null"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-select>
              </template>
            </v-tooltip> -->
            <v-text-field label="Εκπρόσωπος / Εκπαιδευτής εταιρίας" hide-details="auto" dense outlined
              v-model="shipRepresentative" type="text">
              <template v-slot:label>
                Εκπρόσωπος / Εκπαιδευτής εταιρίας
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Κόροι Ολικής Χωρητικότητας" hide-details="auto" dense outlined v-model="kox"
              type="text" :error-messages="koxErrors">
              <template v-slot:label>
                <span class="error--text">*</span> Κόροι Ολικής Χωρητικότητας
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense outlined v-model="dateOfShippingFormatted" v-bind="attrs" hide-details="auto"
                  v-on="on" class="mb-4" @blur="
                    dateOfShipping = parseDate(
                      dateOfShippingFormatted,
                      'dateOfShipping'
                    )
                  " :disabled="shipCompanyLoader || shipCompanyId == null" :error-messages="dateOfShippingErrors">
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Ημερομηνία έναρξης
                  </template>
                </v-text-field>
              </template>
              <v-date-picker locale="el" v-model="dateOfShipping" scrollable @input="menu = false" hide-details="auto">
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense outlined v-model="dateOfDismissalFormatted" hide-details="auto" v-bind="attrs"
                  v-on="on" class="mb-4" :error-messages="dateOfDismissalErrors" @blur="
                    dateOfDismissal = parseDate(
                      dateOfDismissalFormatted,
                      'dateOfDismissal'
                    )
                  " :disabled="shipCompanyLoader || shipCompanyId == null">
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>

                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Ημερομηνία Απόλυσης
                  </template>
                </v-text-field>
              </template>
              <v-date-picker locale="el" v-model="dateOfDismissal" scrollable hide-details="auto"
                @input="menu1 = false">
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field label="Νηολόγιο" dense outlined v-model="nhologio" hide-details="auto" type="text"
                  :error-messages="nhologioErrors" v-bind="attrs" v-on="on"
                  :disabled="shipCompanyLoader || shipCompanyId == null">
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Νηολόγιο
                  </template>
                </v-text-field>
              </template>
              <span>Νηολόγιο</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field label="Υπηρεσία σε ημέρες" hide-details="auto" dense outlined v-model="maritimeService"
                  type="text" :error-messages="maritimeServiceErrors" v-bind="attrs" v-on="on"
                  :disabled="shipCompanyLoader || shipCompanyId == null">
                </v-text-field>
              </template>
              <span>Υπηρεσία σε ημέρες</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-textarea dense outlined v-model="memo" label="Σχόλια" hide-details="auto" type="text"
              :disabled="shipCompanyLoader || shipCompanyId == null">
            </v-textarea>
          </v-col>
        </v-row>

        <div class="d-flex justify-center mt-4">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn depressed color="success" class="ml-4" type="submit" :loading="loader">Δημιουργία</v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
import moment from "moment"

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  async created() {
    try {
      await this.getShipCompanies({ page: 0 });
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      loader: false,
      menu: false,
      menu1: false,
      shipCompanyLoader: false,

      dateOfDismissal: null,
      dateOfDismissalFormatted: null,
      dateOfShipping: null,
      dateOfShippingFormatted: null,
      nhologio: null,
      memo: null,
      shipCompanyId: null,
      category: null,
      kox: null,
      // shipCompanyRepresentativeId: null,
      shipRepresentative: null,
      // shipCompanyVesselId: null,
      shipName: null,
      maritimeService: null,
    };
  },

  watch: {
    dateOfDismissal(val) {
      this.dateOfDismissalFormatted = this.formatDate(this.dateOfDismissal);
      this.maritimeService = this.calcDays(val, this.dateOfShipping)
    },

    dateOfShipping(val) {
      this.dateOfShippingFormatted = this.formatDate(this.dateOfShipping);
      this.maritimeService = this.calcDays(this.dateOfDismissal, val)
    },
  },

  validations: {
    dateOfShipping: {
      required,
    },

    dateOfDismissal: {
      required,
    },

    shipCompanyId: {
      required,
    },
    kox: {
      required,
    },
    // shipCompanyVesselId: {
    //   required,
    // },
    shipName: {
      required,
    },
    nhologio: {
      required,
    },

    maritimeService: {
      numeric,
    },
  },

  computed: {
    ...mapState({
      shipCompanies: (state) => state.ship_companies.shipCompanies,
      shipVessels: (state) => state.ship_companies.shipVessels,
      shipCompanyReps: (state) => state.ship_companies.shipCompanyReps,

      progTrainPeriodId: (state) => state.generic_filter.semesterId,
      listSemester: (state) => state.generic_filter.listSemester,
      staticList: (state) => state.auth.userInfo.staticView,
    }),

    dateOfShippingErrors() {
      const errors = [];
      if (!this.$v.dateOfShipping.$dirty) return errors;
      if (!this.$v.dateOfShipping.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    dateOfDismissalErrors() {
      const errors = [];
      if (!this.$v.dateOfDismissal.$dirty) return errors;
      if (!this.$v.dateOfDismissal.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    nhologioErrors() {
      const errors = [];
      if (!this.$v.nhologio.$dirty) return errors;
      if (!this.$v.nhologio.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    shipCompanyIdErrors() {
      const errors = [];
      if (!this.$v.shipCompanyId.$dirty) return errors;
      if (!this.$v.shipCompanyId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    // shipCompanyVesselIdErrors() {
    //   const errors = [];
    //   if (!this.$v.shipCompanyVesselId.$dirty) return errors;
    //   if (!this.$v.shipCompanyVesselId.required)
    //     errors.push("Υποχρεωτικό πεδίο");
    //   return errors;
    // },
    shipNameErrors() {
      const errors = [];
      if (!this.$v.shipName.$dirty) return errors;
      if (!this.$v.shipName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    koxErrors() {
      const errors = [];
      if (!this.$v.kox.$dirty) return errors;
      if (!this.$v.kox.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    maritimeServiceErrors() {
      const errors = [];
      if (!this.$v.maritimeService.$dirty) return errors;
      if (!this.$v.maritimeService.numeric)
        errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },
  },

  methods: {
    ...mapActions([
      "addProgTripDetail",
      "getShipCompanies",
      // "getShipVessels",
      "getShipCompanyReps",
    ]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        let content = {
          progTripId: this.$route.params.prog_trip_id,
          shipCompanyId: this.shipCompanyId,
          // shipCompanyVesselId: this.shipCompanyVesselId,
          shipName: this.shipName,
          dateOfShipping: this.dateOfShipping,
          dateOfDismissal: this.dateOfDismissal,
          nhologio: this.nhologio,
          kox: this.kox,
          category: this.category,
        };

        if (this.memo != null) {
          content.memo = this.memo;
        }

        if (this.maritimeService != null) {
          content.maritimeService = this.maritimeService;
        }

        if (this.shipRepresentative != null) {
          content.shipRepresentative = this.shipRepresentative;
        }

        await this.addProgTripDetail(content);
        this.loader = false;
        await this.$emit("handler");
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    // async onShipCompanyChange() {
    //   try {
    //     this.shipCompanyLoader = true;

    //     await this.getShipVessels({
    //       page: 0,
    //       shipCompanyId: this.shipCompanyId,
    //     });

    //     await this.getShipCompanyReps({
    //       page: 0,
    //       shipCompanyId: this.shipCompanyId,
    //     });

    //     this.shipCompanyVesselId = null;
    //     this.shipCompanyRepresentativeId = null;
    //     this.shipCompanyLoader = false;
    //   } catch (e) {
    //     this.shipCompanyLoader = false;

    //     console.log(e);
    //   }
    // },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "dateOfDismissal") {
          this.dateOfDismissal = null;
          this.dateOfDismissalFormatted = null;
        } else if (dateName == "dateOfShipping") {
          this.dateOfShipping = null;
          this.dateOfShippingFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
    calcDays(startDate, endDate) {
      if (startDate != null && endDate != null) {
        var start = moment(startDate, "YYYY-MM-DD");
        var end = moment(endDate, "YYYY-MM-DD");

        //Difference in number of days
        console.log(moment.duration(start.diff(end)).asDays())
        return (Math.floor(moment.duration(start.diff(end)).asDays())) + 1;
      }
      return 0

    }
  },
};
</script>

<style scoped lang="scss">
</style>