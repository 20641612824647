import axios from "axios";
import store from "../store";

export default {
  state: {
    faculties: [],
    facultiesCategories: [],
    currentFaculty: JSON.parse(localStorage.getItem("faculty")) || null,
    selectedSchoolType: null,
  },

  mutations: {
    getFaculties(state, faculties) {
      state.faculties = faculties;
    },
    setCurrentFaculty(state, faculty) {
      state.currentFaculty = faculty;
      localStorage.setItem("faculty", JSON.stringify(faculty));
    },
    getFacultiesCategories(state, facultiesCategories) {
      state.facultiesCategories = facultiesCategories;
    },
    setSelectedSchoolType(state, schoolType) {
      state.selectedSchoolType = schoolType;
    },
  },

  actions: {
    async getFaculties({ commit, dispatch }, filters) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/master/school/list`,
          {
            params: {
              page: filters.page,
              limit: filters.limit,
              kind: filters.kind,
            },
          }
        );
        commit("getFaculties", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async addFaculty({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/master/school`,
          payload
        );
        // await dispatch("getFaculties", {
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.faculties.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: `Η δημιουργία ${
            store.state.auth.appId == 1 ? "Ακαδημίας" : "Δομής Μετεκπαίδευσης"
          } ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async editFaculty({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/master/school/${payload.id}`,
          payload.content
        );
        // await dispatch("getFaculties", {
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.faculties.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: `Η επεξεργασία  ${
            store.state.auth.appId == 1 ? "Ακαδημίας" : "Δομής Μετεκπαίδευσης"
          }  ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteFaculty({ commit, dispatch, rootState, state }, id) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/master/school/${id}`
        );
        if (state.faculties.limit == 1 && state.faculties.page != 1) {
          commit("changePage", true);
        } else {
          // await dispatch("getFaculties", {
          //   page:
          //     state.faculties.limit == 1
          //       ? rootState.base.filters.page - 1
          //       : rootState.base.filters.page,
          //   limit:
          //     rootState.base.filters.itemsPerPage == -1
          //       ? state.faculties.elements
          //       : rootState.base.filters.itemsPerPage,
          // });
        }

        commit("toggleSnackbar", {
          open: true,
          text: `Η διαγραφή  ${
            store.state.auth.appId == 1 ? "Ακαδημίας" : "Δομής Μετεκπαίδευσης"
          }  ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getFacultiesCategories({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/104`
        );
        commit("getFacultiesCategories", res.data);
      } catch (e) {
        throw e;
      }
    },

    setSelectedSchoolType({ commit }, payload) {
      commit("setSelectedSchoolType", payload);
    },
  },
};
