var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-4"},[_c('v-card-text',[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
        'items-per-page-text': 'Σειρές ανά σελίδα',
        'items-per-page-all-text': 'Όλες',
        'items-per-page-options': [5, 10, 15, -1],
      },"headers":_vm.headers,"items":_vm.certifications.content,"server-items-length":_vm.certifications.elements,"options":_vm.options,"loading":_vm.tableLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.startDate).toLocaleDateString("el")))])]}},{key:"item.endDate",fn:function(ref){
      var item = ref.item;
return [(item.endDate != undefined)?_c('span',[_vm._v(_vm._s(new Date(item.endDate).toLocaleDateString("el")))]):_c('span',[_vm._v("-")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }