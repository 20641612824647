<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="4"
        @onSearchAction="onSearch"
        :key="query"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: true,
          class: false,
          lesson: false,
        }"
      />

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="appId == 1 ? headersAen : headersKesen"
            :items="!filtersSet ? [] : schedulingExams.content"
            :server-items-length="schedulingExams.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.startDate`]="{ item }">
              <span>{{
                new Date(item.startDate).toLocaleDateString("el")
              }}</span>
            </template>

            <template v-slot:[`item.endDate`]="{ item }">
              <span>{{ new Date(item.endDate).toLocaleDateString("el") }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.schools`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary ml-auto"
            @click="openDialog(null, 'add')"
            v-if="this.genericFilter.academicYearId != null"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="1000px"
      scrollable
    >
      <AddExam
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="1000px"
      scrollable
    >
      <EditExam
        v-if="dialog.open && dialog.type == 'edit'"
        :exam="dialog.exam"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteExam
        v-if="dialog.open && dialog.type == 'delete'"
        :exam="dialog.exam"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>


<script>
import { mapState, mapMutations, mapActions } from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import AddExam from "../../components/SchedulingExamsModals/AddExam.vue";
import EditExam from "../../components/SchedulingExamsModals/EditExam.vue";
import DeleteExam from "../../components/SchedulingExamsModals/DeleteExam.vue";

export default {
  name: "SchedulingExam",
  components: {
    GenericFilter,
    AddExam,
    EditExam,
    DeleteExam,
  },
  async created() {
    try {
      if (this.genericFilter.semesterId != null) await this.handler();
    } catch (e) {
      throw e;
    }
  },

  data() {
    return {
      tableLoader: false,
      search: "",
      query: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      filtersSet: false,
      headersAen: [
        {
          text: "Περιγραφή",
          value: "description",
          sortable: false,
        },

        {
          text: "Ημερομηνία έναρξης",
          value: "startDate",
          sortable: false,
        },

        {
          text: "Ημερομηνία λήξης",
          value: "endDate",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      headersKesen: [
        {
          text: "Σειρά",
          value: "seira",
          sortable: false,
        },

        {
          text: "Περιγραφή",
          value: "description",
          sortable: false,
        },

        {
          text: "Ημερομηνία έναρξης",
          value: "startDate",
          sortable: false,
        },

        {
          text: "Ημερομηνία λήξης",
          value: "endDate",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        exam: null,
        open: false,
        type: null,
      },
    };
  },
  watch: {
    async options() {
      if (this.genericFilter.semesterId != null) await this.handler();
    },
    $route() {
      this.query = this.$route.params.id;
    },
  },

  computed: {
    ...mapState({
      schedulingExams: (state) => state.scheduling_exams.schedulingExams,
      genericFilter: (state) => state.generic_filter,
      pageChanged: (state) => state.base.pageChanged,
      appId: (state) => state.auth.appId,
    }),
  },
  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getSchedulingExams"]),

    openDialog(exam, type) {
      console.log(exam);
      this.dialog = {
        exam,
        open: true,
        type,
      };
    },
    closeDialog() {
      this.dialog = {
        exam: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.handler();
    },

    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },

    async handler() {
      try {
        this.tableLoader = true;
        await this.getSchedulingExams({
          id: this.genericFilter.academicYearId,
          examPeriodId: this.genericFilter.semesterId,
          page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
          limit:
            this.options.itemsPerPage == -1 ? null : this.options.itemsPerPage,
        });
        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },
  },
};
</script>