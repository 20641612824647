<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <ClosurePeriodFilter
        :level="3"
        :key="query"
        @onSearchAction="onSearch"
        :listActive="1"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          dateFormatted: true,
          date: false,
        }"
      />
      <v-card-text class="pa-0 pb-4 pt-4 d-flex flex-column flex-sm-row">
        <v-card class="flex-grow-1 mb-5 mb-sm-0">
          <v-card-text>
            <v-data-table
              no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
              loading-text="Φόρτωση..."
              :footer-props="{
                'items-per-page-text': 'Σειρές ανά σελίδα',
                'items-per-page-all-text': 'Όλες',
                'items-per-page-options': [5, 10, 15, 100],
              }"
              :headers="appId == 1 ? headersExamAen : headersExam"
              :items="data.content"
              :options.sync="options"
              :server-items-length="data.elements"
              :loading="tableLoader"
            >
              <template v-slot:[`item.endDate`]="{ item }">
                <span>{{
                  new Date(item.endDate).toLocaleDateString("el")
                }}</span>
              </template>
              <template v-slot:[`item.startDate`]="{ item }">
                <span>{{
                  new Date(item.startDate).toLocaleDateString("el")
                }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon @click="openDialog(item, type)">
                      <v-icon color="red darken-1" v-bind="attrs" v-on="on">
                        mdi-calendar-remove
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Κλείσιμο περιόδου</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog persistent v-model="dialog.open" max-width="500px" scrollable>
      <ExamPeriodClosureModal
        v-if="dialog.open"
        :type="dialog.type"
        :data="dialog.data"
        @close="closeDialog"
      />
    </v-dialog>
    <Loader v-if="this.showLoader" />
  </div>
</template>


<script>
import { mapState } from "vuex";
import axios from "axios";
import ClosurePeriodFilter from "../../components/ClosurePeriodFilter/ClosurePeriodFilter.vue";
import ExamPeriodClosureModal from "../../components/PeriodClosureModal/ExamPeriodClosureModal.vue";
import Loader from "../../components/Loader/Loader.vue";

export default {
  name: "TrainPeriodClosure",
  components: {
    ClosurePeriodFilter,
    ExamPeriodClosureModal,
    Loader,
  },
  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      data: [],
      query: false,
      headersExamAen: [
        {
          text: "Πρότυπο Εξεταστικής περιόδου",
          value: "examPeriodDescription",
          sortable: false,
        },
        {
          text: "Εξεταστική περίοδος",
          value: "progExamPrdDescription",
          sortable: false,
        },
        {
          text: "Εξάμηνο σπουδών",
          value: "trainPeriodDescription",
          sortable: false,
        },
        {
          text: "Ημ/νια έναρξης",
          value: "startDate",
          sortable: false,
        },
        {
          text: "Ημ/νια λήξης",
          value: "endDate",
          sortable: false,
        },
        { text: "Επιλογές", value: "actions", sortable: false },
      ],
      headersExamKesen: [
        {
          text: "Πρότυπο Εξεταστικής περιόδου",
          value: "examPeriodDescription",
          sortable: false,
        },
        {
          text: "Εξεταστική περίοδος",
          value: "progExamPrdDescription",
          sortable: false,
        },
        {
          text: "Κύκλος σπουδών",
          value: "trainPeriodDescription",
          sortable: false,
        },
        {
          text: "Ημ/νια έναρξης",
          value: "startDate",
          sortable: false,
        },
        {
          text: "Ημ/νια λήξης",
          value: "endDate",
          sortable: false,
        },
        { text: "Επιλογές", value: "actions", sortable: false },
      ],
      dialog: {
        data: null,
        open: false,
        type: null,
      },
      showLoader: false,
    };
  },
  watch: {
    async options() {
      if (
        this.genericFilter.academicYearId != null &&
        this.genericFilter.date != null
      )
        await this.handler();
    },
    $route() {
      this.query = this.$route.params.id;
    },
  },
  methods: {
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    async handler() {
      this.data = [];
      this.showLoader = true;

      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/prog/exam/list/open`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              yearId: this.genericFilter.academicYearId,
              date: this.genericFilter.date,
            },
          }
        );
        this.data = res.data;
        this.showLoader = false;
      } catch (e) {
        this.showLoader = false;
        this.data = [];
        this.$store.dispatch("errorHandler", e);
      }
      this.showLoader = false;
    },
    openDialog(item, type) {
      this.dialog = {
        data: item,
        open: true,
        type,
      };
    },
    closeDialog() {
      this.dialog = {
        data: null,
        open: false,
        type: null,
      };

      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.handler();
    },
    async fetchDataDynamic(groupId, parentId) {
      console.log(groupId);
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },
  },
  computed: {
    ...mapState({
      genericFilter: (state) => state.generic_filter,
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
    }),
  },
};
</script>
