
 <template>
  <v-card
    color="transparent"
    class="component-wrapper d-flex align-center justify-center elevation-0"
  >
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="6"
        :key="query"
        @onSearchAction="onSearch"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: true,
          class: true,
          lesson: true,
        }"
      />
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="getHeaders"
            :items="studentGrades.content"
            :options.sync="options"
            :server-items-length="studentGrades.elements"
            :loading="tableLoader"
          >
            <template v-slot:[`item.entry_date`]="{ item }">
              <span v-if="item.entry_date != null">
                {{ new Date(item.entry_date).toLocaleDateString("el") }}
              </span>
            </template>

            <template v-slot:[`item.exm_date`]="{ item }">
              <span v-if="item.entry_date != null">
                {{ new Date(item.exm_date).toLocaleDateString("el") }}
              </span>
            </template>

            <template v-slot:[`item.flg_success`]="{ item }">
              <span v-if="item.flg_success != null">
                {{ item.flg_success }}
              </span>
            </template>

            <template v-slot:[`item.approveOral`]="{ item }">
              <v-icon v-if="item.approveOral" color="success"
                >mdi-check</v-icon
              >
              <v-icon v-if="!item.approveOral" color="error">mdi-close</v-icon>
            </template>

            <template v-slot:[`item.approveWritten`]="{ item }">
              <v-icon v-if="item.approveWritten" color="success"
                >mdi-check</v-icon
              >
              <v-icon v-if="!item.approveWritten" color="error"
                >mdi-close</v-icon
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </v-card>
</template>



<script>
import { mapState, mapMutations, mapActions } from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import axios from "axios";
export default {
  components: {
    GenericFilter,
  },

  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      query: false,
      filtersSet: false,
      studentGrades: [],
      lessonInfo: null,
      headers: [
        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },

        {
          text: "Ημερομηνία καταχώρησης",
          value: "entryDate",
          sortable: false,
        },

        {
          text: "Ημερομηνία εξέτασης",
          value: "exmDate",
          sortable: false,
        },

        {
          text: "Βαθμολογία προόδου",
          value: "oral",
          sortable: false,
        },

        {
          text: "Βαθμολογία γραπτών",
          value: "written",
          sortable: false,
        },

        {
          text: "Τελική βαθμολογία",
          value: "grade",
          sortable: false,
        },

        {
          text: "Επίδοση",
          value: "gradeCharac",
          sortable: false,
        },

        {
          text: "Έλεγχος προόδου",
          value: "approveOral",
          sortable: false,
        },

        {
          text: "Έλεγχος γραπτής",
          value: "approveWritten",
          sortable: false,
        },
      ],
      headersOnlyWritten: [
        {
          text: "ΑΜ",
          value: "student_school_code",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "last_name",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "first_name",
          sortable: false,
        },

        {
          text: "Ημερομηνία καταχώρησης",
          value: "entry_date",
          sortable: false,
        },

        {
          text: "Ημερομηνία εξέτασης",
          value: "exm_date",
          sortable: false,
        },
        {
          text: "Βαθμολογία γραπτών",
          value: "written",
          sortable: false,
        },

        {
          text: "Τελική βαθμολογία",
          value: "grade",
          sortable: false,
        },

        {
          text: "Επίδοση",
          value: "grade_charac",
          sortable: false,
        },

        {
          text: "Έλεγχος γραπτής",
          value: "approved_written",
          sortable: false,
        },
      ],
      headersOnlyOrally: [
        {
          text: "ΑΜ",
          value: "student_school_code",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "last_name",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "first_name",
          sortable: false,
        },

        {
          text: "Ημερομηνία καταχώρησης",
          value: "entry_date",
          sortable: false,
        },

        {
          text: "Ημερομηνία εξέτασης",
          value: "exm_date",
          sortable: false,
        },

        {
          text: "Βαθμολογία προόδου",
          value: "oral",
          sortable: false,
        },

        {
          text: "Τελική βαθμολογία",
          value: "grade",
          sortable: false,
        },
        {
          text: "Επίδοση",
          value: "grade_charac",
          sortable: false,
        },
        {
          text: "Έλεγχος προόδου",
          value: "approved_oral",
          sortable: false,
        },
      ],
    };
  },

  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },
    $route() {
      this.query = this.$route.params.id;
    },
  },

  computed: {
    ...mapState({
      pageChanged: (state) => state.base.pageChanged,
      masterSchoolId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      yearId: (state) => state.generic_filter.academicYearId,
      progTrainPeriodId: (state) => state.generic_filter.semesterId,
      classId: (state) => state.generic_filter.classId,
      courseId: (state) => state.generic_filter.courseId,
    }),

    getHeaders() {
      if (this.lessonInfo != null) {
        return this.lessonInfo.examType.cd == "3"
          ? this.headers
          : this.lessonInfo.examType.cd == "2"
          ? this.headersOnlyOrally
          : this.lessonInfo.examType.cd == "1"
          ? this.headersOnlyWritten
          : this.headers;
      } else {
        return this.headers;
      }
    },
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getStudentGrades"]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        // get lesson info
        const resLesson = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/lesson/${this.courseId}`,
          {
            params: {
              page: 0,
            },
          }
        );
        this.lessonInfo = resLesson.data;

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/student/grade/preview`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              masterSchoolId: this.masterSchoolId,
              schoolId: this.schoolId,
              yearId: this.yearId,
              progTrainPeriodId: this.progTrainPeriodId,
              lessonId: this.courseId,
              classId: this.classId,
              // approved: false,
            },
          }
        );

        this.studentGrades = res.data;

        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.studentGrades = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>