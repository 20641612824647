import axios from "axios";

export default {
  state: {
    exams: [],

    //dropdowns
    studentParts: [],
    examLimss: [],
  },

  mutations: {
    getExams(state, exams) {
      state.exams = exams;
    },

    //dropdowns

    getStudentParts(state, studentParts) {
      state.studentParts = studentParts;
    },

    getExamLimss(state, examLimss) {
      state.examLimss = examLimss;
    },
  },

  actions: {
    async getExams({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/exam/period/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,

              trainPeriodId: payload.trainPeriodId,
            },
          }
        );
        commit("getExams", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async addExam({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/exam/period`,
          payload
        );
        // await dispatch("getExams", {
        //   trainPeriodId: payload.trainPeriodId,
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.exams.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: "Η δημιουργία εξεταστικής περιόδου ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async editExam({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/exam/period/${payload.id}`,
          payload.content
        );
        // await dispatch("getExams", {
        //   trainPeriodId: payload.content.trainPeriodId,
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.exams.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: "Η επεξεργασία εξεταστικής περιόδου ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteExam({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/exam/period/${payload.id}`
        );
        if (state.exams.limit == 1 && state.exams.page != 1) {
          commit("changePage", true);
        } else {
          //   await dispatch("getExams", {
          //     trainPeriodId: payload.trainPeriodId,
          //     page: rootState.base.filters.page,
          //     limit:
          //       rootState.base.filters.itemsPerPage == -1
          //         ? state.exams.elements
          //         : rootState.base.filters.itemsPerPage,
          //   });
        }
        commit("toggleSnackbar", {
          open: true,
          text: "Η διαγραφή εξεταστικής περιόδου ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },
    ///dropdowns

    async getExamLimss({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/116`
        );
        commit("getExamLimss", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getStudentParts({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/117`
        );
        commit("getStudentParts", res.data);
      } catch (e) {
        throw e;
      }
    },
  },
};
