<template>
  <div>
    <!-- appbar -->
    <v-app-bar app color="primary">
      <v-app-bar-nav-icon
        color="white"
        @click="
          !$vuetify.breakpoint.xsOnly ? (mini = !mini) : (drawer = !drawer)
        "
      ></v-app-bar-nav-icon>

      <div class="ml-auto d-flex align-center">
        <v-select
          dense
          v-if="userInfo"
          solo
          label="Ρολος"
          :value="this.selectedRole"
          placeholder="Ρόλος"
          :items="this.userInfo.userInfo.roles"
          item-text="description"
          item-value="id"
          class="roleSelected"
          hide-details="auto"
          @change="this.onSetSelectedRole"
          no-data-text="Δεδομένα μη διαθέσιμα"
        ></v-select>

        <v-menu offset-y transition="scroll-y-transition" class="menu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed icon color="white" v-bind="attrs" v-on="on">
              <v-badge
                :content="unreadNotifications.elements"
                :value="unreadNotifications.elements"
                color="success"
                overlap
              >
                <v-icon>mdi-bell</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <div
            class="pa-3 text-body-1 white--text menu d-flex flex-column primary"
          >
            <span
              v-if="
                unreadNotifications.elements > 1 ||
                unreadNotifications.elements == 0
              "
              class="font-weight-bold white--text"
              >{{ unreadNotifications.elements }}
              νέες
            </span>

            <span
              v-else-if="unreadNotifications.elements == 1"
              class="font-weight-bold white--text"
              >{{ unreadNotifications.elements }} νέα
            </span>

            <v-btn
              plain
              text
              dense
              v-if="
                unreadNotifications.elements > 1 ||
                unreadNotifications.elements == 0
              "
              class="text-body-2 white--text pa-0"
              @click="$router.push('/notifications')"
            >
              <span class="mr-auto">Ειδοποιήσεις</span>
            </v-btn>

            <v-btn
              text
              dense
              plain
              v-else-if="unreadNotifications.elements == 1"
              class="text-body-2 white--text pa-0"
              @click="$router.push('/notifications')"
            >
              <span class="mr-auto">Ειδοποίηση</span>
            </v-btn>
          </div>
          <v-list dense>
            <v-list-item
              v-for="(n, i) in unreadNotifications.content"
              :key="i"
              link
              active-class="primary--text"
              @click="$router.push('/notifications')"
            >
              <v-list-item-title>{{ n.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y transition="scroll-y-transition" class="menu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed large icon color="white" v-bind="attrs" v-on="on">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>

          <div
            class="pa-3 primary text-body-1 white--text menu d-flex flex-column"
            v-if="userInfo.userInfo.person"
          >
            <span class="font-weight-bold"
              >{{ userInfo.userInfo.person.firstName }}
              {{ userInfo.userInfo.person.lastName }}</span
            >

            <span class="text-body-2"> Email χρήστη</span>
          </div>

          <v-list dense>
            <v-list-item link active-class="primary--text" to="/profile">
              <v-icon>mdi-account-outline</v-icon>
              <v-list-item-title>Προφίλ</v-list-item-title>
            </v-list-item>

            <v-list-item
              class="text--red"
              link
              @click="onLogout"
              active-class="primary--text"
            >
              <v-icon>mdi-power</v-icon>
              <v-list-item-title>Έξοδος</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <!-- appbar -->

    <!-- navdrawer -->
    <v-navigation-drawer
      v-model="drawer"
      :width="300"
      :permanent="!$vuetify.breakpoint.xsOnly"
      :mini-variant="!$vuetify.breakpoint.xsOnly ? mini : false"
      :expand-on-hover="mini ? true : false"
      app
    >
      <v-list-item class="list-height" @click="goToHome()">
        <v-list-item-avatar
          class="mr-1 ml-0"
          style="height: 30px; min-width: 32px; width: 32px; padding: 0px"
        >
          <v-img src="@/assets/ethnosimo.png" v-if="this.appId == 1"></v-img>
          <v-img src="@/assets/aggyres.png" v-if="this.appId == 2"></v-img>
        </v-list-item-avatar>

        <v-list-item-title class="greyish--text" v-if="this.appId == 1"
          >Ακαδημία Εμπορικού Ναυτικού</v-list-item-title
        >
        <v-list-item-title
          class="greyish--text"
          style="font-size: 14px; display: flex"
          v-if="this.appId == 2"
          >Δημόσια Σχολή Εμπορικού Ναυτικού</v-list-item-title
        >
      </v-list-item>

      <v-divider></v-divider>
      <v-list dense class="red_list">
        <div v-if="userInfo.userInfo.roles">
          <div v-for="item in this.selectedMenu" :key="item.name">
            <v-list-item
              v-if="item.isParent == 0"
              :key="item.name"
              link
              :to="item.path"
              color="primary"
            >
              <v-list-item-icon class="mr-2">
                <v-icon class="links">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>

            <v-list-group
              v-else
              v-model="item.active"
              :prepend-icon="item.action"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-icon class="mr-2">
                  <v-icon class="links">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </template>
              <div v-for="child in item.childMenus" :key="child.name">
                <v-list-item
                  v-if="child.isParent == 0"
                  :key="child.name"
                  link
                  :to="child.path"
                  class="childMenu"
                >
                  <v-list-item-icon class="mr-2">
                    <v-icon class="links">{{ child.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ child.name }}</v-list-item-title>
                </v-list-item>
                <v-list-group
                  v-else
                  v-model="child.active"
                  :prepend-icon="child.action"
                  no-action
                  sub-group
                  class="subGroup"
                >
                  <template v-slot:activator>
                    <div style="display: flex; flex-direction: row">
                      <v-list-item-icon class="mr-2">
                        <v-icon class="links">{{ child.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title
                        v-text="child.name"
                      ></v-list-item-title>
                    </div>
                  </template>

                  <div
                    v-for="subChild in child.childMenus"
                    :key="subChild.name"
                  >
                    <v-list-item :key="subChild.name" link :to="subChild.path">
                      <v-list-item-icon class="mr-2 ml-7">
                        <v-icon class="links">{{ subChild.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subChild.name }}</v-list-item-title>
                    </v-list-item>
                  </div>
                </v-list-group>
              </div>
            </v-list-group>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>
    <!-- navdrawer -->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  async created() {
    console.log(this.appId);
    try {
      console.log(this.selectedSystemRole);
      if (this.selectedSystemRole != "LOGGED_IN_USER_ROLE") {
        await this.getUnreadNotifications({
          page: 1,
          limit: 10,
        });
      }
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      messages: 1,
      drawer: false,
      mini: false,
      list: [
        {
          title: "Σχολές",
          icon: "mdi-school-outline",
          to: "/faculties",
        },
        {
          title: "Κατηγορίες εκπαιδευτικών",
          icon: "mdi-account-group-outline",
          to: "/professors_categories",
        },
        {
          title: "Χρήστες",
          icon: "mdi-account-multiple-outline",
          to: "/users",
        },
        {
          title: "Ρόλοι",
          icon: "mdi-account-lock-outline",
          to: "/roles",
        },
        {
          title: "Ειδοποιήσεις",
          icon: "mdi-bell-outline",
          to: "/notifications",
        },
        {
          title: "Αιτήσεις",
          icon: "mdi-clipboard-outline",
          to: "/applications",
        },
      ],
    };
  },
  watch: {
    mini() {
      this.$store.dispatch("setMiniMenu", this.mini);
    },
  },
  computed: {
    ...mapState({
      unreadNotifications: (state) => state.notifications.unreadNotifications,
      userProfile: (state) => state.users.userProfile,
      userInfo: (state) => state.auth,
      selectedRole: (state) => state.auth.selectedRole,
      selectedSystemRole: (state) => state.auth.selectedSystemRole,
      appId: (state) => state.auth.appId,
    }),
    // ...mapState(["auth"]),
    ...mapGetters(["isLoggedIn"]),
    selectedMenu() {
      try {
        let menu = this.userInfo.userInfo.roles.filter((m) => {
          if (m.id == this.selectedRole) {
            return m.menu;
          }
        });
        if (menu.length > 0) {
          return menu[0].menu;
        } else {
          return [];
        }
      } catch (e) {
        return [];
      }
    },
  },

  methods: {
    ...mapActions(["logout", "getUnreadNotifications", "getUserProfile"]),

    onLogout() {
      this.$store.dispatch("setAllFilter", {
        facultyId: null,
        schoolId: null,
        academicYearId: null,
        semesterId: null,
        classId: null,
        courseId: null,
        dates: null,
        date: null,
        active: null,
        searchText: null,
      });
      this.logout();
      this.$router.push("/login/");
    },
    onSetSelectedRole(value) {
      let page = this.userInfo.userInfo.roles.filter((m) => {
        if (m.id == value) {
          return m;
        }
      });

      this.$store
        .dispatch("setSelectedRole", {
          roleId: value,
          name: page[0].systemRole,
        })
        .then(() => {
          this.$router.push(page[0].defaultPage);
        });
    },
    goToHome() {
      let page = this.userInfo.userInfo.roles.filter((m) => {
        if (m.id == this.selectedRole) {
          return m;
        }
      });
      this.$router.push(page[0].defaultPage);
    },
  },
};
</script>

<style scoped lang="scss">
.subGroup:has(.v-list-item) {
  // background-color: red !important;
  display: flex;
  flex-direction: row-reverse !important;
}

.list-height {
  height: 64px !important;
  width: 310px;
}

.avatar-image {
  width: 31px !important;
  height: 31px !important;
}

.menu {
  width: 350px !important;
}

.roleSelected {
  background-color: white !important;
}
.childMenu {
  margin-left: 25px;
}

// .theme--light.v-list-item--active:before,
// .theme--light.v-list-item--active:hover:before,
// .theme--light.v-list-item:focus:before {
//   opacity: 0;
// }
</style>