import axios from 'axios'
export default {
    state: {
        certificates: [],
    },

    mutations: {
        getCertificates(state, certificates) {
            state.certificates = certificates
        },
    },

    actions: {
        async getCertificates({ commit }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/certificate/list`,
                    {
                        params: {
                            page: payload.page,
                            limit: payload.limit,

                            schoolId: payload.id
                        }
                    })
                commit('getCertificates', res.data)
            }
            catch (e) {
                throw e;
            }
        },

        async addCertificate({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/certificate`, payload)
                // await dispatch('getCertificates', {
                //     id: payload.schoolId,
                //     page: rootState.base.filters.page,
                //     limit: rootState.base.filters.itemsPerPage == -1 ? state.certificates.elements : rootState.base.filters.itemsPerPage,

                // })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία πιστοποιητικού ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async editCertificate({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/certificate/${payload.certificateId}`, payload.content)
                // await dispatch('getCertificates', {
                //     id: payload.content.schoolId,
                //     page: rootState.base.filters.page,
                //     limit: rootState.base.filters.itemsPerPage == -1 ? state.certificates.elements : rootState.base.filters.itemsPerPage,

                // })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία πιστοποιητικού ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async deleteCertificate({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/certificate/${payload.certificateId}`,)
                if (state.certificates.limit == 1 && state.certificates.page != 1) {
                    commit('changePage', true)
                } else {
                    // await dispatch('getCertificates', {
                    //     id: payload.schoolId,
                    //     page: rootState.base.filters.page,
                    //     limit: rootState.base.filters.itemsPerPage == -1 ? state.certificates.elements : rootState.base.filters.itemsPerPage,

                    // })
                }
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή πιστοποιητικού ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

    }
}