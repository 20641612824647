
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-breadcrumbs :items="breadcrumb_items" divider=">"></v-breadcrumbs>

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="teachers.content"
            :server-items-length="teachers.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onTeacherView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  created() {
    this.breadcrumb_items.push(
      {
        text: `${this.appId == 1 ? "Ακαδημίες" : "Δομές Μετεκπαίδευσης"} (${
          this.currentFaculty.description
        })`,
        disabled: false,
        link: true,
        exact: true,
        to: "/faculties",
      },
      {
        text: `Εκπαιδευτικό προσωπικό `,
        disabled: true,
        to: `/faculties/${this.currentFaculty.id}/teachers`,
      }
    );
  },

  data() {
    return {
      search: "",
      breadcrumb_items: [],
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },

        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },

        {
          text: "Κατηγορία",
          value: "category.description",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        teacher: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    options: {
      async handler() {
        try {
          this.tableLoader = true;
          this.setFilters(this.options);
          await this.getTeachers({
            id: this.currentFaculty.id,
            page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
            limit:
              this.options.itemsPerPage == -1
                ? null
                : this.options.itemsPerPage,
          });
          this.tableLoader = false;
        } catch (e) {
          console.log(e);
          this.$store.dispatch("errorHandler", e);

          this.tableLoader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      teachers: (state) => state.teachers.teachers,
      pageChanged: (state) => state.base.pageChanged,
      appId: (state) => state.auth.appId,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getTeachers"]),

    async onTeacherView(teacher) {
      this.$router.push(
        `${this.$route.path}/${teacher.personId}/viewteacher/proswpika_stoixeia`
      );
    },
  },
};
</script>

<style scoped lang="scss">
</style>