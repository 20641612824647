<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία πτυχιακής
      <v-btn depressed icon color="white" class="ml-auto" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col>
            <v-select dense outlined disabled v-model="masterSchoolId" hide-details="auto" :items="listFaculty"
              item-text="description" item-value="cd" no-data-text="Δεδομένα μη διαθέσιμα">
              <template v-slot:label>
                {{ appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης" }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select dense outlined disabled v-model="schoolId" hide-details="auto" :items="genericFilter.listSchool"
              item-text="description" item-value="cd" no-data-text="Δεδομένα μη διαθέσιμα">
              <template v-slot:label>
                {{ appId == 1 ? "Σχολή" : getFieldName() }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select dense outlined disabled v-model="yearId" hide-details="auto"
              :items="genericFilter.listAcademicYear" item-text="description" item-value="cd"
              no-data-text="Δεδομένα μη διαθέσιμα">
              <template v-slot:label>
                {{ appId == 1 ? "Ακαδημαϊκό έτος" : "Εκπαιδευτικό έτος" }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense outlined v-model="examDateFormatted" hide-details="auto" class="mb-4"
                  @blur="examDate = parseDate(examDateFormatted, 'examDate')" :error-messages="examDateErrors">
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Ημερομηνία έναρξης
                  </template>
                </v-text-field>
              </template>
              <v-date-picker locale="el" v-model="examDate" scrollable @input="menu = false">
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete dense outlined v-model="personId" label="Επιλογή σπουδαστή" :items="students"
              :item-text="(item) => item.description" item-value="cd" :loading="autocompleteLoader"
              :error-messages="userErrorMessages">
              <template v-slot:label>
                <span class="error--text">*</span>
                Επιλογή σπουδαστή
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-select v-model="examinerPerson1Id" class="mb-4" :error-messages="examinerPerson1IdErrors"
          :items="examinerList" hide-details="auto" clearable item-text="fullName" item-value="id" dense outlined
          no-data-text="Δεδομένα μη διαθέσιμα">
          <template v-slot:label>
            <span class="error--text">*</span>
            Επιβλέπων καθηγητής 1
          </template>
        </v-select>

        <v-select v-model="examinerPerson2Id" class="mb-4" label="Επιβλέπων καθηγητής 2" :items="examinerList"
          hide-details="auto" item-text="fullName" clearable :disabled="isDisabled(2)" item-value="id" dense outlined
          no-data-text="Δεδομένα μη διαθέσιμα">
        </v-select>

        <v-select v-model="examinerPerson3Id" class="mb-4" label=" Εξεταστής 1" hide-details="auto"
          :items="examinerList" item-text="fullName" clearable item-value="id" :disabled="isDisabled(3)" dense outlined
          no-data-text="Δεδομένα μη διαθέσιμα">
        </v-select>

        <v-select v-model="examinerPerson4Id" class="mb-4" label=" Εξεταστής 2" hide-details="auto" clearable
          :items="examinerList" item-text="fullName" item-value="id" :disabled="isDisabled(4)" dense outlined
          no-data-text="Δεδομένα μη διαθέσιμα">
        </v-select>
        <v-text-field v-model="grade" dense outlined type="number" :min="0" :max="10">
          <template v-slot:label> Τελικός βαθμός </template>
        </v-text-field>
        <v-text-field v-model="decisionNum" dense outlined>
          <template v-slot:label>
            Αριθμός της Απόφασης Εκπαιδευτικού Συμβουλίου
          </template>
        </v-text-field>
        <!-- <v-select
          dense
          outlined
          v-model="grade"
          :items="getDataStaticList(153)"
          item-text="description"
          hide-details="auto"
          item-value="cd"
          type="text"
          class="mb-4"
          :error-messages="gradeErrors"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Τελικός χαρακτηρισμός εργασίας
          </template>
        </v-select> -->

        <v-textarea dense outlined v-model="subject" :error-messages="subjectErrors" hide-details="auto">
          <template v-slot:label>
            <span class="error--text">*</span>
            Θέμα
          </template>
        </v-textarea>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn depressed color="success" class="ml-4" type="submit" :loading="loader">Αποθήκευση</v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import Helpers from "../../helpers/helpers";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  props: ["degree"],

  async created() {
    try {
      await this.loadFaculties();
      await this.fetchStudents();
      this.schoolId = this.genericFilter.schoolId;
      this.yearId = this.genericFilter.academicYearId;
      console.log('schoolId', this.genericFilter.schoolId)
      this.getExaminerList({
        schoolId: this.genericFilter.schoolId,
        page: 0,
        enabled: true,
      });

      this.examDate = this.degree.examDate;
      this.examinerPerson1Id = this.degree.examinerPerson1.id;
      this.examinerPerson2Id = this.degree.examinerPerson2?.id;
      this.examinerPerson3Id = this.degree.examinerPerson3?.id;
      this.examinerPerson4Id = this.degree.examinerPerson4?.id;
      this.grade = this.degree?.grade;
      this.subject = this.degree.subject;
      this.decisionNum = this.degree.decisionNum;
      this.masterSchoolId = this.genericFilter.facultyId;
      this.schoolId = this.genericFilter.schoolId;
      this.personId = String(this.degree.person.id);
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      menu: false,
      loader: false,
      examDate: null,
      examDateFormatted: null,
      examinerPerson1Id: null,
      examinerPerson2Id: null,
      examinerPerson3Id: null,
      examinerPerson4Id: null,
      grade: null,
      subject: null,
      masterSchoolId: null,
      listFaculty: [],
      schoolId: null,
      yearId: null,
      students: [],
      personId: null,
      autocompleteLoader: false,
      decisionNum: null,
    };
  },

  watch: {
    examDate(val) {
      this.examDateFormatted = this.formatDate(this.examDate);
    },
  },

  validations: {
    examDate: {
      required,
    },
    examinerPerson1Id: {
      required,
    },
    subject: {
      required,
    },
    personId: {
      required,
    },
  },

  computed: {
    ...mapState({
      currentStudent: (state) => state.students.currentStudent,
      staticList: (state) => state.auth.userInfo.staticView,
      examinerList: (state) => state.degrees.examinerList,
      selectedRole: (state) => state.auth.selectedRole,
      genericFilter: (state) => state.generic_filter,
      appId: (state) => state.auth.appId,
    }),

    examDateErrors() {
      const errors = [];
      if (!this.$v.examDate.$dirty) return errors;
      if (!this.$v.examDate.required) errors.push("Υποχρεωτικό πεδίο");

      return errors;
    },

    examinerPerson1IdErrors() {
      const errors = [];
      if (!this.$v.examinerPerson1Id.$dirty) return errors;
      if (!this.$v.examinerPerson1Id.required) errors.push("Υποχρεωτικό πεδίο");

      return errors;
    },

    gradeErrors() {
      const errors = [];
      if (!this.$v.grade.$dirty) return errors;
      if (!this.$v.grade.required) errors.push("Υποχρεωτικό πεδίο");

      return errors;
    },

    subjectErrors() {
      const errors = [];
      if (!this.$v.subject.$dirty) return errors;
      if (!this.$v.subject.required) errors.push("Υποχρεωτικό πεδίο");

      return errors;
    },
    userErrorMessages() {
      const errors = [];
      console.log(this.$v);
      if (!this.$v.personId.$dirty) return errors;
      if (!this.$v.personId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },
  methods: {
    ...mapActions(["editDegree", "getExaminerList"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        let content = {
          examDate: this.examDate,
          examinerPerson1Id: this.examinerPerson1Id,
          grade: Number(this.grade),
          schoolId: this.genericFilter.schoolId,
          personId: Number(this.personId),
          subject: this.subject,
          decisionNum: this.decisionNum,
          yearId: this.yearId,
        };

        if (this.examinerPerson2Id != null)
          content.examinerPerson2Id = this.examinerPerson2Id;

        if (this.examinerPerson3Id != null)
          content.examinerPerson3Id = this.examinerPerson3Id;

        if (this.examinerPerson4Id != null)
          content.examinerPerson4Id = this.examinerPerson4Id;

        await this.editDegree({
          degreeId: this.degree.id,
          content,
        });

        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
    getFieldName() {
      return Helpers.getFieldName(1);
    },
    async fetchStudents() {
      let body = {
        type: this.appId,
        parentCd: this.genericFilter.academicYearId,
        roleId: this.selectedRole,
      };
      try {
        this.autocompleteLoader = true;
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/2308`,
          body
        );
        this.autocompleteLoader = false;
        this.students = res.data;
      } catch (e) {
        return [];
        // throw e;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "examDate") {
          this.examDate = null;
          this.examDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
    async loadFaculties() {
      let result = await this.fetchDataDynamic(2020, null);
      this.listFaculty = result.data;
    },

    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },

    onSetFaculty() {
      try {
        this.getExaminerList({
          schoolId: this.genericFilter.schoolId,
          page: 0,
          enabled: true,
          // schoolId: this.currentStudent.school.id,
        });

      } catch (e) {
        console.log(e);
      }
    },
    isDisabled(num) {
      if (num == 2) {
        if (this.examinerPerson1Id != null) {
          return false;
        }
      } else if (num == 3) {
        if (
          this.examinerPerson1Id != null /*&& this.examinerPerson2Id != null*/
        ) {
          return false;
        }
      } else if (num == 4) {
        if (
          this.examinerPerson1Id != null &&
          /*this.examinerPerson2Id != null &&*/
          this.examinerPerson3Id != null
        ) {
          return false;
        }
      }

      return true;
    },
  },
};
</script>

<style scoped lang="scss">
</style>