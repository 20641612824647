import axios from "axios";


export default {
  state: {
    token: localStorage.getItem("token") || null,
    userInfo: [],
    appId: 2,
    selectedRole: null,
    selectedSystemRole: null,
    taxis: null,
    menuId: null,
    kindId: null,
    username: null
  },

  getters: {
    isLoggedIn: (state) => !!state.token,
    getAppId: (state) => state.addId,
  },

  mutations: {
    login(state, payload) {
      state.token = payload.token;
      state.userInfo = payload;
      state.selectedRole = payload.person.selectedRole.id;
      state.selectedSystemRole = payload.person.selectedRole.systemRole;
      state.username = payload.username;
    },
    refreshToken(state, refreshToken) {
      state.token = refreshToken;
    },
    logout(state) {
      state.token = null;
      state.userInfo = null;
      state.taxis = null;
      state.username = null;
    },
    setAppId(state, payload) {
      state.appId = payload;
    },
    setSelectedRole(state, role) {
      state.selectedRole = role.roleId;
      state.selectedSystemRole = role.name;
    },
    setTaxis(state, payload) {
      state.taxis = payload.taxis;
      state.token = payload.token;
    },
    setMenu(state, payload) {
      state.menuId = payload?.menuId;
      state.kindId = payload?.kindId
    },
  },

  actions: {
    async login({ commit, dispatch }, credentials) {
      try {
        // var CryptoJS = require('crypto-js');
        //var aesEcb = require('aes-ecb');
        // console.log('key',)
        // var key = CryptoJS.enc.Base64.parse(process.env.VUE_APP_ENCRYPT_KEY).toString();
        // var encrypt = aesEcb.encrypt(keyString, input);
        // var encryptedPass =  CryptoJs.AES.encrypt(credentials.password, '!a3nk3s3nS3cr3t!', { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
        // var encryptedPass2 = CryptoJs.AES.encrypt(credentials.password, '!a3nk3s3nS3cr3t!', { mode: CryptoJS.mode.ECB,})
       
        // console.log('encryptedPass1', encryptedPass.toString())
        // console.log('encryptedPass2', encryptedPass2.toString())
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ldap/login`,
          credentials
          // { ...credentials, password: encryptedPass.toString() }
        );
        const token = res.data.token;
        localStorage.setItem("token", token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        let payload = { ...res.data, username: credentials.username }
        commit("login", payload);
      } catch (e) {
        dispatch("logout");
        throw e;
      }
    },

    async refreshToken({ commit, dispatch }) {
      try {
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/refresh`);
        const token = res.data.token;
        localStorage.setItem("token", token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        commit("refreshToken", token);
      } catch (e) {
        // dispatch('logout');
        throw e;
      }
    },

    logout({ commit }) {
      commit("logout");
      localStorage.clear();

      delete axios.defaults.headers.common["Authorization"];
    },

    onSetApp({ commit }, appId) {
      commit("setAppId", appId);
    },

    async setSelectedRole({ commit }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/change/role`,
          {
            params: {
              newRoleId: payload.roleId,
            },
          }
        );
        const token = res.data.token;
        localStorage.setItem("token", token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        commit("refreshToken", token);
        commit("setSelectedRole", payload);
      } catch (e) {
        throw e;
      }
    },

    loginWithTaxis({ commit }, payload) {
      console.log("loginwithtaxis");
      commit("login", payload.login);
      commit("setTaxis", payload.taxis);
    },
  },
};
