<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      <span v-if="type == 'submit'">{{ this.application.description }}</span>
      <span v-else>Προβολή αίτησης</span>
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4" id="form">
        <v-row v-if="type == 'submit'">
          <v-col cols="12">
            <v-card v-if="selectedSystemRole == 'SECRETARY_ROLE'" class="mb-4">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      v-if="application.categoryType.cd != 15"
                      dense
                      outlined
                      v-model="user"
                      label="Επιλογή χρήστη"
                      :items="studentList.content"
                      :item-text="
                        (item) => item.firstName + ' ' + item.lastName
                      "
                      item-value="personId"
                      :loading="loadingStudents"
                      :error-messages="userErrorMessages"
                      @change="onSelectStudent"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                      v-if="application.categoryType.cd == 15"
                      dense
                      outlined
                      v-model="user"
                      label="Επιλογή χρήστη"
                      :items="teacherList"
                      :item-text="(item) => item.fullName"
                      item-value="id"
                      :loading="autocompleteLoader"
                      :error-messages="userErrorMessages"
                      @change="onSelectStudent"
                    >
                    </v-autocomplete>

                    <!-- <v-select
                      dense
                      outlined
                      v-model="schoolId"
                      label="Σχολή"
                      :items="schools.content"
                      item-text="description"
                      item-value="personId"
                      @change="handler"
                    >
                    </v-select>

                    <v-select
                      dense
                      outlined
                      v-model="yearId"
                      label="Εκπαιδευτικό έτος"
                      :items="academicYears.content"
                      item-text="description"
                      item-value="personId"
                      @change="handler"
                    >
                    </v-select>

                    <v-select
                      dense
                      outlined
                      v-model="semesterId"
                      label="Διδακτική περίοδος"
                      :items="semesters.content"
                      item-text="description"
                      item-value="personId"
                      @change="handler"
                    >
                    </v-select>

                    <v-select
                      dense
                      outlined
                      v-model="lessonId"
                      label="Μάθημα"
                      :items="courses.content"
                      item-text="description"
                      item-value="personId"
                      @change="handler"
                    >
                    </v-select> -->
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-card>
          <v-card-text>
            <v-select
              dense
              outlined
              v-model="masterSchoolId"
              label="ΠΡΟΣ:"
              :items="this.faculties"
              disabled
              item-text="description"
              item-value="cd"
              @change="onChangeDataList(event, 0)"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
            </v-select>

            <v-row v-for="(row, i) in totalRows" :key="i" align="center">
              <v-col
                v-for="(input, j) in filteredApplications[i]"
                :key="j"
                :order="input.positionAa"
                :cols="input.fieldSize"
              >
                <span v-if="input.type == '0'" class="body-1">
                  <strong>{{ input.label }}</strong>
                </span>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="content[i][j].value"
                      dense
                      outlined
                      v-if="input.type == '1'"
                      :label="input.label"
                      type="text"
                      v-bind="attrs"
                      :disabled="
                        content[i][j].isDisabled && userInfo.id != 1
                          ? true
                          : false
                      "
                      hide-details="auto"
                      v-on="on"
                      :error-messages="errorMessages(input, i, j)"
                    >
                      <template v-slot:label>
                        <span
                          class="error--text"
                          v-if="input.required.cd == '1'"
                          >*</span
                        >
                        {{ input.label }}
                      </template>
                    </v-text-field>
                  </template>
                  <span>{{ input.label }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="content[i][j].value"
                      dense
                      outlined
                      v-if="input.type == '2'"
                      :disabled="
                        content[i][j].isDisabled && userInfo.id != 1
                          ? true
                          : false
                      "
                      :label="input.label"
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span
                          class="error--text"
                          v-if="input.required.cd == '1'"
                          >*</span
                        >
                        {{ input.label }}
                      </template>
                    </v-text-field>
                  </template>
                  <span>{{ input.label }}</span>
                </v-tooltip>

                <v-menu
                  ref="menu"
                  v-model="content[i][j].menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-x
                  left
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-if="input.type == '3'"
                      dense
                      outlined
                      v-model="content[i][j].value"
                      :label="input.label"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errorMessages(input, i, j)"
                    >
                      <template v-slot:label>
                        <span
                          class="error--text"
                          v-if="input.required.cd == '1'"
                          >*</span
                        >
                        {{ input.label }}
                      </template>
                    </v-text-field>
                  </template>

                  <v-date-picker
                    locale="el"
                    v-model="content[i][j].value"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      text
                      color="primary"
                      @click="
                        content[i][j].menu = false;
                        content[i][j].value = null;
                      "
                    >
                      Ακύρωση
                    </v-btn>
                    <v-btn
                      dense
                      text
                      color="primary"
                      @click="content[i][j].menu = false"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>

                <v-checkbox
                  v-model="content[i][j].value"
                  :label="input.label"
                  v-if="input.type == '4'"
                  :disabled="
                    content[i][j].isDisabled && userInfo.id != 1 ? true : false
                  "
                  :error-messages="errorMessages(input, i, j)"
                >
                </v-checkbox>

                <v-tooltip top v-if="input.type == '5'">
                  <template v-slot:activator="{ on, attrs }">
                    <div>
                      <v-file-input
                        v-model="content[i][j].value"
                        dense
                        outlined
                        :label="input.label"
                        v-if="!content[i][j].isDisabled"
                        prepend-inner-icon="mdi-paperclip"
                        :prepend-icon="null"
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errorMessages(input, i, j)"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <span
                            class="error--text"
                            v-if="input.required.cd == '1'"
                            >*</span
                          >
                          {{ input.label }}
                        </template>
                      </v-file-input>
                      <div v-else class="d-flex align-center">
                        <span
                          style="font-size: initial"
                          class="mr-2 text-no-wrap"
                        >
                          {{ input.label }}:
                        </span>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            () => downloadAttachment(content[i][j].value.id)
                          "
                        >
                          <v-icon>mdi-paperclip</v-icon>
                          {{ content[i][j].value.name }}
                        </v-btn>
                      </div>
                    </div>
                  </template>
                </v-tooltip>

                <v-select
                  v-model="content[i][j].value"
                  :label="input.label"
                  v-if="input.type == '6'"
                  dense
                  item-text="description"
                  item-value="cd"
                  :disabled="
                    content[i][j].isDisabled && userInfo.id != 1 ? true : false
                  "
                  hide-details="auto"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                  :items="fetchDataList(input.lovId)"
                  @change="onChangeDataList($event, input)"
                  outlined
                  :error-messages="errorMessages(input, i, j)"
                >
                  <template v-slot:label>
                    <span class="error--text" v-if="input.required.cd == '1'"
                      >*</span
                    >
                    {{ input.label }}
                  </template>
                </v-select>

                <v-textarea
                  v-if="input.type == '7'"
                  dense
                  v-model="content[i][j].value"
                  outlined
                  :disabled="
                    content[i][j].isDisabled && userInfo.id != 1 ? true : false
                  "
                  :error-messages="errorMessages(input, i, j)"
                  :label="input.label"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <div class="d-flex flex-grow-1 align-center justify-center mt-8">
          <v-btn depressed color="error" class="mr-2" @click="$emit('close')">
            Ακύρωση
          </v-btn>
          <v-btn
            v-if="type == 'submit'"
            depressed
            color="success"
            :loading="loader"
            @click="onSubmit"
          >
            Υποβολή
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
    <Loader v-if="this.showLoader" />
  </v-card>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";
import axios from "axios";
import Loader from "../Loader/Loader.vue";

export default {
  props: [
    "application",
    "type",
    "onlineApplicationId",
    "faculties",
    "masterSchool",
    "schoolId",
  ],
  components: {
    Loader,
  },
  async created() {
    try {
      this.masterSchoolId = this.masterSchool;
      if (this.masterSchoolId != null) {
        this.onChangeDataList(this.masterSchoolId, { id: 0 });
      }
      for (let i = 1; i <= this.totalRows; i++) {
        this.filteredApplications[i - 1] =
          this.application.commonOnlineApplicationSetupList.filter(
            (el) => el.lineAa == i
          );
      }

      for (let i = 0; i < this.filteredApplications.length; i++) {
        this.content.push([]);
        for (let j = 0; j < this.filteredApplications[i].length; j++) {
          this.content[i].push({
            value: this.filteredApplications[i][j].type == "4" ? false : null, //assign false if input is checkbox
            label: this.filteredApplications[i][j].label, //TODO INPUT FIELDNAME
            menu: this.filteredApplications[i][j].type == "3" ? false : null, //assign value only if input is date
            fieldName: this.filteredApplications[i][j].fieldName, //assign value only if input is date
            commonOnlineApplicationSetupId: this.filteredApplications[i][j].id,
            type: this.filteredApplications[i][j].type,
            defaultValue:
              this.$route.path == "/configure_applications"
                ? null
                : this.filteredApplications[i][j].defaultValue,
            required: this.filteredApplications[i][j].required
              ? this.filteredApplications[i][j].required.cd == "1"
                ? true
                : false
              : null,
            isDisabled: false,
          });
        }
      }
      if (this.type == "submit") this.autocompleteLoader = true;
      this.application.commonOnlineApplicationSetupList.forEach((element) => {
        if (Object.prototype.hasOwnProperty.call(element, "lovId")) {
          if (!Object.prototype.hasOwnProperty.call(element, "parentRefId")) {
            this.fetchDataDynamic(element.lovId, null);
          }
        }
      });

      //check person
      if (
        this.selectedSystemRole != "LOGGED_IN_USER_ROLE" &&
        this.selectedSystemRole != "SECRETARY_ROLE"
      ) {
        await this.getUserDetails(this.userInfo.id);
        this.user = this.userInfo.id;
        this.setDefaultValues();
      }

      if (this.selectedSystemRole == "LOGGED_IN_USER_ROLE") {
        if (this.auth.taxis != undefined && this.auth.taxis != "") {
          this.personInfo = this.auth.taxis;
        } else if (![null, undefined].includes(this.auth.userInfo)) {
          if (this.auth.userInfo.person.id != 1)
            await this.getUserDetails(this.auth.userInfo.person.id);
          else this.personInfo = { username: this.auth.username };
        }
        this.setDefaultValues();
      }

      // await this.getFaculties({
      //   page: 0,
      // }),
      //   (this.faculty = this.faculties.content[0].id);

      // await this.getSchools({
      //   id: this.faculty,
      //   page: 0,
      // }),
      //   await this.getAcademicYears({
      //     id: this.faculty,
      //     page: 0,
      //   }),
      //   await this.getSemesters({
      //     id: this.faculty,
      //     page: 0,
      //   }),
      //   await this.getCourses({
      //     id: this.faculty,
      //     page: 0,
      //   }),
      if (this.selectedSystemRole == "SECRETARY_ROLE") {
        // await this.getStudents({
        //   page: 0,
        //   enabled: true,
        //   id: this.masterSchool,
        // });
        this.fetchStudents();
      }
      if (this.selectedSystemRole == "LOGGED_IN_USER_ROLE") {
        this.user = this.userInfo.id;
      }

      this.autocompleteLoader = false;
    } catch (e) {
      console.log(e);
      this.autocompleteLoader = false;
    }
    // console.log(this.filteredApplications);
    if (this.application.categoryType.cd == 15) {
      await this.fetchTeachers();
    }
  },

  data() {
    return {
      menu: {},
      loader: false,
      showLoader: false,
      autocompleteLoader: false,
      filteredApplications: [],
      content: [],
      teacherList: [],
      body: [],
      user: null,
      personInfo: null,
      masterSchoolId: null,
      // schoolId: null,
      yearId: null,
      semesterId: null,
      lessonId: null,
      dataList: [],
      studentList: [],
      loadingStudents: false,
    };
  },

  validations() {
    if (this.selectedSystemRole == "SECRETARY_ROLE") {
      return {
        user: {
          required,
        },
        content: {
          $each: {
            $each: {
              value: {
                required: requiredIf((el) => {
                  return el.required;
                }),
              },
            },
          },
        },
      };
    } else {
      return {
        content: {
          $each: {
            $each: {
              value: {
                required: requiredIf((el) => {
                  return el.required;
                }),
              },
            },
          },
        },
      };
    }
  },

  computed: {
    ...mapState({
      students: (state) => state.students.students,
      // faculties: (state) => state.faculties.faculties,
      schools: (state) => state.schools.schools,
      academicYears: (state) => state.academic_years.academicYears,
      semesters: (state) => state.semesters.semesters,
      courses: (state) => state.courses.courses,
      userProfile: (state) => state.users.userProfile,
      userInfo: (state) => state.auth.userInfo.person,
      selectedRole: (state) => state.auth.selectedRole,
      selectedSystemRole: (state) => state.auth.selectedSystemRole,
      appId: (state) => state.auth.appId,
      auth: (state) => state.auth,
    }),
    totalRows: function () {
      return Math.max.apply(
        Math,
        this.application.commonOnlineApplicationSetupList.map(function (o) {
          return o.lineAa;
        })
      );
    },
    userErrorMessages() {
      const errors = [];
      console.log(this.$v);
      if (!this.$v.user.$dirty) return errors;
      if (!this.$v.user.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions([
      "getStudents",
      "submitApplication",
      "getFaculties",
      "getSchools",
      "getAcademicYears",
      "getSemesters",
      "getCourses",
    ]),

    async fetchTeachers() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/teacher/list/fullname`,
          {
            params: {
              masterSchoolId: this.faculty,
            },
          }
        );
        this.teacherList = res.data;
        console.log(res.data);
      } catch (e) {
        console.log(e);
      }
    },

    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid && this.selectedSystemRole == "SECRETARY_ROLE") {
          document
            .getElementById("form")
            .scrollIntoView({ behavior: "smooth" });
          return;
        } else if (this.$v.$invalid) {
          document
            .getElementById("form")
            .scrollIntoView({ behavior: "smooth" });
          return;
        }

        this.loader = true;
        this.body = [];
        for (let i = 0; i < this.content.length; i++) {
          for (let j = 0; j < this.content[i].length; j++) {
            if (this.content[i][j].type != 0) {
              if (this.content[i][j].type == "5") {
                if (this.content[i][j].defaultValue != undefined) {
                  if (this.content[i][j].value != null) {
                    if (this.content[i][j].value.id != undefined) {
                      this.body.push({
                        attachedFileId: this.content[i][j].value.id,
                        valueDataType: this.content[i][j].type,
                        commonOnlineApplicationSetupId:
                          this.content[i][j].commonOnlineApplicationSetupId,
                      });
                    } else {
                      this.body.push({
                        attachedFile: this.content[i][j].value,
                        valueDataType: this.content[i][j].type,
                        commonOnlineApplicationSetupId:
                          this.content[i][j].commonOnlineApplicationSetupId,
                      });
                    }
                  }
                } else {
                  this.body.push({
                    attachedFile: this.content[i][j].value,
                    valueDataType: this.content[i][j].type,
                    commonOnlineApplicationSetupId:
                      this.content[i][j].commonOnlineApplicationSetupId,
                  });
                }
              } else {
                this.body.push({
                  value: this.content[i][j].value,
                  valueDataType: this.content[i][j].type,
                  commonOnlineApplicationSetupId:
                    this.content[i][j].commonOnlineApplicationSetupId,
                });
              }
            }
          }
        }

        let content = {
          onlineApplicationId: this.onlineApplicationId,
          details: this.body,
          personId: this.user,
          replyAttachFile: "",
          masterSchoolId: this.masterSchoolId,
        };

        // formData.append("details", this.body);

        if (this.selectedSystemRole == "SECRETARY_ROLE")
          content.personId == this.user;
        else content.personId = this.userInfo.id;

        let formData = new FormData();
        formData.append("onlineApplicationId", this.onlineApplicationId);
        formData.append(
          "personId",
          this.selectedSystemRole == "SECRETARY_ROLE"
            ? this.user
            : this.userInfo.id
        );
        formData.append("masterSchoolId", this.masterSchoolId);

        this.body.forEach((b, i) => {
          if (b.valueDataType == "5") {
            formData.append(
              `details[${i}][attachedFileId]`,
              b.attachedFileId == undefined ? null : b.attachedFileId
            );
            formData.append(`details[${i}][attachedFile]`, b.attachedFile);
            formData.append(`details[${i}][value]`, null);
            formData.append(`details[${i}][valueDataType]`, b.valueDataType);
            formData.append(
              `details[${i}][commonOnlineApplicationSetupId]`,
              b.commonOnlineApplicationSetupId
            );
          } else {
            formData.append(`details[${i}][value]`, b.value);
            formData.append(`details[${i}][valueDataType]`, b.valueDataType);
            formData.append(
              `details[${i}][commonOnlineApplicationSetupId]`,
              b.commonOnlineApplicationSetupId
            );
          }
        });

        await this.submitApplication(formData);
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },

    errorMessages(item, i, j) {
      if (item.required != undefined) {
        if (item.required.cd == "1") {
          const errors = [];
          if (!this.$v.content.$each[i].$each[j].value.$dirty) return errors;
          if (!this.$v.content.$each[i].$each[j].value.required)
            errors.push("Υποχρεωτικό πεδίο");
          return errors;
        } else return;
      }
    },
    // async handler() {
    //   this.getStudents({
    //     page: 0,
    //     enabled: true,
    //     id: this.masterSchoolId,
    //   });
    // },
    async fetchStudents() {
      try {
        this.loadingStudents = true;

        let params = {
          masterSchoolId: this.masterSchoolId,
          // schoolId: this.schoolId,
          page: 0,
        };
        if (this.schoolId != undefined && this.schoolId != null) {
          params.schoolId = this.schoolId;
        }

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/student/register/list/view`,
          {
            params: params,
          }
        );
        this.studentList = res.data;

        this.loadingStudents = false;
      } catch (e) {
        console.log(e);
        this.studentList = [];
        this.loadingStudents = false;
      }
    },
    async downloadAttachment(id) {
      try {
        const res = await axios({
          method: "get",
          url: `${process.env.VUE_APP_BASE_URL}/file/${id}`,
          responseType: "arraybuffer",
        });

        let filename = res.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[0];
        let blob = new Blob([res.data], { type: res.headers.contentType });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = decodeURI(filename);
        link.click();
      } catch (e) {
        this.$store.dispatch("errorHandler", e);

        throw e;
      }
    },
    fetchDataList(groupId) {
      return this.dataList.filter((m) => {
        if (m.lovGroupId == groupId) {
          return m;
        }
      });
    },
    onChangeDataList(event, item) {
      let fields = this.application.commonOnlineApplicationSetupList.filter(
        (element) => {
          if (Object.prototype.hasOwnProperty.call(element, "parentRefId")) {
            if (element.parentRefId == item.id) {
              return element;
            }
          }
        }
      );

      fields.forEach((el) => {
        if (Object.prototype.hasOwnProperty.call(el, "lovId")) {
          this.dataList = this.dataList.filter((e) => {
            if (e.lovGroupId != el.lovId) {
              return e;
            }
          });
          this.fetchDataDynamic(el.lovId, event);
        }
      });
    },
    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      if (this.selectedSystemRole == "SECRETARY_ROLE" && this.user != null) {
        body.personId = this.user;
      }
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        res.data.forEach((m) => {
          this.dataList.push(m);
        });
      } catch (e) {
        // this.dataList = [];
        throw e;
      }
    },
    async onSelectStudent(e) {
      await this.getUserDetails(e);
      this.setDefaultValues();
    },
    setDefaultValues() {
      this.showLoader = true;
      this.content.map((c) => {
        let j = c.map((j) => {
          // console.log(j.defaultValue);
          if (j.defaultValue != undefined) {
            j.value =
              this.personInfo == null ||
              this.personInfo[j.defaultValue] == undefined
                ? null
                : this.personInfo[j.defaultValue];

            j.isDisabled =
              this.personInfo == null ||
              this.personInfo[j.defaultValue] == undefined ||
              this.personInfo[j.defaultValue] == "" ||
              this.personInfo[j.defaultValue] == null
                ? false
                : true;

            // console.log(this.personInfo[j.defaultValue]);
          }
          return j;
        });
        c = j;
        // return c;
      });
      this.showLoader = false;
    },
    async getUserDetails(personId) {
      try {
        this.showLoader = true;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/store/details/${personId}`
        );
        if (
          ![null, undefined, ""].includes(this.auth.username) &&
          this.auth.selectedSystemRole != "SECRETARY_ROLE"
        ) {
          this.personInfo = { ...res.data, username: this.auth.username };
        } else {
          this.personInfo = res.data;
        }
        this.showLoader = false;
      } catch (e) {
        this.showLoader = false;
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>