
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="4"
        :search="true"
        @onSearchAction="onSearch"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: true,
          class: false,
          lesson: false,
        }"
      />

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="progTrips.content"
            :options.sync="options"
            :server-items-length="progTrips.elements"
            :loading="tableLoader"
          >
            <template v-slot:[`item.dateOfShipping`]="{ item }">
              <span>{{
                new Date(item.dateOfShipping).toLocaleDateString("el")
              }}</span>
            </template>

            <template v-slot:[`item.dateOfDismissal`]="{ item }">
              <span v-if="item.dateOfDismissal != null">{{
                new Date(item.dateOfDismissal).toLocaleDateString("el")
              }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onProgTripView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary"
            class="mr-2"
            :disabled="!filtersSet"
            @click="openDialog(null, 'add')"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="1000px"
      scrollable
    >
      <AddProgTrip
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="1000px"
      scrollable
    >
      <EditProgTrip
        v-if="dialog.open && dialog.type == 'edit'"
        :progTrip="dialog.progTrip"
        @handler="handler"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteProgTrip
        v-if="dialog.open && dialog.type == 'delete'"
        :progTrip="dialog.progTrip"
        @close="closeDialog"
        @handler="handler"
        :progTrips="progTrips"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import AddProgTrip from "../../components/ProgTripsModals/AddProgTrip.vue";
import EditProgTrip from "../../components/ProgTripsModals/EditProgTrip.vue";
import DeleteProgTrip from "../../components/ProgTripsModals/DeleteProgTrip.vue";
import axios from "axios";
export default {
  components: {
    AddProgTrip,
    EditProgTrip,
    DeleteProgTrip,
    GenericFilter,
  },

  async created() {
    try {
      if (this.semesterId != null) await this.handler();
    } catch (e) {
      throw e;
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        from.path !=
        "/prog_trips/" + from.params?.prog_trip_id + "/details"
      ) {
        vm.clearGeneric();
      }
    });
  },
  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      filtersSet: false,
      progTrips: [],
      headers: [
        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },
        {
          text: "ΜΕΘ",
          value: "meth",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },

        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },

        {
          text: "Ημερομηνία έναρξης",
          value: "dateOfShipping",
          sortable: false,
        },

        {
          text: "Ημερομηνία απόλυσης",
          value: "dateOfDismissal",
          sortable: false,
        },
        {
          text: "Βαθμός",
          value: "grade",
          sortable: false,
        },
        {
          text: "Επίδοση",
          value: "gradeCharacterised",
          sortable: false,
        },
        {
          text: "Αποτέλεσμα",
          value: "educationalTripAssessment.description",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      dialog: {
        progTrip: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },
  },

  computed: {
    ...mapState({
      pageChanged: (state) => state.base.pageChanged,

      facultyId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      academicYearId: (state) => state.generic_filter.academicYearId,
      semesterId: (state) => state.generic_filter.semesterId,

      searchText: (state) => state.generic_filter.searchText,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage", "setCurrentProgTrip"]),
    clearGeneric() {
      this.$store.dispatch("setAllFilter", {
        facultyId: null,
        schoolId: null,
        academicYearId: null,
        semesterId: null,
        classId: null,
        courseId: null,
        dates: null,
        date: null,
        active: null,
        searchText: null,
        examYearId: null,
        progExamPeriodId: null,
        examClassId: null,
        examLessonId: null,
        examDate: null,
      });
    },
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/prog/trip/list`,
          {
            params: {
              masterSchoolId: this.facultyId,
              schoolId: this.schoolId,
              yearId: this.academicYearId,
              progTrainPeriodId: this.semesterId,
              searchText: this.searchText != "" ? this.searchText : null,

              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
            },
          }
        );
        this.progTrips = res.data;
        if (!this.filtersSet) this.filtersSet = true;

        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.progTrips = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    onProgTripView(progTrip) {
      this.setCurrentProgTrip(progTrip);
      this.$router.push(`prog_trips/${progTrip.id}/details`);
    },

    openDialog(progTrip, type) {
      this.dialog = {
        progTrip,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        progTrip: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>