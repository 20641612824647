import axios from 'axios'
export default {
    state: {
        shipCompanies: [],
        currentShipCompany: null,
        shipVessels: [],
        shipCompanyReps: []
    },

    mutations: {
        getShipCompanies(state, shipCompanies) {
            state.shipCompanies = shipCompanies
        },

        getShipVessels(state, shipVessels) {
            state.shipVessels = shipVessels
        },

        getShipCompanyReps(state, shipCompanyReps) {
            state.shipCompanyReps = shipCompanyReps
        },

        setCurrentShipCompany(state, currentShipCompany) {

            state.currentShipCompany = currentShipCompany
        }
    },

    actions: {
        async getShipCompanies({ commit, dispatch }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/ship/company/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                    }
                })
                commit('getShipCompanies', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async addShipCompany({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/ship/company`, payload)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία πλοιοκτήτριας εταιρίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async editShipCompany({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/ship/company/${payload.shipCompanyId}`, payload.content)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία πλοιοκτήτριας εταιρίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async deleteShipCompany({ commit, dispatch, rootState, state }, shipCompanyId) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/ship/company/${shipCompanyId}`)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή πλοιοκτήτριας εταιρίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })


            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },



        async getShipVessels({ commit, dispatch }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/ship/company/vessel/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                        shipCompanyId: payload.shipCompanyId
                    }
                })
                commit('getShipVessels', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async addShipCompanyVessel({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/ship/company/vessel`, payload)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία πλοίου εταιρίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);


                throw e;
            }
        },

        async editShipCompanyVessel({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/ship/company/vessel/${payload.shipCompanyVesselId}`, payload.content)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία πλοίου εταιρίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async deleteShipCompanyVessel({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/ship/company/vessel/${payload.shipCompanyVesselId}`)

                await dispatch('getShipVessels', {
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.shipCompanies.elements : rootState.base.filters.itemsPerPage,
                    shipCompanyId: payload.shipCompanyId

                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία πλοίου εταιρίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })


            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },



        async getShipCompanyReps({ commit, dispatch }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/ship/company/representative/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                        shipCompanyId: payload.shipCompanyId
                    }
                })
                commit('getShipCompanyReps', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async addShipCompanyRep({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/ship/company/representative`, payload)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία εκπροσώπου / εκπαιδευτή εταιρίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async editShipCompanyRep({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/ship/company/representative/${payload.shipCompanyRepId}`, payload.content)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία εκπροσώπου / εκπαιδευτή εταιρίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async deleteShipCompanyRep({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/ship/company/representative/${payload.shipCompanyRepId}`)

                await dispatch('getShipCompanyReps', {
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.shipCompanies.elements : rootState.base.filters.itemsPerPage,
                    shipCompanyId: payload.shipCompanyId

                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή εκπροσώπου / εκπαιδευτή εταιρίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }


            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },
    }
}