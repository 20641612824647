import axios from 'axios'
export default {
    state: {
        students: [],
        currentStudent: null
    },

    mutations: {
        getStudents(state, students) {
            state.students = students
        },

        setCurrentStudent(state, currentStudent) {
            state.currentStudent = currentStudent

        }
    },

    actions: {
        async getStudents({ commit, dispatch }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/student/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                        masterSchoolId: payload.id,
                        schoolId: payload.schoolId,
                        enabled: payload.enable,
                        progTrainPrdId: payload.progTrainPrdId,
                        classId: payload.classId,
                        searchText: payload.searchText
                    }
                })
                commit('getStudents', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },




    }

}