
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-card class="mb-4 pa-4">
        <v-card-text class="d-flex flex-column">
          <v-row>
            <v-col>
              <v-select
                dense
                outlined
                v-model="schoolTypeId"
                type="text"
                clearable
                class="mb-4"
                :items="schoolTypesList"
                @change="fetchSchools"
                item-text="description"
                :error-messages="schoolTypeIdErrors"
                item-value="cd"
                no-data-text="Δεδομένα μη διαθέσιμα"
              >
                <template v-slot:label>
                  <span class="error--text">*</span>
                  {{
                    appId == 1
                      ? "Τύπος Ακαδημίας"
                      : "Τύπος Δομής Μετεκπαίδευσης"
                  }}
                </template>
              </v-select>
            </v-col>
            <v-col>
              <v-select
                dense
                outlined
                v-model="schoolId"
                no-data-text="Δεδομένα μη διαθέσιμα"
                clearable
                type="text"
                class="mb-4"
                :items="schoolsList"
                @change="onSetSchool"
                item-text="description"
                :error-messages="schoolIdErrors"
                item-value="id"
              >
                <template v-slot:label>
                  <span class="error--text">*</span>
                  {{ appId == 1 ? "Σχολές" : getFieldName() }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-btn
            class="ml-auto"
            depressed
            color="primary"
            @click="this.fetchSemesters"
          >
            Αναζήτηση
          </v-btn>
        </v-card-text>
      </v-card>
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="semesters.content"
            :server-items-length="semesters.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.exams`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onExamsView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.courses`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onCoursesView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.semesters`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onSemestersView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            class="ml-auto"
            color="primary ml-auto"
            @click="openDialog(null, 'add')"
            :disabled="disableButton"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      scrollable
    >
      <AddSemester
        v-if="dialog.open && dialog.type == 'add'"
        :schoolId="schoolId"
        :protSchoolDetails="schoolDetails"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      scrollable
    >
      <EditSemester
        v-if="dialog.open && dialog.type == 'edit'"
        :schoolId="schoolId"
        :semester="dialog.semester"
        :protSchoolDetails="schoolDetails"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteSemester
        v-if="dialog.open && dialog.type == 'delete'"
        :semester="dialog.semester"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import AddSemester from "../../../components/SemesterModals/AddSemester.vue";
import EditSemester from "../../../components/SemesterModals/EditSemester.vue";
import DeleteSemester from "../../../components/SemesterModals/DeleteSemester.vue";
import Helpers from "../../../helpers/helpers";

export default {
  components: {
    AddSemester,
    EditSemester,
    DeleteSemester,
  },
  async created() {
    await this.fetchSchoolTypes();
    if (
      this.semesterFilter.schoolType != null &&
      this.semesterFilter.schoolId != null
    ) {
      this.schoolTypeId = this.semesterFilter.schoolType;
      await this.fetchSchools();
      this.schoolId = this.semesterFilter.schoolId;
      await this.fetchSemesters();
    }
  },
  data() {
    return {
      tableLoader: false,
      search: "",
      semesters: [],
      schoolTypesList: [],
      schoolsList: [],
      schoolTypeId: null,
      schoolId: null,
      schoolDetails: null,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Κωδικός",
          value: "cd",
          sortable: false,
        },
        {
          text: "Περιγραφή",
          value: "description",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
        {
          text: "Εξεταστικές περίοδοι",
          value: "exams",
          sortable: false,
        },
        {
          text: "Μαθήματα",
          value: "courses",
          sortable: false,
        },
      ],

      dialog: {
        semester: null,
        open: false,
        type: null,
      },
    };
  },
  watch: {
    options: {
      async handler() {
        try {
          if (this.schoolId != null) {
            this.tableLoader = true;
            this.setFilters(this.options);
            this.fetchSemesters();
            this.tableLoader = false;
          }
        } catch (e) {
          console.log(e);
          this.tableLoader = false;
        }
      },
    },
    deep: true,
    $route() {
      this.fetchSchoolTypes();
    },
  },
  validations() {
    return {
      schoolTypeId: {
        required,
      },
      schoolId: {
        required,
      },
    };
  },
  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      //   semesters: (state) => state.semesters.semesters,
      pageChanged: (state) => state.base.pageChanged,
      semesterFilter: (state) => state.semesters,
      appId: (state) => state.auth.appId,
    }),
    schoolTypeIdErrors() {
      const errors = [];
      if (!this.$v.schoolTypeId.$dirty) return errors;
      if (!this.$v.schoolTypeId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    schoolIdErrors() {
      const errors = [];
      if (!this.$v.schoolId.$dirty) return errors;
      if (!this.$v.schoolId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    disableButton() {
      if (this.schoolTypeId == null || this.schoolId == null) {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapMutations(["setCurrentSemester", "setFilters", "changePage"]),
    ...mapActions(["getSemesters"]),

    onExamsView(semester) {
      // this.setCurrentSemester(semester);
      this.$router.push({
        path: "/admin/exams",
        query: {
          semesterId: semester.id,
          schoolId: this.schoolId,
          semesterDescription: semester.description,
        },
      });
    },
    getFieldName() {
      return Helpers.getFieldName(2);
    },
    onCoursesView(semester) {
      this.$router.push({
        path: "/admin/courses",
        query: {
          semesterId: semester.id,
          schoolId: this.schoolId,
          semesterDescription: semester.description,
        },
      });
    },
    openDialog(semester, type) {
      this.dialog = {
        semester,
        open: true,
        type,
      };
    },
    closeDialog() {
      this.dialog = {
        semester: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.fetchSemesters();
    },
    async fetchSchoolTypes() {
      let body = {
        type: 1,
        parentCd: null,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/104`,
          body
        );
        this.schoolTypesList = res.data;
        if (res.data.length == 1) {
          this.schoolTypeId = res.data[0].cd;
          this.fetchSchools();
        }
      } catch (e) {
        throw e;
      }
    },
    async fetchSchools() {
      try {
        if (this.schoolTypeId != null) {
          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/school/list/prototypes`,
            {
              params: {
                page: 0,
                schoolTypeId: this.schoolTypeId,
              },
            }
          );
          this.schoolsList = res.data.content;
          if (res.data.content.length == 1) {
            this.schoolId = res.data.content[0].id;
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async fetchSemesters() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.$store.dispatch("setFilterSemester", {
          schoolTypeId: this.schoolTypeId,
          schoolId: this.schoolId,
        });

        this.tableLoader = true;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/train/period/list/prototypes`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              schoolId: this.schoolId,
            },
          }
        );
        this.semesters = res.data;
        this.tableLoader = false;
      } catch (e) {
        throw e;
      }
    },
    async onSetSchool() {
      try {
        if (this.schoolId != null) {
          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/school/${this.schoolId}`
          );
          this.schoolDetails = res.data;
        }
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>