import axios from 'axios'
export default {
    state: {
        lendingBooks: []
    },

    mutations: {
        getLendingBooks(state, lendingBooks) {
            state.lendingBooks = lendingBooks
        }
    },

    actions: {
        async getLendingBooks({ commit, dispatch }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/library/book/lend/list`, {
                    params: {
                        limit: payload.limit,
                        page: payload.page,
                        libraryBookId: payload.libraryBookId,

                    }
                })

                commit('getLendingBooks', res.data)
            } catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async addLendingBook({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/library/book/lend`, payload)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η χρέωση βιβλίου ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async editLendingBook({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/library/book/lend/${payload.libraryBookLendingId}`, payload.content)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία χρέωσης βιβλίου ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async deleteLendingBook({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/library/book/lend/${payload.libraryBookLendingId}`)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή χρέωσης βιβλίου ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },
    }
}