<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία διδαχθείσας ύλης
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-2">
        <v-row>
          <v-col>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="actionDateFormatted"
                  :error-messages="actionDateErrors"
                  label="Ημερομηνία"
                  prepend-inner-icon="mdi-calendar"
                  class="mb-4"
                  @blur="
                    actionDate = parseDate(actionDateFormatted, 'actionDate')
                  "
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Ημερομηνία
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="actionDate"

                scrollable
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row v-if="selectedSystemRole != 'TEACHER_ROLE'">
          <v-col>
            <v-select
              v-model="teacherId"
              class="mb-4"
              :error-messages="teacherIdErrors"
              hide-details="auto"
              :items="teacherList"
              item-text="teacherName"
              item-value="id"
              dense
              outlined
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                <span class="error--text">*</span> Εκπαιδευτικός
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              dense
              outlined
              v-model="classId"
              :items="classList"
              item-text="description"
              hide-details="auto"
              disabled
              item-value="cd"
              no-data-text="Δεδομένα μη διαθέσιμα"
              :error-messages="classErrors"
              class="mb-4"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Τμήμα
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-select
              dense
              outlined
              v-model="lessonId"
              :items="lessonList"
              item-text="description"
              hide-details="auto"
              disabled
              no-data-text="Δεδομένα μη διαθέσιμα"
              item-value="cd"
              :error-messages="lessonErrors"
              class="mb-4"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Μάθημα
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="startTime"
              class="mb-4"
              :error-messages="startTimeErrors"
              hide-details="auto"
              :items="getDataStaticList(225)"
              item-text="description"
              item-value="cd"
              dense
              outlined
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                <span class="error--text">*</span> Ώρα
              </template>
            </v-select>
          </v-col>
          <!-- <v-col>
            <v-select
              v-model="endTime"
              class="mb-4"
              :error-messages="endTimeErrors"
              hide-details="auto"
              :items="getDataStaticList(225)"
              item-text="description"
              item-value="cd"
              dense
              outlined
            >
              <template v-slot:label>
                <span class="error--text">*</span> Ώρα λήξης
              </template>
            </v-select> -->
          <!-- <v-text-field
              dense
              outlined
              v-model="endTime"
              label="Ώρα λήξης"
              type="text"
              hide-details="auto"
              class="mb-4"
              :error-messages="endTimeErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Ώρα λήξης
              </template>
            </v-text-field> -->
          <!-- </v-col> -->
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              dense
              outlined
              rows="3"
              v-model="memo"
              label="Σχόλιο"
              :error-messages="descriptionErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Θέμα
              </template></v-textarea
            >
          </v-col>
        </v-row>
        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import axios from "axios";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  async created() {
    await this.getClass();
    await this.getLessons();
    if (this.selectedSystemRole != "TEACHER_ROLE") await this.getTeachers();
    this.classId = this.genericFilter.classId;
    this.lessonId = this.genericFilter.courseId;

    // this.getSystemRoles();
  },
  data() {
    return {
      loader: false,
      actionDate: null,
      actionDateFormatted: null,
      teacherList: [],
      menu: null,
      classId: null,
      teacherId: null,
      classList: [],
      lessonId: null,
      lessonList: [],
      memo: null,
      startTime: null,
      // endTime: null,
    };
  },
  watch: {
    actionDate() {
      this.actionDateFormatted = this.formatDate(this.actionDate);
    },
    // startTime: function () {
    //   if (this.startTime.length == 2) {
    //     this.startTime = this.startTime + ":";
    //   }
    // },
    // endTime: function () {
    //   if (this.endTime.length == 2) {
    //     this.endTime = this.endTime + ":";
    //   }
    // },
  },
  validations() {
    const self = this;
    return {
      actionDate: {
        required,
      },
      classId: {
        required,
      },
      lessonId: {
        required,
      },
      startTime: {
        required,
      },
      teacherId: {
        required: requiredIf((e) => {
          self.selectedSystemRole != "TEACHER_ROLE";
        }),
      },
      // endTime: {
      //   required,
      // },
      memo: {
        required,
      },
    };
  },

  computed: {
    ...mapState({
      privilegesList: (state) => state.roles.privileges,
      selectedRole: (state) => state.auth.selectedRole,
      selectedSystemRole: (state) => state.auth.selectedSystemRole,
      appId: (state) => state.auth.appId,
      genericFilter: (state) => state.generic_filter,
      staticList: (state) => state.auth.userInfo.staticView,
      classIdGen: (state) => state.generic_filter.classId,
      person: (state) => state.auth.userInfo.person.id,
    }),
    actionDateErrors() {
      const errors = [];
      if (!this.$v.actionDate.$dirty) return errors;
      if (!this.$v.actionDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    classErrors() {
      const errors = [];
      if (!this.$v.classId.$dirty) return errors;
      if (!this.$v.classId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    teacherIdErrors() {
      const errors = [];
      if (!this.$v.teacherId.$dirty) return errors;
      if (!this.$v.teacherId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    lessonErrors() {
      const errors = [];
      if (!this.$v.lessonId.$dirty) return errors;
      if (!this.$v.lessonId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    startTimeErrors() {
      const errors = [];
      if (!this.$v.startTime.$dirty) return errors;
      if (!this.$v.startTime.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    // endTimeErrors() {
    //   const errors = [];
    //   if (!this.$v.endTime.$dirty) return errors;
    //   if (!this.$v.endTime.required) errors.push("Υποχρεωτικό πεδίο");
    //   return errors;
    // },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.memo.$dirty) return errors;
      if (!this.$v.memo.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addRole"]),
    async getTeachers() {
      const resp = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/class/teacher/list`,
        {
          params: {
            eduClassId: this.classIdGen,
            page: 0,
          },
        }
      );
      // console.log('teacher',resp.data);
      this.teacherList = resp.data.content.map((t) => {
        return {
          teacherName: t.person.lastName + " " + t.person.firstName,
          id: t.id,
        };
      });
    },
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          actionDate: this.actionDate,
          scheduleHour: this.startTime,
          // endTime: this.endTime,
          memo: this.memo,
          classId: this.classId,
          lessonId: this.lessonId,
          personId:
            this.selectedSystemRole != "TEACHER_ROLE" ? this.teacherId : this.person,
        };
        const resp = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/archive/teaching/material`,
          content
        );
        this.$store.dispatch("toggleSnackbar", {
          open: true,
          text: "Η δημιουργία ολοκληρώθηκε με επιτυχία",
          color: "success",
        });

        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.$store.dispatch("toggleSnackbar", {
          open: true,
          text: `Σφάλμα. παρακαλώ ξαναπροσπαθήστε σε λίγο`,
          color: "error",
        });
        this.loader = false;
      }
    },
    async getClass() {
      let body = {
        type: 1,
        parentCd: this.genericFilter.semesterId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/2004`,
          body
        );
        this.classList = res.data;
      } catch (e) {
        throw e;
      }
    },

    async getLessons() {
      let body = {
        type: this.appId,
        parentCd: this.genericFilter.semesterId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/2008`,
          body
        );
        this.lessonList = res.data;
      } catch (e) {
        throw e;
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "actionDate") {
          this.actionDate = null;
          this.actionDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>