<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Προσθήκη σπουδαστή
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pa-4 d-flex flex-column">
      <v-data-table
        class="mb-4"
        no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
        loading-text="Φόρτωση..."
        :footer-props="{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        }"
        :headers="headers"
        :items="nicStudents.content"
        :options.sync="options"
        :server-items-length="nicStudents.elements"
        :loading="tableLoader"
        show-select
        :single-select="false"
        v-model="selectedStudent"
        item-key="id"
      >
      </v-data-table>

      <v-btn
        depressed
        color="primary"
        class="ml-auto"
        @click="onSubmit"
        :loading="loader"
      >
        Προσθήκη
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
export default {
  created() {
    console.log(this.currentClass);
  },
  data() {
    return {
      tableLoader: false,
      loader: false,
      selectedStudent: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      nicStudents: [],

      headers: [
        {
          text: "ΑΜ",
          value: "studentCode",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },

        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },
      ],
    };
  },

  watch: {
    async options() {
      await this.handler();
    },
  },

  computed: {
    ...mapState({
      currentClass: (state) => state.classes.currentClass,
    }),
  },

  methods: {
    ...mapMutations(["setFilters"]),
    ...mapActions(["addStudentToClass"]),

    async onSubmit() {
      try {
        this.loader = true;
        let body = this.selectedStudent.map((s) => {
          return { eduClassId: this.currentClass.id, personId: s.id };
        });
    
        await this.addStudentToClass(body);
        this.loader = false;
        await this.$emit("handler");
        this.$emit("close");
      } catch (e) {
        this.loader = false;

        console.log(e);
      }
    },

    async handler() {
      try {
        this.tableLoader = true;

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/listForClass`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              progTrainPeriodId: this.currentClass.progTrainPeriod.id,
              classId: this.currentClass.classId,
              propertyType: 1,
            },
          }
        );

        this.nicStudents = res.data;

        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.nicStudents = [];

        this.tableLoader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>