<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-card class="mb-4">
        <v-card-text class="d-flex flex-column">
          <v-row>
            <v-col cols="12" sm="4">
              <v-select
                clearable
                dense
                outlined
                label=" Σχέση Εργασίας"
                v-model="categoryType"
                :items="getDataStaticList(142)"
                item-text="description"
                item-value="cd"
                no-data-text="Δεδομένα μη διαθέσιμα"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="4">
              <v-select
                clearable
                dense
                no-data-text="Δεδομένα μη διαθέσιμα"
                outlined
                :label="appId == 1 ? 'Βαθμίδες' : 'Ομάδα κατηγορίας'"
                v-model="groupType"
                :items="getDataStaticList(152)"
                item-text="description"
                item-value="cd"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="4">
              <v-select
                clearable
                no-data-text="Δεδομένα μη διαθέσιμα"
                dense
                outlined
                label="Ενεργός"
                v-model="isActive"
                :items="getDataStaticList(100)"
                item-text="description"
                item-value="cd"
              ></v-select>
            </v-col>
          </v-row>

          <v-btn class="ml-auto" depressed color="primary" @click="onSearch">
            Αναζήτηση
          </v-btn>
        </v-card-text>
      </v-card>

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="professorsCategories.content"
            :loading="tableLoader"
            :options.sync="options"
            :server-items-length="professorsCategories.elements"
          >
            <template v-slot:[`item.enabled`]="{ item }">
              <span v-if="item.enabled == true"> Ενεργός </span>
              <span v-if="item.enabled == false"> Ανενεργός </span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary ml-auto"
            @click="openDialog(null, 'add')"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddProfessorCategory
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="500px"
      scrollable
    >
      <EditProfessorCategory
        v-if="dialog.open && dialog.type == 'edit'"
        :professorsCategory="dialog.professorsCategory"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteProfessorCategory
        v-if="dialog.open && dialog.type == 'delete'"
        :professorsCategory="dialog.professorsCategory"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import AddProfessorCategory from "../../components/ProfessorsCategoryModals/AddProfessorsCategory.vue";
import EditProfessorCategory from "../../components/ProfessorsCategoryModals/EditProfessorsCategory.vue";
import DeleteProfessorCategory from "../../components/ProfessorsCategoryModals/DeleteProfessorsCategory.vue";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  components: {
    AddProfessorCategory,
    EditProfessorCategory,
    DeleteProfessorCategory,
  },

  created() {
    this.clearProfessorsFilters();
  },

  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Κωδικός",
          value: "cd",
          sortable: false,
        },

        // {
        //   text: "Ώρες ανά βδομάδα",
        //   value: "hoursPerWeek",
        //   sortable: false,
        // },
        {
          text: "Σχέση Εργασίας",
          value: "categoryType.description",
          sortable: false,
        },
        {
          text: "Βαθμίδα",
          value: "groupType.description",
          sortable: false,
        },
        {
          text: "Ειδικότητα",
          value: "description",
          sortable: false,
        },
        {
          text: "Ενεργός",
          value: "enabled",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        open: false,
        type: null,
        professorsCategory: null,
      },
    };
  },

  watch: {
    async options() {
      await this.handler();
    },
    deep: true,
  },

  computed: {
    ...mapState({
      professorsCategories: (state) =>
        state.professors_categories.professorsCategories,
      pageChanged: (state) => state.base.pageChanged,
      staticList: (state) => state.auth.userInfo.staticView,
      appId: (state) => state.auth.appId,
    }),

    categoryType: {
      get() {
        return this.$store.state.professors_categories.categoryType;
      },
      set(value) {
        this.setCategoryType(value);
      },
    },

    groupType: {
      get() {
        return this.$store.state.professors_categories.groupType;
      },
      set(value) {
        this.setGroupType(value);
      },
    },

    isActive: {
      get() {
        return this.$store.state.professors_categories.enabled;
      },
      set(value) {
        this.setEnabled(value);
      },
    },
  },

  methods: {
    ...mapMutations([
      "setFilters",
      "changePage",
      "setCategoryType",
      "setGroupType",
      "setEnabled",
      "clearProfessorsFilters",
    ]),

    ...mapActions([
      "getProfessorsCategories",
      "getContracts",
      "getCategoryTeams",
    ]),

    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },

    openDialog(professorsCategory, type) {
      this.dialog = {
        professorsCategory,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        professorsCategory: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        await this.getProfessorsCategories({
          page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
          limit:
            this.options.itemsPerPage == -1 ? null : this.options.itemsPerPage,
          groupType: this.groupType,
          categoryType: this.categoryType,
          enabled: this.isActive,
        });
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    getDataStaticList(code) {
      let result = this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
      if (result.length == 1) {
        if (code == 142) {
          this.categoryType = this.result[0].cd;
        } else if (code == 152) {
          this.categoryType = this.result[0].cd;
        }
      }
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
</style>