import axios from "axios";
import store from "../store";

export default {
  state: {
    configureApplications: [],
    currentApplication: [],
    commonApplicationActions: [],
    commonOnlineApplicationActions: [],

    currentCommonOnlineApplication: null,

    currentCategoryType: null,
    //dropdown
    categoryTypes: [],
    categoryTypesFetched: false,
    selectedFields: [],
  },

  mutations: {
    getConfigureApplications(state, configureApplications) {
      state.configureApplications = configureApplications;
    },
    getCurrentApplication(state, currentApplication) {
      state.currentApplication = currentApplication;
    },
    setCurrentCategoryType(state, currentCategoryType) {
      state.currentCategoryType = currentCategoryType;
    },

    setCategoryTypesFetched(state, fetched) {
      state.categoryTypesFetched = fetched;
    },

    getCommonApplicationActions(state, commonApplicationActions) {
      state.commonApplicationActions = commonApplicationActions;
    },

    getCommonOnlineApplicationActions(state, commonOnlineApplicationsActions) {
      state.commonOnlineApplicationActions = commonOnlineApplicationsActions;
    },

    setCurrentCommonOnlineApplication(state, currentCommonOnlineApplication) {
      state.currentCommonOnlineApplication = currentCommonOnlineApplication;
    },

    //dropdown
    getCategoryTypes(state, categoryTypes) {
      state.categoryTypes = categoryTypes;
    },

    getSelectedFields(state, selectedFields) {
      state.selectedFields = selectedFields;
    },
  },

  actions: {
    async getConfigureApplications({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/common/online/application/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              type: payload.appId,
              categoryType: payload.categoryType,
            },
          }
        );
        commit("getConfigureApplications", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getCurrentApplication({ commit, dispatch }, id) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/common/online/application/${id}`
        );
        commit("getCurrentApplication", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async addApplication({ commit, dispatch, state, rootState }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/common/online/application`,
          payload.content
        );
        await dispatch("getConfigureApplications", {
          page: rootState.base.filters.page,
          limit:
            rootState.base.filters.itemsPerPage == -1
              ? state.configureApplications.elements
              : rootState.base.filters.itemsPerPage,
          appId: payload.appId,
          categoryType: payload?.content?.categoryType,
        });
        commit("toggleSnackbar", {
          open: true,
          text: "Η δημιουργία αίτησης ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async editApplication({ commit, dispatch, state, rootState }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/common/online/application/${payload.id}`,
          payload.content
        );
        // await dispatch("getConfigureApplications", {
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.configureApplications.elements
        //       : rootState.base.filters.itemsPerPage,
        //   appId: payload.appId,
        //   categoryType: payload?.categoryType,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: "Η επεξεργασία αίτησης ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteApplication({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/common/online/application/${payload.id}`
        );
        if (state.configureApplications.limit == 1) {
          commit("changePage", true);
        } else {
          await dispatch("getConfigureApplications", {
            page: rootState.base.filters.page,
            limit:
              rootState.base.filters.itemsPerPage == -1
                ? state.configureApplications.elements
                : rootState.base.filters.itemsPerPage,
            appId: payload.appId,
          });
        }
        commit("toggleSnackbar", {
          open: true,
          text: "Η διαγραφλή αίτησης ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async assignApplication({ commit, dispatch }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/online/application`,
          payload.content
        );
        let school = store.state.auth.appId == 1 ? "τη σχολή" : "τον εκπαιδευτικό κύκλο/ειδικό τμήμα";
        commit("toggleSnackbar", {
          open: true,
          text: `Η σύνδεση της αίτησης με ${school} ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },
    async editAssignApplication({ commit, dispatch }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/online/application/${payload.content.id}`,
          payload.content
        );
        commit("toggleSnackbar", {
          open: true,
          text: `Η επεξεργασία της σύνδεση της αίτησης με ${
            store.state.auth.appId == 1 ? "τη σχολή" : "τον εκπαιδευτικό κύκλο/ειδικό τμήμα"
          } ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteAssignApplication({ commit, dispatch }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/online/application/${payload.id}`
        );
        commit("toggleSnackbar", {
          open: true,
          text: `Η διαγραφή της σύνδεσης της αίτησης με ${
            store.state.auth.appId == 1 ? "τη σχολή" : "τον εκπαιδευτικό κύκλο/ειδικό τμήμα"
          } ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getCommonApplicationActions({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/common/application/action/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              deleted: payload.deleted,
            },
          }
        );
        commit("getCommonApplicationActions", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getCommonOnlineApplicationActions({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/common/online/application/action/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              deleted: payload.deleted,
              commonOnlineApplicationId: payload.commonOnlineApplicationId,
            },
          }
        );
        commit("getCommonOnlineApplicationActions", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    //dropdown
    async getCategoryTypes({ commit, state }) {
      if (state.categoryTypesFetched == false) {
        try {
          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/value/159`
          );
          commit("getCategoryTypes", res.data);
          commit("setCategoryTypesFetched", true);
        } catch (e) {
          throw e;
        }
      }
    },

    async getSelectedFields({ commit }, payload) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/2224`,
          payload
        );
        commit("getSelectedFields", res.data);
      } catch (e) {
        throw e;
      }
    },

    async addAction({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/common/online/application/action`,
          payload
        );

        await dispatch("getCommonOnlineApplicationActions", {
          page: rootState.base.filters.page,
          limit:
            rootState.base.filters.itemsPerPage == -1
              ? state.commonOnlineApplicationActions.elements
              : rootState.base.filters.itemsPerPage,
          commonOnlineApplicationId: payload.commonOnlineApplicationId,
        });

        commit("toggleSnackbar", {
          open: true,
          text: `Η δημιουργία ενέργειας ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async editAction({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/common/online/application/action/${payload.commonOnlineApplicationActionId}`,
          payload.content
        );

        await dispatch("getCommonOnlineApplicationActions", {
          page: rootState.base.filters.page,
          limit:
            rootState.base.filters.itemsPerPage == -1
              ? state.commonOnlineApplicationActions.elements
              : rootState.base.filters.itemsPerPage,
          commonOnlineApplicationId: payload.content.commonOnlineApplicationId,
        });

        commit("toggleSnackbar", {
          open: true,
          text: `Η επεξεργασία ενέργειας ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteAction({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/common/online/application/action/${payload.commonOnlineApplicationActionId}`
        );

        await dispatch("getCommonOnlineApplicationActions", {
          page: rootState.base.filters.page,
          limit:
            rootState.base.filters.itemsPerPage == -1
              ? state.commonOnlineApplicationActions.elements
              : rootState.base.filters.itemsPerPage,
          commonOnlineApplicationId: payload.commonOnlineApplicationId,
        });

        commit("toggleSnackbar", {
          open: true,
          text: `Η διαγραφή ενέργειας ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },
  },
};
