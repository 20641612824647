import axios from "axios";
export default {
  state: {
    classExamPersons: [],
    listForExam: [],
  },

  mutations: {
    getClassExamPersons(state, classExamPersons) {
      state.classExamPersons = classExamPersons;
    },

    getListForExam(state, listForExam) {
      state.listForExam = listForExam;
    },
  },

  actions: {
    async createExamClasses({ commit, dispatch }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/class/exam/init`,
          payload.classesInputs,
          {
            params: {
              progExamPeriodId: payload.progExamPeriodId,
              lessonId: payload.lessonId,
              examDate: payload.examDate,
              sortingType: payload.sortingType,
            },
          }
        );
        commit("toggleSnackbar", {
          open: true,
          text: `Η δημιουργία ${
            payload.length == 1 ? "τμήματος" : "τμημάτων"
          } ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async createExamClass({ commit, dispatch }, payload) {
      try {
        await axios.post(`${process.env.VUE_APP_BASE_URL}/class/exam`, {
          description: payload.description,
          examDate: payload.examDate,
          lessonId: payload.lessonId,
          type: payload.type,
          progExamPeriodId: payload.progTrainPeriodId,
        });
        commit("toggleSnackbar", {
          open: true,
          text: `Η δημιουργία τμήματος ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async editExamClass({ commit, dispatch }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/class/exam/${payload.id}`,
          {
            description: payload.description,
            examDate: payload.examDate,
            lessonId: payload.lessonId,
            type: payload.type,
            progExamPeriodId: payload.progTrainPeriodId,
          }
        );
        commit("toggleSnackbar", {
          open: true,
          text: `Η επεξεργασία τμήματος ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },
    async deleteExamClass({ commit, dispatch }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/class/exam/${payload.classExamId}`
        );
        commit("toggleSnackbar", {
          open: true,
          text: `Η διαγραφή τμήματος ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getClassExamPersons({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/class/exam/person/list`,
          {
            params: {
              limit: payload.limit,
              page: payload.page,
              classExamId: payload.classExamId,
            },
          }
        );

        commit("getClassExamPersons", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getListForExam({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/listForExam`,
          {
            params: {
              limit: payload.limit,
              page: payload.page,
              masterSchoolId: payload.masterSchoolId,
              schoolId: payload.schoolId,
              yearId: payload.yearId,
              progExamPeriodId: payload.progExamPeriodId,
              lessonId: payload.lessonId,
              newExamined: payload.newExamined,
            },
          }
        );

        commit("getListForExam", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async addClassExamPerson({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/class/exam/person/mass`,
          payload
        );

        await dispatch("getClassExamPersons", {
          classExamId: payload.classExamId,
          page: rootState.base.filters.page,
          limit:
            rootState.base.filters.itemsPerPage == -1
              ? state.classExamPersons.elements
              : rootState.base.filters.itemsPerPage,
        });
        commit("toggleSnackbar", {
          open: true,
          text: `Η προσθήκη σπουδαστή στο τμήμα ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteClassExamPerson(
      { commit, dispatch, rootState, state },
      payload
    ) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/class/exam/person/${payload.classExamPersonId}`
        );

        await dispatch("getClassExamPersons", {
          classExamId: payload.classExamId,
          page: rootState.base.filters.page,
          limit:
            rootState.base.filters.itemsPerPage == -1
              ? state.classExamPersons.elements
              : rootState.base.filters.itemsPerPage,
        });
        commit("toggleSnackbar", {
          open: true,
          text: `Η διαγραφή σπουδαστή απο το τμήμα ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },
  },
};
