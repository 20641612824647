
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="transferAppl.content"
            :server-items-length="transferAppl.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:top>
              <div class="d-flex flex-column">
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-select
                      dense
                      outlined
                      v-model="selectedFaculty"
                      :items="listFaculty"
                      item-text="description"
                      item-value="cd"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span
                        >{{
                          appId == 1
                            ? "Προς Ακαδημία"
                            : " Προς Δομή Μετεκπαίδευσης"
                        }}
                      </template></v-select
                    >
                  </v-col>
                  <!-- <v-col cols="12" sm="6" md="3">
                    <v-select
                      dense
                      outlined
                      label="Σχολή"
                      v-model="selectedSchool"
                      :items="listSchool"
                      item-text="description"
                      item-value="cd"
                    ></v-select>
                  </v-col> -->
                  <v-col cols="12" sm="6" md="3">
                    <v-select
                      dense
                      outlined
                      label="Κατάσταση"
                      v-model="status"
                      :items="listStatus"
                      item-text="description"
                      item-value="id"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    ></v-select>
                  </v-col>
                </v-row>
                <div class="ml-auto">
                  <v-btn
                    class="ml-auto"
                    style="margin-right: 5px"
                    depressed
                    color="primary"
                    @click="onSearch"
                    :disabled="isDisabled"
                  >
                    Αναζήτηση
                  </v-btn>
                  <v-btn
                    class="ml-auto"
                    depressed
                    color="primary"
                    @click="calculateGrade"
                    :disabled="isDisabledAppl"
                  >
                    Μοριοδότηση Αιτήσεων
                  </v-btn>
                </div>
              </div>
            </template>

            <template v-slot:[`item.username`]="{ item }">
              <span>
                {{ item.person.firstName + " " + item.person.lastName }}
              </span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    icon
                    @click="openDialog(item.personApplication, 'view')"
                  >
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>

              <v-tooltip left v-if="showButton == 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    icon
                    @click="openDialog(item, 'approve', 'approve')"
                  >
                    <v-icon color="success" small v-bind="attrs" v-on="on">
                      mdi-checkbox-marked-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Έλεγχος</span>
              </v-tooltip>

              <v-tooltip left v-if="showButton == 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    icon
                    @click="openDialog(item, 'approve', 'reject')"
                  >
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-close-box-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Απόρριψη</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'view'"
      max-width="1500px"
      scrollable
    >
      <ViewSubmittedApplication
        v-if="dialog.open && dialog.type == 'view'"
        :application="dialog.application"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'approve'"
      max-width="500px"
      scrollable
    >
      <TransferApproveApplication
        v-if="dialog.open && dialog.type == 'approve'"
        :application="dialog.application"
        :appType="dialog.appType"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from "axios";
import ViewSubmittedApplication from "../../components/Applications/ViewSubmittedApplication.vue";
import TransferApproveApplication from "../../components/Applications/TransferApproveApplication.vue";

export default {
  components: {
    ViewSubmittedApplication,
    TransferApproveApplication,
  },

  async created() {
    try {
      await this.loadFaculties();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      tableLoader: false,
      faculty: null,
      start: false,
      status: 0,
      transferAppl: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      showButton: null,
      headers: [
        {
          text: "Απο Ακαδημία",
          value: "fromMasterSchoolDescription",
          sortable: false,
        },
        {
          text: "Όνομα",
          value:
            "personApplication.onlineApplication.commonOnlineApplication.description",
          sortable: false,
        },
        {
          text: "Όνομα Σπουδαστή",
          value: "username",
          sortable: false,
        },
        {
          text: "Βαθμός",
          value: "grade",
          sortable: false,
        },

        {
          text: "Σημειώσεις",
          value: "memo",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      dialog: {
        open: false,
        type: null,
        role: null,
      },
      selectedSchool: null,
      selectedFaculty: null,
      listSchool: [],
      listFaculty: [],
      listStatus: [
        { id: 0, description: "Εν αναμονή" },
        { id: -9, description: "Αποριφθέντα" },
        { id: 1, description: "Εγκεκριμένα" },
      ],
    };
  },
  watch: {
    options: {
      async handler() {
        try {
          if (this.selectedFaculty != null) {
            await this.getTranferApplication();
          }
        } catch (e) {
          console.log(e);
          this.tableLoader = false;
        }
      },
    },
    deep: true,
  },
  computed: {
    ...mapState({
      appId: (state) => state.auth.appId,
    }),
    isDisabled() {
      if (this.selectedFaculty != "" && this.selectedFaculty != null) {
        return false;
      } else {
        return true;
      }
    },
    isDisabledAppl() {
      if (
        this.selectedFaculty != "" &&
        this.selectedFaculty != null &&
        this.status == 0
      ) {
        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    ...mapMutations(["setFilters"]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.getTranferApplication();
      }
    },
    async getTranferApplication() {
      try {
        this.setFilters(this.options);
        try {
          this.showButton = this.status;
          this.tableLoader = true;
          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/transfer/list`,
            {
              params: {
                page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                limit:
                  this.options.itemsPerPage == -1
                    ? null
                    : this.options.itemsPerPage,
                masterSchoolId: this.selectedFaculty,
                approve: this.status,
              },
            }
          );
          console.log(res);
          this.transferAppl = res.data;
        } catch (e) {
          throw e;
        }

        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    openDialog(application, type, appType) {
      this.dialog = {
        application,
        open: true,
        type,
        appType: appType != null ? appType : null,
      };
    },
    closeDialog() {
      this.dialog = {
        application: null,
        open: false,
        type: null,
        appType: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.getTranferApplication();
    },
    async onSetFaculty(value) {
      // call end point tou get schools 2001
      let result = await this.fetchDataDynamic(2001, value);
      this.listSchool = result.data;
    },
    async loadFaculties() {
      let result = await this.fetchDataDynamic(2000, null);
      this.listFaculty = result.data;
      if (result.data.length == 1) {
        this.selectedFaculty = result.data[0].cd;
      }
    },
    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },
    async calculateGrade() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/transfer/calculate/grades`
        );
        this.getTranferApplication();
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>