
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-breadcrumbs :items="breadcrumb_items" divider=">"></v-breadcrumbs>

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="books.hashContent"
            :options.sync="options"
            :server-items-length="books.elements"
            :loading="tableLoader"
          >
            <template v-slot:top>
              <v-text-field
                dense
                outlined
                v-model="searchText"
                label="Τίτλος, Συγγραφέας, ISBN"
                @keyup.enter="handler"
                clearable
              ></v-text-field>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary"
            class="mr-2"
            @click="openDialog(null, 'add')"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="1000px"
      scrollable
    >
      <AddBook
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="1000px"
      scrollable
    >
      <EditBook
        v-if="dialog.open && dialog.type == 'edit'"
        :book="dialog.book"
        @close="closeDialog"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteBook
        v-if="dialog.open && dialog.type == 'delete'"
        :book="dialog.book"
        @close="closeDialog"
        @handler="handler"
        :books="books"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import AddBook from "../../components/BooksModals/AddBook.vue";
import EditBook from "../../components/BooksModals/EditBook.vue";
import DeleteBook from "../../components/BooksModals/DeleteBook.vue";
import axios from "axios";
export default {
  components: {
    AddBook,
    EditBook,
    DeleteBook,
  },

  created() {
    this.breadcrumb_items.push(
      {
        text: `${this.currentLibrary.description}`,
        disabled: false,
        link: true,
        exact: true,
        to: "/libraries",
      },
      {
        text: `Βιβλία`,
        disabled: true,
      }
    );
  },

  data() {
    return {
      searchText: null,
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      breadcrumb_items: [],
      headers: [
        {
          text: "Τίτλος ",
          value: "title",
          sortable: false,
        },
        {
          text: "Συγγραφέας",
          value: "author",
          sortable: false,
        },

        {
          text: "Ποσότητα",
          value: "quantity",
          sortable: false,
        },

        {
          text: "Εκδότης",
          value: "publisher",
          sortable: false,
        },

        {
          text: "ISBN",
          value: "isbn",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      books: [],

      dialog: {
        book: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      await this.handler();
    },
  },

  computed: {
    ...mapState({
      pageChanged: (state) => state.base.pageChanged,

      currentLibrary: (state) => state.libraries.currentLibrary,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/library/book/list`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              libraryId: this.$route.params.library_id,
              searchText: this.searchText != "" ? this.searchText : null,
            },
          }
        );

        this.books = res.data;
        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.books = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    openDialog(book, type) {
      this.dialog = {
        book,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        book: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>