var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"component-wrapper d-flex align-center justify-center",attrs:{"color":"transparent"}},[_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('GenericFilter',{attrs:{"level":6,"listActive":1,"requiredFields":{
        masterSchool: true,
        school: true,
        year: true,
        progTrainPeriod: true,
        class: true,
        lesson: true,
      }},on:{"onSearchAction":_vm.onSearch}}),_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',{staticClass:"pa-4"},[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"12"}},[_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","outlined":"","error-messages":_vm.exmDateErrors},on:{"blur":function($event){_vm.exmDate = _vm.parseDate(_vm.exmDateFormatted, 'exmDate')}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-calendar")])]},proxy:true},{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Ημερομηνία ενημέρωσης ")]},proxy:true}],null,true),model:{value:(_vm.exmDateFormatted),callback:function ($$v) {_vm.exmDateFormatted=$$v},expression:"exmDateFormatted"}})]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"locale":"el","scrollable":""},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.exmDate),callback:function ($$v) {_vm.exmDate=$$v},expression:"exmDate"}})],1)],1),(_vm.progExam && _vm.progExam.endDate)?_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('h4',{staticStyle:{"line-height":"3"}},[_vm._v(" Ημερομηνία Τελευταίας Καταχώρησης: "+_vm._s(_vm.progEndDate)+" ")])]):_vm._e()],1)],1),_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          },"headers":_vm.headers,"items":_vm.studentGrades.hashContent,"options":_vm.options,"server-items-length":_vm.studentGrades.elements,"loading":_vm.tableLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.oral",fn:function(ref){
          var item = ref.item;
return _vm._l((_vm.gradeList),function(grade,i){return _c('span',{key:i},[(grade.personId == item.person_id)?_c('span',[(_vm.edit)?_c('v-text-field',{attrs:{"dense":"","single-line":"","error-messages":_vm.errorMessages(i)},on:{"change":function () { return _vm.onChangeGrade(i); }},model:{value:(_vm.gradeList[i].oral),callback:function ($$v) {_vm.$set(_vm.gradeList[i], "oral", $$v)},expression:"gradeList[i].oral"}}):_c('span',[_vm._v(_vm._s(item.oral))])],1):_vm._e()])})}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(item.oral != null)?_c('span',[(!_vm.edit)?_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item, 'delete')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Διαγραφή")])])],1):_vm._e()]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"custom-card-buttons"},[(!_vm.edit)?_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"primary","disabled":!_vm.filtersSet || _vm.studentGrades.hashContent.length == 0},on:{"click":function($event){_vm.edit = !_vm.edit}}},[_vm._v(" Επεξεργασία "),_c('v-icon',{attrs:{"dense":"","right":""}},[_vm._v("mdi-pencil-outline")])],1):_vm._e(),(_vm.edit)?_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"error"},on:{"click":_vm.onClose}},[_vm._v(" Ακύρωση ")]):_vm._e(),(_vm.edit)?_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"success","loading":_vm.saveLoader},on:{"click":_vm.onSave}},[_vm._v(" Αποθήκευση ")]):_vm._e()],1)],1)],1),(_vm.dialog.type == 'delete')?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'delete')?_c('DeleteGrade',{attrs:{"gradeProp":_vm.dialog.grade},on:{"close":_vm.closeDialog,"onDelete":_vm.handler}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }