<template>
  <div class="component-wrapper d-flex flex-column align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
    >
      <v-card-title class="greyish--text font-weight-regular">
        {{ userInfo.person.firstName }} {{ userInfo.person.lastName }}
      </v-card-title>

      <v-card-text>
        <v-card class="mb-4">
          <v-card-title class="greyish--text font-weight-regular">
            Προσωπικά στοιχεία
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="d-flex flex-column">
            <div class="d-flex align-center justify-start mb-2">
              <span class="body-1 grey--text mr-2">Πατρώνυμο:</span>
              <span class="body-1">{{ userInfo.person.fatherName }}</span>
            </div>
            <div class="d-flex align-center justify-start mb-2">
              <span class="body-1 grey--text mr-2">Μητρώνυμο:</span>
              <span class="body-1">{{ userInfo.person.motherName }}</span>
            </div>
            <div class="d-flex align-center justify-start">
              <span class="body-1 grey--text mr-2">Ημερομηνία Γέννησης:</span>
              <span class="body-1">{{
                new Date(userInfo.person.dateOfBirth).toLocaleDateString("el")
              }}</span>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mb-4" v-if="taxis == null">
          <v-card-title class="greyish--text font-weight-regular">
            Αλλαγή κωδικού πρόσβασης
          </v-card-title>
          <v-divider></v-divider>
          <v-form @submit.prevent="onSubmit" class="pa-4">
            <v-card-text class="d-flex flex-column">
              <v-text-field
                class="d-flex align-center justify-start m-2"
                v-model="password"
                :valuePassword="password"
                label="Δώστε νέο κωδικό πρόσβασης"
                :append-icon="valuePassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (valuePassword = !valuePassword)"
                :type="valuePassword ? 'password' : 'text'"
              ></v-text-field>
              <v-text-field
                class="d-flex align-center justify-start m-2"
                v-model="repeatPassword"
                :valueRepeatPassword="repeatPassword"
                label="Επαλήθευση κωδικού πρόσβασης"
                :append-icon="valueRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="
                  () => (valueRepeatPassword = !valueRepeatPassword)
                "
                :type="valueRepeatPassword ? 'password' : 'text'"
                :error-messages="repeatPasswordErrors"
              ></v-text-field>
              <div class="d-flex justify-center">
                <v-btn
                  depressed
                  color="success"
                  class="ml-4"
                  type="submit"
                  :loading="loader"
                  >Αποθήκευση</v-btn
                >
              </div>
            </v-card-text>
          </v-form>
        </v-card>
        <v-card v-if="userInfo.person.personAddress != undefined">
          <v-card-title class="greyish--text font-weight-regular">
            Στοιχεία επικοινωνίας
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="d-flex flex-column">
            <div class="d-flex align-center justify-start mb-2">
              <span class="body-1 grey--text mr-2">Πόλη:</span>
              <span class="body-1">{{
                userInfo.person.personAddress.city
              }}</span>
            </div>
            <div class="d-flex align-center justify-start mb-2">
              <span class="body-1 grey--text mr-2">Διεύθυνση:</span>
              <span class="body-1">{{
                userInfo.person.personAddress.streetName
              }}</span>
            </div>
            <div class="d-flex align-center justify-start mb-2">
              <span class="body-1 grey--text mr-2">Αριθμός:</span>
              <span class="body-1">{{
                userInfo.person.personAddress.num
              }}</span>
            </div>
            <div class="d-flex align-center justify-start mb-2">
              <span class="body-1 grey--text mr-2">Ταχυδρομικός κώδικας:</span>
              <span class="body-1">{{
                userInfo.person.personAddress.zipCode
              }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, sameAs } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  created() {
    //this.password = this.person.password;
    //this.repeatPassword = this.person.repeatPassword;
  },
  data: () => ({
    loader: false,
    password: null,
    repeatPassword: null,
    valuePassword: true,
    valueRepeatPassword: true,
  }),
  validations: {
    password: {
      required,
    },
    repeatPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.userInfo,
      taxis: (state) => state.auth.taxis,
    }),
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.repeatPassword.$dirty) return errors;
      if (!this.$v.repeatPassword.sameAsPassword)
        errors.push("Οι δύο κωδικοί δεν ταυτίζονται");
      return errors;
    },
  },
  methods: {
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        this.loader = true;
        let content = {
          password: this.password,
        };
        const resp = await axios.put(
          `${process.env.VUE_APP_BASE_URL}/change/password/`,
          content
        );
        this.$store.dispatch("toggleSnackbar", {
          open: true,
          text: "Η αλλαγή του κωδικού πρόσβασης ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
        this.password = "";
        this.repeatPassword = "";
        this.loader = false;
      } catch (e) {
        this.loader = false;
        this.$store.dispatch("errorHandler", e);

        throw e;
      }
    },

    async savePassword({ commit, dispatch }, payload) {
      try {
        commit("toggleSnackbar", {
          open: true,
          text: `Η αλλαγή του κωδικού πρόσβασης ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>