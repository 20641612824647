var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper d-flex justify-center"},[(!_vm.dialogRole.open)?_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('GenericFilter',{attrs:{"level":2,"requiredFields":{
        masterSchool: true,
        school: true,
        year: false,
        progTrainPeriod: false,
        class: false,
        lesson: false,
      }},on:{"onSearchAction":_vm.onSearch}}),_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          },"headers":_vm.headers,"items":!_vm.filtersSet ? [] : _vm.teachers.content,"server-items-length":_vm.teachers.elements,"options":_vm.options,"loading":_vm.tableLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pen ")])],1)]}}],null,true)},[_c('span',[_vm._v("Επεξεργασία")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.onTeacherView(item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Προβολή")])]),(_vm.selectedSystemRole == 'ADMIN_ROLE')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.onUserRoleView(item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-key-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ρόλοι")])]):_vm._e()]}},{key:"item.enable",fn:function(ref){
          var item = ref.item;
return [(item)?_c('span',[_vm._v("Ναι")]):_vm._e(),(!item)?_c('span',[_vm._v("Οχι")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"custom-card-buttons"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"primary","disabled":!_vm.filtersSet},on:{"click":function($event){return _vm.$router.push('/add_teacher')}}},[_vm._v(" Δημιουργία "),_c('v-icon',{attrs:{"dense":"","right":""}},[_vm._v("mdi-plus")])],1)],1)],1)],1):_vm._e(),(_vm.dialogRole.open)?_c('UserRoles',{attrs:{"user":{
      id: _vm.dialogRole.user.personId,
      firstName: _vm.dialogRole.user.firstName,
      lastName: _vm.dialogRole.user.lastName,
    },"path":"/teachers"},on:{"close":_vm.closeDialogRole}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }