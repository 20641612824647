
 <template>
  <div class="component-wrapper d-flex justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
      v-if="!dialogRole.open"
    >
      <GenericFilter
        :level="2"
        @onSearchAction="onSearch"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: false,
          progTrainPeriod: false,
          class: false,
          lesson: false,
        }"
      />

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="!filtersSet ? [] : teachers.content"
            :server-items-length="teachers.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onEdit(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onTeacherView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
              <v-tooltip left v-if="selectedSystemRole == 'ADMIN_ROLE'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onUserRoleView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-account-key-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ρόλοι</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.enable`]="{ item }">
              <span v-if="item">Ναι</span>
              <span v-if="!item">Οχι</span>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary"
            class="mr-2"
            @click="$router.push('/add_teacher')"
            :disabled="!filtersSet"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>
    <UserRoles
      v-if="dialogRole.open"
      :user="{
        id: dialogRole.user.personId,
        firstName: dialogRole.user.firstName,
        lastName: dialogRole.user.lastName,
      }"
      path="/teachers"
      @close="closeDialogRole"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import UserRoles from "../Users/UserRoles.vue";
import axios from "axios";

export default {
  components: {
    GenericFilter,
    UserRoles,
  },

  async created() {
    try {
      this.filtersSet = false;
      if (this.filtersSet) await this.handler();
    } catch (e) {
      throw e;
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let array = [
        `/teachers/${from?.params.teacher_id}/edit`,
        `/teachers/${from?.params.teacher_id}/viewteacher/proswpika_stoixeia`,
        `/teachers/${from?.params.teacher_id}/viewteacher/eggrafes`,
        `/teachers/${from?.params.teacher_id}/viewteacher/applications`,
        `/teachers/${from?.params.teacher_id}/viewteacher/experience`,
      ];
      if (!array.includes(from.path)) {
        vm.clearGeneric();
      }
    });
  },
  data() {
    return {
      search: "",
      tableLoader: false,
      filtersSet: false,
      teachers: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },

        {
          text: "Βαθμίδα",
          value: "category.groupType.description",
          sortable: false,
        },
        {
          text: "Ειδικότητα",
          value: "category.description",
          sortable: false,
        },

        {
          text: "Ενεργός",
          value: "enable",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      dialog: {
        teacher: null,
        open: false,
        type: null,
      },
      dialogRole: {
        open: false,
        user: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },
    deep: true,
  },

  computed: {
    ...mapState({
      listFaculty: (state) => state.faculties.faculties.content,
      pageChanged: (state) => state.base.pageChanged,
      facultyId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      active: (state) => state.generic_filter.active,
      selectedSystemRole: (state) => state.auth.selectedSystemRole,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getFaculties", "setCurrentUser", "setCurrentTeacher"]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },

    clearGeneric() {
      this.$store.dispatch("setAllFilter", {
        facultyId: null,
        schoolId: null,
        academicYearId: null,
        semesterId: null,
        classId: null,
        courseId: null,
        dates: null,
        date: null,
        active: null,
        searchText: null,
        examYearId: null,
        progExamPeriodId: null,
        examClassId: null,
        examLessonId: null,
        examDate: null,
      });
    },

    async onTeacherView(teacher) {
      this.$router.push(
        `${this.$route.path}/${teacher.personId}/viewteacher/proswpika_stoixeia`
      );
    },
    async onEdit(user) {
      try {
        this.$router.push(`/teachers/${user.personId}/edit`);
      } catch (e) {
        console.log(e);
      }
    },

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/teacher/list`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              schoolId: this.schoolId,
              enable:
                this.active == "1" ? true : this.active != null ? false : null,
            },
          }
        );
        this.teachers = res.data;
        if (!this.filtersSet) this.filtersSet = true;

        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.teachers = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },
    closeDialogRole() {
      this.dialogRole = {
        user: null,
        open: false,
      };
    },
    onUserRoleView(user) {
      this.dialogRole = {
        user: user,
        open: true,
      };
      // this.$store.commit("setCurrentUser", user);
      // this.$router.push(`/users/${user.id}/roles`);
    },
  },
};
</script>

<style scoped lang="scss">
</style>