<template>
  <div style="display: contents">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <h3>{{ this.schoolGenCd.masterSchool.description }}</h3>
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="appId == 1 ? headers : headersKesen"
            :items="schools"
            :loading="tableLoader"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.schools`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn depressed color=" mr-2 " @click="$emit('close')"> Πίσω </v-btn>
          <v-btn
            depressed
            color="primary "
            @click="openDialog(null, 'add')"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="1000px"
      scrollable
    >
      <AddSchoolGenCdSchools
        v-if="dialog.open && dialog.type == 'add'"
        :masterSchoolId="schoolGenCd.masterSchool.id"
        :schoolGenCdId="schoolGenCd.id"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteSchoolGenCdSchools
        v-if="dialog.open && dialog.type == 'delete'"
        :schoolGenCd="schoolGenCd.id"
        :schoolId="dialog.schoolGenCd.id"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from "axios";
import AddSchoolGenCdSchools from "../../components/SchoolGenCd/AddSchoolGenCdSchools.vue";
import DeleteSchoolGenCdSchools from "../../components/SchoolGenCd/DeleteSchoolGenCdSchools.vue";

export default {
  name: "schoolGenCdSchools",
  components: {
    AddSchoolGenCdSchools,
    DeleteSchoolGenCdSchools,
  },
  props: ["schoolGenCd"],
  async created() {
    await this.fetchScoolsGenCd();
  },
  data() {
    return {
      schoolTypesList: [],
      schoolTypeId: null,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      tableLoader: false,
      headers: [
        {
          text: "Σχολή ",
          value: "school.description",
          sortable: false,
        },

        { text: "Επιλογές", value: "actions", sortable: false },
      ],
      headersKesen: [
        {
          text: "Εκπαιδευτικός Κύκλος/Ειδικό Τμήμα ",
          value: "school.description",
          sortable: false,
        },

        { text: "Επιλογές", value: "actions", sortable: false },
      ],
      dialog: {
        school: null,
        open: false,
        type: null,
      },
      schools: [],
    };
  },
  // watch: {
  //   async options() {
  //     await this.fetchScoolsGenCd();
  //   },
  // },
  computed: {
    ...mapState({
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
    }),
  },
  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    async fetchScoolsGenCd() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/school/cd/${this.schoolGenCd.id}`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
            },
          }
        );
        if (res.data.schools != undefined) {
          this.schools = res.data?.schools;
        } else {
          this.schools = [];
        }
        this.tableLoader = false;
      } catch (e) {
        this.tableLoader = false;
        throw e;
      }
    },
    openDialog(schoolGenCd, type) {
      this.dialog = {
        schoolGenCd,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        schoolGenCd: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }

      this.fetchScoolsGenCd();
    },
  },
};
</script>
