<template>
  <div class="component-wrapper d-flex flex-column align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
    >
      <v-card-title class="greyish--text font-weight-regular">
        Ειδοποιήσεις
        <div class="d-flex flex-column flex-grow-1"></div>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-card class="mb-4">
          <v-card-text class="pa-4">
            <v-data-table
              no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
              loading-text="Φόρτωση..."
              :footer-props="{
                'items-per-page-text': 'Σειρές ανά σελίδα',
                'items-per-page-all-text': 'Όλες',
                'items-per-page-options': [5, 10, 15, -1],
              }"
              :headers="headers"
              :items="notifications.content"
              :options.sync="options"
              :server-items-length="notifications.elements"
              :loading="tableLoader"
            >
              <template v-slot:[`item.creationDate`]="{ item }">
                {{ new Date(item.creationDate).toLocaleDateString("el") }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon @click="openDialog(item, 'view')">
                      <v-icon color="primary" small v-bind="attrs" v-on="on">
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Προβολή</span>
                </v-tooltip>

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon @click="openDialog(item, 'delete')">
                      <v-icon color="error" small v-bind="attrs" v-on="on">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Διαγραφή</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'view'"
      scrollable
      max-width="500px"
    >
      <ViewNotification
        v-if="dialog.open"
        :notification="dialog.notification"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteNotification
        v-if="dialog.open && dialog.type == 'delete'"
        :notification="dialog.notification"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ViewNotification from "../components/NotificationModals/ViewNotification.vue";
import DeleteNotification from "../components/NotificationModals/DeleteNotification.vue";

export default {
  components: {
    ViewNotification,
    DeleteNotification,
  },

  data() {
    return {
      page: 1,
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Τίτλος",
          value: "title",
          sortable: false,
        },
        {
          text: "Ημερομηνία",
          value: "creationDate",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        notification: null,
        open: false,
      },
    };
  },
  watch: {
    async options() {
      await this.handler();
    },
    deep: true,
  },

  computed: {
    ...mapState({
      notifications: (state) => state.notifications.notifications,
      pageChanged: (state) => state.base.pageChanged,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getNotifications"]),

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        await this.getNotifications({
          page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
          limit:
            this.options.itemsPerPage == -1 ? null : this.options.itemsPerPage,
        });
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    openDialog(notification, type) {
      this.dialog = {
        notification,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        notification: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>