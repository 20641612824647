
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="6"
        @onSearchAction="onSearch"
        :search="this.selectedSystemRole == 'TEACHER_ROLE' ? false : true"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: false,
          progTrainPeriod: false,
          progTrainPeriodYear: false,
          class: false,
          lesson: false,
        }"
      />

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="announcements.content"
            :options.sync="options"
            :server-items-length="announcements.elements"
            :loading="tableLoader"
          >
            <template v-slot:[`item.startDate`]="{ item }">
              {{ new Date(item.startDate).toLocaleDateString("el") }}
            </template>

            <template v-slot:[`item.endDate`]="{ item }">
              <span v-if="item.endDate">
                {{ new Date(item.endDate).toLocaleDateString("el") }}</span
              >
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary"
            class="mr-2"
            :disabled="!filtersSet"
            @click="openDialog(null, 'add')"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddAnnouncement
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
        :school="schoolId"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="500px"
      scrollable
    >
      <EditAnnouncement
        v-if="dialog.open && dialog.type == 'edit'"
        :announcement="dialog.announcement"
        @close="closeDialog"
        :school="schoolId"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteAnnouncement
        v-if="dialog.open && dialog.type == 'delete'"
        :announcement="dialog.announcement"
        @close="closeDialog"
        :school="schoolId"
        @handler="handler"
        :announcements="announcements"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import AddAnnouncement from "../../components/Announcements/AddAnnouncement.vue";
import EditAnnouncement from "../../components/Announcements/EditAnnouncement.vue";
import DeleteAnnouncement from "../../components/Announcements/DeleteAnnouncement.vue";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import axios from "axios";
export default {
  components: {
    AddAnnouncement,
    EditAnnouncement,
    DeleteAnnouncement,
    GenericFilter,
  },

  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      announcements: [],
      filtersSet: false,
      headers: [
        {
          text: "Τίτλος",
          value: "title",
          sortable: false,
        },
        {
          text: "Μήνυμα",
          value: "message",
          sortable: false,
        },

        {
          text: "Τύπος",
          value: "type.description",
          sortable: false,
        },

        {
          text: "Ενεργή",
          value: "flgActive.description",
          sortable: false,
        },

        {
          text: "Ημερομηνία έναρξης",
          value: "startDate",
          sortable: false,
        },

        {
          text: "Ημερομηνία Λήξης",
          value: "endDate",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        announcement: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.schoolId != null) await this.handler();
    },
  },

  computed: {
    ...mapState({
      schools: (state) => state.schools.schools,
      pageChanged: (state) => state.base.pageChanged,
      searchText: (state) => state.generic_filter.searchText,

      selectedSystemRole: (state) => state.auth.selectedSystemRole,
      facultyId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      academicYearId: (state) => state.generic_filter.academicYearId,
      yearSemesterId: (state) => state.generic_filter.yearSemesterId,
      semesterId: (state) => state.generic_filter.semesterId,
      courseId: (state) => state.generic_filter.courseId,
      classId: (state) => state.generic_filter.classId,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getSchools"]),

    async handler() {
      try {
        this.tableLoader = true;

        this.setFilters(this.options);

        const res = await axios.get(
          this.selectedSystemRole == "TEACHER_ROLE"
            ? `${process.env.VUE_APP_BASE_URL}/school/note/list/teacher`
            : `${process.env.VUE_APP_BASE_URL}/school/note/list`,
          {
            params: {
              masterSchoolId: this.facultyId,
              schoolId: this.schoolId,
              // yearId: this.academicYearId,
              progTrainPeriodId: this.yearSemesterId,
              lessonId: this.courseId,
              classId: this.classId,
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              searchText: this.searchText != "" ? this.searchText : null,
            },
          }
        );

        this.announcements = res.data;
        if (!this.filtersSet) this.filtersSet = true;

        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.announcements = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },

    openDialog(announcement, type) {
      this.dialog = {
        announcement,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        announcement: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>