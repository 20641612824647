import axios from "axios";
export default {
  state: {
    ptyxiakhErgasia: [],
    poreiaPtyxiakhsErgasias: [],
    currentDegree: null,
    examinerList: [],
  },

  mutations: {
    getPtyxiakhErgasia(state, ptyxiakhErgasia) {
      state.ptyxiakhErgasia = ptyxiakhErgasia;
    },

    getPoreiaPtyxiakhsErgasias(state, poreiaPtyxiakhsErgasias) {
      state.poreiaPtyxiakhsErgasias = poreiaPtyxiakhsErgasias;
    },

    getExaminerList(state, examinerList) {
      state.examinerList = examinerList;
    },

    setCurrentDegree(state, currentDegree) {
      state.currentDegree = currentDegree;
    },
  },

  actions: {
    async getPtyxiakhErgasia({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/degree/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              personId: payload.id,
            },
          }
        );
        commit("getPtyxiakhErgasia", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getPoreiaPtyxiakhsErgasias({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/degree/event/list`,
          {
            params: {
              page: payload.page,
              degreeId: payload.degreeId,
              limit: payload.limit,
              personId: payload.id,
            },
          }
        );
        commit("getPoreiaPtyxiakhsErgasias", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getExaminerList({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/teacher/list/fullname`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              schoolId: payload.schoolId,
              enabled: payload.enabled,
              // progTrainPeriodId: payload.progTrainPeriodId,
              // propertyType: 3,
            },
          }
        );
        console.log("res ", res);
        commit("getExaminerList", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async addDegree({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.post(`${process.env.VUE_APP_BASE_URL}/degree`, payload);
        // await dispatch("getPtyxiakhErgasia", {
        //   id: payload.personId,
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.ptyxiakhErgasia.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: "Η δημιουργία πτυχιακής εργασίας ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async editDegree({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/degree/${payload.degreeId}`,
          payload.content
        );
        // await dispatch("getPtyxiakhErgasia", {
        //   id: payload.content.personId,
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.ptyxiakhErgasia.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: "Η επεξεργασία πτυχιακής εργασίας ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteDegree({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/degree/${payload.degreeId}`
        );
        // await dispatch("getPoreiaPtyxiakhsErgasias", {
        //   id: payload.personId,
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.ptyxiakhErgasia.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: "Η διαγραφή πτυχιακής εργασίας ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async addDegreeEvent({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/degree/event`,
          payload
        );
        // await dispatch("getPoreiaPtyxiakhsErgasias", {
        //   id: payload.personId,
        //   degreeId: payload.degreeId,

        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.poreiaPtyxiakhsErgasias.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: "Η δημιουργία πτυχιακής εργασίας ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async editDegreeEvent({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/degree/event/${payload.degreeId}`,
          payload.content
        );
        // await dispatch("getPoreiaPtyxiakhsErgasias", {
        //   id: payload.personId,
        //   degreeId: payload.content.degreeId,
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.poreiaPtyxiakhsErgasias.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: "Η επεξεργασία πτυχιακής εργασίας ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteDegreeEvent({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/degree/event/${payload.degreeId}`
        );
        // await dispatch("getPoreiaPtyxiakhsErgasias", {
        //   id: state.currentDegree.id,
        //   degreeId: payload.degreeId,
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.poreiaPtyxiakhsErgasias.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: "Η διαγραφή πτυχιακής εργασίας ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },
  },
};
