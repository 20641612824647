var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper d-flex align-center justify-center"},[_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          },"headers":_vm.headers,"items":_vm.shipCompanies.content,"options":_vm.options,"server-items-length":_vm.shipCompanies.elements,"loading":_vm.tableLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"dense":"","outlined":"","label":"Όνομα","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handler.apply(null, arguments)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})]},proxy:true},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item, 'edit')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pen ")])],1)]}}],null,true)},[_c('span',[_vm._v("Επεξεργασία")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item, 'delete')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Διαγραφή")])])]}},{key:"item.vessel",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.onVesselView(item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Προβολή")])])]}},{key:"item.companyRep",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.onCompanyRepView(item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Προβολή")])])]}},{key:"item.streetName",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.streetName + " " + item.number)+" ")])]}}],null,true)})],1)],1),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.openDialog(null, 'add')}}},[_vm._v(" Δημιουργία "),_c('v-icon',{attrs:{"dense":"","right":""}},[_vm._v("mdi-plus")])],1)],1)],1),(_vm.dialog.type == 'add')?_c('v-dialog',{attrs:{"persistent":"","max-width":"800px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'add')?_c('AddShipCompany',{on:{"close":_vm.closeDialog,"handler":_vm.handler}}):_vm._e()],1):_vm._e(),(_vm.dialog.type == 'edit')?_c('v-dialog',{attrs:{"persistent":"","max-width":"800px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'edit')?_c('EditShipCompany',{attrs:{"shipCompany":_vm.dialog.shipCompany},on:{"handler":_vm.handler,"close":_vm.closeDialog}}):_vm._e()],1):_vm._e(),(_vm.dialog.type == 'delete')?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'delete')?_c('DeleteShipCompany',{attrs:{"shipCompany":_vm.dialog.shipCompany,"shipCompanies":_vm.shipCompanies},on:{"close":_vm.closeDialog,"handler":_vm.handler}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }