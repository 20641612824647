<template>
  <v-card>
    <v-toolbar
      :color="appType == 'approve' ? 'success' : 'error'"
      class="white--text"
    >
      <span v-if="appType == 'approve'"> Έλεγχος αίτησης </span>
      <span v-else-if="appType == 'reject'"> Απόρριψη αίτησης </span>
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pa-4">
      <div class="d-flex flex-column">
        <div
          class="
            d-flex
            align-center
            justify-center
            text-body-1
            black--text
            mb-4
          "
        >
          <v-icon
            :color="appType == 'approve' ? 'success' : 'error'"
            class="mr-1"
            >mdi-alert-circle-outline</v-icon
          >
          <div v-if="appType == 'approve'">
            Είστε σίγουροι ότι θέλετε να εγκρίνετε αυτή την αίτηση?
          </div>
          <div v-if="appType == 'reject'">
            Είστε σίγουροι ότι θέλετε να απορρίψετε αυτή την αίτηση?
            <v-textarea
              dense
              outlined
              v-model="rejectReason"
              hide-details="auto"
              class="mt-1"
              :error-messages="rejectReasonErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Σχόλιο απόρριψης
              </template>
            </v-textarea>
          </div>
        </div>
      </div>
      <div v-if="appType == 'approve'" class="d-flex justify-center pb-2">
        <v-btn
          depressed
          color="success"
          class="mr-2"
          @click="onAccept"
          :loading="loader"
        >
          Έλεγχος
        </v-btn>
        <v-btn depressed color="error" class="mr-4" @click="$emit('close')"
          >Ακύρωση</v-btn
        >
      </div>

      <div v-else-if="appType == 'reject'" class="d-flex justify-center pb-2">
        <v-btn
          depressed
          outlined
          color="error"
          class="mr-4"
          @click="$emit('close')"
          >Ακύρωση</v-btn
        >

        <v-btn depressed color="error" @click="onReject" :loading="loader">
          Απόρριψη
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { requiredIf } from "vuelidate/lib/validators";
export default {
  props: ["appType", "application"],

  data() {
    return {
      loader: false,
      rejectReason: null,
    };
  },
  validations() {
    const self = this;
    return {
      rejectReason: {
        required: requiredIf((e) => {
          return self.appType == "reject";
        }),
      },
    };
  },
  computed: {
    rejectReasonErrors() {
      const errors = [];
      if (!this.$v.rejectReason.$dirty) return errors;
      if (!this.$v.rejectReason.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },
  methods: {
    async onAccept() {
      try {
        this.loader = true;
        try {
          const res = await axios.put(
            `${process.env.VUE_APP_BASE_URL}/transfer/${this.application.id}/approve`,
            {
              approve: true,
            }
          );
          console.log(res);
        } catch (e) {
          throw e;
        }
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },

    async onReject() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        try {
          const res = await axios.put(
            `${process.env.VUE_APP_BASE_URL}/transfer/${this.application.id}/approve`,
            {
              approve: false,
              rejectReason: this.rejectReason,
            }
          );
          console.log(res);
        } catch (e) {
          throw e;
        }
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },
  },
};
</script>

<style>
</style>