var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper d-flex align-center justify-center"},[_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('GenericFilter',{key:_vm.query,attrs:{"level":6,"listActive":1,"requiredFields":{
        masterSchool: true,
        school: true,
        year: true,
        progTrainPeriod: true,
        class: true,
        lesson: _vm.requiredLesson,
      }},on:{"onSearchAction":_vm.onSearch}}),_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          },"headers":_vm.appId == 1 ? _vm.headers : _vm.headersKesen,"items":_vm.absences.content,"options":_vm.options,"server-items-length":_vm.absences.elements,"loading":_vm.tableLoader,"show-select":"","item-key":"dailyAbsenceId"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.absDate",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.absDate).toLocaleDateString("el")))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.onStudentView(item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Προβολή")])])]}}],null,true),model:{value:(_vm.selectedAbsences),callback:function ($$v) {_vm.selectedAbsences=$$v},expression:"selectedAbsences"}})],1),_c('div',{staticClass:"custom-card-buttons"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"primary","disabled":_vm.selectedAbsences.length == 0,"loading":_vm.approveLoader},on:{"click":_vm.onMassApprove}},[_vm._v(" Μαζικός έλεγχος ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }