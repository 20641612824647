<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Διαμόρφωση Μενού
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <div class="title d-flex flex-direction-row justify-space-between">
        <h4>
          Ρόλος:<strong>{{ this.role.description }}</strong>
        </h4>
        <v-btn depressed class="mr-2" color="primary" @click="onAddMenu()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <div
        v-for="item in menuRole"
        :key="item.id"
        class="d-flex justify-content-center"
      >
        <v-row>
          <v-col>
            <v-select
              clearable
              outlined
              v-model="item.menuId"
              label="Μενού"
              :items="menus"
              class="mb-4"
              item-text="name"
              item-value="id"
              hide-details="auto"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
            </v-select>
          </v-col>
          <v-col>
            <v-btn depressed color="error" class="mr-2" @click="onDelete(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn depressed class="mr-2" color="primary" @click="onAddMenu()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <hr />
        </v-row>
      </div>
    </v-card-text>
    <div class="d-flex justify-center btn-container">
      <v-btn
        depressed
        color="success"
        class="mr-4"
        type="submit"
        :loading="loader"
        @click="saveMenuRole()"
        >Αποθήκευση</v-btn
      >
      <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
    </div>
    <Loader v-if="this.showLoader" />
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import Loader from "../Loader/Loader.vue";

export default {
  props: ["role"],
  components: {
    Loader,
  },
  async created() {
    try {
      this.showLoader = true;
      await this.getMenuRole();
      await this.getMenus();
      this.showLoader = false;
    } catch (e) {
      console.log(e);
    }
  },
  data() {
    return {
      loader: false,
      title: null,
      content: null,
      roleIds: [],
      notificationCategoryId: null,
      notificationStatusId: null,
      menuRole: [],
      copyMenuRole: [],
      menus: [],
      showLoader: false,
    };
  },
  computed: {
    ...mapState({
      allRoles: (state) => state.roles.allRoles,
      notificationStatus: (state) => state.notifications.notificationStatus,
      notificationCategories: (state) =>
        state.notifications.notificationCategories,
    }),
  },

  methods: {
    ...mapActions([
      "getAllRoles",
      "getNotificationStatus",
      "getNotificationCategories",
      "addNotification",
    ]),

    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          content: this.content,
          notificationCategoryId: this.notificationCategoryId,
          notificationStatusId: this.notificationStatusId,
          roleIds: this.roleIds,
          title: this.title,
        };
        await this.addNotification(content);
        this.$emit("close");
        this.loader = false;
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },
    async getMenuRole() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/menu/roles/list`,
          {
            params: {
              roleId: this.role.id,
            },
          }
        );
        this.menuRole = res.data;
        this.copyMenuRole = JSON.parse(JSON.stringify(res.data));
      } catch (e) {
        throw e;
      }
    },
    async getMenus() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/menu/list`
        );
        this.menus = res.data;
      } catch (e) {
        throw e;
      }
    },
    onDelete(item) {
      this.menuRole = this.menuRole.filter((m) => {
        if (m.id != item.id) {
          return m;
        }
      });
    },
    onAddMenu() {
      let id = 1;
      if (this.menuRole != undefined && this.menuRole.length != 0) {
        id = Math.max.apply(
          Math,
          this.menuRole.map(function (o) {
            return o.id;
          })
        );
      }

      this.menuRole.push({
        roleId: this.role.id,
        menuId: null,
        id: id + 1,
      });
    },
    menuActions() {
      let insertItems = [];
      let updateItems = [];
      let deleteItems = [];

      console.log(this.copyMenuRole);
      deleteItems = JSON.parse(
        JSON.stringify(
          this.copyMenuRole.filter((f) => {
            let exists = false;
            for (var i = 0; i < this.menuRole.length; i++) {
              if (this.menuRole[i].id == f.id) {
                exists = true;
                break;
              }
            }
            if (exists == false) {
              return f.id;
            }
          })
        )
      );

      insertItems = JSON.parse(
        JSON.stringify(
          this.menuRole.filter((f) => {
            let exists = false;
            for (var i = 0; i < this.copyMenuRole.length; i++) {
              if (this.copyMenuRole[i].id == f.id) {
                exists = true;
                break;
              }
            }
            if (exists == false) {
              return f;
            }
          })
        )
      );

      updateItems = JSON.parse(
        JSON.stringify(
          this.menuRole.filter((f) => {
            let updated = false;
            for (var i = 0; i < this.copyMenuRole.length; i++) {
              //   console.log(this.copyMenuRole[i].menuId != f.menuId);
              if (
                this.copyMenuRole[i].id == f.id &&
                this.copyMenuRole[i].menuId != f.menuId
              ) {
                updated = true;
                break;
              }
            }
            if (updated == true) {
              return f;
            }
          })
        )
      );

      try {
        let list = insertItems.map((m) => {
          return {
            menuId: m.menuId,
            roleId: this.role.id,
          };
        });
        if (list.length > 0) {
          this.insertMenuRole(list);
        }
      } catch (error) {
        console.error("on create ", error);
      }

      try {
        let list = [];
        for (var k = 0; k < updateItems.length; k++) {
          let item = {
            id: updateItems[k].id,
            menuId: updateItems[k].menuId,
            roleId: this.role.id,
          };
          list.push(item);
        }
        if (list.length > 0) {
          this.updateMenuRole(list);
        }
      } catch (error) {
        console.error("in update", error);
      }

      try {
        if (deleteItems.length > 0) {
          let list = [];
          deleteItems.forEach((element) => {
            list.push(element.id);
          });
          console.log(list);
          this.deleteMenuRole(list);
        }
      } catch (error) {
        console.error("in remove", error);
      }

      console.log("deleteItems ", deleteItems);
      console.log("updateItems ", updateItems);
      console.log("addItems ", insertItems);
    },
    async saveMenuRole() {
      await this.menuActions();
      this.$emit("close");
    },
    async insertMenuRole(items) {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/menu/roles/create-all`,
        items
      );
    },
    async updateMenuRole(items) {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/menu/roles/update-all`,
        items
      );
    },
    async deleteMenuRole(items) {
      console.log(items);
      await axios.delete(
        `${process.env.VUE_APP_BASE_URL}/menu/roles/delete-all`,
        { data: { ids: items } }
      );
    },
  },
};
</script>


<style scoped lang="scss">
.title {
  border-bottom: 1px solid #00000061;
  margin-bottom: 15px;
  padding: 5px;
}
.btn-container {
  padding: 10px;
  border-top: 1px solid #00000024;
}
</style>