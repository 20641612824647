<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία ρόλου
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="d-flex flex-column pa-4">
      <v-progress-circular
        v-if="cardLoader"
        indeterminate
        color="primary"
        class="align-self-center ma-4"
        :size="50"
      ></v-progress-circular>

      <v-form v-else @submit.prevent="onSubmit" class="pa-4">
        <v-select
          dense
          outlined
          v-model="roleId"
          type="text"
          hide-details="auto"
          class="mb-4"
          :items="getRoleList"
          item-text="description"
          item-value="id"
          :error-messages="roleIdErrors"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Ρόλος
          </template>
        </v-select>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  props: ["userRoles", "user"],
  async created() {
    try {
      await this.getRoles({
        page: 0,
      });
      this.cardLoader = false;
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      cardLoader: true,
      loader: false,
      roleId: "",
    };
  },

  validations: {
    roleId: {
      required,
    },
  },

  computed: {
    ...mapState({
      roles: (state) => state.roles.roles,
    }),
    roleIdErrors() {
      const errors = [];
      if (!this.$v.roleId.$dirty) return errors;
      if (!this.$v.roleId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    getRoleList() {
      let result = this.roles.content.filter((r) => {
        if (!this.userRoles.includes(r.id)) {
          return r;
        }
      });
      return result;
    },
  },

  methods: {
    ...mapActions(["addUserRole", "getRoles"]),
    async onSubmit() {
      try {
        console.log(this.user)
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        await this.addUserRole({
          personId: this.user.id,
          roleId: this.roleId,
        });
        this.loader = false;

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>