var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper d-flex align-center justify-center"},[_c('v-card',{staticClass:"pa-d d-flex flex-column",attrs:{"max-height":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('GenericFilter',{key:_vm.query,attrs:{"level":_vm.level,"requiredFields":{
        masterSchool: true,
        school: true,
        year: true,
        progTrainPeriod: false,
        class: false,
        lesson: false,
      }},on:{"onSearchAction":_vm.onSearch}}),_c('v-card',[_c('v-card-text',{staticClass:"pa-4"},[_vm._l((_vm.data),function(d,i){return _c('v-row',{key:i,staticStyle:{"border-bottom":"1px solid #00000030"}},[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","type":"text","error-messages":_vm.serialNrErrorMessages(d, i),"hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v("Σειρά ")]},proxy:true}],null,true),model:{value:(d.serialNr),callback:function ($$v) {_vm.$set(d, "serialNr", $$v)},expression:"d.serialNr"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","outlined":"","hide-details":"auto","error-messages":_vm.startDateErrorMessages(d, i)},on:{"blur":function($event){d.startDate = _vm.parseDate(d.startDateFormatted, 'startDate')}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-calendar")])]},proxy:true},{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Ημερομηνία έναρξης ")]},proxy:true}],null,true),model:{value:(d.startDateFormatted),callback:function ($$v) {_vm.$set(d, "startDateFormatted", $$v)},expression:"d.startDateFormatted"}})]}}],null,true),model:{value:(d.menu),callback:function ($$v) {_vm.$set(d, "menu", $$v)},expression:"d.menu"}},[_c('v-date-picker',{attrs:{"locale":"el","hide-details":"auto","scrollable":""},on:{"change":function($event){return _vm.onChangeStartDate(i)},"input":function($event){d.menu = false}},model:{value:(d.startDate),callback:function ($$v) {_vm.$set(d, "startDate", $$v)},expression:"d.startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","outlined":"","hide-details":"auto","prepend-inner-icon":"mdi-calendar","error-messages":_vm.endDateErrorMessages(d, i),"clearable":""},on:{"blur":function($event){d.endDate = _vm.parseDate(d.endDateFormatted, 'endDate')}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Ημερομηνία λήξης ")]},proxy:true},{key:"prepend-inner",fn:function(){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-calendar")])]},proxy:true}],null,true),model:{value:(d.endDateFormatted),callback:function ($$v) {_vm.$set(d, "endDateFormatted", $$v)},expression:"d.endDateFormatted"}})]}}],null,true),model:{value:(d.menu1),callback:function ($$v) {_vm.$set(d, "menu1", $$v)},expression:"d.menu1"}},[_c('v-date-picker',{attrs:{"locale":"el","scrollable":"","hide-details":"auto"},on:{"input":function($event){d.menu1 = false},"change":function($event){return _vm.onChangeEndDate(i)}},model:{value:(d.endDate),callback:function ($$v) {_vm.$set(d, "endDate", $$v)},expression:"d.endDate"}})],1)],1),_c('v-col',{staticStyle:{"display":"flex","justify-content":"center"},attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-btn',{staticClass:"mr-1",attrs:{"depressed":"","color":"success"},on:{"click":function($event){return _vm.addDuration(i)}}},[_c('v-icon',[_vm._v("mdi-plus-box")])],1),_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":function($event){return _vm.removeDuration(i)}}},[_c('v-icon',[_vm._v("mdi-minus-box")])],1)],1)],1)}),(_vm.data.length > 0)?_c('v-row',[_c('v-col',{attrs:{"align":"right"}},[_c('v-btn',{attrs:{"color":"success","loading":_vm.saveLoader},on:{"click":_vm.onSubmit}},[_vm._v(" Αποθήκευση ")])],1)],1):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }