import axios from "axios";
import store from "../store";
import Helpers from '../helpers/helpers'

export default {
  state: {
    schools: [],
    currentSchool: JSON.parse(localStorage.getItem("school")) || null,
    trainPrds: [],
    titleIds: [],
    prdTypes: [],
    apops: [],
    allSchools: [],
  },

  mutations: {
    getSchools(state, schools) {
      state.schools = schools;
    },

    setCurrentSchool(state, school) {
      state.currentSchool = school;
      localStorage.setItem("school", JSON.stringify(school));
    },

    getTrainPrds(state, trainPrds) {
      state.trainPrds = trainPrds;
    },

    getTitleIds(state, titleIds) {
      state.titleIds = titleIds;
    },

    getPrdTypes(state, prdTypes) {
      state.prdTypes = prdTypes;
    },

    getApops(state, apops) {
      state.apops = apops;
    },

    getAllSchools(state, schools) {
      state.allSchools = schools;
    },
  },

  actions: {
    async getSchools({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/school/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              masterSchoolId: payload.id,
            },
          }
        );
        commit("getSchools", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async addSchool({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.post(`${process.env.VUE_APP_BASE_URL}/school`, payload);
        // await dispatch("getSchools", {
        //   id: payload.masterSchoolId,
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.schools.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: `Η δημιουργία  ${
            store.state.auth.appId == 1 ? "σχολής" : Helpers.getFieldName(2)
          }  ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async editSchool({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/school/${payload.schoolId}`,
          payload.content
        );
        // await dispatch("getSchools", {
        //   id: payload.content.masterSchoolId,
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.schools.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: `Η επεξεργασία ${
            store.state.auth.appId == 1 ? "σχολής" : Helpers.getFieldName(2)
          }  ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteSchool({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/school/${payload.schoolId}`
        );
        if (state.schools.limit == 1 && state.schools.page != 1) {
          commit("changePage", true);
        } else {
          //   await dispatch("getSchools", {
          //     id: payload.masterSchoolId,
          //     page:
          //       state.schools.limit == 1
          //         ? rootState.base.filters.page - 1
          //         : rootState.base.filters.page,
          //     limit:
          //       rootState.base.filters.itemsPerPage == -1
          //         ? state.schools.elements
          //         : rootState.base.filters.itemsPerPage,
          //   });
        }
        commit("toggleSnackbar", {
          open: true,
          text: `Η διαγραφή ${
            store.state.auth.appId == 1 ? "σχολής" : Helpers.getFieldName(2)
          }  ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getTrainPrds({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/105`
        );
        commit("getTrainPrds", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getTitleIds({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/106`
        );
        commit("getTitleIds", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getPrdTypes({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/107`
        );
        commit("getPrdTypes", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getApops({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/100`
        );
        commit("getApops", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getAllSchools({ commit }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/school/list`,
          {
            params: {
              page: 0,

              masterSchoolId: payload.id,
            },
          }
        );
        commit("getAllSchools", res.data);
      } catch (e) {
        throw e;
      }
    },
  },
};
