<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Σύνδεση εκπαιδευτή
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-select
          dense
          outlined
          v-model="eduClass"
          type="text"
          class="mb-4"
          :items="listClass"
          hide-details="auto"
          item-text="description"
          item-value="cd"
          :error-messages="eduClassErrors"
          :disabled="classId != null"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Τμήμα
          </template>
        </v-select>

        <v-select
          dense
          outlined
          v-model="lesson"
          hide-details="auto"
          type="text"
          class="mb-4"
          :items="listCourse"
          item-text="description"
          item-value="cd"
          :error-messages="lessonErrors"
          :disabled="courseId != null"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Μάθημα
          </template>
        </v-select>

        <v-select
          dense
          outlined
          v-model="teacher"
          type="text"
          hide-details="auto"
          class="mb-4"
          :items="teachers.content"
          :item-text="(el) => el.firstName + ' ' + el.lastName"
          item-value="personId"
          :error-messages="teacherErrors"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Εκπαιδευτικός
          </template>
        </v-select>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  async created() {
    try {
      if (this.classId != null) this.eduClass = this.classId;
      if (this.courseId != null) this.lesson = this.courseId;
      await this.getTeachers({
        id: this.facultyId,
        page: 0,
      });
    } catch (e) {}
  },

  props: ["schoolId"],
  data() {
    return {
      loader: false,
      lesson: null,
      eduClass: null,
      teacher: null,
    };
  },

  validations: {
    lesson: {
      required,
    },
    eduClass: {
      required,
    },

    teacher: {
      required,
    },
  },

  computed: {
    ...mapState({
      teachers: (state) => state.teachers.teachers,
      listClass: (state) => state.generic_filter.listClass,
      listCourse: (state) => state.generic_filter.listCourse,
      facultyId: (state) => state.generic_filter.facultyId,
      semesterId: (state) => state.generic_filter.semesterId,

      classId: (state) => state.generic_filter.classId,
      courseId: (state) => state.generic_filter.courseId,
    }),

    lessonErrors() {
      const errors = [];
      if (!this.$v.lesson.$dirty) return errors;
      if (!this.$v.lesson.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    eduClassErrors() {
      const errors = [];
      if (!this.$v.eduClass.$dirty) return errors;
      if (!this.$v.eduClass.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    teacherErrors() {
      const errors = [];
      if (!this.$v.teacher.$dirty) return errors;
      if (!this.$v.teacher.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addTeacherLesson", "getTeachers"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        var resultLesson;
        var resultTeacher;
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          lessonId: this.lesson,
          personId: this.teacher,
          progTrainPeriodId: this.semesterId,
          eduClassId: this.eduClass,
        };
        try {
          resultLesson = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/attend/lesson`,
            {
              lessonId: content.lessonId,
              personId: content.personId,
              progTrainPeriodId: content.progTrainPeriodId,
            }
          );
        } catch (e) {
          resultLesson = 400;
        }

        console.log(resultLesson);
        try {
          resultTeacher = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/class/teacher`,
            {
              eduClassId: content.eduClassId,
              endDate: content.endDate,
              lessonId: content.lessonId,
              personId: content.personId,
              startDate: content.startDate,
            }
          );
        } catch (e) {
          resultTeacher = 400;
        }

        if (resultLesson == 400 && resultTeacher == 400) {
          this.$store.dispatch("toggleSnackbar", {
            open: true,
            text: `Σφάλμα. παρακαλώ ξαναπροσπαθήστε σε λίγο`,
            color: "error",
          });
        } else {
          this.$emit("close");
        }
        // await this.addTeacherLesson(content);
        this.loader = false;
        await this.$emit("handler");
        // this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>