import axios from "axios";
import store from "../store";

export default {
  state: {
    schedulingSemesters: [],
    absFlags: [],
    actives: [],
    registerFlags: [],

    currentSchedulingSemester:
      JSON.parse(localStorage.getItem("currentSchedulingSemester")) || null,
  },

  mutations: {
    getSchedulingSemesters(state, schedulingSemesters) {
      state.schedulingSemesters = schedulingSemesters;
    },
    //dropdown
    getAbsFlags(state, absFlags) {
      state.absFlags = absFlags;
    },

    getActives(state, actives) {
      state.actives = actives;
    },

    getRegisterFlags(state, registerFlags) {
      state.registerFlags = registerFlags;
    },

    setCurrentSchedulingSemester(state, schedulingSemester) {
      state.currentSchedulingSemester = schedulingSemester;
      localStorage.setItem(
        "currentSchedulingSemester",
        JSON.stringify(schedulingSemester)
      );
    },
  },

  actions: {
    async getSchedulingSemesters({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/prog/train/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              yearId: payload.id,
            },
          }
        );
        commit("getSchedulingSemesters", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async addSchedulingSemester(
      { commit, dispatch, rootState, state },
      payload
    ) {
      try {
        await axios.post(`${process.env.VUE_APP_BASE_URL}/prog/train`, payload);
        await dispatch("getSchedulingSemesters", {
          id: payload.yearId,
          page: rootState.base.filters.page,
          limit:
            rootState.base.filters.itemsPerPage == -1
              ? state.schedulingSemesters.elements
              : rootState.base.filters.itemsPerPage,
        });
        let text =
          store.state.auth.appId == 1
            ? "Η δημιουργία προγραμματισμού εξαμήνου σπουδών ολοκληρώθηκε με επιτυχία"
            : "Η δημιουργία προγραμματισμού κύκλου σπουδών ολοκληρώθηκε με επιτυχία";

        commit("toggleSnackbar", {
          open: true,
          text: text,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async editSchedulingSemester(
      { commit, dispatch, rootState, state },
      payload
    ) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/prog/train/${payload.id}`,
          payload.content
        );
        await dispatch("getSchedulingSemesters", {
          id: payload.content.yearId,
          page: rootState.base.filters.page,
          limit:
            rootState.base.filters.itemsPerPage == -1
              ? state.schedulingSemesters.elements
              : rootState.base.filters.itemsPerPage,
        });
        console.log(store.state.auth.appId);
        let text =
          store.state.auth.appId == 1
            ? "Η επεξεργασία προγραμματισμού εξάμηνου σπουδών ολοκληρώθηκε με επιτυχία"
            : "Η επεξεργασία προγραμματισμού κύκλου σπουδών ολοκληρώθηκε με επιτυχία";
        commit("toggleSnackbar", {
          open: true,
          text: text,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteSchedulingSemester(
      { commit, dispatch, rootState, state },
      payload
    ) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/prog/train/${payload.id}`
        );
        if (
          state.schedulingSemesters.limit == 1 &&
          state.schedulingSemesters.page != 1
        ) {
          commit("changePage", true);
        } else {
          await dispatch("getSchedulingSemesters", {
            id: payload.yearId,
            page: rootState.base.filters.page,
            limit:
              rootState.base.filters.itemsPerPage == -1
                ? state.schedulingSemesters.elements
                : rootState.base.filters.itemsPerPage,
          });
        }
        let text =
          store.state.auth.appId == 1
            ? "Η διαγραφή προγραμματισμού εξαμήνου σπουδών ολοκληρώθηκε με επιτυχία"
            : "Η διαγραφή προγραμματισμού κύκλου σπουδών ολοκληρώθηκε με επιτυχία";
        commit("toggleSnackbar", {
          open: true,
          text: text,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    //dropdowns

    async getAbsFlags({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/120`
        );
        commit("getAbsFlags", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getActives({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/118`
        );
        commit("getActives", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getRegisterFlags({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/119`
        );
        commit("getRegisterFlags", res.data);
      } catch (e) {
        throw e;
      }
    },
  },
};
