var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"component-wrapper d-flex align-center justify-center",attrs:{"color":"transparent"}},[_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('GenericFilter',{attrs:{"level":6,"listActive":1,"requiredFields":{
        masterSchool: true,
        school: true,
        year: true,
        progTrainPeriod: true,
        class: true,
        lesson: true,
      }},on:{"onSearchAction":_vm.onSearch}}),_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          },"headers":_vm.headers,"items":_vm.studentsWithGrades,"options":_vm.options,"server-items-length":_vm.studentGrades.elements,"loading":_vm.tableLoader,"show-select":"","item-key":"person_id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
          var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":!item.isSelectable,"value":item,"hide-details":""},model:{value:(_vm.selectedStudentGrades),callback:function ($$v) {_vm.selectedStudentGrades=$$v},expression:"selectedStudentGrades"}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(item.studentGrades != null)?_c('span',_vm._l((item.studentGrades),function(grade,i){return _c('span',{key:i},[(grade.attendLesson.lesson.id == _vm.courseId)?_c('span',[_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(grade, 'approve')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Έλεγχος")])])],1)]):_vm._e()])}),0):_vm._e()]}}],null,true),model:{value:(_vm.selectedStudentGrades),callback:function ($$v) {_vm.selectedStudentGrades=$$v},expression:"selectedStudentGrades"}})],1),_c('div',{staticClass:"custom-card-buttons"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"success","disabled":_vm.selectedStudentGrades.length == 0,"loading":_vm.approveLoader},on:{"click":_vm.onMassApprove}},[_vm._v(" Μαζικός έλεγχος ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }