<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία αίθουσας
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-2">
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              dense
              outlined
              v-model="facultyId"
              type="text"
              class="mb-4"
              :items="faculties.content"
              hide-details="auto"
              item-text="description"
              item-value="id"
              :disabled="true"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                {{ appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης" }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              dense
              outlined
              v-model="cd"
              hide-details="auto"
              type="text"
              class="mb-4"
              :error-messages="cdErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Κωδικός
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="startDateFormatted"
                  class="mb-4"
                  hide-details="auto"
                  @blur="startDate = parseDate(startDateFormatted, 'startDate')"
                  :error-messages="startDateErrors"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Ημερομηνία έναρξης
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                hide-details="auto"
                v-model="startDate"
                scrollable
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="endDateFormatted"
                  label="Ημερομηνία λήξης"
                  hide-details="auto"
                  prepend-inner-icon="mdi-calendar"
                  class="mb-4"
                  @blur="endDate = parseDate(endDateFormatted, 'endDate')"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="endDate"
                scrollable
                @input="menu1 = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              dense
              outlined
              hide-details="auto"
              v-model="examCapacity"
              type="text"
              class="mb-4"
              :error-messages="examCapacityErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Χωριτηκότητα σε περίοδο εξετάσεων
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              dense
              outlined
              v-model="normalCapacity"
              type="text"
              hide-details="auto"
              class="mb-4"
              :error-messages="normalCapacityErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Χωριτηκότητα κανονικού μαθήματος
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-textarea
              dense
              outlined
              v-model="description"
              type="text"
              hide-details="auto"
              class="mb-4"
              :error-messages="descriptionErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Περιγραφή
              </template>
            </v-textarea>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  async created() {
    try {
      await this.getFaculties({
        page: 0,
      });
    } catch (e) {
      console.log * e;
    }
  },
  data() {
    return {
      menu: false,
      menu1: false,
      loader: false,
      cd: null,
      description: null,
      endDate: null,
      endDateFormatted: null,
      startDate: null,
      startDateFormatted: null,
      examCapacity: null,
      masterSchoolId: null,
      normalCapacity: null,
    };
  },

  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
    },

    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },

  validations: {
    cd: {
      required,
    },

    description: {
      required,
    },

    startDate: {
      required,
    },

    normalCapacity: {
      required,
      numeric,
    },

    examCapacity: {
      required,
      numeric,
    },
  },

  computed: {
    ...mapState({
      faculties: (state) => state.faculties.faculties,
      facultyId: (state) => parseInt(state.generic_filter.facultyId),
      appId: (state) => state.auth.appId,
    }),

    cdErrors() {
      const errors = [];
      if (!this.$v.cd.$dirty) return errors;
      if (!this.$v.cd.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    examCapacityErrors() {
      const errors = [];
      if (!this.$v.examCapacity.$dirty) return errors;
      if (!this.$v.examCapacity.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.examCapacity.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },

    normalCapacityErrors() {
      const errors = [];
      if (!this.$v.normalCapacity.$dirty) return errors;
      if (!this.$v.normalCapacity.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.normalCapacity.numeric)
        errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },

    startDateErrors() {
      const errors = [];
      if (!this.$v.startDate.$dirty) return errors;
      if (!this.$v.startDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addSchoolRoom", "getFaculties"]),

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "startDate") {
          this.startDate = null;
          this.startDateFormatted = null;
        } else if (dateName == "endDate") {
          this.endDate = null;
          this.endDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          cd: this.cd,
          description: this.description,
          examCapacity: this.examCapacity,
          masterSchoolId: this.facultyId,
          normalCapacity: this.normalCapacity,
          startDate: this.startDate,
        };

        if (this.endDate != null) content.endDate = this.endDate;

        await this.addSchoolRoom(content);
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>