<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column justify-center align-center"
    >
      <img src="../../assets/underConstrunction.png" />
    </v-card>
  </div>
</template>

<script>
export default {
  name: "UnderConstruction",
};
</script>