<template>
  <v-card class="d-flex flex-column">
    <v-toolbar color="primary" class="white--text">
      Ώρες διδασκαλίας
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card class="mt-4">
      <v-card-text>
        <v-data-table
          no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
          loading-text="Φόρτωση..."
          :footer-props="{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          }"
          :headers="appId == 1 ? teacherHoursHeaders : teacherHoursHeadersKesen"
          :items="teacherHours.content"
          :server-items-length="teacherHours.elements"
          :options.sync="teacherHoursOptions"
          :loading="teacherHoursTableLoader"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed icon @click="openDialog(item, 'view')">
                  <v-icon color="primary" small v-bind="attrs" v-on="on">
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <span>Προβολή</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-btn depressed color="error" class="ml-auto ma-4" @click="$emit('close')"
      >Επιστροφή
    </v-btn>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from "axios";
export default {
  props: ["item"],
  data() {
    return {
      teacherHours: [],
      teacherHoursTableLoader: false,
      teacherHoursOptions: {
        itemsPerPage: 10,
        page: 1,
      },
      teacherHoursHeaders: [
        {
          text: "Σχολή",
          value: "schoolDescription",
          sortable: false,
        },
        {
          text: "Έτος",
          value: "yearDescription",
          sortable: false,
        },
        {
          text: "Μήνας",
          value: "month",
          sortable: false,
        },
        {
          text: "Σύνολο ωρών",
          value: "totalHours",
          sortable: false,
        },
      ],
      teacherHoursHeadersKesen: [
        {
          text: "Εκπαιδευτικός Κύκλος/Ειδικό Τμήμα",
          value: "schoolDescription",
          sortable: false,
        },
        {
          text: "Έτος",
          value: "yearDescription",
          sortable: false,
        },
        {
          text: "Μήνας",
          value: "month",
          sortable: false,
        },
        {
          text: "Σύνολο ωρών",
          value: "totalHours",
          sortable: false,
        },
      ],
    };
  },

  watch: {
    teacherHoursOptions: {
      async handler() {
        try {
          this.teacherHoursTableLoader = true;
          this.setFilters(this.teacherHour);

          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/teacher/hours/preview`,
            {
              params: {
                page:
                  this.teacherHoursOptions.itemsPerPage == -1
                    ? 0
                    : this.teacherHoursOptions.page,
                limit:
                  this.teacherHoursOptions.itemsPerPage == -1
                    ? null
                    : this.teacherHoursOptions.itemsPerPage,
                personId: this.currentTeacher.id,
                masterSchoolId: this.item.masterSchool?.id,
              },
            }
          );
          this.teacherHours = res.data;
          this.teacherHoursTableLoader = false;
        } catch (e) {
          console.log(e);
          this.teacherHours = [];
          this.$router.dispatch("errorHandler", e);

          this.teacherHoursTableLoader = false;
        }
      },
    },
  },

  computed: {
    ...mapState({
      currentTeacher: (state) => state.teachers.currentTeacher,
      currentRegister: (state) => state.users.currentRegister,
      currentFaculty: (state) => state.faculties.currentFaculty,
      appId: (state) => state.auth.appId,
    }),
  },

  methods: {
    ...mapMutations(["setFilters"]),
  },
};
</script>

<style scoped lang="scss">
</style>