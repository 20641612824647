import errors from "../errors.json";
export default {
  state: {
    errors: null,
  },

  mutations: {
    getErrors(state, errors) {
      state.errors = errors;
    },
  },

  actions: {
    getErrors({ commit }) {
      commit("getErrors", errors);
    },

    errorHandler({ commit, state }, error) {
      console.log('call errorHandler', error?.response?.data?.code)
      if (error?.response?.data?.code ==475|| error?.response?.data?.code == 476) {
        commit("toggleSnackbar", {
          open: true,
          text: `${error?.response?.data?.error}`,
          color: "error",
        });
      } else if (error?.response?.data?.code?.toString() in state.errors) {
        commit("toggleSnackbar", {
          open: true,
          text: `${state.errors[error?.response?.status?.toString()]}`,
          color: "error",
        });
      }
    },
  },
};
