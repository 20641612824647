
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="6"
        :search="true"
        :listActive="1"
        @onSearchAction="onSearch"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: true,
          class: true,
          lesson: requiredLesson,
        }"
      />
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="absences.content"
            :options.sync="options"
            :server-items-length="absences.elements"
            :loading="tableLoader"
          >
            <template v-slot:[`item.absDate`]="{ item }">
              <span>{{ new Date(item.absDate).toLocaleDateString("el") }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onStudentView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary"
            class="mr-2"
            :disabled="!filtersSet"
            @click="openDialog(null, 'add')"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddAbsence
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
        from="1"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="500px"
      scrollable
    >
      <EditAbsence
        v-if="dialog.open && dialog.type == 'edit'"
        :absence="dialog.absence"
        @close="closeDialog"
        from="1"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteAbsence
        v-if="dialog.open && dialog.type == 'delete'"
        :absence="dialog.absence"
        @close="closeDialog"
        :absences="absences"
        @handler="handler"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import AddAbsence from "../../components/AbsencesModals/AddAbsence.vue";
import EditAbsence from "../../components/AbsencesModals/EditAbsence.vue";
import DeleteAbsence from "../../components/AbsencesModals/DeleteAbsence.vue";
import axios from "axios";
export default {
  components: {
    GenericFilter,
    AddAbsence,
    EditAbsence,
    DeleteAbsence,
  },

  async created() {
    try {
      if (this.progTrainPeriodId != null) await this.handler();
    } catch (e) {
      throw e;
    }
  },
  
  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      filtersSet: false,
      absences: [],
      headers: [
        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },

        {
          text: "Ώρες",
          value: "absHours",
          sortable: false,
        },

        {
          text: "Μάθημα",
          value: "lessonDescription",
          sortable: false,
        },

        {
          text: "Ημερομηνία",
          value: "absDate",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        absence: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },
  },

  computed: {
    ...mapState({
      pageChanged: (state) => state.base.pageChanged,
      progTrainPeriodId: (state) => state.generic_filter.semesterId,
      courseId: (state) => state.generic_filter.courseId,
      classId: (state) => state.generic_filter.classId,
      dates: (state) => state.generic_filter.dates,
      searchText: (state) => state.generic_filter.searchText,
      schoolDetails: (state) => state.generic_filter.schoolDetails,
    }),
    requiredLesson() {
      if (this.schoolDetails != null) {
        if (this.schoolDetails.absence) {
          if (this.schoolDetails.absence.cd == "1") {
            return true;
          }
        }
      }
      return false;
    },
  },

  methods: {
    ...mapMutations(["setFilters", "changePage", "setCurrentStudent"]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    onStudentView(student) {
      this.setCurrentStudent(student);
      this.$router.push(
        `/absences/${student.personId}/view_student/atomika_stoixeia`
      );
    },

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/daily/absence/list/view`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              progTrainPeriodId: this.progTrainPeriodId,
              lessonId: this.courseId,
              classId: this.classId,
              absenceDateFrom: this.dates,
              approved: false,
              searchText: this.searchText != "" ? this.searchText : null,
            },
          }
        );

        this.absences = res.data;

        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        this.tableLoader = false;
      } catch (e) {
        this.absences = [];
        this.$store.dispatch("errorHandler", e);

        console.log(e);
        this.tableLoader = false;
      }
    },

    openDialog(absence, type) {
      this.dialog = {
        absence,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        absence: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>