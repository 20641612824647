import axios from 'axios'
export default {
    state: {
        schoolRooms: []
    },

    mutations: {
        getSchoolRooms(state, schoolRooms) {
            state.schoolRooms = schoolRooms
        }
    },

    actions: {
        async getSchoolRooms({ commit, dispatch }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/school/room/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                        masterSchoolId: payload.masterSchoolId
                    }
                })
                commit('getSchoolRooms', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async addSchoolRoom({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/school/room`, payload)
                await dispatch('getSchoolRooms', {
                    masterSchoolId: rootState.generic_filter.facultyId,
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.schoolRooms.elements : rootState.base.filters.itemsPerPage,
                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία αίθουσας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async editSchoolRoom({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/school/room/${payload.schoolRoomId}`, payload.content)
                await dispatch('getSchoolRooms', {
                    masterSchoolId: rootState.generic_filter.facultyId,
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.schoolRooms.elements : rootState.base.filters.itemsPerPage,
                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία αίθουσας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async deleteSchoolRoom({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/school/room/${payload.schoolRoomId}`)
                if (state.schoolRooms.limit == 1 && state.schoolRooms.page != 1) {

                    commit('changePage', true)
                } else {
                    await dispatch('getSchoolRooms', {
                        masterSchoolId: rootState.generic_filter.facultyId,
                        page: rootState.base.filters.page,
                        limit: rootState.base.filters.itemsPerPage == -1 ? state.schoolRooms.elements : rootState.base.filters.itemsPerPage,

                    })
                }
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή προγραμματισμού αίθουσας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

    }
}