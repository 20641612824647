<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Μαζική ενημέρωση
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              v-model="grade"
              hide-details="auto"
              :error-messages="gradeErrors"
              type="number"
              :min="0"
              :max="1"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Βαθμός
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <div class="d-flex justify-center mt-2">
          <v-btn
            depressed
            color="success"
            class="mr-4"
            :loading="loader"
            @click="sumbitForm"
            >Ενημέρωση</v-btn
          >
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "MassiveGrades",
  props: {
    onSubmit: Function,
  },
  data() {
    return {
      grade: null,
      loader: false,
    };
  },
  validations() {
    return {
      grade: {
        required,
        minValue: 0,
        maxValue: 1,
      },
    };
  },
  computed: {
    gradeErrors() {
      const errors = [];
      if (!this.$v.grade.$dirty) return errors;
      if (!this.$v.grade.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    async sumbitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loader = true;
      await this.onSubmit(this.grade);
      this.loader = false;
    },
  },
};
</script>