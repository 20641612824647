var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 d-flex flex-column"},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',{staticClass:"greyish--text"},[_vm._v(" Πορεία πτυχιακής εργασίας ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        },"headers":_vm.poreiaHeaders,"items":_vm.poreiaPtyxiakhsErgasias.content,"server-items-length":_vm.poreiaPtyxiakhsErgasias.elements,"options":_vm.poreiaOptions,"loading":_vm.poreiaTableLoader},on:{"update:options":function($event){_vm.poreiaOptions=$event}},scopedSlots:_vm._u([{key:"item.dateEvent",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.dateEvent).toLocaleDateString("el")))])]}},{key:"item.examiner",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.examiner.firstName + " " + item.examiner.lastName))])]}}],null,true)})],1),(_vm.dialog.type == 'add')?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'add')?_c('AddDegreeEvent',{on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e(),(_vm.dialog.type == 'edit')?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'edit')?_c('EditDegreeEvent',{attrs:{"thesis":_vm.thesis,"degree":_vm.dialog.degree},on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e(),(_vm.dialog.type == 'delete')?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open && _vm.dialog.type == 'delete')?_c('DeleteDegreeEvent',{attrs:{"degree":_vm.dialog.degree},on:{"close":_vm.closeDialog}}):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"d-flex ml-auto"},[(_vm.$route.path.split('/')[1] == 'users')?_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":function($event){return _vm.$router.push(("/users/" + (_vm.currentUser.id) + "/view_student/ptyxiakh_ergasia"))}}},[_vm._v("Επιστροφή ")]):_vm._e(),(_vm.$route.path.split('/')[1] == 'absences')?_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":function($event){return _vm.$router.push(
          ("/absences/" + (_vm.currentUser.id) + "/view_student/ptyxiakh_ergasia")
        )}}},[_vm._v("Επιστροφή ")]):_vm._e(),(_vm.$route.path.split('/')[1] == 'students')?_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":function($event){return _vm.$router.push(
          ("/students/" + (_vm.currentUser.id) + "/view_student/ptyxiakh_ergasia")
        )}}},[_vm._v("Επιστροφή ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }