
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-breadcrumbs :items="breadcrumb_items" divider=">"></v-breadcrumbs>

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="courses.content"
            :server-items-length="courses.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.results`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onViewResults(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary ml-auto"
            @click="openDialog(null, 'add')"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      scrollable
      max-width="1200px"
    >
      <AddCourse
        v-if="dialog.open && dialog.type == 'add'"
        :semesterId="this.$route.query.semesterId"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      scrollable
      max-width="1200px"
    >
      <EditCourse
        v-if="dialog.open && dialog.type == 'edit'"
        :course="dialog.course"
        :semesterId="this.$route.query.semesterId"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteCourse
        v-if="dialog.open && dialog.type == 'delete'"
        :course="dialog.course"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'view'"
      max-width="1500px"
      scrollable
    >
      <ViewCourse
        v-if="dialog.open && dialog.type == 'view'"
        :course="dialog.course"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import AddCourse from "../../../components/CourseModals/AddCourse.vue";
import EditCourse from "../../../components/CourseModals/EditCourse.vue";
import DeleteCourse from "../../../components/CourseModals/DeleteCourse.vue";
import ViewCourse from "../../../components/CourseModals/ViewCourse.vue";
export default {
  components: {
    AddCourse,
    EditCourse,
    DeleteCourse,
    ViewCourse,
  },
  created() {
    this.breadcrumb_items.push(
      {
        text: `${this.$route.query.semesterDescription}`,
        disabled: false,
        link: true,
        exact: true,
        to: `/admin/semester/${this.kindId}`,
      },
      {
        text: `Μαθήματα`,
        disabled: true,
      }
    );
  },
  data() {
    return {
      tableLoader: false,
      search: "",
      breadcrumb_items: [],
      courses: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Κωδικός",
          value: "cd",
          sortable: false,
        },
        {
          text: "Περιγραφή",
          value: "description",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        course: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    options: {
      async handler() {
        try {
          this.setFilters(this.options);
          this.fetchCourses();
        } catch (e) {
          console.log(e);
          this.tableLoader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      currentSemester: (state) => state.semesters.currentSemester,
      // courses: (state) => state.courses.courses,
      pageChanged: (state) => state.base.pageChanged,
      kindId: (state) => state.auth.kindId,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage", "setCurrentCourse"]),
    ...mapActions(["getCourses"]),

    onViewResults(course) {
      this.setCurrentCourse(course);
      this.$router.push(`${this.$route.path}/${course.id}/examresults`);
    },
    openDialog(course, type) {
      this.dialog = {
        course,
        open: true,
        type,
      };
    },
    closeDialog() {
      this.dialog = {
        course: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.fetchCourses();
    },
    async fetchCourses() {
      this.tableLoader = true;
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/lesson/list/prototypes`,
        {
          params: {
            page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
            limit:
              this.options.itemsPerPage == -1
                ? null
                : this.options.itemsPerPage,
            trainPeriodId: this.$route.query.semesterId,
          },
        }
      );
      this.courses = res.data;
      this.tableLoader = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>