
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
    >
      <div style="padding: 10px">
        <h3>Ανακοινώσεις</h3>
      </div>
      <v-card class="mb-4" id="announ">
        <v-card-text class="pa-4">
          <!-- <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="userAnnouncements.content"
            :options.sync="options"
            :server-items-length="userAnnouncements.elements"
            :loading="tableLoader"
            :item-class="'elevation-1'"
          >
            <template v-slot:[`item`]="{ item }">
              <v-expansion-panel>
                <v-expansion-panel-header>Panel 1</v-expansion-panel-header>
                <v-expansion-panel-content>
                  Some content
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-card class="pa-4 my-4 hey" id="announcementCard">
                <v-card-text class="d-flex align-center">
                  <v-icon style="color: #b5b5b5">mdi-bell</v-icon>
                  <v-btn
                    @click="openDialog(item, 'view')"
                    plain
                    text
                    color="black"
                    class="body-1"
                    id="announcementTitle"
                  >
                    <span v-if="item.message.length < 30">{{
                      item.message
                    }}</span>
                    <span v-else>{{ item.message.slice(0, 40) }}...</span>
                  </v-btn>
                  <span class="body-2 ml-auto" id="announcementDate">
                    {{ new Date(item.creationDate).toLocaleDateString("el") }}
                  </span>
                </v-card-text>
              </v-card>
            </template>
            <template slot="expand" slot-scope="props">
              <v-card flat>
                <v-card-text>{{ props }}</v-card-text>
                <v-card-text>Peek-a-boo!</v-card-text>
                <div class="datatable-container"></div>
              </v-card>
            </template>
          </v-data-table> -->
          <v-expansion-panels multiple>
            <v-expansion-panel
              v-for="announcement in userAnnouncements.content"
              :key="announcement.id"
              style="padding: 10px; margin: 10px"
            >
              <v-expansion-panel-header>
                <div style="display: flex; justify-content: space-between">
                  <div>{{ announcement.title }}</div>
                  <div>
                    {{
                      new Date(announcement.creationDate).toLocaleDateString(
                        "el"
                      )
                    }}
                  </div>
                </div></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <div style="width: 80%">
                    <h4 style="color: #000000a1">Περιεχόμενο</h4>
                    <span class="black--text body-1 mb-2">
                      {{ " " + announcement.message }}
                    </span>
                  </div>
                  <div
                    style="
                      border-left: 1px solid #ebf2f6;
                      display: flex;
                      flex-direction: column;
                      padding: 5px;
                      align-items: center;
                      width: 20%;
                    "
                  >
                    <h4 style="color: #000000a1">Aρχεία</h4>
                    <span
                      class="d-flex flex-column align-start"
                      v-if="announcement.attachedFiles != null"
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <v-btn
                        v-for="(file, i) in announcement.attachedFiles"
                        :key="i + file.id"
                        plain
                        color="primary"
                        class="pa-0"
                        @click="onDownload(file.id)"
                        >{{ file.name }}
                        <v-icon color="success">mdi-download</v-icon>
                      </v-btn>
                    </span>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'view'"
        max-width="800px"
        scrollable
      >
        <ViewAnnouncement
          v-if="dialog.open && dialog.type == 'view'"
          @close="closeDialog"
          :announcement="dialog.announcement"
        />
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ViewAnnouncement from "../../components/Announcements/ViewAnnouncement.vue";

export default {
  components: {
    ViewAnnouncement,
  },
  created() {
    this.handler();
  },
  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [],

      dialog: {
        announcement: null,
        open: false,
        type: null,
      },
    };
  },

  computed: {
    ...mapState({
      userAnnouncements: (state) => state.announcements.userAnnouncements,
      schools: (state) => state.schools.schools,
      pageChanged: (state) => state.base.pageChanged,
      userInfo: (state) => state.auth.userInfo,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getUserAnnouncements", "getSchools", "downloadFile"]),

    async onDownload(id) {
      try {
        await this.downloadFile(id);
      } catch (e) {
        console.log(e);
      }
    },
    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        await this.getUserAnnouncements({
          page: 0,
          limit:
            this.options.itemsPerPage == -1
              ? this.userAnnouncements.elements
              : this.options.itemsPerPage,
        });

        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    openDialog(announcement, type) {
      this.dialog = {
        announcement,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        announcement: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.hey {
  cursor: pointer;
}
.hey:hover {
  // background-color: rgba(238, 238, 238, 0.397) !important;
  transition: 0.2s ease;
}
#announcementCard {
  // border: 1px solid #2962ff7a;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;
  color: black;
  background-color: white;
}
#announcementTitle {
  color: black;
  font-weight: 600;
}
#announcementDate {
  color: black;
  font-weight: 600;
}
.v-data-table {
  background-color: #ebf2f6;
}
#announ {
  background-color: #ebf2f6;
}
</style>