<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία ανακοίνωσης
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col>
            <v-select
              v-model="facultyId"
              outlined
              dense
              :disabled="true"
              :items="listFaculty"
              hide-details="auto"
              item-text="description"
              item-value="cd"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                {{ appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης" }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-if="schoolId != null"
              v-model="schoolId"
              outlined
              dense
              :disabled="true"
              :items="listSchool"
              hide-details="auto"
              item-text="description"
              item-value="cd"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                {{ appId == 1 ? "Σχολή" : getFieldName() }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-if="semesterId != null"
              v-model="semesterId"
              outlined
              dense
              :disabled="true"
              hide-details="auto"
              :items="listSemester"
              item-text="description"
              item-value="cd"
              no-data-text="Δεδομένα μη διαθέσιμα"
              :label="
                appId == 1 ? 'Έτος-Εξάμηνο σπουδών' : 'Έτος-κύκλο σπουδών'
              "
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-if="classId != null"
              v-model="classId"
              outlined
              dense
              :disabled="true"
              hide-details="auto"
              :items="listClass"
              item-text="description"
              item-value="cd"
              label="Τμήμα"
              no-data-text="Δεδομένα μη διαθέσιμα"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-if="courseId != null"
              v-model="courseId"
              outlined
              dense
              hide-details="auto"
              :disabled="true"
              :items="listCourse"
              item-text="description"
              item-value="cd"
              label="Μάθημα"
              no-data-text="Δεδομένα μη διαθέσιμα"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="selectedSystemRole != 'TEACHER_ROLE'">
          <v-col>
            <v-select
              dense
              outlined
              v-model="type"
              :items="getDataStaticList(146)"
              hide-details="auto"
              item-text="description"
              item-value="cd"
              no-data-text="Δεδομένα μη διαθέσιμα"
              :error-messages="typeErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Τύπος
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              dense
              outlined
              v-model="flgActive"
              label="Ενεργό"
              hide-details="auto"
              :items="getDataStaticList(100)"
              item-text="description"
              item-value="cd"
              :error-messages="flgActiveErrors"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Ενεργό
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="startDateFormatted"
                  class="mb-4"
                  hide-details="auto"
                  @blur="startDate = parseDate(startDateFormatted, 'startDate')"
                  :error-messages="startDateErrors"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Ημερομηνία έναρξης
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="startDate"
                hide-details="auto"
                scrollable
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="endDateFormatted"
                  label="Ημερομηνία λήξης"
                  hide-details="auto"
                  prepend-inner-icon="mdi-calendar"
                  class="mb-4"
                  @blur="endDate = parseDate(endDateFormatted, 'endDate')"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="endDate"
                hide-details="auto"
                scrollable
                @input="menu1 = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="title"
              class="mb-4"
              hide-details="auto"
              :error-messages="titleErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Τίτλος
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              dense
              outlined
              v-model="message"
              hide-details="auto"
              :error-messages="messageErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Μήνυμα
              </template>
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input
              label="Επισυναπτόμενα αρχεία"
              dense
              outlined
              v-model="attachedFiles"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              multiple
              chips
              small-chips
              :clearable="false"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip close @click:close="deleteChip(index, text)">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import Helpers from "../../helpers/helpers";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  created() {
    if (this.selectedSystemRole == "TEACHER_ROLE") {
      this.type = "2";
    }
  },
  data() {
    return {
      menu: false,
      menu1: false,
      loader: false,

      endDate: null,
      endDateFormatted: null,
      flgActive: null,
      masterSchoolId: null,
      message: null,
      title: null,
      attachedFiles: null,

      startDate: null,
      startDateFormatted: null,
      type: null,
    };
  },

  validations: {
    flgActive: {
      required,
    },

    message: {
      required,
    },
    startDate: {
      required,
    },
    type: {
      required,
    },
    title: {
      required,
    },
  },

  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
    },

    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },

  computed: {
    ...mapState({
      staticList: (state) => state.auth.userInfo.staticView,
      selectedSystemRole: (state) => state.auth.selectedSystemRole,
      kindId: (state) => state.auth.kindId,
      appId: (state) => state.auth.appId,

      facultyId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      academicYearId: (state) => state.generic_filter.academicYearId,
      semesterId: (state) => state.generic_filter.yearSemesterId,
      classId: (state) => state.generic_filter.classId,
      courseId: (state) => state.generic_filter.courseId,

      listFaculty: (state) => state.generic_filter.listFaculty,
      listSchool: (state) => state.generic_filter.listSchool,
      listSemester: (state) => state.generic_filter.listYearSemester,
      listClass: (state) => state.generic_filter.listClass,
      listCourse: (state) => state.generic_filter.listCourse,
    }),

    flgActiveErrors() {
      const errors = [];
      if (!this.$v.flgActive.$dirty) return errors;
      if (!this.$v.flgActive.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      if (!this.$v.message.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    startDateErrors() {
      const errors = [];
      if (!this.$v.startDate.$dirty) return errors;
      if (!this.$v.startDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    typeErrors() {
      const errors = [];
      if (!this.$v.type.$dirty) return errors;
      if (!this.$v.type.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    titleErrors() {
      const errors = [];
      if (!this.$v.title.$dirty) return errors;
      if (!this.$v.title.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addAnnouncement"]),
    getFieldName() {
      return Helpers.getFieldName(1);
    },

    deleteChip(index, text) {
      this.attachedFiles.splice(index, 1);
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "startDate") {
          this.startDate = null;
          this.startDateFormatted = null;
        } else if (dateName == "endDate") {
          this.endDate = null;
          this.endDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        const formData = new FormData();

        formData.set("flgActive", this.flgActive);
        formData.set("masterSchoolId", this.facultyId);
        formData.set("message", this.message);
        formData.set("startDate", this.startDate);
        formData.set("type", this.type);
        formData.set("title", this.title);

        if (this.schoolId != null) formData.set("schoolId", this.schoolId);
        if (this.semesterId != null)
          formData.set("progTrainPeriodId", this.semesterId);
        if (this.classId != null) formData.set("eduClassID", this.classId);
        if (this.courseId != null) formData.set("lessonId", this.courseId);

        if (this.endDate != null) formData.set("endDate", this.endDate);

        if (this.attachedFiles != null) {
          for (let i = 0; i < this.attachedFiles.length; i++) {
            formData.append("attachedFiles", this.attachedFiles[i]);
          }
        }

        await this.addAnnouncement({
          schoolId: this.school,
          masterSchoolId: this.$route.params.master_school_id,
          formData,
        });
        this.loader = false;
        await this.$emit("handler");
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>