<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Σύνδεση με ρόλο
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col>
            <v-select
              v-model="roleId"
              label="Ρόλος"
              dense
              item-text="description"
              item-value="id"
              hide-details="auto"
              :items="roles"
              outlined
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
            </v-select>
          </v-col>
        </v-row>
        <div class="d-flex justify-center btn-container">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
    <Loader v-if="this.showLoader" />
  </v-card>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import Loader from "../Loader/Loader.vue";

export default {
  async created() {
    await this.fetchRoles();
  },
  props: ["report"],
  components: {
    Loader,
  },
  data() {
    return {
      loader: false,
      roles: [],
      roleId: null,
      reportId: null,
      showLoader: false,
    };
  },
  validations: {
    roleId: {
      required,
    },
  },
  computed: {},
  methods: {
    roleErrors() {
      const errors = [];
      if (!this.$v.roleId.$dirty) return errors;
      if (!this.$v.roleId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    async fetchRoles() {
      try {
        this.showLoader = true;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/role/list/view`,
          {
            params: {
              page: 0,
            },
          }
        );
        console.log(res);
        this.roles = res.data.content;

        this.showLoader = false;
      } catch (e) {
        throw e;
      }
    },
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/report/${this.report.id}/add/role`,
          null,
          {
            params: {
              roleId: this.roleId,
            },
          }
        );

        this.loader = false;

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>