var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"primary"}},[_vm._v(" Επεξεργασία ταυτοποίησης "),_c('v-btn',{staticClass:"ml-auto",attrs:{"depressed":"","icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-4"},[_c('v-form',{staticClass:"pa-4",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-select',{staticClass:"mb-4",attrs:{"dense":"","outlined":"","type":"text","error-messages":_vm.typeErrors,"items":_vm.getDataStaticList(101),"item-text":"description","item-value":"cd","no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Τύπος ταυτοποίησης ")]},proxy:true}]),model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","outlined":"","error-messages":_vm.folderNumberErrors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Αριθμός εγγράφου ")]},proxy:true}]),model:{value:(_vm.folderNumber),callback:function ($$v) {_vm.folderNumber=$$v},expression:"folderNumber"}}),(_vm.type == '3')?_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","outlined":""},on:{"blur":function($event){_vm.issueDate = _vm.parseDate(_vm.issueDateFormatted, 'issueDate')}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-calendar")])]},proxy:true},{key:"label",fn:function(){return [_vm._v(" Ημερομηνία έκδοσης ")]},proxy:true}],null,true),model:{value:(_vm.issueDateFormatted),callback:function ($$v) {_vm.issueDateFormatted=$$v},expression:"issueDateFormatted"}})]}}],null,false,3373470419),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"el","scrollable":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.issueDate),callback:function ($$v) {_vm.issueDate=$$v},expression:"issueDate"}})],1):_vm._e(),(_vm.type == '3')?_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","outlined":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Εκδούσα Αρχή ")]},proxy:true}],null,false,895730873),model:{value:(_vm.issuingAuthority),callback:function ($$v) {_vm.issuingAuthority=$$v},expression:"issuingAuthority"}}):_vm._e(),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","outlined":"","error-messages":_vm.startDateErrors},on:{"blur":function($event){_vm.startDate = _vm.parseDate(_vm.startDateFormatted, 'startDate')}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-calendar")])]},proxy:true},{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Ημερομηνία έναρξης ")]},proxy:true}],null,true),model:{value:(_vm.startDateFormatted),callback:function ($$v) {_vm.startDateFormatted=$$v},expression:"startDateFormatted"}})]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":"el","scrollable":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","outlined":""},on:{"blur":function($event){_vm.endDate = _vm.parseDate(_vm.endDateFormatted, 'endDate')}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-calendar")])]},proxy:true},{key:"label",fn:function(){return [_vm._v(" Ημερομηνία λήξης ")]},proxy:true}],null,true),model:{value:(_vm.endDateFormatted),callback:function ($$v) {_vm.endDateFormatted=$$v},expression:"endDateFormatted"}})]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"el","scrollable":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('v-textarea',{attrs:{"dense":"","outlined":"","label":"Σχόλια"},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}}),_c('v-file-input',{attrs:{"label":"Επισυναπτόμενο αρχείο","dense":"","outlined":"","prepend-icon":"","prepend-inner-icon":"mdi-paperclip"},model:{value:(_vm.attachedFile),callback:function ($$v) {_vm.attachedFile=$$v},expression:"attachedFile"}}),(_vm.pistopoihsh.attachedFile != null)?_c('v-btn',{staticClass:"pa-0",attrs:{"plain":"","color":"primary"},on:{"click":function($event){return _vm.onDownload(_vm.pistopoihsh.attachedFile.id)}}},[_vm._v(_vm._s(_vm.pistopoihsh.attachedFile.name)+" "),_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-download")])],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mr-4",attrs:{"depressed":"","color":"error"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Ακύρωση")]),_c('v-btn',{attrs:{"depressed":"","color":"success","type":"submit","loading":_vm.loader}},[_vm._v("Αποθήκευση")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }