var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"primary"}},[_vm._v(" Επεξεργασία εκπαιδευτικού ταξιδιού "),_c('v-btn',{staticClass:"ml-auto",attrs:{"depressed":"","icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-4 d-flex flex-column"},[(_vm.cardLoader)?_c('v-progress-circular',{staticClass:"align-self-center ma-4",attrs:{"indeterminate":"","color":"primary","size":50}}):_c('v-form',{staticClass:"pa-4",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"auto","items":_vm.listSemester,"item-text":"description","item-value":"cd","disabled":true,"no-data-text":"Δεδομένα μη διαθέσιμα"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.appId == 1 ? "Εξάμηνο σπουδών" : "Κύκλο σπουδών")+" ")]},proxy:true}]),model:{value:(_vm.progTrainPeriodId),callback:function ($$v) {_vm.progTrainPeriodId=$$v},expression:"progTrainPeriodId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"outlined":"","disabled":"","dense":"","label":"Σπουδαστής","value":_vm.progTrip.lastName + ' ' + _vm.progTrip.firstName}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"dense":"","outlined":"","hide-details":"auto","error-messages":_vm.dateOfShippingErrors},on:{"blur":function($event){_vm.dateOfShipping = _vm.parseDate(
                    _vm.dateOfShippingFormatted,
                    'dateOfShipping'
                  )}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-calendar")])]},proxy:true},{key:"label",fn:function(){return [_c('span',{staticClass:"error--text"},[_vm._v("*")]),_vm._v(" Ημερομηνία έναρξης ")]},proxy:true}],null,true),model:{value:(_vm.dateOfShippingFormatted),callback:function ($$v) {_vm.dateOfShippingFormatted=$$v},expression:"dateOfShippingFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":"el","scrollable":"","hide-details":"auto"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.dateOfShipping),callback:function ($$v) {_vm.dateOfShipping=$$v},expression:"dateOfShipping"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"dense":"","outlined":"","hide-details":"auto","label":"Ημερομηνία απόλυσης"},on:{"blur":function($event){_vm.dateOfDismissal = _vm.parseDate(
                    _vm.dateOfDismissalFormatted,
                    'dateOfDismissal'
                  )}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-calendar")])]},proxy:true}],null,true),model:{value:(_vm.dateOfDismissalFormatted),callback:function ($$v) {_vm.dateOfDismissalFormatted=$$v},expression:"dateOfDismissalFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"el","scrollable":"","hide-details":"auto"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.dateOfDismissal),callback:function ($$v) {_vm.dateOfDismissal=$$v},expression:"dateOfDismissal"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Συνολική θαλάσσια υπηρεσία σε ημέρες","dense":"","outlined":"","type":"text","error-messages":_vm.totalMaritimeServiceErrors,"hide-details":"auto"},model:{value:(_vm.totalMaritimeService),callback:function ($$v) {_vm.totalMaritimeService=$$v},expression:"totalMaritimeService"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Συνολική θαλάσσια υπηρεσία σε ημέρες")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"dense":"","hide-details":"auto","outlined":""},on:{"blur":function($event){_vm.examDate = _vm.parseDate(_vm.examDateFormatted, 'examDate')}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-calendar")])]},proxy:true},{key:"label",fn:function(){return [_vm._v(" Ημερομηνία εξέτασης ")]},proxy:true}],null,true),model:{value:(_vm.examDateFormatted),callback:function ($$v) {_vm.examDateFormatted=$$v},expression:"examDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"el","hide-details":"auto","scrollable":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.examDate),callback:function ($$v) {_vm.examDate=$$v},expression:"examDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"label":"Αποτέλεσμα εκπαιδευτικού ταξιδιού","dense":"","outlined":"","type":"text","items":_vm.getDataStaticList(144),"hide-details":"auto","item-text":"description","item-value":"cd","no-data-text":"Δεδομένα μη διαθέσιμα"},model:{value:(_vm.educationalTripAssessment),callback:function ($$v) {_vm.educationalTripAssessment=$$v},expression:"educationalTripAssessment"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v("Αποτέλεσμα εκπαιδευτικού ταξιδιού")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","type":"number","min":0,"max":10},on:{"change":_vm.onChangeGrade},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Bαθμός ")]},proxy:true}]),model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{ref:"menu3",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"dense":"","hide-details":"auto","outlined":""},on:{"blur":function($event){_vm.receiveDateKep = _vm.parseDate(
                    _vm.receiveDateKepFormatted,
                    'receiveDateKep'
                  )}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-calendar")])]},proxy:true},{key:"label",fn:function(){return [_vm._v(" Ημερομηνία παραλαβής ΚΕΠ ")]},proxy:true}],null,true),model:{value:(_vm.receiveDateKepFormatted),callback:function ($$v) {_vm.receiveDateKepFormatted=$$v},expression:"receiveDateKepFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"locale":"el","hide-details":"auto","scrollable":""},on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.receiveDateKep),callback:function ($$v) {_vm.receiveDateKep=$$v},expression:"receiveDateKep"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"label":"Μη παρουσίαση για αξιολόγηση","dense":"","outlined":"","type":"text","items":_vm.getDataStaticList(100),"item-text":"description","hide-details":"auto","item-value":"cd","no-data-text":"Δεδομένα μη διαθέσιμα"},model:{value:(_vm.nonPresEval),callback:function ($$v) {_vm.nonPresEval=$$v},expression:"nonPresEval"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v("Μη παρουσίαση για αξιολόγηση")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"label":"Μόνιμη διαγραφή","dense":"","outlined":"","type":"text","items":_vm.getDataStaticList(100),"item-text":"description","item-value":"cd","hide-details":"auto","no-data-text":"Δεδομένα μη διαθέσιμα"},model:{value:(_vm.permanentlyDelete),callback:function ($$v) {_vm.permanentlyDelete=$$v},expression:"permanentlyDelete"}},'v-select',attrs,false),on))]}}])},[_c('span',[_vm._v("Μόνιμη διαγραφή")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"dense":"","outlined":"","hide-details":"auto","label":"Σχόλια","type":"text"},model:{value:(_vm.memo),callback:function ($$v) {_vm.memo=$$v},expression:"memo"}})],1)],1),_c('div',{staticClass:"d-flex justify-center mt-2"},[_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Ακύρωση")]),_c('v-btn',{staticClass:"ml-4",attrs:{"depressed":"","color":"success","type":"submit","loading":_vm.loader}},[_vm._v("Αποθήκευση")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }