<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία μαθήματος
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-card class="mb-4">
          <v-card-title class="mb-4"> Βασικά στοιχεία </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  v-model="cd"
                  type="text"
                  :error-messages="cdErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Κωδικός
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-textarea
                  dense
                  outlined
                  hide-details="auto"
                  v-model="description"
                  :error-messages="descriptionErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Περιγραφή
                  </template>
                </v-textarea>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-textarea
                  dense
                  outlined
                  v-model="shortDescription"
                  hide-details="auto"
                  :error-messages="shortDescriptionErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Σύντομη περιγραφή
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title class="mb-4"> Επιπλέον πληροφορίες </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-select
                  dense
                  outlined
                  v-model="type"
                  type="text"
                  hide-details="auto"
                  :items="getDataStaticList(133)"
                  item-text="description"
                  item-value="cd"
                  :error-messages="typeErrors"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Είδος μαθήματος
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  dense
                  outlined
                  v-model="hoursPerWeek"
                  type="text"
                  hide-details="auto"
                  :error-messages="hoursPerWeekErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Ώρες ανά βδομάδα
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      v-model="labHoursPerWeek"
                      type="text"
                      :error-messages="labHoursPerWeekErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Ώρες εργαστηρίου ανά βδομάδα
                      </template>
                    </v-text-field>
                  </template>
                  <span>Ώρες εργαστηρίου ανά βδομάδα</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  dense
                  outlined
                  v-model="totalHours"
                  hide-details="auto"
                  type="text"
                  :error-messages="totalHoursErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Σύνολο ωρών
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="totalLabHours"
                      type="text"
                      :error-messages="totalLabHoursErrors"
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Σύνολο εργαστηριακών ωρών
                      </template>
                    </v-text-field>
                  </template>
                  <span>Σύνολο εργαστηριακών ωρών</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="examTypeId"
                      type="text"
                      hide-details="auto"
                      :items="getDataStaticList(103)"
                      item-text="description"
                      item-value="cd"
                      :error-messages="examTypeIdErrors"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Είδος εξέτασης μαθήματος
                      </template>
                    </v-select>
                  </template>
                  <span>Είδος εξέτασης μαθήματος</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      v-model="examPeriodNr"
                      type="text"
                      :error-messages="examPeriodNrErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Αριθμός εξεταστικών περιόδων που μπορεί να εξεταστεί
                        κάποιος
                      </template>
                    </v-text-field>
                  </template>
                  <span
                    >Αριθμός εξεταστικών περιόδων που μπορεί να εξεταστεί
                    κάποιος</span
                  >
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  dense
                  outlined
                  v-model="periodicity"
                  type="text"
                  hide-details="auto"
                  :error-messages="periodicityErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Χρόνος επανάληψης
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="examinationTime"
                      type="text"
                      hide-details="auto"
                      :error-messages="examinationTimeErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Μέγιστος χρόνος που μπορεί να οφείλεται (μήνες)
                      </template>
                    </v-text-field>
                  </template>
                  <span>Μέγιστος χρόνος που μπορεί να οφείλεται (μήνες)</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="creditUnits"
                      hide-details="auto"
                      type="text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label> Πιστωτικές Μονάδες </template>
                    </v-text-field>
                  </template>
                  <span> Πιστωτικές Μονάδες</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title class="mb-4"> Απουσίες και βαθμολογίες</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="unjustifiedAbsences"
                      type="text"
                      hide-details="auto"
                      :error-messages="unjustifiedAbsencesErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Σύνολο αδικαιολόγητων απουσιών
                      </template>
                    </v-text-field>
                  </template>
                  <span>Σύνολο αδικαιολόγητων απουσιών </span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  dense
                  outlined
                  v-model="totalAbsences"
                  type="text"
                  hide-details="auto"
                  :error-messages="totalAbsencesErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Σύνολο απουσιών
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="passGrade"
                      type="text"
                      hide-details="auto"
                      :error-messages="passGradeErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Βάση βαθμολογίας μαθήματος
                      </template>
                    </v-text-field>
                  </template>
                  <span>Βάση βαθμολογίας μαθήματος</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      v-model="minGrade"
                      type="text"
                      :error-messages="minGradeErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Ελάχιστο όριο βαθμολογίας μαθήματος
                      </template>
                    </v-text-field>
                  </template>
                  <span>Ελάχιστο όριο βαθμολογίας μαθήματος</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="maxGrade"
                      hide-details="auto"
                      type="text"
                      :error-messages="maxGradeErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Μέγιστο όριο βαθμολογίας μαθήματος
                      </template>
                    </v-text-field>
                  </template>
                  <span>Μέγιστο όριο βαθμολογίας μαθήματος</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-textarea
              dense
              outlined
              v-model="memo"
              label="Σχόλια"
              hide-details="auto"
            >
            </v-textarea>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-checkbox
              class="mt-2 mr-2"
              v-model="flgActive"
              label="Ενεργό μάθημα"
              hide-details="auto"
              true-value="1"
              false-value="0"
            >
            </v-checkbox>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Αποθήκευση</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
export default {
  props: ["course", "semesterId"],
  async created() {
    try {
      this.cardLoader = true;

      this.cd = this.course.cd;
      this.description = this.course.description;
      this.shortDescription = this.course.shortDescription;
      this.flgActive = this.course.flgActive.cd;
      this.creditUnits = this.course?.creditUnits;
      this.hoursPerWeek = this.course.hoursPerWeek;
      this.labHoursPerWeek = this.course.labHoursPerWeek;
      this.totalHours = this.course.totalHours;
      this.totalLabHours = this.course.totalLabHours;
      this.minGrade = this.course.minGrade;
      this.maxGrade = this.course.maxGrade;
      this.passGrade = this.course.passGrade;
      this.examPeriodNr = this.course.examPeriodNr;
      this.examinationTime = this.course.examinationTime;
      this.periodicity = this.course.periodicity;
      this.maxTimeNotPassed = this.course.maxTimeNotPassed;
      this.unjustifiedAbsences = this.course.unjustifiedAbsences;
      this.totalAbsences = this.course.totalAbsences;
      this.type = this.course.lessonType.cd;
      this.examTypeId = this.course.examType.cd;
      this.memo = this.course.memo;
      this.cardLoader = false;
    } catch (e) {
      this.cardLoader = false;
      console.log(e);
    }
  },
  data() {
    return {
      cardLoader: false,
      loader: false,
      cd: null,
      description: null,
      shortDescription: null,
      flgActive: 0,
      hoursPerWeek: null,
      creditUnits: null,
      labHoursPerWeek: null,
      totalHours: null,
      totalLabHours: null,
      minGrade: null,
      maxGrade: null,
      passGrade: null,
      examPeriodNr: null,
      examinationTime: null,
      periodicity: null,
      unjustifiedAbsences: null,
      totalAbsences: null,
      type: null,
      examTypeId: null,
      memo: null,
    };
  },

  validations: {
    cd: {
      required,
    },
    description: {
      required,
    },
    shortDescription: {
      required,
    },
    hoursPerWeek: {
      required,
      numeric,
    },
    labHoursPerWeek: {
      required,
      numeric,
    },
    totalHours: {
      required,
      numeric,
    },
    totalLabHours: {
      required,
      numeric,
    },
    minGrade: {
      required,
      numeric,
    },
    maxGrade: {
      required,
      numeric,
    },
    passGrade: {
      required,
      numeric,
    },
    examPeriodNr: {
      required,
      numeric,
    },
    periodicity: {
      required,
      numeric,
    },
    examinationTime: {
      required,
      numeric,
    },
    unjustifiedAbsences: {
      required,
      numeric,
    },
    totalAbsences: {
      required,
      numeric,
    },
    type: {
      required,
    },
    examTypeId: {
      required,
    },
  },

  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      currentSemester: (state) => state.semesters.currentSemester,
      allCourses: (state) => state.courses.allCourses,
      staticList: (state) => state.auth.userInfo.staticView,
    }),

    cdErrors() {
      const errors = [];
      if (!this.$v.cd.$dirty) return errors;
      if (!this.$v.cd.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    shortDescriptionErrors() {
      const errors = [];
      if (!this.$v.shortDescription.$dirty) return errors;
      if (!this.$v.shortDescription.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    hoursPerWeekErrors() {
      const errors = [];
      if (!this.$v.hoursPerWeek.$dirty) return errors;
      if (!this.$v.hoursPerWeek.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.hoursPerWeek.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    labHoursPerWeekErrors() {
      const errors = [];
      if (!this.$v.labHoursPerWeek.$dirty) return errors;
      if (!this.$v.labHoursPerWeek.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.labHoursPerWeek.numeric)
        errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    totalHoursErrors() {
      const errors = [];
      if (!this.$v.totalHours.$dirty) return errors;
      if (!this.$v.totalHours.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.totalHours.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    totalLabHoursErrors() {
      const errors = [];
      if (!this.$v.totalLabHours.$dirty) return errors;
      if (!this.$v.totalLabHours.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.totalLabHours.numeric)
        errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    minGradeErrors() {
      const errors = [];
      if (!this.$v.minGrade.$dirty) return errors;
      if (!this.$v.minGrade.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.minGrade.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    maxGradeErrors() {
      const errors = [];
      if (!this.$v.maxGrade.$dirty) return errors;
      if (!this.$v.maxGrade.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.maxGrade.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    passGradeErrors() {
      const errors = [];
      if (!this.$v.passGrade.$dirty) return errors;
      if (!this.$v.passGrade.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.passGrade.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    examPeriodNrErrors() {
      const errors = [];
      if (!this.$v.examPeriodNr.$dirty) return errors;
      if (!this.$v.examPeriodNr.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.examPeriodNr.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    periodicityErrors() {
      const errors = [];
      if (!this.$v.periodicity.$dirty) return errors;
      if (!this.$v.periodicity.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.periodicity.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    examinationTimeErrors() {
      const errors = [];
      if (!this.$v.examinationTime.$dirty) return errors;
      if (!this.$v.examinationTime.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.examinationTime.numeric)
        errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    unjustifiedAbsencesErrors() {
      const errors = [];
      if (!this.$v.unjustifiedAbsences.$dirty) return errors;
      if (!this.$v.unjustifiedAbsences.required)
        errors.push("Υποχρεωτικό πεδίο");

      if (!this.$v.unjustifiedAbsences.numeric)
        errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    totalAbsencesErrors() {
      const errors = [];
      if (!this.$v.totalAbsences.$dirty) return errors;
      if (!this.$v.totalAbsences.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.totalAbsences.numeric)
        errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.type.$dirty) return errors;
      if (!this.$v.type.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    examTypeIdErrors() {
      const errors = [];
      if (!this.$v.examTypeId.$dirty) return errors;
      if (!this.$v.examTypeId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions([
      "editCourse",
      "getAllCourses",
      "getExamTypes",
      "getLessonTypes133",
    ]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }

        this.loader = true;

        let content = {
          cd: this.cd,
          description: this.description,
          examPeriodNr: this.examPeriodNr,
          examTypeId: this.examTypeId,
          examinationTime: this.examinationTime,
          flgActive: this.flgActive,
          hoursPerWeek: this.hoursPerWeek,
          labHoursPerWeek: this.labHoursPerWeek,
          lessonTypeId: this.type,
          maxGrade: this.maxGrade,
          minGrade: this.minGrade,
          passGrade: this.passGrade,
          periodicity: this.periodicity,
          shortDescription: this.shortDescription,
          totalAbsences: this.totalAbsences,
          totalHours: this.totalHours,
          totalLabHours: this.totalLabHours,
          trainPeriodId: this.semesterId,
          unjustifiedAbsences: this.unjustifiedAbsences,
        };
        if (this.memo != null) {
          content.memo = this.memo;
        }

        if (this.creditUnits != null) {
          content.creditUnits = this.creditUnits;
        }

        await this.editCourse({
          id: this.course.id,
          content,
        });
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>