<template>
  <v-card class="mt-4">
    <v-card-text>
      <v-data-table
        no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
        loading-text="Φόρτωση..."
        :footer-props="{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        }"
        :headers="appId == 1 ? headersAen : headersKesen"
        :items="userRegisters.content"
        :server-items-length="userRegisters.elements"
        :options.sync="options"
        :loading="loader"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed icon @click="openDialog(item, 'view')">
                <v-icon color="primary" small v-bind="attrs" v-on="on">
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Προβολή</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.startDate`]="{ item }">
          <span>{{ new Date(item.startDate).toLocaleDateString("el") }}</span>
        </template>

        <template v-slot:[`item.mathimata`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed icon @click="openDialog(item, 'mathimata')">
                <v-icon color="primary" small v-bind="attrs" v-on="on">
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Προβολή</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.wres`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                @click="openDialog(item, 'wres_didaskalias')"
              >
                <v-icon color="primary" small v-bind="attrs" v-on="on">
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Προβολή</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'mathimata'"
      max-width="1200px"
      scrollable
    >
      <Mathimata
        v-if="dialog.open && dialog.type == 'mathimata'"
        :gradeProp="dialog.register"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'wres_didaskalias'"
      max-width="1200px"
      scrollable
    >
      <WresDidaskalias
        v-if="dialog.open && dialog.type == 'wres_didaskalias'"
        :item="dialog.register"
        @close="closeDialog"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Mathimata from "./TeacherMathimata.vue";
import WresDidaskalias from "./TeacherWresDidaskalias.vue";
import axios from "axios";
export default {
  components: {
    Mathimata,
    WresDidaskalias,
  },

  data() {
    return {
      loader: false,
      userRegisters: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headersAen: [
        {
          text: "Ημερομηνία απο",
          value: "startDate",
          sortable: false,
        },
        {
          text: "Ημερομηνία έως",
          value: "endDate",
          sortable: false,
        },
        {
          text: `Ακαδημία`,
          value: "masterSchool.description",
          sortable: false,
        },
        {
          text: `Σχολή`,
          value: "school.school.description",
          sortable: false,
        },
        {
          text: "Βαθμίδα",
          value: "teacherCategory.groupType.description",
          sortable: false,
        },
        {
          text: "Ειδικότητα",
          value: "teacherCategory.description",
          sortable: false,
        },

        {
          text: "Μαθήματα",
          value: "mathimata",
          sortable: false,
        },

        // {
        //   text: "Ώρες Διδασκαλίας",
        //   value: "wres",
        //   sortable: false,
        // },
      ],
      headersKesen: [
        {
          text: "Ημερομηνία",
          value: "startDate",
          sortable: false,
        },
        {
          text: `Δομή Μετεκπαίδευσης`,
          value: "masterSchool.description",
          sortable: false,
        },
        {
          text: `Εκπαιδευτικός Κύκλος/Ειδικό Τμήμα`,
          value: "school.school.description",
          sortable: false,
        },
        {
          text: "Κατηγορία",
          value: "propertyType.description",
          sortable: false,
        },

        // {
        //   text: "Είδος εγγραφής",
        //   value: "registrationType.description",
        //   sortable: false,
        // },

        {
          text: "Μαθήματα",
          value: "mathimata",
          sortable: false,
        },

        // {
        //   text: "Ώρες Διδασκαλίας",
        //   value: "wres",
        //   sortable: false,
        // },
      ],
      dialog: {
        register: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    options: {
      async handler() {
        try {
          this.loader = true;
          this.setFilters(this.options);

          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/person/register/list`,
            {
              params: {
                page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                limit:
                  this.options.itemsPerPage == -1
                    ? null
                    : this.options.itemsPerPage,
                personId: this.currentTeacher.id,
                propertyType: 3,
              },
            }
          );
          this.userRegisters = res.data;

          this.loader = false;
        } catch (e) {
          console.log(e);
          this.userRegisters = [];
          this.$router.dispatch("errorHandler", e);

          this.loader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentTeacher: (state) => state.teachers.currentTeacher,
      appId: (state) => state.auth.appId,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "setCurrentRegister"]),

    openDialog(register, type) {
      this.setCurrentRegister(register);
      this.dialog = {
        register,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        register: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>