<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="level"
        @onSearchAction="onSearch"
        :key="query"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: false,
          progTrainPeriod: false,
          class: false,
          lesson: false,
        }"
      />
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="this.appId == 1 ? headersAen : headersKesen"
            :items="!filtersSet ? [] : academicYears.content"
            :server-items-length="academicYears.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.startDate`]="{ item }">
              <span>{{
                new Date(item.startDate).toLocaleDateString("el")
              }}</span>
            </template>

            <template v-slot:[`item.endDate`]="{ item }">
              <span>{{ new Date(item.endDate).toLocaleDateString("el") }}</span>
            </template>

            <template v-slot:[`item.absDate`]="{ item }">
              <span v-if="item.absDate">{{
                new Date(item.absDate).toLocaleDateString("el")
              }}</span>
            </template>

            <template v-slot:[`item.close`]="{ item }">
              <span v-if="item.close == 1"> Ναι </span>
              <span v-else-if="item.close == 0"> Όχι </span>
            </template>

            <template v-slot:[`item.flgActive.cd`]="{ item }">
              <span v-if="item.flgActive.cd == '1'"> Ναι </span>
              <span v-else-if="item.flgActive.cd == '0'"> Όχι </span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>

              <!-- <Audit
                :data="{
                  createdDate: item.creationDate,
                  updatedDate: item.lastUpdateDate,
                  data: [item.createdBy, item.lastUpdatedBy],
                }"
              /> -->
            </template>

            <template v-slot:[`item.academicYears`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.semester`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onSemesterView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary ml-auto"
            @click="openDialog(null, 'add')"
            :disabled="!filtersSet"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddAcademicYear
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
        from="1"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="500px"
      scrollable
    >
      <EditAcademicYear
        v-if="dialog.open && dialog.type == 'edit'"
        :academicYear="dialog.academicYear"
        @close="closeDialog"
        from="1"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteAcademicYear
        v-if="dialog.open && dialog.type == 'delete'"
        :academicYear="dialog.academicYear"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>


<script>
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import AddAcademicYear from "../../components/AcademicYearsModals/AddAcademicYear.vue";
import EditAcademicYear from "../../components/AcademicYearsModals/EditAcademicYear.vue";
import DeleteAcademicYear from "../../components/AcademicYearsModals/DeleteAcademicYear.vue";
// import Audit from "../../components/Audit/Audit.vue";
import axios from "axios";
import { mapState, mapActions } from "vuex";

export default {
  name: "AcademicYear",
  components: {
    GenericFilter,
    AddAcademicYear,
    EditAcademicYear,
    DeleteAcademicYear,
    // Audit,
  },
  async created() {
    try {
      if (this.genericFilter.schoolId != null) await this.handler();
    } catch (e) {
      throw e;
    }
  },

  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      query: false,
      level: 2,
      filtersSet: false,
      headersAen: [
        {
          text: "Ημερομηνία έναρξης",
          value: "startDate",
          sortable: false,
        },
        {
          text: "Ημερομηνία λήξης",
          value: "endDate",
          sortable: false,
        },
        {
          text: "Δημοσιευμένο",
          value: "flgPublish.description",
          sortable: false,
        },
        {
          text: "Κλειστό",
          value: "close",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      headersKesen: [
        // {
        //   text: "Έτος",
        //   value: "year",
        //   sortable: false,
        // },
        {
          text: "Ημερομηνία έναρξης",
          value: "startDate",
          sortable: false,
        },
        {
          text: "Ημερομηνία λήξης",
          value: "endDate",
          sortable: false,
        },
        // {
        //   text: "Ημερομηνία τελευταίας επικύρωσης απουσιών",
        //   value: "absDate",
        //   sortable: false,
        // },
        {
          text: "Δημοσιευμένο",
          value: "flgPublish.description",
          sortable: false,
        },
        {
          text: "Κλειστό",
          value: "close",
          sortable: false,
        },
        {
          text: "Ενεργό",
          value: "flgActive.cd",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      academicYears: [],
      dialog: {
        academicYear: null,
        open: false,
        type: null,
      },
    };
  },
  watch: {
    async options() {
      if (this.genericFilter.schoolId != null) await this.handler();
    },
    $route() {
      this.query = this.$route.params.id;
    },
  },
  computed: {
    ...mapState({
      // academicYears: (state) => state.academic_years.academicYears,
      genericFilter: (state) => state.generic_filter,
      appId: (state) => state.auth.appId,
    }),
  },
  methods: {
    ...mapActions(["getAcademicYears"]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    async handler() {
      try {
        this.tableLoader = true;
        // await this.getAcademicYears({
        //   id: this.genericFilter.schoolId,
        //   page: this.options.page,
        //   limit:
        //     this.options.itemsPerPage == -1
        //       ? this.academicYears.elements
        //       : this.options.itemsPerPage,
        // });
        await this.fetchAcademicYear();
        if (!this.filtersSet) this.filtersSet = true;

        this.filtersSet = true;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.tableLoader = false;
      }
    },
    async fetchAcademicYear() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/year/list`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              schoolId: this.genericFilter.schoolId,
            },
          }
        );
        this.academicYears = res.data;
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
        throw e;
      }
    },
    openDialog(academicYear, type) {
      this.dialog = {
        academicYear,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        academicYear: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.handler();
    },
  },
};
</script>