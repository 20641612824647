<template>
  <v-footer app inset padless absolute>
    <!-- <div>footer</div> -->
  </v-footer>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
</style>