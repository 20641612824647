<template>
  <div>
    <Loader v-if="this.showLoader" />

    <v-card v-else>
      <v-toolbar color="primary" class="white--text" fixed>
        <span>{{ this.currentCommonOnlineApplication.description }}</span>

        <v-btn
          depressed
          icon
          color="white"
          class="ml-auto"
          @click="$emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pa-4">
        <v-form @submit.prevent="onSubmit" class="pa-4" id="form">
          <v-col cols="12" sm="6" md="4">
            <v-select
              label="Προς:"
              :items="listFaculty"
              item-text="description"
              item-value="cd"
              v-model="masterSchoolId"
              readonly
              no-data-text="Δεδομένα μη διαθέσιμα"
            ></v-select>
          </v-col>
          <v-row v-for="(row, i) in totalRows" :key="i" align="center">
            <v-col
              v-for="(input, j) in filteredApplications[i]"
              :key="j"
              :order="input.positionAa"
              :cols="input.fieldSize"
            >
              <span v-if="input.type == '0'" class="body-1">
                <strong>{{ input.label }}</strong>
              </span>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="content[i][j].value"
                    dense
                    outlined
                    v-if="input.type == '1'"
                    :label="input.label"
                    type="text"
                    v-bind="attrs"
                    :disabled="input.defaultValue != undefined ? true : false"
                    hide-details="auto"
                    v-on="on"
                    readonly
                  >
                  </v-text-field>
                </template>
                <span>{{ input.label }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="content[i][j].value"
                    dense
                    outlined
                    v-if="input.type == '2'"
                    :label="input.label"
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                  >
                  </v-text-field>
                </template>
                <span>{{ input.label }}</span>
              </v-tooltip>

              <v-menu
                ref="menu"
                v-model="content[i][j].menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-x
                left
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-if="input.type == '3'"
                    dense
                    outlined
                    v-model="content[i][j].value"
                    :label="input.label"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="el"
                  v-model="content[i][j].value"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    text
                    color="primary"
                    @click="
                      content[i][j].menu = false;
                      content[i][j].value = null;
                    "
                  >
                    Ακύρωση
                  </v-btn>
                  <v-btn
                    dense
                    text
                    color="primary"
                    @click="content[i][j].menu = false"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-checkbox
                v-model="content[i][j].value"
                :label="input.label"
                v-if="input.type == '4'"
                :disabled="input.defaultValue != undefined ? true : false"
                readonly
              >
              </v-checkbox>
<!-- 
              <template v-slot:activator="{ on, attrs }" v-if="input.type == '5' && [null,undefined].includes(content[i][j].attachedFile) && [null,undefined].includes(content[i][j].attachedFile.id)">
                  <v-file-input
                    v-model="content[i][j].value"
                    dense
                    outlined
                    :label="input.label"
                    v-if="input.type == '5'"
                    prepend-inner-icon="mdi-paperclip"
                    :prepend-icon="null"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                  ></v-file-input>
                </template> -->

              <v-btn
                v-if="input.type == '5' && ![null,undefined].includes(content[i][j].attachedFile) && ![null,undefined].includes(content[i][j].attachedFile.id)"
                plain
                color="primary"
                class="pa-0 hiddenItem"
                @click="onDownload(content[i][j].attachedFile.id)"
                >{{ input.label }}
                <v-icon color="success">mdi-download</v-icon>
              </v-btn>

              <v-select
                v-model="content[i][j].value"
                :label="input.label"
                v-if="input.type == '6'"
                dense
                :disabled="input.defaultValue != undefined ? true : false"
                item-text="description"
                item-value="cd"
                hide-details="auto"
                no-data-text="Δεδομένα μη διαθέσιμα"
                :items="
                  fetchDataList(
                    input.editLovId == undefined ? input.lovId : input.editLovId
                  )
                "
                @change="onChangeDataList($event, input)"
                outlined
                readonly
              >
              </v-select>
              <v-textarea
                v-if="input.type == '7'"
                dense
                v-model="content[i][j].value"
                outlined
                :disabled="
                  input.defaultValue != undefined && userInfo.id != 1
                    ? true
                    : false
                "
                :label="input.label"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <div class="d-flex flex-grow-1 align-center justify-center mt-8 p-8 pb-8">
        <v-btn depressed color="error" class="mr-2" @click="$emit('close')">
          Ακύρωση
        </v-btn>
        <v-btn
          depressed
          color="success"
          :loading="loader"
          @click="onPrint"
          v-if="
            application.onlineApplication.commonOnlineApplication.reportId !=
            undefined
          "
        >
          <v-icon>mdi-printer</v-icon>
          Εκτύπωση
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import Loader from "../Loader/Loader.vue";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

export default {
  props: [
    "application",
    "type",
    "onlineApplicationId",
    "faculties",
    "masterSchool",
  ],
  components: {
    Loader,
  },
  async created() {
    this.loadFaculties();
    this.masterSchoolId = this.application.masterSchool.id.toString();

    await this.getCurrentCommonOnlineApplication(
      this.application.onlineApplication.commonOnlineApplication.id
    );

    if (this.masterSchoolId != null) {
      this.onChangeDataList(this.masterSchoolId, { id: 0 });
    }
    try {
      for (let i = 1; i <= this.totalRows; i++) {
        this.filteredApplications[i - 1] =
          this.currentCommonOnlineApplication.commonOnlineApplicationSetupList.filter(
            (el) => el.lineAa == i
          );
      }

      for (let i = 0; i < this.filteredApplications?.length; i++) {
        this.content.push([]);
        for (let j = 0; j < this.filteredApplications[i]?.length; j++) {
          this.content[i].push({
            value: null,
            label: this.filteredApplications[i][j].label, //TODO INPUT FIELDNAME
            menu: this.filteredApplications[i][j].type == "3" ? false : null, //assign value only if input is date
            fieldName: this.filteredApplications[i][j].fieldName, //assign value only if input is date
            commonOnlineApplicationSetupId: this.filteredApplications[i][j].id,
            type: this.filteredApplications[i][j].type,
            defaultValue: this.filteredApplications[i][j].defaultValue,
            id: this.filteredApplications[i][j].id,
          });
          // if (this.filteredApplications[i][j].parentRefId == undefined && this.filteredApplications[i][j].type=="6") {
          //   console.log('fetchData ',this.filteredApplications[i][j].lovId,this.filteredApplications[i][j].label)
          //   this.fetchDataDynamic(this.filteredApplications[i][j].lovId, null);
          // }

          for (
            let index = 0;
            index < this.application.personApplicationDetails?.length;
            index++
          ) {
            if (
              this.application.personApplicationDetails[index]
                .commonOnlineApplicationSetup.id == this.content[i][j].id
            ) {
              this.application.personApplicationDetails[index].value != "null"
                ? (this.content[i][j].value =
                    this.application.personApplicationDetails[index].value)
                : (this.content[i][j].value = "");

              this.content[i][j].attachedFile =
                this.application.personApplicationDetails[index].attachedFile;

              if (this.filteredApplications[i][j].type == "6") {
                console.log(
                  this.filteredApplications[i][j].label,
                  " ",
                  this.filteredApplications[i][j].lovId,
                  " ",
                  this.application.personApplicationDetails[index].value
                );
                this.onChangeDataList(
                  this.application.personApplicationDetails[index].value,
                  this.filteredApplications[i][j]
                );
              }
            }
          }
        }
      }

      this.currentCommonOnlineApplication.commonOnlineApplicationSetupList.forEach(
        (element) => {
          if (Object.prototype.hasOwnProperty.call(element, "editLovId")) {
            this.fetchDataDynamic(element.editLovId, element.parentRefId);
          } else if (Object.prototype.hasOwnProperty.call(element, "lovId")) {
            this.fetchDataDynamic(element.lovId, element.parentRefId);
          }
        }
      );

      console.log("Common Online Application", this.filteredApplications);

      console.log(
        "Application Details",
        this.application.personApplicationDetails
      );

      this.showLoader = false;
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      menu: {},
      loader: false,
      showLoader: true,
      autocompleteLoader: false,
      filteredApplications: [],
      content: [],
      body: [],
      user: null,
      personInfo: null,
      masterSchoolId: null,
      schoolId: null,
      yearId: null,
      semesterId: null,
      lessonId: null,
      dataList: [],
      listFaculty: [],
    };
  },

  computed: {
    ...mapState({
      students: (state) => state.students.students,

      schools: (state) => state.schools.schools,
      academicYears: (state) => state.academic_years.academicYears,
      semesters: (state) => state.semesters.semesters,
      courses: (state) => state.courses.courses,
      userProfile: (state) => state.users.userProfile,
      userInfo: (state) => state.auth.userInfo.person,
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
      currentCommonOnlineApplication: (state) =>
        state.applications.currentCommonOnlineApplication,
    }),

    totalRows: function () {
      return Math.max.apply(
        Math,
        this.currentCommonOnlineApplication.commonOnlineApplicationSetupList.map(
          function (o) {
            return o.lineAa;
          }
        )
      );
    },
  },

  methods: {
    ...mapActions([
      "getStudents",
      "submitApplication",
      "getFaculties",
      "getSchools",
      "getAcademicYears",
      "getSemesters",
      "getCourses",
      "getCurrentCommonOnlineApplication",
      "downloadFile",
    ]),
    async onPrint() {
      try {
        this.loader = true;
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/application/${this.application.id}/print`,
          {
            responseType: "blob",
          }
        );
        console.log(response);

        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: `application/pdf`,
          })
        );
        var fileLink = document.createElement("a");
        let name =
          this.application.onlineApplication.description != undefined
            ? this.application.onlineApplication.description
            : this.application.onlineApplication.commonOnlineApplication
                .description;

        fileLink.href = fileURL;
        fileLink.setAttribute("download", name + ".pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loader = false;
      } catch (e) {
        this.loader = false;
        throw e;
      }
    },
    async loadFaculties() {
      let result = await this.fetchDataDynamic2(2000, null);
      this.listFaculty = result.data;
    },

    async onDownload(id) {
      try {
        await this.downloadFile(id);
      } catch (e) {
        console.log(e);
      }
    },

    fetchDataList(groupId) {
      return this.dataList.filter((m) => {
        if (m.lovGroupId == groupId) {
          return m;
        }
      });
    },
    onChangeDataList(event, item) {
      let fields =
        this.currentCommonOnlineApplication.commonOnlineApplicationSetupList.filter(
          (element) => {
            if (Object.prototype.hasOwnProperty.call(element, "parentRefId")) {
              if (element.parentRefId == item.id) {
                return element;
              }
            }
          }
        );
      console.log("fields ", fields);
      fields.forEach((el) => {
        if (Object.prototype.hasOwnProperty.call(el, "editLovId")) {
          this.dataList = this.dataList.filter((e) => {
            if (e.lovGroupId != el.editLovId) {
              return e;
            }
          });
          this.fetchDataDynamic(el.editLovId, event);
        } else if (Object.prototype.hasOwnProperty.call(el, "lovId")) {
          this.dataList = this.dataList.filter((e) => {
            if (e.lovGroupId != el.lovId) {
              return e;
            }
          });
          this.fetchDataDynamic(el.lovId, event);
        }
      });
    },
    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        // console.log(res.data);
        res.data.forEach((m) => {
          this.dataList.push(m);
        });
      } catch (e) {
        // this.dataList = [];
        throw e;
      }
    },

    async fetchDataDynamic2(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },

    async onSelectStudent(e) {
      await this.getUserDetails(e);
      this.setDefaultValues();
    },
    setDefaultValues() {
      this.showLoader = true;
      this.content.map((c) => {
        let j = c.map((j) => {
          if (j.defaultValue != undefined) {
            j.value = this.personInfo[j.defaultValue];
          }
          return j;
        });
        c = j;
        // return c;
      });
      this.showLoader = false;
    },
    async getUserDetails(personId) {
      try {
        this.showLoader = true;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/store/details/${personId}`
        );
        this.personInfo = res.data;
        this.showLoader = false;
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@page {
  size: 7in 9.25in;
  margin: 27mm 16mm 27mm 16mm;
}
.hiddenItem {
  display: block;
}
.onHide {
  display: none;
}
</style>