import axios from 'axios'
export default {
    state: {
        attendLessons: []
    },

    mutations: {
        getAttendLessons(state, attendLessons) {
            state.attendLessons = attendLessons
        }
    },

    actions: {
        async getAttendLessons({ commit, dispatch }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/attend/lesson/list`,
                    {
                        params: {
                            page: payload.page,
                            limit: payload.limit,
                            progTrainPeriodId: payload.progTrainPeriodId,
                            personId: payload.personId,
                            lessonId: payload.lessonId
                        }
                    })
                commit('getAttendLessons', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },
    }
}