<template>
  <div id="loader">
    <v-progress-circular
      :size="90"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style>
#loader {
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s;
  background-color: rgba(245, 245, 245, 0.7);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>