import axios from 'axios'
export default {
    state: {
        announcements: [],
        userAnnouncements: []
    },

    mutations: {
        getAnnouncements(state, announcements) {
            state.announcements = announcements
        },

        getUserAnnouncements(state, userAnnouncements) {
            state.userAnnouncements = userAnnouncements
        }
    },

    actions: {
        async getAnnouncements({ commit }, filters) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/school/note/list`,
                    {
                        params: {
                            masterSchoolId: filters.masterSchoolId,
                            schoolId: filters.schoolId,
                            page: filters.page,
                            limit: filters.limit,
                            yearId: filters.yearId,
                            progTrainPeriodId: filters.progTrainPeriodId,
                            lessonId: filters.lessonId,
                            classId: filters.classId,
                            personId: filters.personId

                        }
                    })
                commit('getAnnouncements', res.data)

            }
            catch (e) {
                throw e;
            }
        },

        async getUserAnnouncements({ commit }, filters) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/school/note/list/user`, {
                    page: filters.page,
                    limit: filters.limit
                })
                commit('getUserAnnouncements', res.data)

            }
            catch (e) {
                throw e;
            }
        },

        async addAnnouncement({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/school/note`, payload.formData)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία ανακοίνωσης ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async editAnnouncement({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/school/note/${payload.announcementId}`, payload.formData)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία ανακοίνωσης ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async deleteAnnouncement({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/school/note/${payload.announcementId}`)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή ανακοίνωσης ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

    }
}