 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
    >
      <v-row>
        <v-tabs
          vertical
          left
          v-if="
            this.reports.lovValueResponsesSet &&
            this.reports.lovValueResponsesSet.length > 0
          "
        >
          <div class="headerTitle">Κατηγορίες</div>
          <v-tab
            v-for="(row, i) in this.reports.lovValueResponsesSet"
            :key="i"
            >
            <span style="text-transform: capitalize">
              {{row.description}}
            </span>
          </v-tab>

          <v-tab-item
            v-for="(row, i) in this.reports.lovValueResponsesSet"
            :key="i"
          >
            <div class="headerTitle">{{ row.description }}</div>

            <div
              v-for="(rep, j) in getReports(row.id)"
              :key="j"
              class="applicationCard"
            >
              <v-card flat>
                <v-card-text style="font-size: 15px">
                  <a @click="openDialog(rep)">{{ rep.description }}</a>
                </v-card-text>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-row>
    </v-card>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.open"
      max-width="800px"
      scrollable
    >
      <PrintParameterModal :report="dialog.report" @close="closeDialog" />
    </v-dialog>
    <Loader v-if="this.showLoader" />
  </div>
</template>


<script>
import Loader from "../../components/Loader/Loader.vue";
import PrintParameterModal from "../../components/Reports/PrintParameterModal.vue";

import axios from "axios";

export default {
  name: "PrintReports",
  components: {
    Loader,
    PrintParameterModal,
  },
  async created() {
    await this.fetchReports();
  },
  data() {
    return {
      showLoader: false,
      reports: {
        reports: [],
        lovValueResponsesSet: [],
      },

      dialog: {
        open: false,
        report: null,
      },
    };
  },
  computed: {
    getReports() {
      return (catId) =>
        this.reports.reports.filter((r) => {
          if (catId == r.tpy.id) {
            return r;
          }
        });
    },
  },
  methods: {
    async fetchReports() {
      try {
        this.showLoader = true;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/report/list`
        );

        this.reports = res.data;

        this.showLoader = false;
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    openDialog(report) {
      console.log(report);
      this.dialog = {
        open: true,
        report: report,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        report: null,
      };
    },
  },
};
</script>


<style lang="css">
.v-tab--active {
  color: inherit;
  background-color: #2962ff;
  color: white !important;
}
.applicationCard {
  margin: 5px;
  /* border-bottom: 1px solid; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.applicationCard:hover {
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;
}
.v-tabs-items {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.headerTitle {
  display: flex;
  justify-content: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.v-tab {
  display: flex !important;
  justify-content: inherit !important;
}
</style>