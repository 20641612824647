var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.showLoader)?_c('Loader'):_c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"primary","fixed":""}},[_c('span',[_vm._v(_vm._s(this.currentCommonOnlineApplication.description))]),_c('v-btn',{staticClass:"ml-auto",attrs:{"depressed":"","icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-4"},[_c('v-form',{staticClass:"pa-4",attrs:{"id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"label":"Προς:","items":_vm.listFaculty,"item-text":"description","item-value":"cd","readonly":"","no-data-text":"Δεδομένα μη διαθέσιμα"},model:{value:(_vm.masterSchoolId),callback:function ($$v) {_vm.masterSchoolId=$$v},expression:"masterSchoolId"}})],1),_vm._l((_vm.totalRows),function(row,i){return _c('v-row',{key:i,attrs:{"align":"center"}},_vm._l((_vm.filteredApplications[i]),function(input,j){return _c('v-col',{key:j,attrs:{"order":input.positionAa,"cols":input.fieldSize}},[(input.type == '0')?_c('span',{staticClass:"body-1"},[_c('strong',[_vm._v(_vm._s(input.label))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(input.type == '1')?_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":input.label,"type":"text","disabled":input.defaultValue != undefined ? true : false,"hide-details":"auto","readonly":""},model:{value:(_vm.content[i][j].value),callback:function ($$v) {_vm.$set(_vm.content[i][j], "value", $$v)},expression:"content[i][j].value"}},'v-text-field',attrs,false),on)):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(input.label))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(input.type == '2')?_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":input.label,"hide-details":"auto","readonly":""},model:{value:(_vm.content[i][j].value),callback:function ($$v) {_vm.$set(_vm.content[i][j], "value", $$v)},expression:"content[i][j].value"}},'v-text-field',attrs,false),on)):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(input.label))])]),_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-x":"","left":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(input.type == '3')?_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":input.label,"prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":"auto"},model:{value:(_vm.content[i][j].value),callback:function ($$v) {_vm.$set(_vm.content[i][j], "value", $$v)},expression:"content[i][j].value"}},'v-text-field',attrs,false),on)):_vm._e()]}}],null,true),model:{value:(_vm.content[i][j].menu),callback:function ($$v) {_vm.$set(_vm.content[i][j], "menu", $$v)},expression:"content[i][j].menu"}},[_c('v-date-picker',{attrs:{"locale":"el","scrollable":""},model:{value:(_vm.content[i][j].value),callback:function ($$v) {_vm.$set(_vm.content[i][j], "value", $$v)},expression:"content[i][j].value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","text":"","color":"primary"},on:{"click":function($event){_vm.content[i][j].menu = false;
                      _vm.content[i][j].value = null;}}},[_vm._v(" Ακύρωση ")]),_c('v-btn',{attrs:{"dense":"","text":"","color":"primary"},on:{"click":function($event){_vm.content[i][j].menu = false}}},[_vm._v(" OK ")])],1)],1),(input.type == '4')?_c('v-checkbox',{attrs:{"label":input.label,"disabled":input.defaultValue != undefined ? true : false,"readonly":""},model:{value:(_vm.content[i][j].value),callback:function ($$v) {_vm.$set(_vm.content[i][j], "value", $$v)},expression:"content[i][j].value"}}):_vm._e(),(input.type == '5' && ![null,undefined].includes(_vm.content[i][j].attachedFile) && ![null,undefined].includes(_vm.content[i][j].attachedFile.id))?_c('v-btn',{staticClass:"pa-0 hiddenItem",attrs:{"plain":"","color":"primary"},on:{"click":function($event){return _vm.onDownload(_vm.content[i][j].attachedFile.id)}}},[_vm._v(_vm._s(input.label)+" "),_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-download")])],1):_vm._e(),(input.type == '6')?_c('v-select',{attrs:{"label":input.label,"dense":"","disabled":input.defaultValue != undefined ? true : false,"item-text":"description","item-value":"cd","hide-details":"auto","no-data-text":"Δεδομένα μη διαθέσιμα","items":_vm.fetchDataList(
                    input.editLovId == undefined ? input.lovId : input.editLovId
                  ),"outlined":"","readonly":""},on:{"change":function($event){return _vm.onChangeDataList($event, input)}},model:{value:(_vm.content[i][j].value),callback:function ($$v) {_vm.$set(_vm.content[i][j], "value", $$v)},expression:"content[i][j].value"}}):_vm._e(),(input.type == '7')?_c('v-textarea',{attrs:{"dense":"","outlined":"","disabled":input.defaultValue != undefined && _vm.userInfo.id != 1
                    ? true
                    : false,"label":input.label},model:{value:(_vm.content[i][j].value),callback:function ($$v) {_vm.$set(_vm.content[i][j], "value", $$v)},expression:"content[i][j].value"}}):_vm._e()],1)}),1)})],2)],1),_c('div',{staticClass:"d-flex flex-grow-1 align-center justify-center mt-8 p-8 pb-8"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"error"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Ακύρωση ")]),(
            _vm.application.onlineApplication.commonOnlineApplication.reportId !=
            undefined
          )?_c('v-btn',{attrs:{"depressed":"","color":"success","loading":_vm.loader},on:{"click":_vm.onPrint}},[_c('v-icon',[_vm._v("mdi-printer")]),_vm._v(" Εκτύπωση ")],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }