
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
    >
      <GenericFilter
        :level="1"
        @onSearchAction="handler"
        :requiredFields="{
          masterSchool: false,
          school: false,
          year: false,
          progTrainPeriod: false,
          class: false,
          lesson: false,
        }"
      />

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="!filtersSet ? [] : schoolRooms.content"
            :server-items-length="schoolRooms.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-btn depressed color="primary ml-auto" @click="openDialog(null, 'add')">
        Δημιουργία
        <v-icon dense right>mdi-plus</v-icon>
      </v-btn>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="1000px"
      scrollable
    >
      <AddSchoolRoom
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="1000px"
      scrollable
    >
      <EditSchoolRoom
        v-if="dialog.open && dialog.type == 'edit'"
        :schoolRoom="dialog.schoolRoom"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteSchoolRoom
        v-if="dialog.open && dialog.type == 'delete'"
        :schoolRoom="dialog.schoolRoom"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import AddSchoolRoom from "../../components/SchoolRoomModals/AddSchoolRoom.vue";
import EditSchoolRoom from "../../components/SchoolRoomModals/EditSchoolRoom.vue";
import DeleteSchoolRoom from "../../components/SchoolRoomModals/DeleteSchoolRoom.vue";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";

export default {
  components: {
    AddSchoolRoom,
    EditSchoolRoom,
    DeleteSchoolRoom,
    GenericFilter,
  },

  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },

      filtersSet: false,
      headers: [
        {
          text: "Όνομα",
          value: "description",
          sortable: false,
        },
        {
          text: "Κωδικός",
          value: "cd",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        School: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },

    async facultyId() {
      await this.handler();
      this.filtersSet = true;
    },

    deep: true,
  },

  computed: {
    ...mapState({
      schoolRooms: (state) => state.school_rooms.schoolRooms,
      facultyId: (state) => state.generic_filter.facultyId,
    }),

    ...mapGetters(["facultyChanged"]),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage", "clearAllFilter"]),
    ...mapActions(["getSchoolRooms"]),

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        await this.getSchoolRooms({
          masterSchoolId: this.facultyId,
          page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
          limit:
            this.options.itemsPerPage == -1 ? null : this.options.itemsPerPage,
        });
        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    openDialog(schoolRoom, type) {
      this.dialog = {
        schoolRoom,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        schoolRoom: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>