import axios from "axios"

export default {
    state: {
        courses: [],
        currentCourse: JSON.parse(localStorage.getItem('course')) || null,

        //dropdowns     
        examTypes: [],
        lessonTypes133: []

    },

    mutations: {
        getCourses(state, courses) {
            state.courses = courses
        },

        //dropdowns      
        getExamTypes(state, examTypes) {
            state.examTypes = examTypes
        },

        getLessonTypes133(state, lessonTypes) {
            state.lessonTypes133 = lessonTypes
        },

        setCurrentCourse(state, course) {
            state.currentCourse = course
            localStorage.setItem('course', JSON.stringify(course));
        },
    },

    actions: {
        async getCourses({ commit, dispatch }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/lesson/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                        trainPeriodId: payload.trainPeriodId
                    }
                })
                commit('getCourses', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

            }
        },


        async addCourse({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/lesson`, payload)
                await dispatch('getCourses', {
                    trainPeriodId: payload.trainPeriodId,
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.courses.elements : rootState.base.filters.itemsPerPage,

                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία μαθήματος ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async editCourse({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/lesson/${payload.id}`, payload.content)
                await dispatch('getCourses', {
                    trainPeriodId: payload.content.trainPeriodId,
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.courses.elements : rootState.base.filters.itemsPerPage,

                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία μαθήματος ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async deleteCourse({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/lesson/${payload.id}`)
                if (state.courses.limit == 1 && state.courses.page != 1) {
                    commit('changePage', true)
                } else {
                    await dispatch('getCourses', {
                        trainPeriodId: payload.trainPeriodId,
                        page: rootState.base.filters.page,
                        limit: rootState.base.filters.itemsPerPage == -1 ? state.courses.elements : rootState.base.filters.itemsPerPage,

                    })
                }
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή μαθήματος ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        //dropdowns       


        async getExamTypes({ commit }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/value/103`)
                commit('getExamTypes', res.data)
            }
            catch (e) {
                throw e;
            }
        },

        async getLessonTypes133({ commit }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/value/133`)
                commit('getLessonTypes133', res.data)
            }
            catch (e) {
                throw e;
            }
        },

    },
}